import {
  Anchor,
  Button,
  Flex,
  Select,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { isNotEmpty } from "@mantine/form";
import {
  HttpError,
  IResourceComponentsProps,
  useParsed,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/mantine";
import ImageDropZone from "components/common/ImageDropZone";
import { PollOptionCard } from "components/poll_options/show/PollOptionCard";
import { Poll, UpdatePoll } from "entities/poll";
import {
  selectAudiences,
  selectAudienceStatuses,
} from "entities/survey/audience";
import { Link } from "react-router-dom";
import { formatToJst } from "utils/dates";

export const PollEdit: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParsed();
  const {
    getInputProps,
    saveButtonProps,
    setFieldValue,
    values,
    refineCore: { queryResult },
  } = useForm<Poll, HttpError, UpdatePoll>({
    initialValues: {
      title: "",
      description: "",
      image: "",
      active_from: new Date(),
      active_to: new Date(),
      audience: "tt",
      audience_state: "active",
    },
    validate: {
      title: isNotEmpty(),
      description: isNotEmpty(),
      active_from: isNotEmpty(),
      active_to: isNotEmpty(),
      audience: isNotEmpty(),
      audience_state: isNotEmpty(),
      image: isNotEmpty(),
    },
    transformValues: (values) => {
      return {
        ...values,
        active_from: formatToJst(values.active_from),
        active_to: formatToJst(values.active_to),
      };
    },
    refineCoreProps: {
      queryOptions: {
        select: (data) => {
          const activeFrom = data.data.active_from;
          const activeTo = data.data.active_to;

          return {
            data: {
              ...data.data,
              active_from: new Date(activeFrom),
              active_to: new Date(activeTo),
            },
          };
        },
      },
    },
  });

  const poll = queryResult?.data?.data;

  if (!poll) return <div />;

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <ImageDropZone
        onFileChosen={(data) => setFieldValue("image", data)}
        {...getInputProps("image")}
      />
      <TextInput label="Title" {...getInputProps("title")} />
      <Textarea label="Description" {...getInputProps("description")} />
      <Select
        placeholder="Select audience"
        data={selectAudiences}
        label="Audience"
        clearable={false}
        withAsterisk
        {...getInputProps("audience")}
      />
      <Select
        placeholder="Select audience state"
        data={selectAudienceStatuses}
        label="Audience state"
        clearable={false}
        withAsterisk
        {...getInputProps("audience_state")}
      />
      <DatePicker
        placeholder="Pick dates range"
        maw={400}
        label="Active from"
        defaultValue={new Date()}
        withinPortal
        {...getInputProps("active_from")}
      />
      <DatePicker
        placeholder="Pick dates range"
        maw={400}
        label="Active to"
        defaultValue={new Date()}
        withinPortal
        {...getInputProps("active_to")}
      />
      <Title mt="md" order={3}>
        Options
      </Title>
      {poll.options.map((option) => (
        <PollOptionCard option={option} />
      ))}
      <Flex justify="center">
        <Anchor component={Link} to={`/poll_options/create/${id}`}>
          <Button variant="gradient">Add Option</Button>
        </Anchor>
      </Flex>
    </Edit>
  );
};
