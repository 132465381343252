import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useBack,
  useCustomMutation,
  useShow,
} from "@refinedev/core";
import {
  DeleteButton,
  EditButton,
  ListButton,
  RefreshButton,
  Show,
} from "@refinedev/mantine";
import { Button, Flex, Tabs } from "@mantine/core";
import {
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import { User } from "entities/user";
import useUsersPermissions from "./useUsersPermissions";
import {
  IconBandage,
  IconBuildingHospital,
  IconCoin,
  IconCoinOff,
  IconList,
  IconLogin,
  IconPennant,
  IconPennantOff,
} from "@tabler/icons";

type RunSanityChecksResponse = {
  success: boolean;
  error?: string;
};

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { canCreate, canDelete, canEdit } = useUsersPermissions();
  const location = useLocation();
  const navigate = useNavigate();
  const back = useBack();

  const pathParts = location.pathname.split("/");
  const tab = pathParts[pathParts.length - 1];
  const { id } = useParams();

  const { queryResult } = useShow<User, HttpError>({
    resource: "users",
    id: id,
  });

  const { data, isLoading } = queryResult;
  const user = data?.data as User;

  const apiUrl = useApiUrl();
  const { mutate: runSanityChecks, isLoading: isRunningSanityChecks } =
    useCustomMutation<RunSanityChecksResponse>();

  return (
    <Show
      isLoading={isLoading}
      canEdit={canEdit}
      canDelete={canDelete}
      resource="users"
      headerButtons={
        <Flex gap="sm">
          {user && (
            <Button
              variant="outline"
              leftIcon={<IconBandage />}
              onClick={() =>
                runSanityChecks(
                  {
                    url: `${apiUrl}/users/${id}/run_sanity_checkers`,
                    method: "post",
                    values: {
                      user_id: id,
                    },
                  },
                  {
                    onSuccess: () => {
                      navigate(`/sanity_checkers`);
                    },
                  },
                )
              }
              loading={isRunningSanityChecks}
            >
              Run Sanity Checkers
            </Button>
          )}
          <ListButton resource="users" />
          <RefreshButton />
          {user && (
            <>
              <EditButton resource="users" recordItemId={user.id} />
              <DeleteButton resource="users" recordItemId={user.id} />
            </>
          )}
        </Flex>
      }
    >
      <Tabs
        value={tab}
        onTabChange={(tabValue) => navigate(`/users/show/${id}/${tabValue}`)}
      >
        <Tabs.List>
          <Tabs.Tab value="details" icon={<IconList />}>
            Details
          </Tabs.Tab>
          <Tabs.Tab value="xp_earnings" icon={<IconPennant />}>
            XP Earnings
          </Tabs.Tab>
          <Tabs.Tab value="xp_reductions" icon={<IconPennantOff />}>
            XP Reductions
          </Tabs.Tab>
          <Tabs.Tab value="coin_earnings" icon={<IconCoin />}>
            Coin Earnings
          </Tabs.Tab>
          <Tabs.Tab value="coin_spendings" icon={<IconCoinOff />}>
            Coin Spendings
          </Tabs.Tab>
          <Tabs.Tab value="user_sanity_check_issues" icon={<IconBandage />}>
            Sanity Check Issues
          </Tabs.Tab>
          <Tabs.Tab value="login_activities" icon={<IconLogin />}>
            Login Activities
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={tab}>
          <Outlet context={{ user }} />
        </Tabs.Panel>
      </Tabs>
    </Show>
  );
};
