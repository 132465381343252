import { Flex, Avatar, Text } from "@mantine/core";
import { User } from "entities/user";

export const DetailsUser = ({ user }: { user: User }) => {
  return (
    <Flex my="lg" gap="lg">
      <Flex direction="column">
        <Text size="lg" color="orange">
          {user?.email}
        </Text>
        <Flex gap="xs" justify={"flex-end"}>
          <Text size="sm">{user?.first_name}</Text>
          <Text size="sm">{user?.last_name}</Text>
        </Flex>
      </Flex>
      <Avatar src={user?.image} size="lg" />
    </Flex>
  );
};
