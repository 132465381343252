import { usePermissions as refineUsePermissions } from "@refinedev/core";

type GiftCardCampaignsPermissions = {
    canCreate: boolean,
    canEdit: boolean,
    canDelete: boolean,
}

export default function useUsersPermissions() : GiftCardCampaignsPermissions {
    const { data: role  } = refineUsePermissions<string | null>();

    const canCreate = !!role && role === "admin";
    const canEdit = !!role && ["admin", "staff"].includes(role)
    const canDelete = !!role && role === "admin"

    return { canCreate, canEdit, canDelete }
}
