import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  Flex,
  Select,
  LoadingOverlay,
  Image,
} from "@mantine/core";
import {
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
  TextField,
} from "@refinedev/mantine";
import { Badge } from "@mantine/core";

import type { Header } from "@tanstack/react-table";
import { DateRangePicker } from "@mantine/dates";
import useGiftCardTemplatesPermissions from "./useGiftCardTemplatesPermissions";
import { GiftCardTemplate } from "entities/gift_card_template";
import {
  colorsByChannel,
  selectChannels,
} from "entities/gift_card_template/channel";
import {
  colorsByConstraint,
  selectConstraints,
} from "entities/gift_card_template/constraint";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";

export const GiftCardTemplateList: React.FC<IResourceComponentsProps> = () => {
  const { canCreate, canEdit, canDelete } = useGiftCardTemplatesPermissions();

  const columns = React.useMemo<ColumnDef<GiftCardTemplate>[]>(
    () => [
      {
        id: "id",
        accessorKey: "id",
        header: "Id",
      },
      {
        id: "initial_value",
        accessorKey: "initial_value",
        header: "Initial Value",
        cell: ({ getValue }) => {
          const value = getValue<GiftCardTemplate["initial_value"]>();

          return (
            <Badge size="xl" color="grape">
              ${value}
            </Badge>
          );
        },
      },
      {
        id: "image",
        accessorKey: "image",
        header: "Image",
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          const imageUrl = getValue<GiftCardTemplate["image"]>().url;

          return (
            <Image
              src={imageUrl}
              radius="md"
              alt="Gift Card Background Image"
              height={140}
              width={200}
              withPlaceholder
            ></Image>
          );
        },
      },
      {
        id: "channel",
        accessorKey: "channel",
        header: "Channel",
        cell: ({ getValue }) => {
          const channel = getValue<GiftCardTemplate["channel"]>();
          return (
            <Badge variant="filled" color={colorsByChannel[channel]}>
              {channel}
            </Badge>
          );
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<GiftCardTemplate, any>;
          }) => {
            return (
              <div>
                <Select
                  placeholder="Channel"
                  data={selectChannels}
                  onChange={header.column.setFilterValue}
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "constraint",
        accessorKey: "constraint",
        header: "Constraint",
        cell: ({ getValue }) => {
          const constraint = getValue<GiftCardTemplate["constraint"]>();
          return (
            <Badge variant="filled" color={colorsByConstraint[constraint]}>
              {constraint}
            </Badge>
          );
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<GiftCardTemplate, any>;
          }) => {
            return (
              <div>
                <Select
                  placeholder="Constraint"
                  data={selectConstraints}
                  onChange={header.column.setFilterValue}
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "notes",
        accessorKey: "notes",
        header: "Notes",
        cell: function render({ getValue }) {
          return <TextField value={getValue<any>()} />;
        },
        meta: {
          filterOperator: "contains",
        },
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "Created At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<GiftCardTemplate, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "updated_at",
        accessorKey: "updated_at",
        header: "Updated At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<GiftCardTemplate, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "actions",
        cell: function render({ row: { original: giftCardTemplate } }) {
          return (
            <Flex columnGap={4}>
              <ShowButton hideText recordItemId={giftCardTemplate.id} />
              {canEdit && (
                <EditButton hideText recordItemId={giftCardTemplate.id} />
              )}
              {canDelete && (
                <DeleteButton hideText recordItemId={giftCardTemplate.id} />
              )}
            </Flex>
          );
        },
        meta: {
          isPlaceholder: true,
        },
      },
    ],
    [canCreate, canEdit, canDelete]
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  setOptions((prev) => ({
    ...prev,
    meta: {
      ...prev.meta,
    },
  }));

  return (
    <List canCreate={canCreate}>
      <AppliedFilters<GiftCardTemplate> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<GiftCardTemplate> headerGroups={getHeaderGroups()} />
          <Rows<GiftCardTemplate> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
