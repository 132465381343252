import {
  Container,
  Flex,
  Grid,
  Pagination,
  Tabs,
  Text,
  Title,
} from "@mantine/core";
import { Tab } from "@mantine/core/lib/Tabs/Tab/Tab";
import {
  HttpError,
  useApiUrl,
  useCustomMutation,
  useDelete,
  useList,
} from "@refinedev/core";
import { CoinSpendingProblemCard } from "components/sanity_checkers/list/CoinSpendingProblemCard";
import { LoyaltyOrderProblemCard } from "components/sanity_checkers/list/LoyaltyOrderProblemCard";
import { CoinSpending } from "entities/coin_spending";
import { CoinSpendingProblem } from "entities/coin_spending_problem";
import { LoyaltyOrderProblem } from "entities/loyalty_order_problem";
import { ProblemType, problemTypes } from "entities/sanity_checker";
import { UserOutletContext } from "entities/user";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";

const PAGE_SIZE = 10;

export function UserSanityCheckIssues() {
  const [loyaltyOrderProblemsPage, setLoyaltyOrderProblemsPage] = useState(1);
  const [coinSpendingProblemsPage, setCoinSpendingProblemsPage] = useState(1);
  const [problemsTab, setProblemsTab] = useState<ProblemType>(
    "coin_spending_problem",
  );
  const userOutletCtx = useOutletContext<UserOutletContext | null>();

  const {
    data: loyaltyOrdersProblemsData,
    refetch: refetchLoyaltyOrderProblems,
    isError: isErrorFetchingLoyaltyOrderProblems,
    isLoading: isLoadingLoyaltyOrderProblems,
  } = useList<LoyaltyOrderProblem, HttpError>({
    resource: `users/${userOutletCtx?.user?.id}/loyalty_order_problems`,
    queryOptions: {
      enabled: !!userOutletCtx?.user,
      keepPreviousData: true,
    },
    pagination: {
      pageSize: PAGE_SIZE,
      current: loyaltyOrderProblemsPage,
    },
  });

  const {
    data: coinSpendingProblemsData,
    refetch: refetchCoinSpendingProblems,
    isError: isErrorFetchingCoinSpendingProblems,
    isLoading: isLoadingCoinSpendingProblems,
  } = useList<CoinSpendingProblem, HttpError>({
    resource: `users/${userOutletCtx?.user?.id}/coin_spending_problems`,
    queryOptions: {
      enabled: !!userOutletCtx?.user,
      keepPreviousData: true,
    },
    pagination: {
      pageSize: PAGE_SIZE,
      current: coinSpendingProblemsPage,
    },
  });

  const { mutate: autoFixProblem } = useCustomMutation<
    LoyaltyOrderProblem | CoinSpendingProblem
  >();
  const { mutate: deleteProblem } = useDelete();
  const apiUrl = useApiUrl();

  if (
    isErrorFetchingLoyaltyOrderProblems ||
    isErrorFetchingCoinSpendingProblems
  )
    return <Text>Something went wrong</Text>;

  if (isLoadingLoyaltyOrderProblems || isLoadingCoinSpendingProblems)
    return <Text>Loading...</Text>;

  const refetch = () => {
    if (problemsTab === "loyalty_order_problem") {
      refetchLoyaltyOrderProblems();
    } else if (problemsTab === "coin_spending_problem") {
      refetchCoinSpendingProblems();
    }
  };

  const handleFixProblem = (
    problem: LoyaltyOrderProblem | CoinSpendingProblem,
  ) => {
    autoFixProblem(
      {
        url: `${apiUrl}/users/${userOutletCtx?.user?.id}/${problemsTab}s/${problem.id}/auto_fix`,
        method: "post",
        values: {},
      },
      {
        onSuccess: () => refetch(),
      },
    );
  };

  const handleDeleteProblem = (
    problem: LoyaltyOrderProblem | CoinSpendingProblem,
  ) => {
    deleteProblem(
      {
        resource: `sanity_checkers/${userOutletCtx?.user?.id}/${problemsTab}s`,
        id: problem.id,
      },
      {
        onSuccess: () => refetch(),
      },
    );
  };

  const loyaltyOrderProblems = loyaltyOrdersProblemsData?.data ?? [];
  const coinSpendingProblems = coinSpendingProblemsData?.data ?? [];

  return (
    <Grid bg="gray.1">
      <Grid.Col offset={2} span={8}>
        <Title order={3} my="sm" color="gray.9">
          Sanity Check Issues
        </Title>
        <Tabs
          sx={{ alignSelf: "stretch" }}
          color="red"
          value={problemsTab}
          onTabChange={(value) => value && setProblemsTab(value as ProblemType)}
        >
          <Tabs.List grow>
            <Tabs.Tab value="loyalty_order_problem">Loyalty Order</Tabs.Tab>
            <Tabs.Tab value="coin_spending_problem">Coin Spending</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="loyalty_order_problem" py="sm">
            <Flex direction="column" gap="sm">
              {loyaltyOrderProblems.map((problem: LoyaltyOrderProblem) => {
                return (
                  <LoyaltyOrderProblemCard
                    problem={problem}
                    fixProblem={handleFixProblem}
                    deleteProblem={handleDeleteProblem}
                  />
                );
              })}
            </Flex>
            {loyaltyOrdersProblemsData?.total && (
              <Pagination
                position="right"
                total={loyaltyOrdersProblemsData.total / PAGE_SIZE}
                page={loyaltyOrderProblemsPage}
                onChange={setLoyaltyOrderProblemsPage}
                my="sm"
              />
            )}
          </Tabs.Panel>
          <Tabs.Panel value="coin_spending_problem" py="sm">
            <Flex direction="column" gap="sm">
              {coinSpendingProblems.map((problem: CoinSpendingProblem) => {
                return (
                  <CoinSpendingProblemCard
                    problem={problem}
                    fixProblem={handleFixProblem}
                    deleteProblem={handleDeleteProblem}
                  />
                );
              })}
            </Flex>
            {coinSpendingProblemsData?.total && (
              <Pagination
                position="right"
                total={coinSpendingProblemsData.total / PAGE_SIZE}
                page={coinSpendingProblemsPage}
                onChange={setCoinSpendingProblemsPage}
                my="sm"
              />
            )}
          </Tabs.Panel>
        </Tabs>
      </Grid.Col>
    </Grid>
  );
}
