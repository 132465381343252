import _ from "lodash";

export type Destination = "chargebee" | "shopify"

export const destinations: Destination[] = ["chargebee", "shopify"];

export const selectDestinations = destinations.map((destination) => {
  return { value: destination, label: destination.replaceAll("_", " ").toUpperCase() };
});

export const colorsByDestination: Record<Destination, string> = {
  chargebee: "blue",
  shopify: "green",
};
