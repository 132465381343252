import { Badge, Flex, Grid, Image, Tabs, Text } from "@mantine/core";
import { HttpError, IResourceComponentsProps, useShow } from "@refinedev/core";
import { DateField, NumberField, Show, TextField } from "@refinedev/mantine";
import { DetailsCard } from "components/common/show/DetailsPair";
import { History } from "components/common/show/History";

import { PollOptionCard } from "components/poll_options/show/PollOptionCard";
import { Poll } from "entities/poll";
import { colorsByStatus } from "entities/poll/status";
import {
  colorsByAudience,
  colorsByAudienceState,
} from "entities/survey/audience";
import { PollAnswerList } from "./show/Answers";

export const PollShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<Poll, HttpError>();
  const { data, isLoading } = queryResult;
  const poll = data?.data;

  if (!poll) return <Text>Poll Not Found</Text>;

  return (
    <Show isLoading={isLoading}>
      <Tabs defaultValue="details">
        <Tabs.List>
          <Tabs.Tab value="details">Details</Tabs.Tab>
          <Tabs.Tab value="answers">Answers</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="details">
          <Grid mt="md">
            <Grid.Col span={8}>
              <DetailsCard title="options">
                {poll.options.map((option) => {
                  return <PollOptionCard option={option} />;
                })}
              </DetailsCard>
              {poll?.id && (
                <History<Poll> resources={"polls"} entityId={poll.id} />
              )}
            </Grid.Col>
            <Grid.Col span={4}>
              <Flex direction="column" gap="sm">
                <DetailsCard title="Image">
                  <Image
                    sx={{ maxWidth: 200, maxHeight: 200 }}
                    src={poll?.image}
                  />
                </DetailsCard>
                <DetailsCard title="Status">
                  <Badge color={colorsByStatus[poll.status]}>
                    {poll.status}
                  </Badge>
                </DetailsCard>
                <DetailsCard title="Id">
                  <NumberField value={poll?.id ?? ""} />
                </DetailsCard>
                <DetailsCard title="Title">
                  <TextField value={poll?.title} />
                </DetailsCard>
                <DetailsCard title="Active From">
                  <DateField
                    color="green"
                    weight="bold"
                    value={poll?.active_from}
                  />
                </DetailsCard>
                <DetailsCard title="Active To">
                  <DateField
                    color="green"
                    weight="bold"
                    value={poll?.active_to}
                  />
                </DetailsCard>
                <DetailsCard title="Audience">
                  <Badge color={colorsByAudience[poll?.audience]}>
                    {poll?.audience}
                  </Badge>
                </DetailsCard>
                <DetailsCard title="Audience State">
                  <Badge color={colorsByAudienceState[poll?.audience_state]}>
                    {poll?.audience_state}
                  </Badge>
                </DetailsCard>
              </Flex>
            </Grid.Col>
          </Grid>
        </Tabs.Panel>
        <Tabs.Panel value="answers">
          <PollAnswerList />
        </Tabs.Panel>
      </Tabs>
    </Show>
  );
};
