import { HttpError } from "@refinedev/core";
import axios from "axios";
import Qs from "qs"


const axiosInstance = axios.create({
  params: {
    api_key: process.env.REACT_APP_CP_API_TOKEN,
  },
  paramsSerializer: (params) => {
    return Qs.stringify(params, { arrayFormat: 'indices' })
  },
});


axiosInstance.interceptors.request.use(
    (req) => {
        const token = localStorage.getItem("ichigo_admin_token")

        if (token) {
            req.headers = { ...req.headers, "X-Auth-Token" : token, "Content-Type": "application/json" }
        }

        return req
    }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let errors = "Unknown error"
    if (error.response?.data?.error) {
      Object.entries(error.response?.data?.error).map(([key, errorsArray]) => {
        const _key = key as string
        const _errorsArray = errorsArray as string[]

        errors = `${key}: ${_errorsArray.join(" | ")}.`
      })
    } else if (error.response?.data.errors) {
      errors = error.response?.data.errors
    } else {
      errors = errors + error.response.toString()
    }

    const customError: HttpError = {
      ...error,
      message: errors,
      statusCode: error.response?.status,
    };

    console.log({ customError })
    return Promise.reject(customError);
  }
);

export default axiosInstance
