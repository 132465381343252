import React, { useState } from "react";
import {
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useSelect,
} from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  Flex,
  Select,
  LoadingOverlay,
  Code,
  Text,
  Anchor,
  Image,
} from "@mantine/core";
import {
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
} from "@refinedev/mantine";
import { Badge } from "@mantine/core";

import type { Header } from "@tanstack/react-table";
import { DateRangePicker } from "@mantine/dates";
import useArticlesPermissions from "./useArticlesPermissions";
import { colorsByBrand, selectBrands } from "entities/gift_card_campaign/brand";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { Article } from "entities/article";
import { colorsByStatus, selectStatuses } from "entities/article/status";
import { colorsBySource, selectSources } from "entities/article/source";
import { Category } from "entities/category";

type WpCategoriesResponse = {
  wp_categories: [];
};

export const ArticleList: React.FC<IResourceComponentsProps> = () => {
  const { canEdit, canDelete } = useArticlesPermissions();

  const apiUrl = useApiUrl();

  const { data: wpCategoriesData, isLoading: isLoadingWpCategories } =
    useCustom<WpCategoriesResponse>({
      url: `${apiUrl}/articles/wp_categories`,
      method: "get",
      queryOptions: {
        enabled: true,
      },
    });

  const { options: categoryOptions, queryResult: categoryQueryResult } =
    useSelect<Category>({
      resource: "categories",
      debounce: 1000,
      pagination: {
        pageSize: 10,
        mode: "server",
        current: 1,
      },
      optionLabel: "name",
      optionValue: "id",
    });

  const columns = React.useMemo<ColumnDef<Article>[]>(
    () => [
      {
        id: "thumbnail",
        accessorKey: "thumbnail",
        header: "Thumbnail",
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          const imageUrl = getValue<Article["thumbnail"]>();

          return (
            <Image
              src={imageUrl}
              radius="md"
              alt="Thumbnail for article"
              height={140}
              width={200}
              withPlaceholder
            />
          );
        },
      },
      {
        id: "id",
        accessorKey: "id",
        header: "Id",
      },
      {
        id: "title",
        accessorKey: "title",
        header: "Title",
        cell: ({ getValue }) => {
          const value = getValue<Article["title"]>();

          return <Text>{value}</Text>;
        },
        meta: { filterOperator: "contains" }
      },
      {
        id: "source",
        accessorKey: "source",
        header: "Source",
        cell: ({ getValue }) => {
          const source = getValue<Article["source"]>();
          return (
            <Badge variant="filled" color={colorsBySource[source]}>
              {source}
            </Badge>
          );
        },
        meta: {
          filterElement: ({ header }: { header: Header<Article, any> }) => {
            return (
              <div>
                <Select
                  placeholder="source"
                  data={selectSources}
                  onChange={header.column.setFilterValue}
                  withinPortal
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "status",
        accessorKey: "status",
        header: "Status",
        cell: ({ getValue }) => {
          const status = getValue<Article["status"]>();
          return (
            <Badge variant="filled" color={colorsByStatus[status]}>
              {status}
            </Badge>
          );
        },
        meta: {
          filterElement: ({ header }: { header: Header<Article, any> }) => {
            return (
              <div>
                <Select
                  placeholder="status"
                  data={selectStatuses}
                  onChange={header.column.setFilterValue}
                  withinPortal
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "categories",
        accessorKey: "categories",
        header: "Categories",
        cell: ({ getValue }) => {
          const categories = getValue<Article["categories"]>();
          if (!categories || categories.length === 0)
            return <Text color="gray">No Categories</Text>;

          return (
            <Flex gap="xs" wrap="wrap">
              {categories.map((category) => (
                <Badge variant="outline" color="pink">
                  {category.name}
                </Badge>
              ))}
            </Flex>
          );
        },
        meta: {
          filterOperator: "eq",
          filterElement: ({ header }: { header: Header<Article, any> }) => {
            return (
              <div>
                <Select
                  placeholder="Category"
                  onChange={header.column.setFilterValue}
                  data={categoryOptions}
                  withinPortal
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "wp_categories",
        accessorKey: "wp_categories",
        header: "WP Categories",
        cell: ({ getValue }) => {
          const categories = getValue<Article["wp_categories"]>();
          if (!categories || categories.length === 0)
            return <Text color="gray">No Categories</Text>;

          return (
            <Flex gap="xs" wrap="wrap">
              {categories.map((category) => (
                <Badge variant="outline" color="orange">
                  {category}
                </Badge>
              ))}
            </Flex>
          );
        },
        meta: {
          filterOperator: "contains",
          filterElement: ({ header }: { header: Header<Article, any> }) => {
            return (
              <div>
                <Select
                  placeholder="WP Category"
                  data={wpCategoriesData?.data.wp_categories || []}
                  onChange={header.column.setFilterValue}
                  withinPortal
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "url",
        accessorKey: "url",
        header: "Url",
        cell: ({ getValue }) => {
          const value = getValue<Article["url"]>();

          return <Anchor href={value}>{value}</Anchor>;
        },
        meta: { filterOperator: "contains" }
      },
      {
        id: "slug",
        accessorKey: "slug",
        header: "Slug",
        cell: ({ getValue }) => {
          const value = getValue<Article["slug"]>();

          return <Text>{value}</Text>;
        },
        meta: { filterOperator: "contains" }
      },
      {
        id: "views_count",
        accessorKey: "views_count",
        header: "Views",
        cell: ({ getValue }) => {
          const value = getValue<Article["views_count"]>();
          return <Text weight="bold">{value}</Text>;
        },
        meta: { filterOperator: "contains" }
      },
      {
        id: "likes_count",
        accessorKey: "likes_count",
        header: "Likes",
        cell: ({ getValue }) => {
          const value = getValue<Article["likes_count"]>();
          return <Text weight="bold">{value}</Text>;
        },
        meta: { filterOperator: "contains" }
      },
      {
        id: "estimated_read_time",
        accessorKey: "estimated_read_time",
        header: "Estimated Read time",
        cell: ({ getValue }) => {
          const value = getValue<Article["estimated_read_time"]>();
          return <Text italic>{value}</Text>;
        },
        meta: { filterOperator: "contains" }
      },
      {
        id: "author_id",
        accessorKey: "author_id",
        header: "Author ID",
      },
      {
        id: "author_name",
        accessorKey: "author_name",
        header: "Author Name",
        meta: { filterOperator: "contains" }
      },
      {
        id: "post_id",
        accessorKey: "post_id",
        header: "Post ID",
      },
      {
        id: "wp_published_date",
        accessorKey: "wp_published_date",
        header: "WP Published Date",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({ header }: { header: Header<Article, any> }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "wp_modified_date",
        accessorKey: "wp_modified_date",
        header: "WP Modified Date",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({ header }: { header: Header<Article, any> }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "publish_at",
        accessorKey: "publish_at",
        header: "Publish At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({ header }: { header: Header<Article, any> }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "Created At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({ header }: { header: Header<Article, any> }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "updated_at",
        accessorKey: "updated_at",
        header: "Updated At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({ header }: { header: Header<Article, any> }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "actions",
        cell: function render({ row: { original: GiftCardCampaign } }) {
          return (
            <Flex columnGap={4}>
              <ShowButton hideText recordItemId={GiftCardCampaign.id} />
              {canEdit && (
                <EditButton hideText recordItemId={GiftCardCampaign.id} />
              )}
              {canDelete && (
                <DeleteButton hideText recordItemId={GiftCardCampaign.id} />
              )}
            </Flex>
          );
        },
        meta: {
          isPlaceholder: true,
        },
      },
    ],
    [canEdit, canDelete, wpCategoriesData, categoryOptions]
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  setOptions((prev) => ({
    ...prev,
    meta: {
      ...prev.meta,
    },
  }));

  return (
    <List canCreate={false}>
      <AppliedFilters<Article> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<Article> headerGroups={getHeaderGroups()} />
          <Rows<Article> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
