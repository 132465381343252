import { User } from "entities/user";
import { Source } from "./source";
import { State } from "./state";
import { Lock } from "./lock";

export type XpEarning = {
  id: number;
  source: Source;
  state: State;
  amount: number;
  notes: string;
  created_at: Date;
  updated_at: Date;
  remote_invoice_id?: string;
  user: User;
  locks: Lock[];
};

export type XpEarningForm = Omit<XpEarning, "id" | "user" | "updated_at"> & {
  user: User | null;
  user_id?: User["id"];
};

export type UpdateXpEarning = Omit<
  XpEarning,
  "id" | "created_at" | "updated_at" | "locks" | "user"
> & {
  user: User | null;
  user_id?: number,
  locks: Record<Lock["lock_type"], Partial<Lock> | null>;
};

export const DEFAULT_LOCKS: Record<Lock["lock_type"], Lock> = {
  shipping_subscription: {
    lock_type: "shipping_subscription",
    released_at: null,
    released_notes: null,
  },
  shipping_shop_products: {
    lock_type: "shipping_shop_products",
    released_at: null,
    released_notes: null,
  },
};
