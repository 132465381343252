import { useCustom, useApiUrl, BaseRecord } from "@refinedev/core";
import { Text, Table, Container, useMantineTheme, Loader } from "@mantine/core";
import { User } from "entities/user";
import { Chrono } from "react-chrono";
import { IconArrowRight } from "@tabler/icons";
import { ErrorBoundary } from "react-error-boundary";

type ChangeSet<T> = {
  event: "create" | "update" | "delete";
  whodunnit: User;
  date: Date;
  changeset: Record<keyof T, T[keyof T][]>;
};

type HistoryResponse<T> = {
  history: ChangeSet<T>[];
};

type HistoryProps = {
  resources: string;
  entityId: string | number;
};

export function History<T extends BaseRecord>({
  resources,
  entityId,
}: HistoryProps) {
  const apiUrl = useApiUrl();
  const theme = useMantineTheme();
  const { data: historyData, isLoading: isLoadingHistory } = useCustom<
    HistoryResponse<T>
  >({
    url: `${apiUrl}/${resources}/${entityId}/history`,
    method: "get",
    queryOptions: {
      enabled: true,
    },
  });

  const timelineData = historyData?.data.history
    ?.map((data) => ({
      date: new Date(data.date),
      cardTitle: data.whodunnit
        ? `By ${data.whodunnit?.first_name} ${data.whodunnit?.last_name} (${data.whodunnit?.email})`
        : "Automatic",
      title: "By someone",
      data: data,
    }))
    .sort((a, b) => b.date.getTime() - a.date.getTime());

  const timelineComponents = timelineData?.map((item) => (
    <Table
      key={item.data.date.toString()}
      sx={(theme) => ({ padding: theme.spacing.lg, wordBreak: "break-all" })}
    >
      <thead>
        <tr>
          <td style={{ minWidth: "120px" }}>Key</td>
          <td>Value</td>
        </tr>
      </thead>
      <tbody>
        {Object.entries(item.data.changeset)
          .filter(([key, value]) => key !== "updated_at")
          .map(([key, value]) => {
            if (item.data.event === "create") {
              return (
                <tr key={key}>
                  <td>{key}</td>
                  <td>
                    <Text sx={(theme) => ({ color: theme.colors.blue[5] })}>
                      {value[1]?.toString()}
                    </Text>
                  </td>
                </tr>
              );
            }

            return (
              <tr key={key}>
                <td>{key}</td>
                <td>
                  <Text sx={(theme) => ({ color: theme.colors.red[5] })}>
                    {value[0]?.toString() || "NULL"}
                  </Text>
                </td>
                <td>
                  <IconArrowRight size={20} />
                </td>
                <td>
                  <Text sx={(theme) => ({ color: theme.colors.green[5] })}>
                    {value[1]?.toString() || "NULL"}
                  </Text>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  ));

  return (
    <ErrorBoundary fallback={<p>⚠️Could not load history</p>}>
      <div>
        <Container>
          <h2>History</h2>
          {isLoadingHistory ? (
            <Loader size="lg" />
          ) : (
            <Chrono
              mode="VERTICAL"
              titleDateFormat="YYYY-MM-DD HH:mm:ss"
              items={timelineData}
              theme={{
                secondary: theme.colors.blue,
                cardBgColor:
                  theme.colorScheme === "dark" ? theme.black : theme.white,
                cardForeColor:
                  theme.colorScheme === "dark" ? theme.white : theme.black,
              }}
              hideControls
            >
              {timelineComponents}
            </Chrono>
          )}
        </Container>
      </div>
    </ErrorBoundary>
  );
}
