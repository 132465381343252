import React from "react";
import { createRoot } from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import Widget from "widget/Widget";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

if (process.env.REACT_APP_BUILD_TARGET === 'widget') {
  console.log("BUILD: " + process.env.REACT_APP_BUILD_TARGET)
  root.render(
    <React.StrictMode>
      <Widget />
    </React.StrictMode>
  );
} else {
  console.log("BUILD: " + process.env.REACT_APP_BUILD_TARGET)
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
