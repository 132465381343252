export type Source = "tokyotreat" | "yumetwins" | "sakuraco";

export const sources: Source[] = ["sakuraco", "tokyotreat", "yumetwins"];

export const selectSources = sources.map((source) => ({
  value: source,
  label: source.toUpperCase(),
}));

export const colorsBySource: Record<Source, string> = {
    "tokyotreat": "orange",
    "sakuraco": "brown",
    "yumetwins": "blue"
}
