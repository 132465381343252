import { Header } from "@tanstack/react-table";
import { Box, Flex, Chip } from "@mantine/core";
import { formatISO } from "date-fns";

type AppliedfiltersProps<T> = {
  headers: Header<T, any>[];
};

export default function AppliedFilters<T>({ headers }: AppliedfiltersProps<T>) {
  const appliedFilters = headers.filter((h) => h.column.getIsFiltered());

  if (appliedFilters.length === 0) return <div />;

  return (
    <Box
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.gray[9]
            : theme.colors.gray[0],
        color:
          theme.colorScheme === "dark"
            ? theme.colors.gray[6]
            : theme.colors.gray[5],
        padding: theme.spacing.xs,
        borderRadius: theme.radius.md,
      })}
    >
      Applied Filters
      <Flex
        gap={4}
        wrap="wrap"
        sx={(theme) => ({ marginTop: theme.spacing.xs })}
      >
        {appliedFilters.map((header) => {
          let filterValue = header.column.getFilterValue();
          if (Array.isArray(filterValue)) {
            filterValue = filterValue
              .filter((v) => v)
              .map((v) => formatISO(new Date(v), { representation: "date" }))
              .join("..");
          }

          return (
            <Chip
              key={header.id}
              defaultChecked
              color="cyan"
              variant="outline"
              onChange={(checked) => {
                if (!checked) header.column.setFilterValue(null);
              }}
            >
              <span>
                <b>{header.column.columnDef.header as string}:</b>&nbsp;
                {filterValue as string}
              </span>
            </Chip>
          );
        })}
      </Flex>
    </Box>
  );
}
