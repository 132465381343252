import {
  Box,
  ColorInput,
  Container,
  Divider,
  Flex,
  NumberInput,
  Select,
  Switch,
  Tabs,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { hasLength, isNotEmpty, matches } from "@mantine/form";
import { HttpError, IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/mantine";
import {
  IconAt,
  IconGift,
  IconHome,
  IconListDetails,
  IconMoodSmile,
  IconNotification,
  IconPhoneCheck,
} from "@tabler/icons";
import DateTimePicker from "components/common/DateTimePicker";
import ImageDropZone from "components/common/ImageDropZone";
import { getYear, isBefore, isPast } from "date-fns";
import {
  DEFAULT_REWARD_CONFIG,
  Survey,
  SurveyFormValues,
} from "entities/survey";
import {
  audiencesWithState,
  selectAudiences,
  selectAudienceStatuses,
} from "entities/survey/audience";
import { getDaysVisibleForBox, selectMonths } from "entities/survey/boxMonth";
import {
  DEFAULT_EMAIL_NOTIFICATION_CONFIG,
  DEFAULT_IN_APP_NOTIFICATION_CONFIG,
  DEFAULT_PUSH_NOTIFICATION_CONFIG,
} from "entities/survey/notification";
import {
  DEFAULT_GIVEAWAYS_CONFIG,
  DEFAULT_HOME_BANNER_CONFIG,
  DEFAULT_PACKAGE_DESCRIPTION_CONFIG,
  selectIcons,
} from "entities/survey/placement";
import { selectRewardTypes } from "entities/survey/rewardType";
import { useEffect } from "react";
import { formatToJst } from "utils/dates";
import SendNotificationStrategy from "./notificationConfig/SendNotificationStrategy";
import SubscriptionBoxRewardConfig from "./rewardConfig/SubscriptionBoxRewardConfig";
import TabWithError from "./TabWithError";

function hasLengthOrEmpty(props: any, lengthMessage: string) {
  return (value: any) => {
    if (!value) return null;

    return hasLength(props, lengthMessage)(value);
  };
}

export const SurveyCreate: React.FC<IResourceComponentsProps> = () => {
  const { getInputProps, saveButtonProps, setFieldValue, values, errors } =
    useForm<Survey, HttpError, SurveyFormValues, SurveyFormValues>({
      initialValues: {
        title: "",
        description: "",
        image: "",
        url: "",
        audience: "tt",
        audience_state: "active",
        active_from: new Date(),
        active_to: new Date(),
        reward_type: "none",
        coupon_code: "",
        reward_config: DEFAULT_REWARD_CONFIG,
        home_banner_config: DEFAULT_HOME_BANNER_CONFIG,
        package_description_config: DEFAULT_PACKAGE_DESCRIPTION_CONFIG,
        giveaways_config: DEFAULT_GIVEAWAYS_CONFIG,
        push_notification_config: DEFAULT_PUSH_NOTIFICATION_CONFIG,
        in_app_notification_config: DEFAULT_IN_APP_NOTIFICATION_CONFIG,
        email_notification_config: DEFAULT_EMAIL_NOTIFICATION_CONFIG,
      },
      transformValues: (values) => {
        return {
          ...values,
          active_from: formatToJst(values.active_from),
          active_to: formatToJst(values.active_to),
        };
      },
      validateInputOnBlur: true,
      validate: {
        image: isNotEmpty("Must select a valid image"),
        title: hasLength(
          { min: 3, max: 100 },
          "Title must be be 3-100 characters long",
        ),
        url: matches(
          /^https:\/\/form\.typeform\.com\/to\/\w+$/,
          "Please provide a valid url to Typeform survey (example: https://form.typeform.com/to/jX69GSCS)",
        ),
        description: hasLength(
          { min: 20, max: 400 },
          "Description must be 20-400 characters long",
        ),
        active_from: isNotEmpty(),
        active_to: (value, values) =>
          isBefore(new Date(value), new Date(values.active_from))
            ? "The active to date should come after the active from date"
            : null,
        reward_config: {
          max_winner: (value) =>
            values.reward_type == "subscription_box" && !value
              ? "Max winner must not be empty"
              : null,
        },
        home_banner_config: {
          title: hasLengthOrEmpty(
            { min: 3, max: 100 },
            "Title must be be 3-100 characters long",
          ),
          background_color: isNotEmpty("Back  ground color must not be empty"),
          description: hasLengthOrEmpty(
            { min: 20, max: 400 },
            "Description must be 20-400 characters long",
          ),
        },
        package_description_config: {
          year: (value, values) => {
            if (!values.package_description_config.visible) return;

            const visibleFor = getDaysVisibleForBox(
              new Date(values.active_from),
              new Date(values.active_to),
              value,
              values.package_description_config.month,
            );

            if (visibleFor <= 0) {
              return `The Box Month's range and the survey's active dates are not intersecting`;
            }
          },
          month: (value, values) => {
            if (!values.package_description_config.visible) return;

            const visibleFor = getDaysVisibleForBox(
              new Date(values.active_from),
              new Date(values.active_to),
              values.package_description_config.year,
              value,
            );

            if (visibleFor <= 0) {
              return `The Box Month's range and the survey's active dates are not intersecting`;
            }
          },
        },
        push_notification_config: {
          title: hasLengthOrEmpty(
            { min: 3, max: 100 },
            "Title must be be 3-100 characters long",
          ),
          description: hasLengthOrEmpty(
            { min: 20, max: 400 },
            "Description must be 20-400 characters long",
          ),
          scheduled_at: (value) =>
            value && isPast(value)
              ? "The scheduled at date should not be in the past"
              : null,
        },
        in_app_notification_config: {
          title: hasLengthOrEmpty(
            { min: 3, max: 100 },
            "Title must be be 3-100 characters long",
          ),
          description: hasLengthOrEmpty(
            { min: 20, max: 400 },
            "Description must be 20-400 characters long",
          ),
          scheduled_at: (value) =>
            value && isPast(value)
              ? "The scheduled at date should not be in the past"
              : null,
        },
        email_notification_config: {
          scheduled_at: (value) =>
            value && isPast(value)
              ? "The scheduled at date should not be in the past"
              : null,
        },
      },
    });

  useEffect(() => {
    setFieldValue("reward_config", DEFAULT_REWARD_CONFIG);
  }, [values.reward_type]);

  const daysOfVisibilityForBox = getDaysVisibleForBox(
    new Date(values.active_from),
    new Date(values.active_to),
    values.package_description_config.year,
    values.package_description_config.month,
  );

  const sectionErrors = new Set(
    Object.keys(errors).map((e) => e.split(".")[0]),
  );

  return (
    <Create saveButtonProps={saveButtonProps}>
      <ImageDropZone
        onFileChosen={(data) => setFieldValue("image", data)}
        {...getInputProps("image")}
      />
      <TextInput label="Title" {...getInputProps("title")} withAsterisk />
      <TextInput label="Typeform url" {...getInputProps("url")} withAsterisk />
      <Textarea
        label="Description"
        {...getInputProps("description")}
        withAsterisk
      />
      <Select
        placeholder="Select audience"
        data={selectAudiences}
        label="Audience"
        clearable={false}
        withAsterisk
        {...getInputProps("audience")}
      />
      {audiencesWithState.includes(values.audience) && (
        <Select
          placeholder="Select audience state"
          data={selectAudienceStatuses}
          label="Audience state"
          clearable={false}
          withAsterisk
          {...getInputProps("audience_state")}
        />
      )}
      <DateTimePicker
        label="Active from"
        withAsterisk
        {...getInputProps("active_from")}
      />
      <DateTimePicker
        label="Active to"
        minDate={new Date()}
        withAsterisk
        {...getInputProps("active_to")}
      />
      <Box pl="sm" p="sm">
        <Divider size="sm" mt="md" />
        <Text size="lg" mb="md" color="cyan">
          Reward Configuration
        </Text>
        <Select
          placeholder="Select reward type"
          data={selectRewardTypes}
          label="Reward type"
          defaultValue="none"
          withAsterisk
          {...getInputProps("reward_type")}
        />
        <TextInput label="Coupon Code" {...getInputProps("coupon_code")} />
        {values.reward_type === "subscription_box" && (
          <SubscriptionBoxRewardConfig getInputProps={getInputProps} />
        )}
      </Box>
      <Box pl="sm" p="sm">
        <Divider size="sm" mt="md" />
        <Text size="lg" mb="md" color="cyan">
          Placements Configuration
        </Text>
        <Tabs variant="pills" defaultValue="home_banner">
          <Tabs.List>
            <TabWithError
              value="home_banner"
              icon={<IconHome size={14} />}
              hasError={sectionErrors.has("home_banner_config")}
            >
              Home Banner
            </TabWithError>
            {values.audience !== "shopify_products" && (
              <TabWithError
                value="package_description_page"
                icon={<IconListDetails size={14} />}
                hasError={sectionErrors.has("package_description_config")}
              >
                Package Description Page
              </TabWithError>
            )}
            <Tabs.Tab value="giveaways_page" icon={<IconGift size={14} />}>
              Giveaways Page
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="home_banner">
            <Container pt="sm">
              <Flex p="md" direction="column" gap="sm">
                <Switch
                  size="md"
                  label="Visible on the home banner"
                  {...getInputProps("home_banner_config.visible")}
                />
                {values.home_banner_config.visible && (
                  <>
                    <TextInput
                      label="Title"
                      placeholder="Title"
                      description={
                        "If this is is not set the survey's title will be used"
                      }
                      {...getInputProps("home_banner_config.title")}
                    />
                    <Select
                      label="Icon"
                      placeholder="Select Icon"
                      data={selectIcons}
                      defaultValue="gift"
                      itemComponent={({ value, label, ref, ...others }) => (
                        <Flex p="sm" align="center" ref={ref} {...others}>
                          {value === "gift" && <IconGift />}
                          {value === "smile" && <IconMoodSmile />}
                          <Box ml="xs">{label}</Box>
                        </Flex>
                      )}
                      {...getInputProps("home_banner_config.icon")}
                    />

                    <ColorInput
                      placeholder="Background color"
                      label="Background color"
                      defaultValue="#E9E3F6"
                      {...getInputProps("home_banner_config.background_color")}
                    />
                    <Textarea
                      label="Description"
                      placeholder="Description"
                      description="If this is not set the survey's description will be used"
                      {...getInputProps("home_banner_config.description")}
                    />
                  </>
                )}
              </Flex>
            </Container>
          </Tabs.Panel>
          <Tabs.Panel value="package_description_page">
            <Container pt="sm">
              <Flex p="md" direction="column" gap="sm">
                <Switch
                  size="md"
                  label="Visible on the package description page"
                  {...getInputProps("package_description_config.visible")}
                />
                {values.package_description_config.visible && (
                  <>
                    <NumberInput
                      label="Box's year"
                      placeholder="e.g 2022"
                      min={2015}
                      max={getYear(new Date()) + 1}
                      description="Keep empty to display the survey for any month box"
                      {...getInputProps("package_description_config.year")}
                    />
                    <Select
                      label="Box's Month"
                      data={selectMonths}
                      description="Keep empty to display the survey for any month box"
                      clearable
                      {...getInputProps("package_description_config.month")}
                    />
                  </>
                )}
              </Flex>
            </Container>
          </Tabs.Panel>
          <Tabs.Panel value="giveaways_page">
            <Container pt="sm">
              <Flex p="md" direction="column" gap="sm">
                <Switch
                  size="md"
                  label="Visible on the Giveaways page"
                  {...getInputProps("giveaways_config.visible")}
                />
              </Flex>
            </Container>
          </Tabs.Panel>
        </Tabs>
      </Box>
      <Box pl="sm" p="sm">
        <Divider size="sm" mt="md" />
        <Text size="lg" mb="md" color="cyan">
          Notifications Configuration
        </Text>
        <Tabs variant="pills" defaultValue="push_notification">
          <Tabs.List>
            <TabWithError
              value="push_notification"
              icon={<IconPhoneCheck size={14} />}
              hasError={sectionErrors.has("push_notification_config")}
            >
              Push Notification
            </TabWithError>
            <TabWithError
              value="in_app_notification"
              icon={<IconNotification size={14} />}
              hasError={sectionErrors.has("in_app_notification_config")}
            >
              In App Notification
            </TabWithError>
            <TabWithError
              value="email_notification"
              icon={<IconAt size={14} />}
              hasError={sectionErrors.has("email_notification_config")}
            >
              Email Notification
            </TabWithError>
          </Tabs.List>
          <Tabs.Panel value="push_notification">
            <Container pt="sm">
              <Flex p="md" direction="column" gap="sm">
                <SendNotificationStrategy
                  scheduledAtProps={{
                    ...getInputProps("push_notification_config.scheduled_at"),
                  }}
                  {...getInputProps("push_notification_config.send_strategy")}
                />
                <TextInput
                  label="Title"
                  placeholder="Title"
                  description="If this is not set the survey's title will be used"
                  {...getInputProps("push_notification_config.title")}
                />
                <Textarea
                  label="Description"
                  placeholder="Description"
                  description="If this is not set the survey's description will be used"
                  {...getInputProps("push_notification_config.description")}
                />
              </Flex>
            </Container>
          </Tabs.Panel>
          <Tabs.Panel value="in_app_notification">
            <Container pt="sm">
              <Flex p="md" direction="column" gap="sm">
                <SendNotificationStrategy
                  scheduledAtProps={{
                    ...getInputProps("in_app_notification_config.scheduled_at"),
                  }}
                  {...getInputProps("in_app_notification_config.send_strategy")}
                />
                <TextInput
                  label="Title"
                  placeholder="Title"
                  description="If this is not set the survey's title will be used"
                  {...getInputProps("in_app_notification_config.title")}
                />
                <Textarea
                  label="Description"
                  placeholder="Description"
                  description="If this is not set the survey's description will be used"
                  {...getInputProps("in_app_notification_config.description")}
                />
              </Flex>
            </Container>
          </Tabs.Panel>
          <Tabs.Panel value="email_notification">
            <Container pt="sm">
              <Flex p="md" direction="column" gap="sm">
                <SendNotificationStrategy
                  scheduledAtProps={{
                    ...getInputProps("email_notification_config.scheduled_at"),
                  }}
                  {...getInputProps("email_notification_config.send_strategy")}
                />
              </Flex>
            </Container>
          </Tabs.Panel>
        </Tabs>
      </Box>
    </Create>
  );
};
