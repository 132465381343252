import {
  ActionIcon,
  Avatar,
  Container,
  Flex,
  Group,
  Image,
  Header as MantineHeader,
  Sx,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";

const Title = ({ collapsed }: { collapsed?: boolean }) => {
  return (
    <Container>
      {!collapsed && (
        <Image
          sx={(theme) => ({ padding: theme.spacing.lg })}
          src="assets/logo.png"
        />
      )}
    </Container>
  );
};

export default Title;
