import {
  IResourceComponentsProps,
  useShow,
  useOne,
  HttpError,
  useApiUrl,
  useMany,
  useCustom,
} from "@refinedev/core";
import { Show, DateField, EmailField } from "@refinedev/mantine";
import {
  Title,
  Badge,
  Flex,
  Text,
  Grid,
  Code,
  Card,
  Image,
  Group,
  Table,
} from "@mantine/core";
import { User } from "entities/user";
import { GiftCardCampaign } from "entities/gift_card_campaign";
import { GiftCardCampaignPlan } from "entities/gift_card_campaign/gift_card_campaign_plan";
import { colorsByBrand } from "entities/gift_card_campaign/brand";
import { GiftCardTemplate } from "entities/gift_card_template";
import { DetailsUser } from "components/common/show/DetailsUser";
import { DetailsPair } from "components/common/show/DetailsPair";
import { History } from "components/common/show/History";
import { GiftCard } from "entities/gift_card";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import useManyIndexed from "hooks/useManyIndexed";

type GiftCardsResponse = {
  gift_cards: GiftCard[];
};

export const GiftCardCampaignShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<GiftCardCampaign, HttpError>();
  const { data, isLoading } = queryResult;
  const giftCardCampaign = data?.data;

  const user = useOne<User, HttpError>({
    resource: "users",
    id: giftCardCampaign?.user_id,
    queryOptions: {
      enabled: !!giftCardCampaign?.user_id,
    },
  }).data?.data;

  const plans = useMany<GiftCardCampaignPlan, HttpError>({
    resource: "gift_card_campaign_plans",
    ids:
      queryResult.data?.data.gift_card_campaign_plans.map((plan) => plan.id) ||
      [],
  });

  const templates = useManyIndexed<number, GiftCardTemplate>({
    resource: "gift_card_templates",
    ids:
      queryResult.data?.data.gift_card_campaign_plans.map(
        (plan) => plan.gift_card_template_id
      ) || [],
  });

  const apiUrl = useApiUrl();

  const { data: giftCardsData } = useCustom<GiftCardsResponse>({
    url: `${apiUrl}/gift_card_campaigns/${giftCardCampaign?.id}/gift_cards`,
    method: "get",
    queryOptions: {
      enabled: !!giftCardCampaign?.id,
    },
  });

  const giftCardUserIds =
    giftCardsData?.data.gift_cards.map((giftCard) => giftCard.user_id) || [];

  const users = useManyIndexed<number, User>({
    resource: "users",
    ids: giftCardUserIds,
    queryOptions: {
      enabled: giftCardUserIds.length > 0,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Grid>
        <Grid.Col span={8}>
          <h1>Plans</h1>
          <Flex gap="md" wrap="wrap">
            {templates &&
              plans.data?.data.map((plan) => {
                const template =
                  templates[parseInt(plan.gift_card_template_id)];
                if (!template) return <div>--</div>;

                return (
                  <Link
                    key={plan.id}
                    to={`/gift_card_templates/show/${plan.gift_card_template_id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card
                      withBorder
                      my="md"
                      radius="md"
                      p="sm"
                      sx={(theme) => ({ width: 300 })}
                    >
                      <Card.Section>
                        <Image src={template.image.url} />
                      </Card.Section>
                      <Group py="sm">
                        <Text weight={500}>{plan.duration} Months Plan</Text>
                        <Badge color="blue">{template.channel}</Badge>
                      </Group>
                      <Text color="dimmed">{template.notes}</Text>
                      <Group py="sm">
                        <Badge color="green">${template.initial_value}</Badge>
                        <Badge color="red">{template.constraint}</Badge>
                      </Group>
                    </Card>
                  </Link>
                );
              })}
          </Flex>
          {giftCardCampaign?.id && (
            <History<GiftCardCampaign>
              resources="gift_card_campaigns"
              entityId={giftCardCampaign.id}
            />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Flex direction="column" gap="sm">
            <DetailsPair>
              <Title order={5}>Brand</Title>
              {giftCardCampaign?.brand && (
                <Badge color={colorsByBrand[giftCardCampaign.brand]}>
                  {giftCardCampaign.brand}
                </Badge>
              )}
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Code</Title>
              <Code>{giftCardCampaign?.code}</Code>
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Active from</Title>
              <DateField value={giftCardCampaign?.active_from} />
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Active to</Title>
              <DateField value={giftCardCampaign?.active_to} />
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Created At</Title>
              <DateField value={giftCardCampaign?.created_at} />
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Updated At</Title>
              <DateField value={giftCardCampaign?.updated_at} />
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>User</Title>
              {user && <DetailsUser user={user} />}
            </DetailsPair>
          </Flex>
        </Grid.Col>
      </Grid>
      {giftCardsData?.data.gift_cards && users && (
        <GiftCards giftCards={giftCardsData?.data.gift_cards} users={users} />
      )}
    </Show>
  );
};

const GiftCards = ({
  giftCards,
  users,
}: {
  giftCards: GiftCard[];
  users: Record<number, User | null>;
}) => {
  const navigate = useNavigate();

  return (
    <Flex direction="column" py="lg">
      <h1>Gift Cards</h1>
      <Table striped highlightOnHover>
        <thead>
          <tr>
            <th>Id</th>
            <th>User</th>
            <th>Image</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Code</th>
            <th>Initial balance</th>
            <th>Current balance</th>
            <th>Channel</th>
            <th>Constraint</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {giftCards.map((giftCard: GiftCard) => {
            return (
              <tr
                key={giftCard.id}
                onClick={() => navigate(`/gift_cards/show/${giftCard.id}`)}
                style={{ cursor: "pointer" }}
              >
                <td>{giftCard.id}</td>
                <td>
                  <Text>{users[giftCard.user_id]?.email}</Text>
                </td>
                <td>
                  <Image src={giftCard.image} height={100} width={200} />
                </td>
                <td>
                  <DateField value={giftCard.created_at} />
                </td>
                <td>
                  <DateField value={giftCard.updated_at} />
                </td>
                <td>
                  <Code>{giftCard.code}</Code>
                </td>
                <td>
                  <Badge color="grey">${giftCard.initial_value}</Badge>
                </td>
                <td>
                  <Badge color="green">${giftCard.balance}</Badge>
                </td>
                <td>
                  <Badge color="violet">{giftCard.channel}</Badge>
                </td>
                <td>
                  <Badge color="cyan">{giftCard.constraint}</Badge>
                </td>
                <td>
                  <p>{giftCard.notes}</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Flex>
  );
};
