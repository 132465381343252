import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  Flex,
  Select,
  LoadingOverlay,
  Code,
} from "@mantine/core";
import {
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
} from "@refinedev/mantine";
import { Badge } from "@mantine/core";

import type { Header } from "@tanstack/react-table";
import { DateRangePicker } from "@mantine/dates";
import useGiftCardCampaignsPermissions from "./useGiftCardCampaignsPermissions";
import { GiftCardCampaign } from "entities/gift_card_campaign";
import { colorsByBrand, selectBrands } from "entities/gift_card_campaign/brand";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";

export const GiftCardCampaignList: React.FC<IResourceComponentsProps> = () => {
  const { canCreate, canEdit, canDelete } = useGiftCardCampaignsPermissions();

  const columns = React.useMemo<ColumnDef<GiftCardCampaign>[]>(
    () => [
      {
        id: "id",
        accessorKey: "id",
        header: "Id",
      },
      {
        id: "code",
        accessorKey: "code",
        header: "Code",
        cell: ({ getValue }) => {
          const value = getValue<GiftCardCampaign["code"]>();

          return <Code color="gray"> {value} </Code>;
        },
      },
      {
        id: "brand",
        accessorKey: "brand",
        header: "Brand",
        cell: ({ getValue }) => {
          const brand = getValue<GiftCardCampaign["brand"]>();
          return (
            <Badge variant="filled" color={colorsByBrand[brand]}>
              {brand}
            </Badge>
          );
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<GiftCardCampaign, any>;
          }) => {
            return (
              <div>
                <Select
                  placeholder="brand"
                  data={selectBrands}
                  onChange={header.column.setFilterValue}
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "active_from",
        accessorKey: "active_from",
        header: "Active from",
        cell: function render({ getValue }) {
          return (
            <DateField value={getValue<any>()} format="YYYY-MM-DD HH:mm Z" />
          );
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<GiftCardCampaign, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "active_to",
        accessorKey: "active_to",
        header: "Active to",
        cell: function render({ getValue }) {
          return (
            <DateField value={getValue<any>()} format="YYYY-MM-DD HH:mm Z" />
          );
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<GiftCardCampaign, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "Created At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<GiftCardCampaign, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "updated_at",
        accessorKey: "updated_at",
        header: "Updated At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<GiftCardCampaign, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "actions",
        cell: function render({ row: { original: GiftCardCampaign } }) {
          return (
            <Flex columnGap={4}>
              <ShowButton hideText recordItemId={GiftCardCampaign.id} />
              {canEdit && (
                <EditButton hideText recordItemId={GiftCardCampaign.id} />
              )}
              {canDelete && (
                <DeleteButton hideText recordItemId={GiftCardCampaign.id} />
              )}
            </Flex>
          );
        },
        meta: {
          isPlaceholder: true,
        },
      },
    ],
    [canCreate, canEdit, canDelete]
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  setOptions((prev) => ({
    ...prev,
    meta: {
      ...prev.meta,
    },
  }));

  return (
    <List canCreate={canCreate}>
      <AppliedFilters<GiftCardCampaign> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<GiftCardCampaign> headerGroups={getHeaderGroups()} />
          <Rows<GiftCardCampaign> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
