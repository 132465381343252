import { BaseRecord, HttpError, useOne } from "@refinedev/core";
import {
  UseModalFormReturnType,
  useSelect,
  SaveButton,
} from "@refinedev/mantine";
import {
  Modal,
  TextInput,
  Select,
  Box,
  Text,
  Avatar,
  Flex,
  Group,
} from "@mantine/core";
import { User } from "entities/user";
import { forwardRef } from "react";

interface TransferGiftCardModel {
  id: number;
  user_id: number;
  new_owner_user_id: number;
}
export const TransferModal: React.FC<
  UseModalFormReturnType<BaseRecord, HttpError, TransferGiftCardModel>
> = ({
  getInputProps,
  errors,
  setFieldValue,
  modal: { visible, close, title },
  refineCore: { queryResult },
  saveButtonProps,
}) => {
  const { selectProps: usersProps, queryResult: usersResult } = useSelect<User>(
    {
      resource: "users",
      optionLabel: "email",
      optionValue: "id",
      debounce: 1000,
      pagination: {
        pageSize: 10,
        mode: "server",
        current: 1,
      },
      onSearch: (value) => [
        {
          field: "email",
          operator: "eq",
          value,
        },
      ],
    }
  );

  const userOptions = usersResult.data?.data.map((item) => ({
    label: item.email,
    value: item.id.toString(),
    ...item,
  }));

  const user = useOne<User, HttpError>({
    resource: "users",
    id: getInputProps("user_id").value,
  }).data?.data;
  return (
    <Modal onClose={close} opened={visible} title="Transfer to new user">
      <Flex my="lg" gap="lg">
        <Avatar src={user?.image} size="lg" />
        <Flex direction="column">
          <Text size="lg" color="orange">
            {user?.email}
          </Text>
          <Flex gap="xs">
            <Text size="sm">{user?.first_name}</Text>
            <Text size="sm">{user?.last_name}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Select
        label="User"
        placeholder="Select a user"
        withinPortal
        itemComponent={UserSelectItem}
        my="xl"
        {...usersProps}
        clearable
        data={userOptions || []}
        onChange={(userId) =>
          userId && setFieldValue("new_owner_user_id", parseInt(userId))
        }
      />
      <Text size="xs" italic>
        Use this feature only to transfer a gift card to another user's email at
        the customer's request.Upon clicking 'Save,' email notifications will be
        sent to the previous user's email and the new user's email.
      </Text>
      <Box mt={8} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <SaveButton {...saveButtonProps} />
      </Box>
    </Modal>
  );
};

const UserSelectItem = forwardRef<HTMLDivElement, User>((user: User, ref) => (
  <div ref={ref} {...user} id={user.id.toString()}>
    <Group noWrap>
      <Avatar src={user.image} />
      <Text size="xs">{user.email}</Text>
    </Group>
  </div>
));
