import { Card, CardProps, Flex, Paper, PaperProps, Title } from "@mantine/core";
import { useState } from "react";

export const DetailsPair = ({
  containerProps,
  children,
}: {
  containerProps?: PaperProps;
  children: React.ReactNode;
}) => {
  return (
    <Paper withBorder {...containerProps}>
      <Flex
        justify={"space-between"}
        align={"center"}
        sx={(theme) => ({
          padding: theme.spacing.xs,
        })}
      >
        {children}
      </Flex>
    </Paper>
  );
};

export const DetailsCard = ({
  containerProps,
  title,
  defaultCollapsed,
  children,
}: {
  containerProps?: Omit<CardProps, "children">;
  title?: string;
  defaultCollapsed?: Boolean,
  children: React.ReactNode;
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed)

  return (
    <Card withBorder {...containerProps}>
      {title && (
        <Card.Section onClick={() => setCollapsed(!collapsed)} withBorder>
          <Title
            order={5}
            align="center"
            sx={(theme) => ({
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.cyan[4]
                  : theme.colors.cyan[6],
            })}
          >
            {title}
          </Title>
        </Card.Section>
      )}
      {!collapsed && <Card.Section p="sm">{children}</Card.Section>}
    </Card>
  );
};
