import { Tier, TierBenefit, colorsByTier } from "entities/tier";
import { IResourceComponentsProps, useShow, HttpError } from "@refinedev/core";
import { Show, NumberField, TextField } from "@refinedev/mantine";
import { Title, Badge, Flex, Grid, Card, Group, Text } from "@mantine/core";
import { DetailsPair } from "components/common/show/DetailsPair";
import { History } from "components/common/show/History";
import { IconCake, IconCoin, IconKey, IconUser } from "@tabler/icons";
import { ReactNode } from "react";
import { formatDistanceToNow } from "date-fns";

export const TierShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<Tier, HttpError>();
  const { data, isLoading } = queryResult;
  const tier = data?.data;

  return (
    <Show isLoading={isLoading} canDelete={false}>
      <Grid>
        <Grid.Col span={8}>
          <Flex direction="column" gap="sm" p="lg">
            <h2>Benefits</h2>
            {tier &&
              Object.values(tier.tier_benefits).map((benefit) => {
                return (
                  <Card
                    key={benefit.kind}
                    shadow="sm"
                    p="md"
                    radius="md"
                    withBorder
                  >
                    <Group position="apart" mt="xs" mb="xs">
                      {benefit.kind && (
                        <>
                          <Flex align="center">
                            <Text weight={500} mr="xs">
                              {iconByTierBenefitKind[benefit.kind]}
                            </Text>
                            {benefit.title}
                          </Flex>
                          <Badge color="pink" variant="light">
                            {benefit.kind.replaceAll("_", " ")}
                          </Badge>
                        </>
                      )}
                    </Group>

                    <Text size="sm" color="dimmed">
                      {benefit.description}
                    </Text>
                  </Card>
                );
              })}
          </Flex>
          {tier?.id && <History<Tier> resources={"tiers"} entityId={tier.id} />}
        </Grid.Col>
        <Grid.Col span={4}>
          <Flex direction="column" gap="sm">
            {tier && (
              <DetailsPair>
                <Title order={5}>Name</Title>
                <Badge color={colorsByTier[tier?.name]} size="lg">
                  {tier?.name}
                </Badge>
              </DetailsPair>
            )}
            <DetailsPair>
              <Title order={5}>Coins Per Dollar</Title>
              <Badge color="cyan" size="lg">
                {tier?.coins_per_dollar}
              </Badge>
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Xp Required</Title>
              <Badge color="ping" size="lg">
                {tier?.xp_required}
              </Badge>
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Updated At</Title>
              {tier?.updated_at && (
                <TextField
                  value={formatDistanceToNow(new Date(tier.updated_at))}
                />
              )}
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Id</Title>
              <NumberField value={tier?.id ?? ""} />
            </DetailsPair>
          </Flex>
        </Grid.Col>
      </Grid>
    </Show>
  );
};

const iconByTierBenefitKind: Record<TierBenefit["kind"], ReactNode> = {
  coins_earning_rate: <IconCoin />,
  birthday_bonus: <IconCake />,
  active_member_discount: <IconUser />,
  early_access: <IconKey />,
};
