import { IResourceComponentsProps, useShow, HttpError } from "@refinedev/core";
import { Show } from "@refinedev/mantine";
import { Flex, Grid, Text, Badge } from "@mantine/core";
import { DetailsCard } from "components/common/show/DetailsPair";
import { Category } from "entities/category";
import { History } from "components/common/show/History";

export const CategoryShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<Category, HttpError>();
  const { data, isLoading } = queryResult;
  const category = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Grid>
        <Grid.Col span={8}>
          {category && (
            <History<Category> resources="categories" entityId={category.id} />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          {category && (
            <Flex direction="column" gap="sm">
              <DetailsCard title="Name">
                <Text>{category.name}</Text>
              </DetailsCard>
              <DetailsCard title="Description">
                <Text>{category.description}</Text>
              </DetailsCard>
              {category.wp_categories && (
                <DetailsCard title="WP Categories">
                  <Flex gap="md" wrap="wrap">
                    {category.wp_categories.map((wp_category) => (
                      <Badge color="orange" variant="outline">
                        {wp_category}
                      </Badge>
                    ))}
                  </Flex>
                </DetailsCard>
              )}
            </Flex>
          )}
        </Grid.Col>
      </Grid>
    </Show>
  );
};
