import { useSelect } from "@refinedev/core";
import { Select } from "@mantine/core";
import { Comment } from "entities/comment";
import _ from "lodash";

type CommentSelectProps = {
  onChange: (commentId: Comment["id"]) => any;
  defaultValue?: Comment["id"];
  value?: Comment["id"];
  excludeId?: Comment["id"];
  label: string;
};

export default function CommentSelect({
  onChange,
  value,
  defaultValue,
  excludeId,
  label,
}: CommentSelectProps) {
  const { options } = useSelect<Comment>({
    resource: "comments",
    optionLabel: "body",
    optionValue: "id",
    debounce: 1000,
    defaultValue: defaultValue?.toString(),
    pagination: {
      pageSize: 10,
      mode: "server",
      current: 1,
    },
    onSearch: (value) => [
      {
        field: "body",
        operator: "contains",
        value,
      },
    ],
  });

  const updatedOptions = options
    .map((option) => ({ ...option, value: option.value.toString() }))
    .filter((option) => {
      if (excludeId) return option.value !== excludeId.toString();
      else return true;
    });

  return (
    <Select
      label={label}
      placeholder="Select a comment"
      withinPortal
      my="xl"
      data={updatedOptions}
      onChange={(commentId) => commentId && onChange(parseInt(commentId))}
      value={value ? value?.toString() : defaultValue?.toString()}
      searchable
      clearable
    />
  );
}
