import { getOverlappingDaysInIntervals } from "date-fns";

export type Month =
  | "January"
  | "February"
  | "March"
  | "April"
  | "May"
  | "June"
  | "July"
  | "August"
  | "September"
  | "October"
  | "November"
  | "December";

export const months: Month[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const selectMonths = months.map((month, i) => ({
  value: (i + 1).toString(),
  label: month,
}));

export const getDaysVisibleForBox = (
  activeFrom: Date,
  activeTo: Date,
  year: number,
  month: number
) => {
  const shippableStart = new Date(year, month - 2, 16, 0, 0, 0);
  const shippableEnd = new Date(year, month - 1, 15, 23, 59, 59);

  return getOverlappingDaysInIntervals(
    { start: activeFrom, end: activeTo },
    { start: shippableStart, end: shippableEnd }
  );
};
