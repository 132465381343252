import _ from "lodash";

export type Lock = {
    id?: number,
    lock_type: LockType,
    released_at: Date | null,
    released_notes: string | null,
    created_at?: Date,
    updated_at?: Date
}

export type LockType =
  | "shipping_subscription"
  | "shipping_shop_products"

export const lockTypes: LockType[] = [
  "shipping_subscription",
  "shipping_shop_products"
];

export const selectLockTypes = lockTypes.map((lockType) => {
  return { value: lockType, label: lockType.replaceAll("_", " ").toUpperCase() };
});

export const colorsByLockType: Record<LockType, string> = {
    shipping_subscription: "purple",
    shipping_shop_products: "blue"
};
