import {
  Button,
  Flex,
  Loader,
  Slider,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconGift } from "@tabler/icons";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { CoinSpending } from "entities/coin_spending";
import { Screen, UserContext } from "./Layout";
import { LoyaltyReward } from "entities/loyalty_reward";
import { useApiUrl, useCustomMutation } from "@refinedev/core";
import { User } from "entities/user";

type VariableRewardProps = {
  setScreen: Dispatch<SetStateAction<Screen>>;
  setCoinSpending: Dispatch<SetStateAction<CoinSpending | null>>;
  loyaltyReward: LoyaltyReward;
};

export default function VariableReward({
  setScreen,
  loyaltyReward,
  setCoinSpending,
}: VariableRewardProps) {
  const { user, isUserLoading, refetchUser } = useContext(UserContext);
  const [coinsAmount, setCoinsAmount] = useState(100);
  const apiUrl = useApiUrl();
  const {
    mutate: redeemReward,
    isLoading: isRedeemingReward,
    data: redeemedRewardData,
  } = useCustomMutation<CoinSpending>();

  if (!user || isUserLoading)
    return (
      <Flex h="100%" w="100%" justify="center" align="center">
        <Loader />
      </Flex>
    );

  const handleRedeem = (user: User, reward: LoyaltyReward) => {
    redeemReward(
      {
        url: `${apiUrl}/coin_spendings/redeem_reward`,
        method: "post",
        values: {
          user_id: user.id,
          loyalty_reward_id: reward.id,
          coins_amount: coinsAmount,
        },
      },
      {
        onSuccess: (data, variables, context) => {
          setCoinSpending(data.data);
          setScreen("reward_view");
          refetchUser();
        },
      }
    );
  };

  return (
    <Flex direction="column" justify="space-between" h={500}>
      <Flex gap="md" direction="column" align="center" mt={80}>
        <Flex direction="column" align="center">
          <Text color="gray.6">Your Coin Balance</Text>
          <Title order={2} color="red">
            {user.usable_coins}
          </Title>
        </Flex>
        <Flex direction="column" align="center" my="md">
          <Flex gap="sm" align="center">
            <ThemeIcon color="red" size="xl" variant="light">
              <IconGift />
            </ThemeIcon>
            <Text color="red" size="lg">
              {loyaltyReward.name}
            </Text>
          </Flex>
          <Text color="gray.6">{loyaltyReward.description}</Text>
          <Flex
            direction="column"
            sx={{ alignSelf: "stretch", width: "100%", position: "relative" }}
            align="stretch"
          >
            <Slider
              w="100%"
              value={coinsAmount}
              onChange={setCoinsAmount}
              min={100}
              max={user.usable_coins}
              size="lg"
              color="red"
              marks={[
                { value: 1, label: "100" },
                { value: user.usable_coins, label: `${user.usable_coins}` },
              ]}
            />
            <Text
              color="gray.7"
              size="sm"
              mt={50}
              sx={{ position: "absolute" }}
            >
              {coinsAmount} Coins = ${(coinsAmount / 100).toFixed(2)} Discount
              Coupon
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Button
        color="red"
        loading={isRedeemingReward}
        onClick={() => handleRedeem(user, loyaltyReward)}
      >
        Redeem
      </Button>
    </Flex>
  );
}
