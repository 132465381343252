export type LoginActivity = {
  created_at: Date;
  id: number;
  ip_address: string;
  source: LoginActivitySource;
  kind: LoginActivityKind;
};

export type LoginActivitySource =
  | "customer_portal"
  | "ichigo_admin"
  | "ichigo_app"
  | "japanhaul"
  | "tt_mini_market";

export const loginActivitySources: LoginActivitySource[] = [
  "customer_portal",
  "ichigo_admin",
  "ichigo_app",
  "japanhaul",
  "tt_mini_market",
];

export const colorsBySource: Record<LoginActivitySource, string> = {
  customer_portal: "orange",
  ichigo_admin: "yellow",
  ichigo_app: "blue",
  japanhaul: "red",
  tt_mini_market: "green",
};

export const selectLoginActivitySources = loginActivitySources.map(
  (loginActivitySource) => {
    return { value: loginActivitySource, label: loginActivitySource };
  },
);

export type LoginActivityKind = "signup" | "login";

export const loginActivityKinds: LoginActivityKind[] = ["signup", "login"];

export const colorsByKind = {
  login: "green",
  signup: "cyan",
};

export const selectLoginActivityKinds = loginActivityKinds.map(
  (loginActivityKind) => {
    return { value: loginActivityKind, label: loginActivityKind };
  },
);
