import { Select, TextInput } from "@mantine/core";
import { GetInputProps } from "@mantine/form/lib/types";
import { selectBrands } from "entities/gift_card_campaign/brand";
import { SubscriptionBoxRewardConfig } from "entities/survey";

export type SubscriptionBoxRewardConfigProps = {
  getInputProps: GetInputProps<SubscriptionBoxRewardConfig>;
};

export default function SubscriptionBoxRewardConfigComp({
  getInputProps,
}: SubscriptionBoxRewardConfigProps) {
  console.log(getInputProps("reward_config"));
  return (
    <>
      <Select
        placeholder="Select reward brand"
        data={selectBrands}
        label="Reward brand"
        defaultValue="TT"
        clearable={false}
        withAsterisk
        {...getInputProps("reward_config.brand")}
      />
      <TextInput
        label="Max Winner"
        {...getInputProps("reward_config.max_winner")}
        withAsterisk
      />
    </>
  );
}
