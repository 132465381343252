import { Survey } from ".";

export type SurveyNotificaiton =
  | "push_notification"
  | "in_app_notification"
  | "email_notification";

export const surveyNotifications: SurveyNotificaiton[] = [
  "push_notification",
  "in_app_notification",
  "email_notification",
];

export type NotificationConfig = {
  scheduled_at?: Date;
  send_strategy:
    | "days_after_order_creation"
    | "scheduled";
};

export type OverridableNotificationConfig = {
  title?: Survey["title"];
  description?: Survey["description"];
};

export type PushNotificationConfig = NotificationConfig &
  OverridableNotificationConfig;

export type InAppNotificationConfig = NotificationConfig &
  OverridableNotificationConfig;

export type EmailNotificationConfig = NotificationConfig;

export const DEFAULT_PUSH_NOTIFICATION_CONFIG: PushNotificationConfig = {
  send_strategy: "scheduled",
  scheduled_at: new Date(),
};

export const DEFAULT_IN_APP_NOTIFICATION_CONFIG: InAppNotificationConfig = {
  send_strategy: "scheduled",
  scheduled_at: new Date(),
};

export const DEFAULT_EMAIL_NOTIFICATION_CONFIG: EmailNotificationConfig = {
  send_strategy: "scheduled",
  scheduled_at: new Date(),
};
