import {
  Anchor,
  Button,
  CopyButton,
  Flex,
  Loader,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconCalendar, IconConfetti, IconGift } from "@tabler/icons";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { CoinSpending } from "entities/coin_spending";
import { Screen, UserContext } from "./Layout";
import { useApiUrl, useCustomMutation } from "@refinedev/core";
import { User } from "entities/user";
import { Calendar } from "@mantine/dates";
import { formatToJst } from "utils/dates";

type SetBirthdayProps = {
  setScreen: Dispatch<SetStateAction<Screen>>;
};

export default function SetBirthday({ setScreen }: SetBirthdayProps) {
  const [birthday, setBirthday] = useState<Date | null>(null);
  const { user, isUserLoading, refetchUser } = useContext(UserContext);
  const apiUrl = useApiUrl();
  const {
    mutate: updateUser,
    isLoading: isUpdatingUser,
    data: updatedUserData,
  } = useCustomMutation<User>();

  const saveBirthday = () => {
    if (user && birthday) {
      updateUser(
        {
          url: `${apiUrl}/users/${user.id}`,
          method: "patch",
          values: { birthday: formatToJst(birthday) },
        },
        {
          onSuccess: (data, variables, context) => {
            refetchUser();
            setScreen("actions_screen");
          },
        }
      );
    }
  };

  if (!user || isUserLoading)
    return (
      <Flex h="100%" w="100%" justify="center" align="center">
        <Loader />
      </Flex>
    );

  return (
    <Flex direction="column" justify="space-between" h={500}>
      <Flex gap="md" direction="column" align="center">
        <Title color="gray.8" order={2} mt="lg">
          <IconCalendar />
          &nbsp;Set Birthday
        </Title>
        <Flex gap="sm" align="center" py="xl">
          <Calendar value={birthday} onChange={(date) => setBirthday(date)} />
        </Flex>
      </Flex>
      <Button color="red" loading={isUpdatingUser} onClick={saveBirthday}>
        Save Birthday
      </Button>
    </Flex>
  );
}
