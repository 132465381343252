import type { GetInputProps } from "@mantine/form/lib/types";
import { NotificationConfig } from "entities/survey/notification";
import DateTimePicker from "components/common/DateTimePicker";

type SendNotificationStrategyProps = {
  scheduledAtProps: ReturnType<
    GetInputProps<NotificationConfig["scheduled_at"]>
  >;
};

export default function SendNotificationStrategy({
  scheduledAtProps,
}: SendNotificationStrategyProps) {

  return (
    <DateTimePicker
      label="Scheduled At"
      description="Select the date for which you want this notification to be sent"
      {...scheduledAtProps}
    />
  );
}
