import _ from "lodash"

export type Channel = "ichigo_app" | "tt_mini_market" | "all_channels"

export const channels: Channel[] = ["ichigo_app", "tt_mini_market", "all_channels"]

export const colorsByChannel: Record<Channel, string> = {
    ichigo_app: "green",
    tt_mini_market: "cyan",
    all_channels: "gray",
};

export const selectChannels = channels.map(channel => {
    return { value: channel, label: _.capitalize(channel) }
})
