import {
  ActionIcon,
  Anchor,
  Avatar,
  Badge,
  Card,
  Flex,
  Grid,
  Text,
  Title,
} from "@mantine/core";
import { IconCheck, IconTrash } from "@tabler/icons";
import {
  colorsByKey,
  LoyaltyOrderProblem,
} from "entities/loyalty_order_problem";
import { Link } from "react-router-dom";
import { getInvoiceUrl } from "utils/chargebee";

export type LoyaltyOrderProblemCardProps = {
  problem: LoyaltyOrderProblem;
  fixProblem: (problem: LoyaltyOrderProblem) => void;
  deleteProblem: (problem: LoyaltyOrderProblem) => void;
};

export function LoyaltyOrderProblemCard({
  problem,
  fixProblem,
  deleteProblem,
}: LoyaltyOrderProblemCardProps) {
  return (
    <Card key={problem.id}>
      <Grid>
        <Grid.Col span={4}>
          <Flex direction="column" gap="sm">
            <Badge color={colorsByKey[problem.key]}>{problem.key}</Badge>
            <Badge color="orange">{problem.state}</Badge>
            <Anchor
              component={Link}
              to={`/users/show/${problem.user.id}`}
              target="_blank"
            >
              <Flex align="center">
                <Avatar src={problem.user.image} size="xs" />
                <Text size="xs">{problem.user.email}</Text>
              </Flex>
            </Anchor>
          </Flex>
        </Grid.Col>
        <Grid.Col span={6}>
          <Title order={5} color="gray.7">
            {problem.title}
          </Title>
          {problem.remote_order_id && (
            <Anchor
              href={getInvoiceUrl(problem.remote_order_id)}
              target="_blank"
            >
              <Badge>
                <Text>Order: {problem.remote_order_id}</Text>
              </Badge>
            </Anchor>
          )}
        </Grid.Col>
        <Grid.Col span={2}>
          <Flex align="center" h="100%">
            {problem.state === "fixed" ? (
              <Text color="green" weight="bold" mr="md" italic>
                Fixed!
              </Text>
            ) : (
              <ActionIcon
                color="green"
                title="Fix"
                onClick={() => fixProblem(problem)}
              >
                <IconCheck />
              </ActionIcon>
            )}
            <ActionIcon
              color="gray"
              title="Delete"
              onClick={() => deleteProblem(problem)}
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        </Grid.Col>
      </Grid>
    </Card>
  );
}
