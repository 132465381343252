import React from "react";
import {
  BaseRecord,
  HttpError,
  IResourceComponentsProps,
  useForm,
} from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  Flex,
  Select,
  LoadingOverlay,
  Image,
  Avatar,
  Text,
  Code,
  Anchor,
  ActionIcon,
  Input,
  Menu,
} from "@mantine/core";
import {
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
  TextField,
  useSelect,
  useModalForm,
} from "@refinedev/mantine";
import { Badge } from "@mantine/core";
import { IconRobot, IconDotsVertical } from "@tabler/icons";

import type { Header } from "@tanstack/react-table";
import { DateRangePicker } from "@mantine/dates";
import useGiftCardPermissions from "./useGiftCardPermissions";
import debounce from "debounce";
import { ActivateGiftCard, GiftCard } from "entities/gift_card";
import {
  colorsByChannel,
  selectChannels,
} from "entities/gift_card_template/channel";
import {
  colorsByConstraint,
  selectConstraints,
} from "entities/gift_card_template/constraint";
import { User } from "entities/user";
import { GiftCardCampaign } from "entities/gift_card_campaign";

import { GiftCardCampaignPlan } from "entities/gift_card_campaign/gift_card_campaign_plan";
import _ from "lodash";
import useManyIndexed from "hooks/useManyIndexed";
import { Link } from "react-router-dom";
import { TransferModal } from "./TransferModal";
import {
  colorsByStatus,
  selectStatus,
} from "entities/gift_card_template/status";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";

type MetaType = {
  filterElement: React.FC;
  isPlaceholder: boolean;
};

export const GiftCardList: React.FC<IResourceComponentsProps> = () => {
  const { canCreate, canEdit, canDelete } = useGiftCardPermissions();
  // const { show, close } = useModal();

  const transferModalForm = useModalForm({
    refineCoreProps: { action: "edit" },
    syncWithLocation: true,
    initialValues: {
      id: 0,
      user_id: 0,
      new_owner_user_id: 0,
    },
  });

  const {
    modal: { show },
  } = transferModalForm;

  const columns: ColumnDef<GiftCard>[] = [
    {
      id: "id",
      accessorKey: "id",
      header: "Id",
    },
    {
      id: "image",
      accessorKey: "image",
      header: "Image",
      enableColumnFilter: false,
      cell: ({ getValue }) => {
        const imageUrl = getValue<GiftCard["image"]>();

        return (
          <Image
            src={imageUrl}
            radius="md"
            alt="Gift Card Background Image"
            height={140}
            width={200}
            withPlaceholder
          ></Image>
        );
      },
    },
    {
      id: "balance",
      accessorKey: "balance",
      header: "Balance",
      cell: ({ getValue }) => {
        const value = getValue<GiftCard["balance"]>();

        return (
          <Badge size="lg" color="grape">
            ${value}
          </Badge>
        );
      },
    },
    {
      id: "user",
      accessorKey: "user_id",
      header: "User",
      cell: ({ getValue }) => {
        const userId = getValue<GiftCard["user_id"]>();
        const user = users[userId];

        return (
          <Flex direction="column" align="center">
            <Avatar src={user?.image} size="md" />
            <Text color="white">
              {user?.first_name}
              &nbsp;
              {user?.last_name}
            </Text>
            <Text color="orange">{user?.email}</Text>
          </Flex>
        );
      },
      meta: {
        filterOperator: "eq",
      },
    },
    {
      id: "code",
      accessorKey: "code",
      header: "Code",
      cell: ({ getValue }) => {
        const code = getValue<GiftCard["code"]>();

        return <Code color="lime">{code}</Code>;
      },
      meta: {
        filterOperator: "contains",
      },
    },
    {
      id: "channel",
      accessorKey: "channel",
      header: "Channel",
      cell: ({ getValue }) => {
        const channel = getValue<GiftCard["channel"]>();
        return (
          <Badge variant="filled" color={colorsByChannel[channel]}>
            {channel}
          </Badge>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<GiftCard, any> }) => {
          return (
            <div>
              <Select
                placeholder="Channel"
                data={selectChannels}
                onChange={header.column.setFilterValue}
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "constraint",
      accessorKey: "constraint",
      header: "Constraint",
      cell: ({ getValue }) => {
        const constraint = getValue<GiftCard["constraint"]>();
        return (
          <Badge variant="filled" color={colorsByConstraint[constraint]}>
            {constraint}
          </Badge>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<GiftCard, any> }) => {
          return (
            <div>
              <Select
                placeholder="Constraint"
                data={selectConstraints}
                onChange={header.column.setFilterValue}
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "notes",
      accessorKey: "notes",
      header: "Notes",
      cell: function render({ getValue }) {
        return <TextField value={getValue<any>()} />;
      },
      meta: {
        filterOperator: "contains",
      },
    },
    {
      id: "campaign",
      accessorKey: "gift_card_campaign_plan_id",
      header: "Campaign",
      cell: function render({ getValue }) {
        const giftCardCampaignPlanId =
          getValue<GiftCard["gift_card_campaign_plan_id"]>();

        if (giftCardCampaignPlanId) {
          const item = giftCardCampaignPlans[giftCardCampaignPlanId];

          return (
            <Flex justify="center">
              <Anchor
                component={Link}
                to={`/gift_card_campaigns/show/${item?.gift_card_campaign_id}`}
              >
                <Badge color="gray">{item?.gift_card_campaign.code}</Badge>
              </Anchor>
            </Flex>
          );
        }
      },
      meta: {
        filterElement: ({
          header,
        }: {
          header: Header<GiftCardCampaign, any>;
        }) => {
          return <CampaignFilter header={header} />;
        },
      },
    },
    {
      id: "status",
      accessorKey: "status",
      header: "Status",
      cell: ({ getValue }) => {
        const status = getValue<GiftCard["status"]>();
        return (
          <Badge variant="filled" color={colorsByStatus[status]}>
            {status}
          </Badge>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<GiftCard, any> }) => {
          return (
            <div>
              <Select
                placeholder="Status"
                data={selectStatus}
                onChange={header.column.setFilterValue}
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "expire_at",
      accessorKey: "expire_at",
      header: "Expire At",
      cell: function render({ getValue }) {
        return <DateField value={getValue<any>()} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<GiftCard, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={header.column.setFilterValue}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "created_at",
      accessorKey: "created_at",
      header: "Created At",
      cell: function render({ getValue }) {
        return <DateField value={getValue<any>()} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<GiftCard, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={header.column.setFilterValue}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "updated_at",
      accessorKey: "updated_at",
      header: "Updated At",
      cell: function render({ getValue }) {
        return <DateField value={getValue<any>()} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<GiftCard, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={header.column.setFilterValue}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "created_by",
      accessorKey: "created_by_id",
      header: "Created By",
      cell: ({ getValue }) => {
        const createdById = getValue<GiftCard["created_by_id"]>();

        if (createdById) {
          const user = createdBys[createdById];
          return (
            <Flex direction="column" align="center">
              <Avatar src={user?.image} size="md" />
              <Text color="white">
                {user?.first_name}
                &nbsp;
                {user?.last_name}
              </Text>
              <Text color="orange">{user?.email}</Text>
            </Flex>
          );
        } else {
          return (
            <Flex direction="column" align="center">
              <Avatar size="md">
                <IconRobot size={24} />
              </Avatar>
              <Text color="green">Created Automatically</Text>
            </Flex>
          );
        }
      },
    },
    {
      id: "active_at",
      accessorKey: "notification_sent_at",
      header: "Active At",
      cell: function render(props) {
        return (() => {
          if (props.row.original.notification_sent_at) {
            return (
              <DateField value={props.row.original.notification_sent_at} />
            );
          } else if (props.row.original.notification_scheduled_at) {
            return (
              <DateField value={props.row.original.notification_scheduled_at} />
            );
          } else {
            return <Text></Text>;
          }
        })();
      },
      meta: {
        filterElement: ({ header }: { header: Header<GiftCard, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={header.column.setFilterValue}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "actions",
      accessorKey: "",
      cell: function render({ row: { original: giftCard }, getValue }) {
        return (
          <Flex columnGap={4}>
            <ShowButton hideText recordItemId={giftCard.id} />
            {canEdit && (
              <>
                <EditButton hideText recordItemId={giftCard.id} />
              </>
            )}

            {canDelete && <DeleteButton hideText recordItemId={giftCard.id} />}

            {(canEdit ||
              giftCard.status == "draft" ||
              giftCard.status == "scheduled") && (
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <ActionIcon variant="default">
                    <IconDotsVertical size={18} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  {canEdit && (
                    <Menu.Item
                      onClick={() => {
                        show(getValue() as number);
                      }}
                    >
                      Transfer Ownership
                    </Menu.Item>
                  )}
                  {(giftCard.status == "draft" ||
                    giftCard.status == "scheduled") && (
                    <Menu.Item
                      color="red"
                      onClick={() => {
                        handleSubmit({
                          id: giftCard.id,
                        });
                      }}
                    >
                      Activate Now
                    </Menu.Item>
                  )}
                </Menu.Dropdown>
              </Menu>
            )}
          </Flex>
        );
      },
      meta: {
        isPlaceholder: true,
      },
    },
  ];

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  const userIds = tableData?.data.map((item) => item.user_id) || [];
  const users = useManyIndexed<number, User>({
    resource: "users",
    ids: userIds,
    queryOptions: {
      enabled: userIds.length > 0,
    },
  });

  const createdByIds =
    tableData?.data.filter((item) => !!item.created_by_id) || [];
  const createdBys = useManyIndexed<number, User>({
    resource: "users",
    ids: createdByIds.map((item) => item.created_by_id) as number[],
    queryOptions: {
      enabled: createdByIds.length > 0,
    },
  });

  const giftCardCampaignPlanIds =
    tableData?.data
      .filter((item) => item.gift_card_campaign_plan_id)
      .map((item) => item.gift_card_campaign_plan_id) || [];

  const giftCardCampaignPlans = useManyIndexed<
    number,
    GiftCardCampaignPlan & { gift_card_campaign: GiftCardCampaign }
  >({
    resource: "gift_card_campaign_plans",
    ids: giftCardCampaignPlanIds as number[],
    queryOptions: {
      enabled: giftCardCampaignPlanIds?.length > 0,
    },
  });

  setOptions((prev) => ({
    ...prev,
    meta: {
      ...prev.meta,
    },
  }));

  const defaultFilter = (header: Header<GiftCard, any>) => {
    if ((header.column.columnDef.meta as MetaType)?.isPlaceholder) {
      return null;
    } else if (!header.column.getCanFilter()) {
      return (
        <div>
          <Input disabled />
        </div>
      );
    } else {
      return (
        <div>
          <Input
            // value={(header.column.getFilterValue() as string) ?? ""}
            // This causes a UI lag, we need a way to debounce the network requests only
            onChange={debounce(
              (e: React.ChangeEvent<HTMLInputElement>) =>
                header.column.setFilterValue(e.target.value),
              500
            )}
          />
        </div>
      );
    }
  };

  const appliedFilters = getFlatHeaders().filter((h) =>
    h.column.getIsFiltered()
  );

  const { formLoading, onFinish } = useForm<
    BaseRecord,
    HttpError,
    ActivateGiftCard
  >({
    resource: "gift_cards/activate",
    invalidates: ["list"],
    redirect: "list",
    successNotification: (data, values, resource) => {
      return {
        message: `Gift Card activated successfully`,
        type: "success",
      };
    },
  });

  const handleSubmit = (model: ActivateGiftCard) => {
    onFinish(model);
  };
  return (
    <>
      <TransferModal {...transferModalForm} />
      <List canCreate={canCreate}>
        <AppliedFilters<GiftCard> headers={getFlatHeaders()} />
        <ScrollArea>
          <Table highlightOnHover pos="relative">
            <LoadingOverlay visible={isFetching} />
            <Headers<GiftCard> headerGroups={getHeaderGroups()} />
            <Rows<GiftCard> rows={getRowModel().rows} />
          </Table>
        </ScrollArea>
        <br />
        <Pagination
          position="right"
          total={pageCount}
          page={current}
          onChange={setCurrent}
        />
      </List>
    </>
  );
};

const CampaignFilter = ({
  header,
}: {
  header: Header<GiftCardCampaign, any>;
}) => {
  const { selectProps, queryResult } = useSelect<GiftCardCampaign>({
    resource: "gift_card_campaigns",
    optionLabel: "code",
    optionValue: "code",
    debounce: 1000,
    pagination: {
      pageSize: 10,
      mode: "server",
      current: 1,
    },
    queryOptions: {
      enabled: header.column.getFilterValue() !== "",
    },
    onSearch: (value) => [
      {
        field: "code",
        operator: "contains",
        value,
      },
    ],
  });

  return (
    <div>
      <Select
        placeholder="Campaign"
        {...selectProps}
        onChange={(value) => {
          if (selectProps.onChange) selectProps.onChange(value);
          header.column.setFilterValue(value);
        }}
        clearable
      />
    </div>
  );
};
