import { TabProps, Tabs } from "@mantine/core";
import { IconExclamationCircle } from "@tabler/icons";

type TabWithErrorProps = TabProps & { hasError?: boolean };

export default function TabWithError({
  hasError,
  ...props
}: TabWithErrorProps) {
  return hasError ? (
    <Tabs.Tab
      {...props}
      icon={<IconExclamationCircle size={14} color="red" />}
      color="red"
      sx={{ background: "#ffc2c7" }}
    />
  ) : (
    <Tabs.Tab {...props} />
  );
}
