import {
  BaseRecord,
  HttpError,
  IResourceComponentsProps,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/mantine";
import { NumberInput, Select, Textarea, Image } from "@mantine/core";
import { CreateGiftCardTemplate } from "entities/gift_card_template";
import { selectChannels } from "entities/gift_card_template/channel";
import { selectConstraints } from "entities/gift_card_template/constraint";
import { DatePicker } from "@mantine/dates";
import { formatToJst } from "utils/dates";
import ImageDropZone from "components/common/ImageDropZone";

export const GiftCardTemplateCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const { getInputProps, saveButtonProps, setFieldValue } = useForm<
    BaseRecord,
    HttpError,
    CreateGiftCardTemplate
  >({
    initialValues: {
      initial_value: 0,
      expire_at: new Date(),
      channel: "all_channels",
      constraint: "no_constraints",
      image: { file: undefined, base64: "" },
      notes: "",
    },
    transformValues: (values) => {
      return {
        ...values,
        expire_at: formatToJst(values.expire_at),
      };
    },
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <ImageDropZone
        onFileChosen={(data) => setFieldValue("image.base64", data)}
        value={null}
      />
      <Select
        placeholder="Channel"
        data={selectChannels}
        label="Channel"
        clearable
        {...getInputProps("channel")}
      />
      <Select
        placeholder="Constraint"
        data={selectConstraints}
        label="Constraint"
        clearable
        {...getInputProps("constraint")}
      />
      <NumberInput
        defaultValue={0}
        mt="sm"
        label="Initial value"
        {...getInputProps("initial_value")}
      />
      <DatePicker
        placeholder="Pick dates range"
        maw={400}
        label="Expire at"
        defaultValue={new Date()}
        withinPortal
        {...getInputProps("expire_at")}
      />
      <Textarea label="Notes" {...getInputProps("notes")} />
    </Create>
  );
};
