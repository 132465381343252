import { IResourceComponentsProps, useShow, HttpError } from "@refinedev/core";
import { Show } from "@refinedev/mantine";
import { Image, Flex, Grid, Text, Box, Divider, Badge } from "@mantine/core";
import { DetailsCard } from "components/common/show/DetailsPair";
import { ProductSegment } from "entities/product_segment";
import { History } from "components/common/show/History";
import { colorsByBrand } from "entities/product_segment/brand";

export const ProductSegmentShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<ProductSegment, HttpError>();
  const { data, isLoading } = queryResult;
  const productSegment = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Grid>
        <Grid.Col span={8}>
          {productSegment && (
            <DetailsCard title="Preview">
              <Box w={414} bg="white" p="md">
                <div
                  dangerouslySetInnerHTML={{ __html: productSegment.preview }}
                ></div>
              </Box>
            </DetailsCard>
          )}
          <Divider my="md" />
          <DetailsCard>
            {productSegment && (
              <History<ProductSegment>
                resources="product_segments"
                entityId={productSegment.id}
              />
            )}
          </DetailsCard>
        </Grid.Col>
        <Grid.Col span={4}>
          {productSegment && (
            <Flex direction="column" gap="sm">
              <DetailsCard title="Image">
                <Image sx={{ maxWidth: 200 }} src={productSegment.image} />
              </DetailsCard>
              <DetailsCard title="Thumbnail">
                <Image sx={{ maxWidth: 200 }} src={productSegment.thumbnail} />
              </DetailsCard>
              <DetailsCard title="Brand">
                <Badge
                  variant="filled"
                  color={colorsByBrand[productSegment.brand]}
                >
                  {productSegment.brand}
                </Badge>
              </DetailsCard>
              <DetailsCard title="Title">
                <Text>{productSegment.title}</Text>
              </DetailsCard>
              <DetailsCard title="Description">
                <Text>{productSegment.description}</Text>
              </DetailsCard>
              <DetailsCard title="Product Type">
                <Text>{productSegment.product_type}</Text>
              </DetailsCard>
              <DetailsCard title="Product Label">
                <Text>{productSegment.product_label}</Text>
              </DetailsCard>
              {productSegment.price && (
                <DetailsCard title="Price">
                  <Text>${productSegment.price.toFixed(2)}</Text>
                </DetailsCard>
              )}
              {productSegment.compare_at_price && (
                <DetailsCard title="Compare At Price">
                  <Text>${productSegment.compare_at_price.toFixed(2)}</Text>
                </DetailsCard>
              )}
              <DetailsCard title="Created At">
                <Text>{productSegment.created_at.toString()}</Text>
              </DetailsCard>
              <DetailsCard title="Updated At">
                <Text>{productSegment.updated_at.toString()}</Text>
              </DetailsCard>
            </Flex>
          )}
        </Grid.Col>
      </Grid>
    </Show>
  );
};
