import { Button, Card, Container, Group, Stack, Text, Title } from '@mantine/core';
import { IconTrash } from '@tabler/icons';
import { openConfirmModal } from "@mantine/modals";
import { useCustomMutation, useApiUrl } from "@refinedev/core";
import { showNotification } from "@mantine/notifications";


export const PublicSiteSettingsList = () => {
  const { mutate: purgeCacheMutation } = useCustomMutation();
  const apiUrl = useApiUrl();

  const onConfirm = async (site: 'sakuraco' | 'tokyotreat' | 'ichigo') => {
    purgeCacheMutation(
      {
        method: 'post',
        url: `${apiUrl}/settings/purge_cache`,
        values: {
          brand_id: site,
        },
      },
      {
        onSuccess: () => {
          showNotification({
            title: 'Cache Purged',
            message: 'The cache has been purged successfully',
            color: 'green',
          });
        },
        onError: () => {
          showNotification({
            title: 'Error',
            message: 'An error occurred while purging the cache',
            color: 'red',
          });
        },
      }
    );
  }

  const onCancel = () => {
    console.log('Cancel');
  }


  const openModal = (site: 'sakuraco' | 'tokyotreat' | 'ichigo') => openConfirmModal({
    title: `Purge ${site === 'sakuraco' ? 'Sakuraco' : site === 'tokyotreat' ? 'TokyoTreat' : 'Ichigo'} Cache`,
    children: (
      <Text size="sm">
        This action is irreversible and will clear the cache for the selected site.
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    confirmProps: { color: 'red' },
    onCancel: onCancel,
    onConfirm: () => onConfirm(site),
  });

  return (
    <Container size="md" py="xl">
      <Title order={2} mb="lg">Site Settings</Title>

      <Card withBorder shadow="sm" p="lg" radius="md">
        <Stack>
          <Group position="apart">
            <div>
              <Text fw={500} size="lg">Cache Management</Text>
              <Text c="dimmed" size="sm">
                Clear the site cache to force refresh of all cached content
              </Text>
            </div>
            <Group>
              <Button
                leftIcon={<IconTrash size={16} />}
                color="blue"
                variant="light"
                onClick={() => openModal('ichigo')}
              >
                Purge Ichigo Corporate Cache
              </Button>
              <Button
                leftIcon={<IconTrash size={16} />}
                color="yellow"
                variant="light"
                onClick={() => openModal('sakuraco')}
              >
                Purge Sakuraco Cache
              </Button>
              <Button
                leftIcon={<IconTrash size={16} />}
                color="orange"
                variant="light"
                onClick={() => openModal('tokyotreat')}
              >
                Purge TokyoTreat Cache
              </Button>
            </Group>
          </Group>
        </Stack>
      </Card>
    </Container>
  );
};