import { ADMIN, CUSTOMER, CUSTOMER_EXPERIENCE, DEVELOPER, MARKETER, QUALITY_ASSURANCE } from "entities/user/role";
import useRole from "hooks/useRole";
import { DefaultPermission } from "utils/permission";

export default function useArticlesPermissions(): DefaultPermission {
  const { hasRole, hasNotRole } = useRole()

  return {
    canCreate: false,
    canEdit: hasRole(ADMIN, DEVELOPER, QUALITY_ASSURANCE, MARKETER),
    canDelete: hasRole(ADMIN, DEVELOPER, QUALITY_ASSURANCE, MARKETER),
    canList: hasNotRole(CUSTOMER),
    canShow: hasNotRole(CUSTOMER)
  }
}
