import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Show, NumberField, TextField } from "@refinedev/mantine";
import {
  Title,
  Badge,
  Grid,
  Flex,
  Anchor,
  ThemeIcon,
  Box,
  Card,
  Group,
} from "@mantine/core";
import { XpEarning } from "entities/xp_earning";
import { DetailsPair } from "components/common/show/DetailsPair";
import { formatDistanceToNow } from "date-fns";
import { History } from "components/common/show/History";
import { DetailsUser } from "components/common/show/DetailsUser";
import { Text } from "@mantine/core";
import { colorsByState } from "entities/xp_earning/state";
import { colorsBySource } from "entities/xp_earning/source";
import { getInvoiceUrl } from "utils/chargebee";
import { colorsByLockType } from "entities/xp_earning/lock";
import { IconLock, IconLockOpen } from "@tabler/icons";

export const XpEarningShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<XpEarning>();
  const { data, isLoading } = queryResult;

  const xpEarning = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Grid>
        <Grid.Col span={8}>
          {Object.values(xpEarning?.locks || {}).length !== 0 && (
            <Flex direction="column" gap="sm" p="lg">
              <h2>Locks</h2>
              {xpEarning &&
                Object.values(xpEarning.locks).map((lock) => {
                  const isLocked = !lock.released_at;

                  return (
                    <Card
                      key={lock.lock_type}
                      shadow="sm"
                      p="md"
                      radius="md"
                      withBorder
                    >
                      <Group position="apart" mt="xs" mb="xs">
                        {lock.lock_type && (
                          <>
                            <Flex align="center">
                              <Text weight="bold" mr="xs">
                                {lock.lock_type
                                  .toUpperCase()
                                  .replaceAll("_", " ")}
                              </Text>
                            </Flex>
                            <Flex align="center">
                              {isLocked ? (
                                <>
                                  <Text size="lg" mr="md" color="red" italic>
                                    Locked
                                  </Text>

                                  <ThemeIcon
                                    color="red"
                                    size="xl"
                                    variant="light"
                                  >
                                    <IconLock />
                                  </ThemeIcon>
                                </>
                              ) : (
                                <>
                                  <Text size="lg" mr="md" color="blue" italic>
                                    Unlocked
                                  </Text>
                                  <ThemeIcon
                                    color="blue"
                                    size="xl"
                                    variant="light"
                                  >
                                    <IconLockOpen />
                                  </ThemeIcon>
                                </>
                              )}
                            </Flex>
                          </>
                        )}
                      </Group>

                      {!isLocked && (
                        <Flex direction="column" gap="sm">
                          <DetailsPair>
                            <Title order={5}>Released At</Title>
                            {lock?.released_at && (
                              <TextField
                                value={formatDistanceToNow(
                                  new Date(lock.released_at)
                                )}
                              />
                            )}
                          </DetailsPair>
                          <DetailsPair>
                            <Title order={5}>Released Notes</Title>
                            <Text size="sm" color="dimmed">
                              {lock?.released_notes}
                            </Text>
                          </DetailsPair>
                        </Flex>
                      )}
                    </Card>
                  );
                })}
            </Flex>
          )}
          {xpEarning && (
            <History<XpEarning>
              resources={"xp_earnings"}
              entityId={xpEarning.id}
            />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Flex direction="column" gap="sm">
            <DetailsPair>
              <Title order={5}>User</Title>
              {xpEarning?.user && <DetailsUser user={xpEarning.user} />}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Source
              </Title>
              {xpEarning && (
                <Badge color={colorsBySource[xpEarning.source]}>
                  {xpEarning.source.replaceAll("_", " ")}
                </Badge>
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                State
              </Title>
              {xpEarning && (
                <Badge color={colorsByState[xpEarning.state]}>
                  {xpEarning.state}
                </Badge>
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Amount
              </Title>
              <Text color="green" weight="bold">
                {xpEarning?.amount}
              </Text>
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Created At
              </Title>
              {xpEarning?.created_at && (
                <TextField
                  value={formatDistanceToNow(new Date(xpEarning.created_at))}
                />
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Updated At
              </Title>
              {xpEarning?.updated_at && (
                <TextField
                  value={formatDistanceToNow(new Date(xpEarning.updated_at))}
                />
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Remote Invoice
              </Title>
              {xpEarning?.remote_invoice_id && (
                <Anchor
                  href={getInvoiceUrl(xpEarning.remote_invoice_id)}
                  target="_blank"
                >
                  {xpEarning.remote_invoice_id}
                </Anchor>
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Id
              </Title>
              {xpEarning && <NumberField value={xpEarning?.id} />}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Notes
              </Title>
              {xpEarning && <TextField value={xpEarning.notes} />}
            </DetailsPair>
          </Flex>
        </Grid.Col>
      </Grid>
    </Show>
  );
};
