import _ from "lodash"

export type Status = "ACTIVE" | "INACTIVE" | "EXPIRED"
export const statuses: Status[] = ["ACTIVE", "INACTIVE", "EXPIRED"];

export const colorsByStatus: Record<Status, string> = {
	ACTIVE: "green",
	EXPIRED: "red",
	INACTIVE: "gray"
};

export const selectStatuses = statuses.map((status) => {
	return { value: status, label: _.capitalize(status) };
});
