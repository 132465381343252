import { Grid } from "@mantine/core";
import { SanityChecker } from "entities/sanity_checker";
import { useState } from "react";
import { ProblemsList } from "./list/ProblemsList";
import { SanityCheckersSider } from "./list/SanityCheckersSider";

export function SanityCheckersList() {
  const [selectedSanityChecker, setSelectedSanityChecker] =
    useState<SanityChecker | null>(null);

  return (
    <Grid>
      <Grid.Col span={4}>
        <SanityCheckersSider
          selectedSanityChecker={selectedSanityChecker}
          setSelectedSanityChecker={setSelectedSanityChecker}
        />
      </Grid.Col>
      <Grid.Col span={8}>
        {selectedSanityChecker && (
          <ProblemsList selectedSanityChecker={selectedSanityChecker} />
        )}
      </Grid.Col>
    </Grid>
  );
}
