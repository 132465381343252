import _ from "lodash";

export type Source =
  | "ichigo_app"
  | "japanhaul"
  | "ichigo_admin"
  | "migration"
  | "customer_portal"
  | "automatic";


export const sources: Source[] = [
  "ichigo_app",
  "japanhaul",
  "ichigo_admin",
  "migration",
  "customer_portal",
  "automatic"
];

export const selectSources = sources.map((source) => {
  return { value: source, label: _.capitalize(source.replaceAll("_", " ")) };
});

export const colorsBySource: Record<Source, string> = {
  ichigo_app: "blue",
  japanhaul: "red",
  ichigo_admin: "yellow",
  migration: "pink",
  automatic: "gray",
  customer_portal: "orange"
};
