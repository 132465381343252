import {
  ADMIN,
  CUSTOMER,
  DEVELOPER,
  MARKETER,
  QUALITY_ASSURANCE,
} from "entities/user/role";
import useRole from "hooks/useRole";
import { DefaultPermission } from "utils/permission";

export default function useSaleAnnouncementsPermissions(): DefaultPermission {
  const { hasRole, hasNotRole } = useRole();

  return {
    canCreate: hasRole(ADMIN, DEVELOPER, QUALITY_ASSURANCE, MARKETER),
    canEdit: hasRole(ADMIN, DEVELOPER, QUALITY_ASSURANCE, MARKETER),
    canDelete: hasRole(ADMIN, DEVELOPER, QUALITY_ASSURANCE, MARKETER),
    canList: hasNotRole(CUSTOMER),
    canShow: hasNotRole(CUSTOMER),
  };
}
