import { IResourceComponentsProps, useShow, useOne } from "@refinedev/core";
import {
  Show,
  NumberField,
  EmailField,
  DateField,
  TextField,
  BooleanField,
  TagField,
} from "@refinedev/mantine";
import { Title, Image, Group, Chip, Badge } from "@mantine/core";
import { GiftCardTemplate } from "entities/gift_card_template";

export const GiftCardTemplateShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data as GiftCardTemplate;
  return (
    <Show isLoading={isLoading}>
      <Title my="xs" order={5}>
        Image
      </Title>
      <Image sx={{ maxWidth: 200 }} src={record?.image.url} />
      <Title my="xs" order={5}>
        Initial Value
      </Title>
      <Badge size="lg" color="grape">
        ${record?.initial_value}
      </Badge>
      <Title my="xs" order={5}>
        Channel
      </Title>
      <Badge color="blue">{record?.channel}</Badge>
      <Title my="xs" order={5}>
        Constraint
      </Title>
      <Badge color="orange">{record?.constraint}</Badge>
      <Title my="xs" order={5}>
        Expire At
      </Title>
      <DateField value={record?.expire_at} />
      <Title my="xs" order={5}>
        Id
      </Title>
      <NumberField value={record?.id ?? ""} />
      <Title my="xs" order={5}>
        Created At
      </Title>
      <DateField value={record?.created_at} />
      <Title my="xs" order={5}>
        Updated At
      </Title>
      <DateField value={record?.updated_at} />
      <Title my="xs" order={5}>
        Notes
      </Title>
      <TextField value={record?.notes} />
      <Title my="xs" order={5}>
        Last updated by
      </Title>
      <TextField value={record?.created_by_id} />
    </Show>
  );
};
