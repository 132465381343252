
export type Duration = 1 | 3 | 6 | 12

export const colorsByDuration: Record<Duration, string> = {
    1: "blue",
    3: "yellow",
    6: "red",
    12: "green"
};

export const durations: Duration[] = [1, 3, 6, 12]

export const selectDurations = durations.map(duration => {
    return { value: duration.toString(), label: duration.toString() }
})
