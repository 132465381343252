import { Tabs } from "@mantine/core";
import { useApiUrl, useCustom } from "@refinedev/core";
import {
  IconDashboard,
  IconForms,
  IconGiftCard,
  IconUsers,
} from "@tabler/icons";
import { useState } from "react";

type MetabaseDashboardResponse = {
  iframe_url: string;
};

type DashboardKey =
  | "main_dashboard"
  | "users_dashboard"
  | "gift_cards_dashboard"
  | "surveys_dashboard";

export default function Dashboard() {
  const [activeTab, setActiveTab] = useState<DashboardKey>("main_dashboard");
  const apiUrl = useApiUrl();

  const { data } = useCustom<MetabaseDashboardResponse>({
    url: `${apiUrl}/metabase_dashboards/${activeTab}`,
    method: "get",
    queryOptions: {
      enabled: true,
    },
  });

  return (
    <div>
      <Tabs
        value={activeTab}
        onTabChange={(newValue: DashboardKey) =>
          newValue && setActiveTab(newValue)
        }
      >
        <Tabs.List>
          <Tabs.Tab value="main_dashboard" icon={<IconDashboard />}>
            Overview
          </Tabs.Tab>
          <Tabs.Tab value="users_dashboard" icon={<IconUsers />}>
            Users
          </Tabs.Tab>
          <Tabs.Tab value="surveys_dashboard" icon={<IconForms />}>
            Surveys
          </Tabs.Tab>
          <Tabs.Tab value="gift_cards_dashboard" icon={<IconGiftCard />}>
            Gift Cards
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={activeTab} p="lg">
          <iframe
            src={data?.data.iframe_url}
            frameBorder="0"
            width="100%"
            style={{ minHeight: "calc(100vh - 100px)" }}
            allowTransparency
          ></iframe>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}
