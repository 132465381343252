import _ from "lodash"

export type Brand = "TT" | "YT" | "SC" | "NMNL"

export const colorsByBrand: Record<Brand, string> = {
    TT: "orange",
    SC: "Brown",
    YT: "purple",
    NMNL: "pink"
};

export const brands: Brand[] = ["TT", "YT", "SC", "NMNL"]

export const selectBrands = brands.map(brand => {
    return { value: brand, label: brand }
})
