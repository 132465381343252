import {
  BaseRecord,
  HttpError,
  IResourceComponentsProps,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/mantine";
import { Textarea, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { CreateSalesAnnouncement } from "entities/sale_announcement";

export const SaleAnnouncementCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const { getInputProps, saveButtonProps } = useForm<
    BaseRecord,
    HttpError,
    CreateSalesAnnouncement
  >({
    initialValues: {
      title: "",
      url: "",
      activated_at: new Date(),
    },
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <TextInput label="Title" {...getInputProps("title")} />
      <Textarea label="URL" {...getInputProps("url")} />
      <DatePicker
        placeholder="Pick dates range"
        maw={400}
        label="Activated at"
        defaultValue={new Date()}
        withinPortal
        {...getInputProps("activated_at")}
      />
    </Create>
  );
};
