import { HttpError, IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/mantine";
import {
  NumberInput,
  Textarea,
  Checkbox,
  Title
} from "@mantine/core";
import { CoinEarningReward, UpdateCoinEarningReward } from "entities/coin_earning_reward";
import _ from "lodash";
import { isInRange, isNotEmpty } from "@mantine/form";
import { colorsByKind } from "entities/coin_earning_reward/kind";

export const CoinEarningRewardEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    getInputProps,
    saveButtonProps,
    refineCore: { queryResult },
  } = useForm<CoinEarningReward, HttpError, UpdateCoinEarningReward>({
    initialValues: {
      coins: 0,
      active: false,
      description: "",
    },
    validateInputOnChange: true,
    validate: {
      coins: isInRange({ min: 1 }, "coins should be a positive number"),
      description: isNotEmpty()
    },
  });

  const kind = queryResult?.data?.data?.kind

  return (
    <Edit saveButtonProps={saveButtonProps}>
      {kind && <Title color={colorsByKind[kind]} order={3}>{kind.toUpperCase()}</Title>}
      <NumberInput
        defaultValue={0}
        mt="sm"
        label="Coins"
        min={1}
        {...getInputProps("coins")}
      />
      <Textarea
        defaultValue=""
        mt="sm"
        label="Description"
        {...getInputProps("description")}
      />
      <Checkbox
        mt="sm"
        label="Active"
        {...getInputProps("active", { type: "checkbox" })}
      />
    </Edit>
  );
};
