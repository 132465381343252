import {
  ActionIcon,
  Anchor,
  Avatar,
  Badge,
  Box,
  Card,
  Flex,
  Grid,
  Text,
  Title,
} from "@mantine/core";
import { IconCheck, IconTrash } from "@tabler/icons";
import {
  colorsByKey,
  CoinSpendingProblem,
} from "entities/coin_spending_problem";
import { Link } from "react-router-dom";
import { getInvoiceUrl } from "utils/chargebee";

export type CoinSpendingProblemCardProps = {
  problem: CoinSpendingProblem;
  fixProblem: (problem: CoinSpendingProblem) => void;
  deleteProblem: (problem: CoinSpendingProblem) => void;
};

export function CoinSpendingProblemCard({
  problem,
  fixProblem,
  deleteProblem,
}: CoinSpendingProblemCardProps) {
  return (
    <Card key={problem.id}>
      <Grid>
        <Grid.Col span={4}>
          <Flex direction="column" gap="sm">
            <Badge color={colorsByKey[problem.key]}>{problem.key}</Badge>
            <Badge color="orange">{problem.state}</Badge>
            <Anchor
              component={Link}
              to={`/users/show/${problem.user.id}`}
              target="_blank"
            >
              <Flex align="center">
                <Avatar src={problem.user.image} size="xs" />
                <Text size="xs">{problem.user.email}</Text>
              </Flex>
            </Anchor>
          </Flex>
        </Grid.Col>
        <Grid.Col span={6}>
          <Title order={5} color="gray.7">
            {problem.title}
          </Title>
          {problem.coin_spending && (
            <Box>
              <Anchor
                component={Link}
                to={`/coin_spendings/show/${problem.coin_spending.id}`}
                target="_blank"
              >
                <Flex align="center" mt="md">
                  <Text size="xs">
                    Coin Spending (#{problem.coin_spending.id}) of{" "}
                    {problem.coin_spending.coins_amount} coins
                  </Text>
                </Flex>
              </Anchor>
            </Box>
          )}
        </Grid.Col>
        <Grid.Col span={2}>
          <Flex align="center" h="100%">
            {problem.state === "fixed" ? (
              <Text color="green" weight="bold" mr="md" italic>
                Fixed!
              </Text>
            ) : (
              <ActionIcon
                color="green"
                title="Fix"
                onClick={() => fixProblem(problem)}
              >
                <IconCheck />
              </ActionIcon>
            )}
            <ActionIcon
              color="gray"
              title="Delete"
              onClick={() => deleteProblem(problem)}
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        </Grid.Col>
      </Grid>
    </Card>
  );
}
