export type Status = "published" | "draft";

export const statuses: Status[] = ["draft", "published"];

export const selectStatuses = statuses.map((status) => ({
  value: status,
  label: status.toUpperCase(),
}));

export const colorsByStatus: Record<Status, string> = {
    "draft": "gray",
    "published": "green"
}
