import { Avatar, Card, Flex, HoverCard, Text } from "@mantine/core"
import { useNavigation } from "@refinedev/core"
import { ShowButton } from "@refinedev/mantine"
import { User } from "entities/user"

type UserCardProps = {
  user: User
}

export function UserCard({ user }: UserCardProps) {
  const { show } = useNavigation()

  return (
    <Card key={user.id} px={16} h={75} w={260} onClick={() => show("users", user.id)} withBorder sx={{ cursor: "pointer" }}>
      <Flex justify="space-between" align="center" h="100%">
        <Avatar src={user.image} />
        <Flex direction="column">
          <Text size="md" sx={theme => theme.colorScheme === "dark" ?
            ({ color: theme.white }) : ({ color: theme.black })}>{user.email}</Text>
          <Text size="xs" sx={theme => theme.colorScheme === "dark" ? ({ color: theme.colors.gray[6] }) : ({ color: theme.colors.gray[5] })}>
            {user.first_name} {user.last_name}
          </Text>
        </Flex>
      </Flex>
    </Card >
  )
}

