import { User } from "entities/user";
import { CoinSpending } from "entities/coin_spending";

export type CoinSpendingProblem = {
  id: number;
  key: Key;
  notes: string;
  state: string;
  title: string;
  user: User;
  coin_spending: CoinSpending;
};

export type Key = "orphan_coin_spending_error";

export const keys: Key[] = ["orphan_coin_spending_error"];

export const selectKeys = keys.map((key) => {
  return { value: key, label: key.replaceAll("_", " ").toUpperCase() };
});

export const colorsByKey: Record<Key, string> = {
  orphan_coin_spending_error: "orange",
};
