import {
  Badge,
  Container,
  LoadingOverlay,
  Pagination,
  ScrollArea,
  Select,
  Table,
  Text,
} from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, Header } from "@tanstack/react-table";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { formatDistanceToNow } from "date-fns";
import { User } from "entities/user";
import {
  colorsByKind,
  colorsBySource,
  LoginActivity,
  selectLoginActivityKinds,
  selectLoginActivitySources,
} from "entities/user/loginActivity";
import { useOutletContext } from "react-router-dom";
export type LoginActivitiesResponse = LoginActivity[];

export function LoginActivities() {
  const { user } = useOutletContext<{ user: User | null }>();

  const columns: ColumnDef<LoginActivity>[] = [
    {
      id: "id",
      accessorKey: "id",
      header: "Id",
      meta: {
        isPlaceholder: true,
      },
    },
    {
      id: "source",
      accessorKey: "source",
      header: "Source",
      cell: ({ getValue }) => {
        const source = getValue<LoginActivity["source"]>();
        return (
          <Badge variant="filled" color={colorsBySource[source]}>
            {source}
          </Badge>
        );
      },
      meta: {
        isPlaceholder: true,
      },
    },
    {
      id: "kind",
      accessorKey: "kind",
      header: "Kind",
      cell: ({ getValue }) => {
        const kind = getValue<LoginActivity["kind"]>();
        return (
          <Badge variant="filled" color={colorsByKind[kind]}>
            {kind}
          </Badge>
        );
      },
      meta: {
        isPlaceholder: true,
      },
    },
    {
      id: "ip_address",
      accessorKey: "ip_address",
      header: "IP Address",
      meta: {
        isPlaceholder: true,
      },
    },
    {
      id: "created_at",
      accessorKey: "created_at",
      header: "Created At",
      cell: ({ getValue }) => {
        const createdAt = getValue<LoginActivity["created_at"]>();

        return <Text sx={{ fontStyle: "italic" }}>{createdAt.toString()}</Text>;
      },
      meta: {
        isPlaceholder: true,
      },
    },
  ];

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable<LoginActivity>({
    columns,
    refineCoreProps: {
      resource: `users/${user?.id}/login_activities`,
      queryOptions: {
        enabled: !!user,
      },
    },
  });

  return (
    <Container p="md">
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<LoginActivity> headerGroups={getHeaderGroups()} />
          <Rows<LoginActivity> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </Container>
  );
}
