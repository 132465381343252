import {
  Anchor,
  Avatar,
  Badge,
  Button,
  Card,
  Flex,
  Grid,
  Loader,
  Pagination,
  Progress,
  Select,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import {
  HttpError,
  useApiUrl,
  useCustomMutation,
  useList,
} from "@refinedev/core";
import { EmailField } from "@refinedev/mantine";
import {
  IconAlertTriangle,
  IconCircleCheck,
  IconFileDots,
  IconRocket,
} from "@tabler/icons";
import { LoyaltyOrderProblem } from "entities/loyalty_order_problem";
import {
  ProblemType,
  SanityChecker,
  selectProblemTypes,
} from "entities/sanity_checker";
import { useState } from "react";
import { Link } from "react-router-dom";

const PAGE_SIZE = 10;
const LAST_ORDERED = "last_ordered";
const ALL_USERS = "all_users";

const DEFAULT_USERS_SCOPE = LAST_ORDERED;
const DEFAULT_PROBLEM_TYPE: ProblemType = "loyalty_order_problem";

type SanityCheckersListProps = {
  selectedSanityChecker: SanityChecker | null;
  setSelectedSanityChecker: (sanity_checker: SanityChecker) => void;
};

export function SanityCheckersSider({
  selectedSanityChecker,
  setSelectedSanityChecker,
}: SanityCheckersListProps) {
  const [sanityCheckersPage, setSanityCheckersPage] = useState(1);
  const [usersScope, setUsersScope] = useState(DEFAULT_USERS_SCOPE);
  const [problemType, setProblemType] = useState(DEFAULT_PROBLEM_TYPE);

  const {
    data: sanityCheckersData,
    isLoading: isSanityCheckersLoading,
    isError: isSanityCheckersError,
    refetch: refetchSanityCheckers,
  } = useList<SanityChecker, HttpError>({
    resource: "sanity_checkers",
  });

  const apiUrl = useApiUrl();

  const { mutate: launchSanityChecker } = useCustomMutation<SanityChecker>();

  const handleLaunchSanityChecker = () => {
    launchSanityChecker(
      {
        url: `${apiUrl}/sanity_checkers/`,
        method: "post",
        values: {
          users_scope: usersScope,
          check_type: problemType,
        },
      },
      {
        onSuccess: () => refetchSanityCheckers(),
      },
    );
  };

  const sanityCheckers = sanityCheckersData?.data ?? [];

  if (isSanityCheckersLoading) {
    return <div>Loading...</div>;
  }

  if (isSanityCheckersError) {
    return <div>Something went wrong!</div>;
  }

  return (
    <Flex direction="column" gap="sm">
      <Flex gap="sm">
        <Select
          value={problemType}
          onChange={(newValue) =>
            newValue && setProblemType(newValue as ProblemType)
          }
          defaultValue={DEFAULT_PROBLEM_TYPE}
          data={selectProblemTypes}
        />
        <Select
          value={usersScope}
          onChange={(newValue) => newValue && setUsersScope(newValue)}
          defaultValue={DEFAULT_USERS_SCOPE}
          data={[
            { label: "All Users", value: ALL_USERS },
            {
              label: "Users With New Orders",
              value: LAST_ORDERED,
            },
          ]}
        />
        <Button leftIcon={<IconRocket />} onClick={handleLaunchSanityChecker}>
          Run Sanity Checker
        </Button>
      </Flex>
      {sanityCheckers.map((sanityChecker) => {
        return (
          <Card
            shadow="md"
            withBorder
            onClick={() => setSelectedSanityChecker(sanityChecker)}
            bg={
              selectedSanityChecker?.id === sanityChecker.id
                ? "gray.1"
                : "primary"
            }
          >
            <Grid>
              <Grid.Col span={6}>
                <Title order={5} color="purple">
                  {sanityChecker.check_type}
                </Title>
                <Text size="xs" color="gray.6" weight="bold" mb="sm">
                  {sanityChecker.created_at.toString()}
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Flex justify="end">
                  {sanityChecker.users_scope === "single_user" ? (
                    <Anchor
                      component={Link}
                      to={`/users/show/${sanityChecker.user.id}/user_sanity_check_issues`}
                    >
                      <Flex align="center" gap="sm">
                        <EmailField
                          size="xs"
                          value={sanityChecker.user.email}
                        />
                        <Avatar src={sanityChecker.user.image} />
                      </Flex>
                    </Anchor>
                  ) : (
                    <Badge color="pink">{sanityChecker.users_scope}</Badge>
                  )}
                </Flex>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text size="xs" color="blue">
                  Checking Progress
                </Text>
                <Progress
                  size="md"
                  label="Checker progress"
                  color="blue"
                  value={
                    (sanityChecker.finished_items / sanityChecker.total_items) *
                    100
                  }
                  placeholder="Progress"
                />
                <Text size="xs" color="green">
                  Fixing Progress
                </Text>
                <Progress
                  color="green"
                  size="md"
                  label="Fixing progress"
                  value={sanityChecker.fixing_progress}
                  placeholder="Progress"
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Flex justify="center" align="center">
                  <ThemeIcon color="red" variant="light">
                    <IconAlertTriangle />
                  </ThemeIcon>
                  <Title order={6} color="red">
                    Found issues
                  </Title>
                </Flex>
                <Flex justify="center">
                  <Text color="red">{sanityChecker.issues_count}</Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={4}>
                <Badge color="blue">{sanityChecker.status}</Badge>
              </Grid.Col>
            </Grid>
          </Card>
        );
      })}
      <Pagination
        position="right"
        total={sanityCheckersData.total / PAGE_SIZE}
        page={sanityCheckersPage}
        onChange={setSanityCheckersPage}
        my="sm"
      />
    </Flex>
  );
}
