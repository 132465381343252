import {
  Anchor,
  Button,
  CopyButton,
  Flex,
  Loader,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconConfetti, IconGift } from "@tabler/icons";
import { Dispatch, SetStateAction, useContext } from "react";
import { CoinSpending } from "entities/coin_spending";
import { Screen, UserContext } from "./Layout";
import { japanhaulSite } from "utils/japanhaul";

type RewardViewProps = {
  setScreen: Dispatch<SetStateAction<Screen>>;
  coinSpending: CoinSpending | null;
};

export default function RewardView({
  setScreen,
  coinSpending,
}: RewardViewProps) {
  const { user, isUserLoading } = useContext(UserContext);

  if (!user || isUserLoading || !coinSpending)
    return (
      <Flex h="100%" w="100%" justify="center" align="center">
        <Loader />
      </Flex>
    );

  return (
    <Flex direction="column" justify="space-between" h={500}>
      <Flex gap="md" direction="column" align="center" mt={80}>
        <Title color="gray.8" order={2} mt="lg">
          Congratulations!&nbsp;
          <IconConfetti />
        </Title>
        <Flex gap="sm" align="center" py="xl">
          <ThemeIcon color="red" size="xl" variant="light">
            <IconGift />
          </ThemeIcon>
          <Text color="red" size="lg">
            {coinSpending.loyalty_reward.name}
          </Text>
        </Flex>
        <Flex gap="sm">
          <TextInput color="gray.8" value={coinSpending.reward_code} readOnly />
          <CopyButton value={coinSpending.reward_code} timeout={10000}>
            {({ copied, copy }) => (
              <Button color={copied ? "green" : "red"} onClick={copy}>
                {copied ? "Copied Code" : "Copy Code"}
              </Button>
            )}
          </CopyButton>
        </Flex>
      </Flex>
      <Flex direction="column">
        <Text color="gray.7" mb="xl" italic>
          Copy this coupon code and use it on your next purchase with us. The
          code has also been sent to your email.
        </Text>
        <Anchor
          color="red"
          href={japanhaulSite}
          underline={false}
          align="center"
          sx={{ alignSelf: "stretch" }}
        >
          Continue Shopping
        </Anchor>
      </Flex>
    </Flex>
  );
}
