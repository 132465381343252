import { useSelect } from "@refinedev/core";
import { Select } from "@mantine/core";
import { Article } from "entities/article";
import _ from "lodash";

type ArticleSelectProps = {
  onChange: (articleId: Article["id"]) => any,
  value?: Article["id"],
  defaultValue?: Article["id"]
  label: string
}

export default function ArticleSelect({ onChange, value, defaultValue, label }: ArticleSelectProps) {
  const { options } = useSelect<Article>(
    {
      resource: "articles",
      optionLabel: "title",
      optionValue: "id",
      debounce: 1000,
      defaultValue: defaultValue?.toString(),
      pagination: {
        pageSize: 10,
        mode: "server",
        current: 1,
      },
      onSearch: (value) => [
        {
          field: "title",
          operator: "contains",
          value,
        },
      ],
    }
  );

  const updatedOptions = options.map(option => ({ ...option, value: option.value.toString() }));

  return (
    <Select
      label={label}
      placeholder="Select an article"
      withinPortal
      my="xl"
      clearable
      data={updatedOptions}
      value={value ? value?.toString() : defaultValue?.toString()}
      onChange={(articleId) => articleId && onChange(parseInt(articleId))}
    />
  )
}

