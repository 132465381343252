import {
  BaseRecord,
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useOne,
} from "@refinedev/core";
import {
  DateField,
  Edit,
  SaveButton,
  useForm,
  useSelect,
} from "@refinedev/mantine";
import {
  NumberInput,
  Select,
  Textarea,
  Text,
  Image,
  Group,
  Badge,
  Avatar,
  Code,
  Flex,
  Button,
} from "@mantine/core";
import { CreateGiftCard, GiftCard } from "entities/gift_card";
import { selectChannels } from "entities/gift_card_template/channel";
import { selectConstraints } from "entities/gift_card_template/constraint";
import { DatePicker } from "@mantine/dates";
import { forwardRef, useState } from "react";
import { User } from "entities/user";
import { formatToJst } from "utils/dates";

export const GiftCardEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    getInputProps,
    saveButtonProps,
    setFieldValue,
    values,
    refineCore: { queryResult },
  } = useForm<BaseRecord, HttpError, CreateGiftCard>({
    initialValues: {
      expire_at: new Date(),
      channel: "all_channels",
      constraint: "no_constraints",
      image: { url: undefined },
      notes: "",
      balance: 0,
      user_id: undefined,
      is_save_and_send: false,
      notification_scheduled_at: undefined,
    },
    transformValues: (values) => {
      return {
        ...values,
        expire_at: formatToJst(values.expire_at),
      };
    },
  });

  const { selectProps: usersProps, queryResult: usersResult } = useSelect<User>(
    {
      resource: "users",
      optionLabel: "email",
      optionValue: "id",
      debounce: 1000,
      defaultValue: getInputProps("user_id").value,
      defaultValueQueryOptions: {
        onSuccess: (data) => {
          return data;
        },
      },
      pagination: {
        pageSize: 10,
        mode: "server",
        current: 1,
      },
      onSearch: (value) => [
        {
          field: "email",
          operator: "eq",
          value,
        },
      ],
    }
  );

  const giftCard = queryResult?.data?.data as GiftCard | undefined;

  const imageProps = getInputProps("image");

  const user = useOne<User, HttpError>({
    resource: "users",
    id: giftCard?.user_id,
  }).data?.data;

  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);

  const expireAt = new Date(values.expire_at);

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      footerButtons={({ saveButtonProps }) => (
        <>
          {giftCard?.status == "draft" || giftCard?.status == "scheduled" ? (
            <>
              <SaveButton {...saveButtonProps}>Save to Draft</SaveButton>
              <Button
                {...saveButtonProps}
                onClickCapture={(e: React.PointerEvent<HTMLButtonElement>) => {
                  setFieldValue("is_save_and_send", true);
                }}
              >
                Activate and Notify Now
              </Button>
            </>
          ) : (
            <SaveButton {...saveButtonProps}>Save</SaveButton>
          )}
        </>
      )}
    >
      <Preview file={imageProps.value.file} srcUrl={imageProps.value} />
      <Code color="lime">{giftCard?.code || ""}</Code>

      {giftCard?.is_draft ? (
        <Select
          label="User"
          placeholder="Select a user"
          withinPortal
          my="xl"
          {...usersProps}
          clearable
          data={usersProps.data || []}
          {...getInputProps("user_id")}
          onChange={(userId) =>
            userId && setFieldValue("user_id", parseInt(userId))
          }
        />
      ) : (
        <Flex my="lg" gap="lg">
          <Avatar src={user?.image} size="lg" />
          <Flex direction="column">
            <Text size="lg" color="orange">
              {user?.email}
            </Text>
            <Flex gap="xs">
              <Text size="sm">{user?.first_name}</Text>
              <Text size="sm">{user?.last_name}</Text>
            </Flex>
          </Flex>
        </Flex>
      )}
      <Select
        placeholder="Channel"
        data={selectChannels}
        label="Channel"
        clearable
        {...getInputProps("channel")}
      />
      <Select
        placeholder="Constraint"
        data={selectConstraints}
        label="Constraint"
        clearable
        {...getInputProps("constraint")}
      />
      <NumberInput
        defaultValue={0}
        mt="sm"
        label="balance"
        {...getInputProps("balance")}
      />
      <DatePicker
        placeholder="Pick dates range"
        maw={400}
        label="Expire at"
        defaultValue={new Date()}
        withinPortal
        {...getInputProps("expire_at")}
        value={expireAt}
      />
      <Textarea label="Notes" {...getInputProps("notes")} />

      {/* Set DatePicker default value */}
      {giftCard &&
        (giftCard.status == "draft" || giftCard.status == "scheduled" ? (
          <DatePicker
            placeholder="Schedule gift card activation"
            maw={400}
            label="Schedule gift card activation"
            defaultValue={new Date(giftCard!.notification_scheduled_at)}
            minDate={tomorrow}
            withinPortal
            {...getInputProps("notification_scheduled_at")}
          />
        ) : (
          <>
            <DatePicker
              placeholder="Schedule gift card activation"
              maw={400}
              disabled
              label="Schedule gift card activation"
              defaultValue={new Date(giftCard!.notification_sent_at)}
              minDate={tomorrow}
              withinPortal
            />
          </>
        ))}
    </Edit>
  );
};

const Preview = ({ file, srcUrl }: { file: File; srcUrl: string }) => {
  if (file) {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        src={imageUrl}
        height={100}
        width="auto"
        my="lg"
        imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
      />
    );
  } else if (srcUrl) {
    return <Image src={srcUrl} height={100} width="auto" my="lg" />;
  } else {
    return <div />;
  }
};

const UserSelectItem = forwardRef<HTMLDivElement, User>((user: User, ref) => (
  <div ref={ref} {...user} id={user.id.toString()}>
    <Group noWrap>
      <Avatar src={user.image} />
      <Text size="xs">{user.email}</Text>
    </Group>
  </div>
));
