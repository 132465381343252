import {
  HttpError,
  IResourceComponentsProps,
  useSelect,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/mantine";
import {
  NumberInput,
  Select,
  Textarea,
  TextInput,
  Flex,
  Badge,
  Text,
} from "@mantine/core";
import { User } from "entities/user";
import { selectSources } from "entities/coin_spending/source";
import { isInRange } from "@mantine/form";
import { CreateCoinSpending } from "entities/coin_spending";
import { LoyaltyReward } from "entities/loyalty_reward";
import {
  Destination,
  colorsByDestination,
} from "entities/loyalty_reward/destination";
import { Kind, colorsByKind } from "entities/loyalty_reward/kind";

export const CoinSpendingEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    getInputProps,
    saveButtonProps,
    errors,
    setFieldValue,
    values,
    refineCore: { queryResult },
  } = useForm<CreateCoinSpending, HttpError, CreateCoinSpending>({
    initialValues: {
      source: "ichigo_admin",
      coins_amount: 0,
      notes: "",
      user: null,
      loyalty_reward: null,
      remote_invoice_id: null,
    },
    validateInputOnBlur: true,
    validate: {
      coins_amount: isInRange({ min: 1 }, "COIN should be a positive number"),
    },
  });

  const { options, onSearch } = useSelect<User>({
    resource: "users",
    optionLabel: "email",
    optionValue: "id",
    debounce: 1000,
    pagination: {
      pageSize: 10,
      mode: "server",
      current: 1,
    },
    onSearch: (value) => [
      {
        field: "email",
        operator: "eq",
        value,
      },
    ],
  });

  const {
    onSearch: onRewardSearch,
    queryResult: { data },
  } = useSelect<LoyaltyReward>({
    resource: "loyalty_rewards",
    optionLabel: "name",
    optionValue: "id",
    debounce: 1000,
    pagination: {
      pageSize: 10,
      mode: "server",
      current: 1,
    },
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value,
      },
    ],
  });

  const rewardOptions =
    data?.data
      ?.map((reward) => {
        return {
          value: reward.id.toString(),
          label: reward.name,
          ...reward,
        };
      })
      .sort((a, b) => (a.kind === "fixed_amount" ? -1 : 1)) || [];

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Select
        data={options}
        onSearchChange={(v) => {
          onSearch(v);
        }}
        {...getInputProps("user_id")}
        onChange={(v) => v && setFieldValue("user_id", parseInt(v))}
        placeholder={values.user?.email}
        label="User"
        searchable
      />
      <Select
        placeholder="Source"
        data={selectSources}
        label="Source"
        description="This should normally be 'Ichigo Admin' choose another source only if it makes sense, and please fill the notes for with the reason."
        {...getInputProps("source")}
      />
      <Select
        data={rewardOptions}
        onSearchChange={(v) => {
          onSearch(v);
        }}
        {...getInputProps("loyalty_reward_id")}
        onChange={(v) => {
          if (v) {
            setFieldValue("loyalty_reward_id", v);

            const loyaltyReward = rewardOptions.find(
              (value) => value.id.toString() === v,
            );
            if (loyaltyReward) setFieldValue("loyalty_reward", loyaltyReward);
          }
        }}
        placeholder={values.loyalty_reward?.name}
        label="Loyalty Reward"
        searchable
        itemComponent={(
          props: LoyaltyReward & { id: string; label: string; value: string },
        ) => (
          <Flex {...props} justify="space-between">
            <Flex>
              {props.kind === "fixed_amount" ? (
                <Badge variant="filled" color="yellow" sx={{ width: "50px" }}>
                  ${props.amount_in_usd}
                </Badge>
              ) : (
                <Badge variant="filled" color="pink" sx={{ width: "50px" }}>
                  <Text size="md">∞</Text>
                </Badge>
              )}
              <Text ml="md" weight="bold">
                {props.label}
              </Text>
            </Flex>
            <Flex gap="md">
              <Badge color={colorsByKind[props.kind as Kind]}>
                {props.kind}
              </Badge>
              <Badge
                color={colorsByDestination[props.destination as Destination]}
              >
                {props.destination}
              </Badge>
            </Flex>
          </Flex>
        )}
      />
      {(!values.loyalty_reward ||
        values.loyalty_reward?.kind === "variable_amount") && (
        <NumberInput
          defaultValue={0}
          mt="sm"
          label="Coin Amount"
          min={1}
          {...getInputProps("coins_amount")}
        />
      )}
      <Textarea
        defaultValue=""
        mt="sm"
        label="Notes"
        {...getInputProps("notes")}
      />
      <TextInput
        mt="sm"
        label="Remote Invoice Id"
        {...getInputProps("remote_invoice_id")}
      />
    </Edit>
  );
};
