import _ from "lodash";

export type Source =
  | "ichigo_app"
  | "tt_mini_market"
  | "subscription_sites"
  | "japan_haul"
  | "migration"
  | "ichigo_admin";

export const sources: Source[] = [
  "ichigo_app",
  "tt_mini_market",
  "subscription_sites",
  "ichigo_admin",
  "japan_haul",
  "migration"
];

export const selectSources = sources.map((source) => {
  return { value: source, label: _.capitalize(source.replaceAll("_", " ")) };
});

export const colorsBySource: Record<Source, string> = {
  ichigo_app: "blue",
  tt_mini_market: "orange",
  subscription_sites: "lime",
  ichigo_admin: "yellow",
  japan_haul: "red",
  migration: "pink"
};
