import { Audience, AudienceState } from "./audience";
import { RewardType } from "./rewardType";
import {
  GiveawaysConfig,
  HomeBannerConfig,
  PackageDescriptionConfig,
} from "./placement";
import {
  EmailNotificationConfig,
  InAppNotificationConfig,
  PushNotificationConfig,
} from "./notification";

export type Survey = {
  id: number;
  user_id: number;
  title: string;
  description: string;
  image: string;
  url: string;
  audience: Audience;
  audience_state: AudienceState;
  active_from: Date;
  active_to: Date;
  created_at: Date;
  updated_at: Date;
  reward_ids: number[];
  reward_type: RewardType;
  reward_winner: number;
  coupon_code: string | undefined;
  max_winner: number | undefined;
  home_banner_config: HomeBannerConfig;
  package_description_config: PackageDescriptionConfig;
  giveaways_config: GiveawaysConfig;
  push_notification_config: PushNotificationConfig;
  in_app_notification_config: InAppNotificationConfig;
  email_notification_config: EmailNotificationConfig;
};

export type CreateSurvey = Survey;

export type EditSurvey = Survey;

export type SurveyFormValues = Pick<
  Survey,
  | "title"
  | "description"
  | "image"
  | "url"
  | "audience"
  | "audience_state"
  | "reward_type"
> &
  Partial<Pick<Survey, "user_id">> & {
    active_from: Date | string;
    active_to: Date | string;
    reward_config?: RewardConfig;
    home_banner_config: HomeBannerConfig;
    package_description_config: PackageDescriptionConfig;
    giveaways_config: GiveawaysConfig;
    push_notification_config: PushNotificationConfig;
    in_app_notification_config: InAppNotificationConfig;
    email_notification_config: EmailNotificationConfig;
    coupon_code: string | undefined;
  };

export type RewardConfig = SubscriptionBoxRewardConfig;

export type SubscriptionBoxRewardConfig = {
  brand: string;
  max_winner: number | undefined;
};

export const DEFAULT_REWARD_CONFIG = {
  brand: "TT",
  max_winner: undefined,
};
