import _ from "lodash";

export type Source = "end_of_year_reset" | "ichigo_admin";

export const sources: Source[] = ["end_of_year_reset", "ichigo_admin"];

export const selectSources = sources.map((source) => {
  return { value: source, label: _.capitalize(source.replaceAll("_", " ")) };
});

export const colorsBySource: Record<Source, string> = {
  end_of_year_reset: "lime",
  ichigo_admin: "yellow",
};
