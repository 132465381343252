import { Tabs } from "@mantine/core";
import {
  IResourceComponentsProps,
  useShow,
  HttpError,
  useOne,
} from "@refinedev/core";
import { Show } from "@refinedev/mantine";
import { IconListDetails, IconMessage } from "@tabler/icons";
import { Survey } from "entities/survey";
import { User } from "entities/user";
import { ShowDetails } from "./show/ShowDetails";
import { ShowResponses } from "./show/ShowResponses";

export const SurveyShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<Survey, HttpError>();
  const { data, isLoading } = queryResult;
  const survey = data?.data;

  const user = useOne<User, HttpError>({
    resource: "users",
    id: survey?.user_id,
    queryOptions: {
      enabled: !!survey?.user_id,
    },
  }).data?.data;

  return (
    <Show isLoading={isLoading}>
      <Tabs defaultValue="details">
        <Tabs.List>
          <Tabs.Tab value="details" icon={<IconListDetails />}>
            Details
          </Tabs.Tab>
          <Tabs.Tab value="responses" icon={<IconMessage />}>
            Responses
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="details">
          {survey && user && <ShowDetails survey={survey} user={user} />}
        </Tabs.Panel>
        <Tabs.Panel value="responses" pt="md">
          {survey && <ShowResponses surveyId={survey.id} />}
        </Tabs.Panel>
      </Tabs>
    </Show>
  );
};
