import { IResourceComponentsProps, useShow, HttpError } from "@refinedev/core";
import { Show, NumberField, DateField, TextField } from "@refinedev/mantine";
import { Title, Badge, Flex, Anchor, Grid } from "@mantine/core";
import { DetailsPair } from "components/common/show/DetailsPair";
import { History } from "components/common/show/History";

import { SaleAnnouncement } from "entities/sale_announcement";

export const SaleAnnouncementShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<SaleAnnouncement, HttpError>();
  const { data, isLoading } = queryResult;
  const saleAnnouncement = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Grid>
        <Grid.Col span={8}>
          {saleAnnouncement?.id && (
            <History<SaleAnnouncement>
              resources={"sale_announcements"}
              entityId={saleAnnouncement.id}
            />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Flex direction="column" gap="sm">
            <DetailsPair>
              <Title order={5}>Id</Title>
              <NumberField value={saleAnnouncement?.id ?? ""} />
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Title</Title>
              <TextField value={saleAnnouncement?.title} />
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>URL</Title>
              <Anchor target="_blank" href={saleAnnouncement?.url}>
                {saleAnnouncement?.url}
              </Anchor>
            </DetailsPair>

            <DetailsPair>
              <Title order={5}>Activated At</Title>
              {saleAnnouncement?.activated_at ? (
                <DateField
                  color="green"
                  weight="bold"
                  value={saleAnnouncement?.activated_at}
                />
              ) : (
                <Badge color="gray">Not Active</Badge>
              )}
            </DetailsPair>
          </Flex>
        </Grid.Col>
      </Grid>
    </Show>
  );
};
