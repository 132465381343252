import { Badge, Button, Card, Flex, Group, Text } from "@mantine/core";
import { IconTrophy } from "@tabler/icons";
import { SurveyResponse } from "entities/survey/response";
import { formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";

type UserWinsPros = { wins: SurveyResponse["wins"]; onClose: () => void };

export function UserWins({ wins, onClose }: UserWinsPros) {
  return (
    <Flex direction="column">
      {wins.map((win) => {
        return (
          <Card my="md">
            <Card.Section withBorder px="sm">
              <Group position="apart" mt="md" mb="xs">
                <Flex>
                  <IconTrophy />
                  <Text color="orange" ml="xs">
                    Won {formatDistanceToNow(new Date(win.won_at))}
                  </Text>
                </Flex>
              </Group>
            </Card.Section>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>{win.survey.title}</Text>
              <Badge color="pink" variant="light">
                {win.survey.reward_type}
              </Badge>
            </Group>

            <Text size="sm" color="dimmed">
              {win.survey.description}
            </Text>

            <Button
              component={Link}
              variant="light"
              color="cyan"
              fullWidth
              mt="md"
              radius="md"
              onClick={onClose}
              to={`/surveys/show/${win.survey.id}`}
            >
              Go To Survey
            </Button>
          </Card>
        );
      })}
    </Flex>
  );
}
