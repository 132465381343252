import React from "react";
import {
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
} from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  Flex,
  Select,
  LoadingOverlay,
  Text,
} from "@mantine/core";
import {
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
} from "@refinedev/mantine";
import { Badge } from "@mantine/core";

import type { Header } from "@tanstack/react-table";
import { DateRangePicker } from "@mantine/dates";
import useCategoriesPermissions from "./useCategoriesPermissions";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { Category } from "entities/category";

type WpCategoriesResponse = {
  wp_categories: [];
};

export const CategoryList: React.FC<IResourceComponentsProps> = () => {
  const { canCreate, canEdit, canDelete } = useCategoriesPermissions();

  const apiUrl = useApiUrl();

  const { data: wpCategoriesData, isLoading: isLoadingWpCategories } =
    useCustom<WpCategoriesResponse>({
      url: `${apiUrl}/categories/wp_categories`,
      method: "get",
      queryOptions: {
        enabled: true,
      },
    });

  const columns = React.useMemo<ColumnDef<Category>[]>(
    () => [
      {
        id: "id",
        accessorKey: "id",
        header: "Id",
      },
      {
        id: "name",
        accessorKey: "name",
        header: "Name",
        cell: ({ getValue }) => {
          const value = getValue<Category["name"]>();

          return (
            <Badge color="pink" size="lg" variant="outline">
              {value}
            </Badge>
          );
        },
        meta: {
          filterOperator: "contains",
        },
      },
      {
        id: "description",
        accessorKey: "description",
        header: "Description",
        cell: ({ getValue }) => {
          const value = getValue<Category["description"]>();

          return <Text>{value}</Text>;
        },
        meta: {
          filterOperator: "contains",
        },
      },
      {
        id: "wp_categories",
        accessorKey: "wp_categories",
        header: "WP Categories",
        cell: ({ getValue }) => {
          const categories = getValue<Category["wp_categories"]>();
          if (!categories || categories.length === 0)
            return <Text color="gray">No Categories</Text>;

          return (
            <Flex gap="xs" wrap="wrap">
              {categories.map((category) => (
                <Badge variant="outline" color="orange">
                  {category}
                </Badge>
              ))}
            </Flex>
          );
        },
        meta: {
          filterOperator: "contains",
          filterElement: ({ header }: { header: Header<Category, any> }) => {
            return (
              <div>
                <Select
                  placeholder="WP Category"
                  data={wpCategoriesData?.data.wp_categories || []}
                  onChange={header.column.setFilterValue}
                  withinPortal
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "Created At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({ header }: { header: Header<Category, any> }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "updated_at",
        accessorKey: "updated_at",
        header: "Updated At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({ header }: { header: Header<Category, any> }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "actions",
        cell: function render({ row: { original: category } }) {
          return (
            <Flex columnGap={4}>
              <ShowButton hideText recordItemId={category.id} />
              {canEdit && <EditButton hideText recordItemId={category.id} />}
              {canDelete && (
                <DeleteButton hideText recordItemId={category.id} />
              )}
            </Flex>
          );
        },
        meta: {
          isPlaceholder: true,
        },
      },
    ],
    [canEdit, canDelete, wpCategoriesData]
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  setOptions((prev) => ({
    ...prev,
    meta: {
      ...prev.meta,
    },
  }));

  return (
    <List canCreate={canCreate}>
      <AppliedFilters<Category> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<Category> headerGroups={getHeaderGroups()} />
          <Rows<Category> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
