import { Authenticated, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  AuthPage,
  notificationProvider,
  RefineThemes,
  ThemedLayoutV2,
} from "@refinedev/mantine";

import {
  ColorScheme,
  ColorSchemeProvider,
  Global,
  MantineProvider,
} from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { useLocalStorage } from "@mantine/hooks";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import routerBindings, {
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { dataProvider } from "./dataProvider";
import {
  MantineCreateInferencer,
  MantineEditInferencer,
  MantineShowInferencer,
} from "@refinedev/inferencer/mantine";
import authProvider from "./authProvider";
import axiosInstance from "axiosInstance";
import Header from "components/header";
import Title from "components/header/Title";
import { UserList } from "components/users/List";
import { UserEdit } from "components/users/Edit";
import { UserShow } from "components/users/Show";
import { UserCreate } from "components/users/Create";
import { WaitlistItemList } from "components/waitlist_items/List";
import { GiftCardTemplateList } from "components/gift_card_templates/List";
import { GiftCardTemplateCreate } from "components/gift_card_templates/Create";
import { GiftCardTemplateEdit } from "components/gift_card_templates/Edit";
import { GiftCardTemplateShow } from "components/gift_card_templates/Show";
import { GiftCardList } from "components/gift_cards/List";
import { GiftCardCreate } from "components/gift_cards/Create";
import { GiftCardEdit } from "components/gift_cards/Edit";
import { GiftCardShow } from "components/gift_cards/Show";
import { GiftCardCampaignList } from "components/gift_card_campaigns/List";
import { GiftCardCampaignCreate } from "components/gift_card_campaigns/Create";
import { GiftCardCampaignEdit } from "components/gift_card_campaigns/Edit";
import { GiftCardCampaignShow } from "components/gift_card_campaigns/Show";
import { SurveyList } from "components/surveys/List";
import { SurveyCreate } from "components/surveys/Create";
import { SurveyEdit } from "components/surveys/Edit";
import { SurveyShow } from "components/surveys/Show";
import { ArticleList } from "components/articles/List";
import {
  IconArticle,
  IconBandage,
  IconClock,
  IconCoin,
  IconCoinOff,
  IconCopy,
  IconDashboard,
  IconForms,
  IconGiftCard,
  IconGraph,
  IconPennant,
  IconPennantOff,
  IconPictureInPicture,
  IconSend,
  IconSpeakerphone,
  IconStack,
  IconTag,
  IconTrophy,
  IconUsers,
  IconVaccine,
  IconMessage,
  IconThumbUp,
  IconSettings,
} from "@tabler/icons";
import { TierList } from "components/tier/List";
import { TierEdit } from "components/tier/Edit";
import { TierShow } from "components/tier/Show";
import { XpEarningList } from "components/xp_earning /List";
import { XpEarningShow } from "components/xp_earning /Show";
import { XpEarningEdit } from "components/xp_earning /Edit";
import { XpEarningCreate } from "components/xp_earning /Create";
import { UserDetails } from "components/users/show/UserDetails";
import { CoinEarningList } from "components/coin_earning/List";
import { CoinEarningCreate } from "components/coin_earning/Create";
import { CoinEarningEdit } from "components/coin_earning/Edit";
import { CoinEarningShow } from "components/coin_earning/Show";
import { LoyaltyRewardList } from "components/loyalty_reward/List";
import { LoyaltyRewardCreate } from "components/loyalty_reward/Create";
import { LoyaltyRewardShow } from "components/loyalty_reward/Show";
import { LoyaltyRewardEdit } from "components/loyalty_reward/Edit";
import { CoinSpendingList } from "components/coin_spending/List";
import { CoinSpendingCreate } from "components/coin_spending/Create";
import { CoinSpendingShow } from "components/coin_spending/Show";
import { CoinSpendingEdit } from "components/coin_spending/Edit";
import { XpReductionList } from "components/xp_reduction/List";
import { XpReductionCreate } from "components/xp_reduction/Create";
import { XpReductionShow } from "components/xp_reduction/Show";
import { XpReductionEdit } from "components/xp_reduction/Edit";
import Widget from "widget/Widget";
import { ArticleShow } from "components/articles/Show";
import { ArticleEdit } from "components/articles/Edit";
import { ProductSegmentList } from "components/product_segments/List";
import { ProductSegmentCreate } from "components/product_segments/Create";
import { ProductSegmentEdit } from "components/product_segments/Edit";
import { ProductSegmentShow } from "components/product_segments/Show";
import { CategoryList } from "components/categories/List";
import { CategoryCreate } from "components/categories/Create";
import { CategoryEdit } from "components/categories/Edit";
import { CategoryShow } from "components/categories/Show";
import Dashboard from "components/dashboards/Dashboard";
import { SaleAnnouncementList } from "components/sale_announcements/List";
import { SaleAnnouncementCreate } from "components/sale_announcements/Create";
import { SaleAnnouncementEdit } from "components/sale_announcements/Edit";
import { SaleAnnouncementShow } from "components/sale_announcements/Show";
import { CoinEarningRewardList } from "components/coin_earning_rewards/List";
import { CoinEarningRewardShow } from "components/coin_earning_rewards/Show";
import { CoinEarningRewardEdit } from "components/coin_earning_rewards/Edit";
import { PollList } from "components/polls/List";
import { PollCreate } from "components/polls/Create";
import { PollShow } from "components/polls/Show";
import { PollEdit } from "components/polls/Edit";
import { PollOptionCreate } from "components/poll_options/Create";
import { PollOptionShow } from "components/poll_options/Show";
import { PollOptionEdit } from "components/poll_options/Edit";
import { CommentList } from "components/comments/List";
import { CommentShow } from "components/comments/Show";
import { CommentCreate } from "components/comments/Create";
import { CommentEdit } from "components/comments/Edit";
import { LikeCreate } from "components/like/Create";
import { LikeList } from "components/like/List";
import { LikeEdit } from "components/like/Edit";
import { LikeShow } from "components/like/Show";

import { SanityCheckersList } from "components/sanity_checkers/List";
import { UserSanityCheckIssues } from "components/users/show/UserSanityCheckIssues";
import { LoginActivities } from "components/users/show/LoginActivities";
import { PublicSiteSettingsList } from "components/public_site_settings/List";
import { ModalsProvider } from "@mantine/modals";

function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "light",
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorSchemeProvider
          colorScheme={colorScheme}
          toggleColorScheme={toggleColorScheme}
        >
          {/* You can change the theme colors here. example: theme={{ ...RefineThemes.Magenta, colorScheme:colorScheme }} */}
          <MantineProvider
            theme={{ ...RefineThemes.Blue, colorScheme: colorScheme }}
            withNormalizeCSS
            withGlobalStyles
          >
            <ModalsProvider>
              <Global styles={{ body: { WebkitFontSmoothing: "auto" } }} />
              <NotificationsProvider position="top-right">
                <Refine
                  notificationProvider={notificationProvider}
                  routerProvider={routerBindings}
                  dataProvider={dataProvider(
                    `${process.env.REACT_APP_CP_API_URL}/api/admin`,
                    axiosInstance,
                  )}
                  authProvider={authProvider}
                  options={{
                    syncWithLocation: true,
                    warnWhenUnsavedChanges: true,
                    projectId: "uS6qCn-hrhIyI-XTgEHu",
                  }}
                  resources={[
                    {
                      name: "Dashboard",
                      identifier: "dashboard",
                      list: "/",
                      meta: {
                        canDelete: true,
                        icon: <IconDashboard />,
                      },
                    },
                    {
                      name: "users",
                      list: "/users",
                      create: "/users/create",
                      edit: "/users/edit/:id",
                      show: "/users/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconUsers />,
                      },
                    },
                    {
                      name: "gift_cards_section",
                      meta: {
                        icon: <IconGiftCard />,
                        label: "Gift cards",
                      },
                    },
                    {
                      name: "gift_card_templates",
                      list: "/gift_card_templates",
                      create: "/gift_card_templates/create",
                      edit: "/gift_card_templates/edit/:id",
                      show: "/gift_card_templates/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconCopy />,
                        parent: "gift_cards_section",
                      },
                    },
                    {
                      name: "gift_cards",
                      list: "/gift_cards",
                      create: "/gift_cards/create",
                      edit: "/gift_cards/edit/:id",
                      show: "/gift_cards/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconGiftCard />,
                        parent: "gift_cards_section",
                      },
                    },
                    {
                      name: "gift_card_campaigns",
                      list: "/gift_card_campaigns",
                      create: "/gift_card_campaigns/create",
                      edit: "/gift_card_campaigns/edit/:id",
                      show: "/gift_card_campaigns/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconSend />,
                        parent: "gift_cards_section",
                      },
                    },
                    {
                      name: "waitlist_items",
                      list: "/waitlist_items",
                      create: "/waitlist_items/create",
                      edit: "/waitlist_items/edit/:id",
                      show: "/waitlist_items/show/:id",
                      meta: { canDelete: true, icon: <IconClock /> },
                    },
                    {
                      name: "surveys_section",
                      meta: { icon: <IconForms />, label: "Surveys" },
                    },
                    {
                      name: "surveys",
                      list: "/surveys",
                      create: "/surveys/create",
                      edit: "/surveys/edit/:id",
                      show: "/surveys/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconForms />,
                        parent: "surveys_section",
                      },
                    },
                    {
                      name: "survey_rewards",
                      list: "/surveys/rewards",
                      create: "/surveys/rewards/create",
                      edit: "/surveys/rewards/edit/:id",
                      show: "/surveys/rewards/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconTrophy />,
                        parent: "surveys_section",
                      },
                    },
                    {
                      name: "loyalty_section",
                      meta: { icon: <IconCoin />, label: "Loyalty" },
                    },
                    {
                      name: "tiers",
                      list: "/tiers",
                      edit: "/tiers/edit/:id",
                      show: "/tiers/show/:id",
                      meta: {
                        canDelete: false,
                        icon: <IconStack />,
                        parent: "loyalty_section",
                      },
                    },
                    {
                      name: "xp_earnings",
                      list: "/xp_earnings",
                      create: "/xp_earnings/create",
                      edit: "/xp_earnings/edit/:id",
                      show: "/xp_earnings/show/:id",
                      meta: {
                        canDelete: false,
                        icon: <IconPennant />,
                        parent: "loyalty_section",
                      },
                    },
                    {
                      name: "xp_reductions",
                      list: "/xp_reductions",
                      create: "/xp_reductions/create",
                      edit: "/xp_reductions/edit/:id",
                      show: "/xp_reductions/show/:id",
                      meta: {
                        canDelete: false,
                        icon: <IconPennantOff />,
                        parent: "loyalty_section",
                      },
                    },
                    {
                      name: "loyalty_rewards",
                      list: "/loyalty_rewards",
                      create: "/loyalty_rewards/create",
                      edit: "/loyalty_rewards/edit/:id",
                      show: "/loyalty_rewards/show/:id",
                      meta: {
                        canDelete: false,
                        icon: <IconTrophy />,
                        parent: "loyalty_section",
                      },
                    },
                    {
                      name: "coin_earning_rewards",
                      list: "/coin_earning_rewards",
                      edit: "/coin_earning_rewards/edit/:id",
                      show: "/coin_earning_rewards/show/:id",
                      meta: {
                        canDelete: false,
                        icon: <IconCoin />,
                        parent: "loyalty_section",
                      },
                    },
                    {
                      name: "coin_earnings",
                      list: "/coin_earnings",
                      create: "/coin_earnings/create",
                      edit: "/coin_earnings/edit/:id",
                      show: "/coin_earnings/show/:id",
                      meta: {
                        canDelete: false,
                        icon: <IconCoin />,
                        parent: "loyalty_section",
                      },
                    },
                    {
                      name: "coin_spendings",
                      list: "/coin_spendings",
                      create: "/coin_spendings/create",
                      edit: "/coin_spendings/edit/:id",
                      show: "/coin_spendings/show/:id",
                      meta: {
                        canDelete: false,
                        icon: <IconCoinOff />,
                        parent: "loyalty_section",
                      },
                    },
                    {
                      name: "widget",
                      list: "/widget",
                      meta: {
                        canDelete: false,
                        icon: <IconPictureInPicture />,
                        parent: "loyalty_section",
                      },
                    },
                    {
                      name: "articles_section",
                      meta: { icon: <IconArticle />, label: "Articles" },
                    },
                    {
                      name: "articles",
                      list: "/articles",
                      create: "/articles/create",
                      edit: "/articles/edit/:id",
                      show: "/articles/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconArticle />,
                        parent: "articles_section",
                      },
                    },
                    {
                      name: "product_segments",
                      list: "/product_segments",
                      create: "/product_segments/create",
                      edit: "/product_segments/edit/:id",
                      show: "/product_segments/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconVaccine />,
                        parent: "articles_section",
                      },
                    },
                    {
                      name: "categories",
                      list: "/categories",
                      create: "/categories/create",
                      edit: "/categories/edit/:id",
                      show: "/categories/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconTag />,
                        parent: "articles_section",
                      },
                    },
                    {
                      name: "sale_announcements",
                      list: "/sale_announcements",
                      create: "/sale_announcements/create",
                      edit: "/sale_announcements/edit/:id",
                      show: "/sale_announcements/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconSpeakerphone />,
                      },
                    },
                    {
                      name: "polls",
                      list: "/polls",
                      create: "/polls/create",
                      edit: "/polls/edit/:id",
                      show: "/polls/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconGraph />,
                      },
                    },
                    {
                      name: "poll_options",
                      list: "/poll_options",
                      create: "/poll_options/create/:poll_id",
                      edit: "/poll_options/edit/:id",
                      show: "/poll_options/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconGraph />,
                        hide: true,
                      },
                    },
                    {
                      name: "poll_answers",
                      list: "/poll_answers",
                      meta: {
                        canDelete: true,
                        icon: <IconGraph />,
                        hide: true,
                      },
                    },
                    {
                      name: "comments",
                      list: "/comments",
                      create: "/comments/create",
                      edit: "/comments/edit/:id",
                      show: "/comments/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconMessage />,
                      },
                    },
                    {
                      name: "likes",
                      list: "/likes",
                      create: "/likes/create",
                      edit: "/likes/edit/:id",
                      show: "/likes/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconThumbUp />,
                      },
                    },
                    {
                      name: "sanity_checkers",
                      list: "/sanity_checkers",
                      create: "/sanity_checkers/create",
                      edit: "/sanity_checkers/edit/:id",
                      show: "/sanity_checkers/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconBandage />,
                      },
                    },
                    {
                      name: "public_site_settings",
                      list: "/public_site_settings",
                      meta: {
                        canDelete: false,
                        icon: <IconSettings />,
                      },
                    },
                    {
                      name: "loyalty_order_problems",
                      list: "/survey_checkers/:sanity_checker_id/loyalty_order_problems",
                      edit: "/sanity_checkers/:sanity_checker_id/loyalty_order_problems/edit/:id",
                      show: "/sanity_checkers/:sanity_checker_id/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <IconBandage />,
                        hide: true,
                      },
                    },
                  ]}
                >
                  <Routes>
                    <Route
                      element={
                        <Authenticated>
                          <ThemedLayoutV2 Title={Title} Header={Header}>
                            <Outlet />
                          </ThemedLayoutV2>
                        </Authenticated>
                      }
                    >
                      <Route path="/">
                        <Route index element={<Dashboard />} />
                      </Route>
                      <Route path="/users">
                        <Route index element={<UserList />} />
                        <Route path="create" element={<UserCreate />} />
                        <Route path="edit/:id" element={<UserEdit />} />
                        <Route path="show/:id" element={<UserShow />}>
                          <Route index element={<Navigate to="details" />} />
                          <Route path="details" element={<UserDetails />} />
                          <Route path="xp_earnings" element={<XpEarningList />} />
                          <Route
                            path="login_activities"
                            element={<LoginActivities />}
                          />
                          <Route
                            path="coin_earnings"
                            element={<CoinEarningList />}
                          />
                          <Route
                            path="coin_spendings"
                            element={<CoinSpendingList />}
                          />
                          <Route
                            path="xp_reductions"
                            element={<XpReductionList />}
                          />
                          <Route
                            path="user_sanity_check_issues"
                            element={<UserSanityCheckIssues />}
                          />
                        </Route>
                      </Route>
                      <Route path="/gift_card_templates">
                        <Route index element={<GiftCardTemplateList />} />
                        <Route
                          path="create"
                          element={<GiftCardTemplateCreate />}
                        />
                        <Route
                          path="edit/:id"
                          element={<GiftCardTemplateEdit />}
                        />
                        <Route
                          path="show/:id"
                          element={<GiftCardTemplateShow />}
                        />
                      </Route>
                      <Route path="/gift_cards">
                        <Route index element={<GiftCardList />} />
                        <Route path="create" element={<GiftCardCreate />} />
                        <Route path="edit/:id" element={<GiftCardEdit />} />
                        <Route path="show/:id" element={<GiftCardShow />} />
                      </Route>
                      <Route path="/gift_card_campaigns">
                        <Route index element={<GiftCardCampaignList />} />
                        <Route
                          path="create"
                          element={<GiftCardCampaignCreate />}
                        />
                        <Route
                          path="edit/:id"
                          element={<GiftCardCampaignEdit />}
                        />
                        <Route
                          path="show/:id"
                          element={<GiftCardCampaignShow />}
                        />
                      </Route>
                      <Route path="/surveys">
                        <Route index element={<SurveyList />} />
                        <Route path="create" element={<SurveyCreate />} />
                        <Route path="edit/:id" element={<SurveyEdit />} />
                        <Route path="show/:id" element={<SurveyShow />} />
                        <Route path="rewards">
                          <Route index element={<div />} />
                        </Route>
                      </Route>
                      <Route path="/tiers">
                        <Route index element={<TierList />} />
                        <Route path="edit/:id" element={<TierEdit />} />
                        <Route path="show/:id" element={<TierShow />} />
                      </Route>
                      <Route path="xp_earnings">
                        <Route index element={<XpEarningList />} />
                        <Route path="show/:id" element={<XpEarningShow />} />
                        <Route path="edit/:id" element={<XpEarningEdit />} />
                        <Route path="create" element={<XpEarningCreate />} />
                      </Route>
                      <Route path="coin_earning_rewards">
                        <Route index element={<CoinEarningRewardList />} />
                        <Route
                          path="show/:id"
                          element={<CoinEarningRewardShow />}
                        />
                        <Route
                          path="edit/:id"
                          element={<CoinEarningRewardEdit />}
                        />
                      </Route>
                      <Route path="coin_earnings">
                        <Route index element={<CoinEarningList />} />
                        <Route path="show/:id" element={<CoinEarningShow />} />
                        <Route path="edit/:id" element={<CoinEarningEdit />} />
                        <Route path="create" element={<CoinEarningCreate />} />
                      </Route>
                      <Route path="loyalty_rewards">
                        <Route index element={<LoyaltyRewardList />} />
                        <Route path="show/:id" element={<LoyaltyRewardShow />} />
                        <Route path="edit/:id" element={<LoyaltyRewardEdit />} />
                        <Route path="create" element={<LoyaltyRewardCreate />} />
                      </Route>
                      <Route path="coin_spendings">
                        <Route index element={<CoinSpendingList />} />
                        <Route path="show/:id" element={<CoinSpendingShow />} />
                        <Route path="edit/:id" element={<CoinSpendingEdit />} />
                        <Route path="create" element={<CoinSpendingCreate />} />
                      </Route>
                      <Route path="xp_reductions">
                        <Route index element={<XpReductionList />} />
                        <Route path="show/:id" element={<XpReductionShow />} />
                        <Route path="edit/:id" element={<XpReductionEdit />} />
                        <Route path="create" element={<XpReductionCreate />} />
                      </Route>
                      <Route path="/articles">
                        <Route index element={<ArticleList />} />
                        <Route path="edit/:id" element={<ArticleEdit />} />
                        <Route path="show/:id" element={<ArticleShow />} />
                      </Route>
                      <Route path="/product_segments">
                        <Route index element={<ProductSegmentList />} />
                        <Route path="create" element={<ProductSegmentCreate />} />
                        <Route path="edit/:id" element={<ProductSegmentEdit />} />
                        <Route path="show/:id" element={<ProductSegmentShow />} />
                      </Route>
                      <Route path="/categories">
                        <Route index element={<CategoryList />} />
                        <Route path="create" element={<CategoryCreate />} />
                        <Route path="edit/:id" element={<CategoryEdit />} />
                        <Route path="show/:id" element={<CategoryShow />} />
                      </Route>
                      <Route path="/sale_announcements">
                        <Route index element={<SaleAnnouncementList />} />
                        <Route
                          path="create"
                          element={<SaleAnnouncementCreate />}
                        />
                        <Route
                          path="edit/:id"
                          element={<SaleAnnouncementEdit />}
                        />
                        <Route
                          path="show/:id"
                          element={<SaleAnnouncementShow />}
                        />
                      </Route>
                      <Route path="/poll_options">
                        <Route
                          path="create/:poll_id"
                          element={<PollOptionCreate />}
                        />
                        <Route path="show/:id" element={<PollOptionShow />} />
                        <Route path="edit/:id" element={<PollOptionEdit />} />
                      </Route>
                      <Route path="/polls">
                        <Route index element={<PollList />} />
                        <Route path="create" element={<PollCreate />} />
                        <Route path="show/:id" element={<PollShow />} />
                        <Route path="edit/:id" element={<PollEdit />} />
                      </Route>
                      <Route path="/comments">
                        <Route index element={<CommentList />} />
                        <Route path="create" element={<CommentCreate />} />
                        <Route path="edit/:id" element={<CommentEdit />} />
                        <Route path="show/:id" element={<CommentShow />} />
                      </Route>
                      <Route path="/likes">
                        <Route index element={<LikeList />} />
                        <Route path="create" element={<LikeCreate />} />
                        <Route path="edit/:id" element={<LikeEdit />} />
                        <Route path="show/:id" element={<LikeShow />} />
                      </Route>
                      <Route path="/waitlist_items">
                        <Route index element={<WaitlistItemList />} />
                        <Route
                          path="create"
                          element={<MantineCreateInferencer />}
                        />
                        <Route
                          path="edit/:id"
                          element={<MantineEditInferencer />}
                        />
                        <Route
                          path="show/:id"
                          element={<MantineShowInferencer />}
                        />
                      </Route>
                      <Route path="/sanity_checkers">
                        <Route index element={<SanityCheckersList />} />
                        <Route path="create" element={<div />} />
                        <Route path="edit/:id" element={<div />} />
                        <Route path="show/:id" element={<div />} />
                      </Route>
                      <Route path="/public_site_settings">
                        <Route index element={<PublicSiteSettingsList />} />
                      </Route>
                      <Route path="/widget" element={<Widget />} />
                    </Route>
                    <Route path="/login" element={<AuthPage />} />
                  </Routes>
                  <RefineKbar />
                  <UnsavedChangesNotifier />
                  <DocumentTitleHandler />
                </Refine>
              </NotificationsProvider>
            </ModalsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
