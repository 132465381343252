import { IResourceComponentsProps, useShow, HttpError } from "@refinedev/core";
import { Show } from "@refinedev/mantine";
import {
  Flex,
  Grid,
  Text,
  Badge,
  Divider
} from "@mantine/core";
import { DetailsCard } from "components/common/show/DetailsPair";
import { CoinEarningReward } from "entities/coin_earning_reward";
import { colorsByKind } from "entities/coin_earning_reward/kind";
import { History } from "components/common/show/History";

export const CoinEarningRewardShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<CoinEarningReward, HttpError>();
  const { data, isLoading } = queryResult;
  const coin_earning_reward = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Grid>
        <Grid.Col span={8}>
          <DetailsCard title="Description">
            {coin_earning_reward && (
              <Text italic>
                {coin_earning_reward.description}
              </Text>
            )}
          </DetailsCard>
          <Divider my="md" />
          <DetailsCard title="History">
            {coin_earning_reward && (
              <History<CoinEarningReward> resources="coin_earning_rewards" entityId={coin_earning_reward.id} />
            )}
          </DetailsCard>
        </Grid.Col>
        <Grid.Col span={4}>
          {coin_earning_reward && (
            <Flex direction="column" gap="sm">
              <DetailsCard title="Kind">
                <Badge color={colorsByKind[coin_earning_reward.kind]}>
                  {coin_earning_reward.kind}
                </Badge>
              </DetailsCard>
              <DetailsCard title="Coins">
                <Text color="blue" size="lg">{coin_earning_reward.coins}</Text>
              </DetailsCard>
              <DetailsCard title="Created At">
                <Text>{coin_earning_reward.created_at.toString()}</Text>
              </DetailsCard>
              <DetailsCard title="Updated At">
                <Text>{coin_earning_reward.updated_at.toString()}</Text>
              </DetailsCard>
            </Flex>
          )}
        </Grid.Col>
      </Grid>
    </Show>
  );
};
