import {
  BaseRecord,
  HttpError,
  IResourceComponentsProps,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/mantine";
import { Button, Select, Textarea } from "@mantine/core";
import { CreateComment } from "entities/comment";
import UserSelect from "components/users/UserSelect";
import ArticleSelect from "components/articles/ArticleSelect";
import { isNotEmpty } from "@mantine/form";
import { selectCommentableTypes } from "entities/comment/commentable_type";
import CommentSelect from "./CommentSelect";
import ImageDropZone from "components/common/ImageDropZone";
import PollSelect from "components/polls/PollSelect";

export const CommentCreate: React.FC<IResourceComponentsProps> = () => {
  const { getInputProps, saveButtonProps, setFieldValue, values } = useForm<
    BaseRecord,
    HttpError,
    CreateComment
  >({
    initialValues: {
      body: "",
      commentable_id: undefined,
      commentable_type: "WordpressArticle",
      commentable: undefined,
      parent_comment_id: undefined,
      user: undefined,
      image: "",
    },
    validate: {
      user_id: isNotEmpty(),
      commentable_id: isNotEmpty(),
      body: isNotEmpty(),
    },
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <ImageDropZone
        onFileChosen={(data) => setFieldValue("image", data)}
        label="Image"
        title="Drop an image here"
        description="This is an optional image"
        maxSize={5 * 1024 ** 2} // 5mb
        {...getInputProps("image")}
      />
      <CommentSelect
        label="Parent comment"
        onChange={(commentId) => setFieldValue("parent_comment_id", commentId)}
      />
      <UserSelect onChange={(userId) => setFieldValue("user_id", userId)} />
      <Select
        placeholder="Channel"
        data={selectCommentableTypes}
        label="Commentable Type"
        clearable={false}
        {...getInputProps("commentable_type")}
      />
      {values.commentable_type === "WordpressArticle" && (
        <ArticleSelect
          label="Article to comment on"
          value={values.commentable_id}
          onChange={(articleId) => setFieldValue("commentable_id", articleId)}
        />
      )}
      {values.commentable_type === "Poll" && (
        <PollSelect
          label="Poll to comment on"
          value={values.commentable_id}
          onChange={(pollId) => setFieldValue("commentable_id", pollId)}
        />
      )}

      <Textarea label="Body" {...getInputProps("body")} />
    </Create>
  );
};
