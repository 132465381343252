import {
  HttpError,
  IResourceComponentsProps,
  useSelect,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/mantine";
import { NumberInput, Select, Textarea } from "@mantine/core";
import { User } from "entities/user";
import { selectSources } from "entities/xp_reduction/source";
import { isInRange } from "@mantine/form";
import { CreateXpReduction } from "entities/xp_reduction";

export const XpReductionEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    getInputProps,
    saveButtonProps,
    errors,
    setFieldValue,
    values,
    refineCore: { queryResult },
  } = useForm<CreateXpReduction, HttpError, CreateXpReduction>({
    initialValues: {
      source: "ichigo_admin",
      amount: 0,
      notes: "",
      user: null,
    },
    validateInputOnBlur: true,
    validate: {
      amount: isInRange({ min: 1 }, "COIN should be a positive number"),
    },
  });

  const { options, onSearch } = useSelect<User>({
    resource: "users",
    optionLabel: "email",
    optionValue: "id",
    debounce: 1000,
    pagination: {
      pageSize: 10,
      mode: "server",
      current: 1,
    },
    onSearch: (value) => [
      {
        field: "email",
        operator: "eq",
        value,
      },
    ],
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Select
        data={options}
        onSearchChange={(v) => {
          onSearch(v);
        }}
        {...getInputProps("user_id")}
        onChange={(v) => v && setFieldValue("user_id", parseInt(v))}
        placeholder={values.user?.email}
        label="User"
        searchable
      />
      <Select
        placeholder="Source"
        data={selectSources}
        label="Source"
        description="This should normally be 'Ichigo Admin' choose another source only if it makes sense, and please fill the notes for with the reason."
        {...getInputProps("source")}
      />
      <NumberInput
        defaultValue={0}
        mt="sm"
        label="XP Amount"
        min={0}
        {...getInputProps("amount")}
      />
      <Textarea
        defaultValue=""
        mt="sm"
        label="Notes"
        {...getInputProps("notes")}
      />
    </Edit>
  );
};
