import _ from "lodash"

export type ROLE_ADMIN = "admin"
export type ROLE_DEVELOPER = "developer"
export type ROLE_QUALITY_ASSURANCE = "quality_assurance"
export type ROLE_CUSTOMER_EXPERIENCE = "customer_experience"
export type ROLE_MARKETER = "marketer"
export type ROLE_CURATOR = "curator"
export type ROLE_STAFF = "staff"
export type ROLE_CUSTOMER = "customer"

export const ADMIN: ROLE_ADMIN = "admin"
export const DEVELOPER: ROLE_DEVELOPER = "developer"
export const QUALITY_ASSURANCE: ROLE_QUALITY_ASSURANCE = "quality_assurance"
export const CUSTOMER_EXPERIENCE: ROLE_CUSTOMER_EXPERIENCE = "customer_experience"
export const MARKETER: ROLE_MARKETER = "marketer"
export const CURATOR: ROLE_CURATOR = "curator"
export const STAFF: ROLE_STAFF = "staff"
export const CUSTOMER: ROLE_CUSTOMER = "customer"

export const DEFAULT_ROLE = CUSTOMER

export type Role =
  ROLE_ADMIN |
  ROLE_DEVELOPER |
  ROLE_QUALITY_ASSURANCE |
  ROLE_CUSTOMER_EXPERIENCE |
  ROLE_MARKETER |
  ROLE_CURATOR |
  ROLE_STAFF |
  ROLE_CUSTOMER


export const roles: Role[] = [
  ADMIN,
  DEVELOPER,
  QUALITY_ASSURANCE,
  CUSTOMER_EXPERIENCE,
  MARKETER,
  CURATOR,
  STAFF,
  CUSTOMER,
]


export const colorsByRole: Record<Role, string> = {
  admin: "green",
  developer: "cyan",
  quality_assurance: "pink",
  customer_experience: "yellow",
  marketer: "brown",
  curator: "red",
  staff: "blue",
  customer: "gray"
};

export const selectRoles = roles.map(role => {
  return { value: role, label: _.capitalize(role) }
})
