import React, { useState } from "react";
import {
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
} from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, Header } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  LoadingOverlay,
  Flex,
  Badge,
  Text,
  Avatar,
  Select,
  NumberInput,
  Button,
  Menu,
  ActionIcon,
  Modal,
  Alert,
} from "@mantine/core";
import {
  CreateButton,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  TextField,
} from "@refinedev/mantine";

import useXpPermissions from "./useXpPermissions";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { DateRangePicker } from "@mantine/dates";
import { formatDistanceToNow } from "date-fns";
import { XpReduction } from "entities/xp_reduction";
import { colorsBySource, selectSources } from "entities/xp_reduction/source";
import { formatToJst } from "utils/dates";
import { useOutletContext } from "react-router-dom";
import { UserOutletContext } from "entities/user";
import {
  IconCalendar,
  IconCheck,
  IconDotsVertical,
  IconExclamationCircle,
  IconX,
} from "@tabler/icons";

export const XpReductionList: React.FC<IResourceComponentsProps> = () => {
  const { canEdit, canDelete, canCreate } = useXpPermissions();
  const userOutletCtx = useOutletContext<UserOutletContext>();

  const columns: ColumnDef<XpReduction>[] = [
    {
      id: "id",
      accessorKey: "id",
      header: "Id",
    },
    {
      id: "user",
      accessorKey: "user",
      header: "User",
      cell({ getValue }) {
        const user = getValue<XpReduction["user"]>();

        return (
          <Text size="md" weight="bold">
            <Flex direction="column" align="center">
              <Avatar src={user?.image} size="md" />
              <Text color="white">
                {user?.first_name}
                &nbsp;
                {user?.last_name}
              </Text>
              <Text color="orange">{user?.email}</Text>
            </Flex>
          </Text>
        );
      },
      meta: {
        filterOperator: "eq",
        disableSort: true,
      },
    },
    {
      id: "source",
      accessorKey: "source",
      header: "Source",
      cell: ({ getValue }) => {
        const source = getValue<XpReduction["source"]>();

        return (
          <Badge variant="light" color={colorsBySource[source]}>
            {source}
          </Badge>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<XpReduction, any> }) => {
          return (
            <div>
              <Select
                placeholder="Source"
                data={selectSources}
                onChange={header.column.setFilterValue}
                withinPortal
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "amount",
      accessorKey: "amount",
      header: "Xp Amount",
      cell({ getValue }) {
        const coinsAmount = getValue<XpReduction["amount"]>();

        return (
          <Badge size="lg" color="pink">
            {coinsAmount}
          </Badge>
        );
      },
      meta: {
        filterOperator: "eq",
        filterElement: ({ header }: { header: Header<XpReduction, any> }) => {
          return (
            <div>
              <NumberInput
                placeholder="Amount"
                onChange={(v) => header.column.setFilterValue(v?.toString())}
                mx="auto"
                maw={400}
              />
            </div>
          );
        },
      },
    },
    {
      id: "notes",
      accessorKey: "notes",
      header: "Notes",
    },
    {
      id: "created_at",
      accessorKey: "created_at",
      header: "Created At",
      cell({ getValue }) {
        const dateValue = getValue<XpReduction["created_at"]>();
        if (!dateValue) return <div />;

        const date = new Date(dateValue);

        return <TextField value={formatDistanceToNow(date)} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<XpReduction, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={(v) =>
                  header.column.setFilterValue(
                    v.map((date) => date && formatToJst(date))
                  )
                }
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
        disableSort: true,
      },
    },
    {
      id: "updated_at",
      accessorKey: "updated_at",
      header: "Updated At",
      cell({ getValue }) {
        const dateValue = getValue<XpReduction["updated_at"]>();
        if (!dateValue) return <div />;

        const date = new Date(dateValue);

        return <TextField value={formatDistanceToNow(date)} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<XpReduction, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={header.column.setFilterValue}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "actions",
      cell: function render({ row: { original: xp_reduction } }) {
        return (
          <Flex columnGap={4}>
            <ShowButton
              hideText
              resource="xp_reductions"
              recordItemId={xp_reduction.id}
            />
            {canEdit && (
              <EditButton
                hideText
                resource="xp_reductions"
                recordItemId={xp_reduction.id}
              />
            )}
            {canDelete && (
              <DeleteButton
                hideText
                resource="xp_reductions"
                recordItemId={xp_reduction.id}
              />
            )}
          </Flex>
        );
      },
      meta: {
        isPlaceholder: true,
      },
    },
  ];

  const updatedColumns = columns.filter(
    (col) => col.id !== "user" || !userOutletCtx?.user
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns: updatedColumns,
    refineCoreProps: {
      resource: userOutletCtx?.user ? "xp_reductions" : undefined,
      filters: {
        permanent: userOutletCtx?.user
          ? [
              {
                field: "user_id",
                operator: "eq",
                value: userOutletCtx?.user?.id,
              },
            ]
          : undefined,
      },
      pagination: {
        pageSize: 50,
      },
    },
  });

  const [eoyModalOpen, setEoyModalOpen] = useState(false);
  const { mutate: triggerEoyXpReset, isLoading: isTriggeringEoyXpReset } =
    useCustomMutation<{}>();
  const apiUrl = useApiUrl();

  return (
    <List
      canCreate={canCreate}
      resource="xp_reductions"
      headerButtons={
        <>
          <CreateButton>Create</CreateButton>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <ActionIcon>
                <IconDotsVertical />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>Bulk Actions</Menu.Label>
              <Menu.Item
                icon={<IconCalendar />}
                onClick={() => setEoyModalOpen(true)}
              >
                End Of Year Reset
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </>
      }
    >
      <Modal
        withCloseButton={true}
        opened={eoyModalOpen}
        onClose={() => setEoyModalOpen(false)}
      >
        <Flex direction="column">
          <Alert color="orange" icon={<IconExclamationCircle />}>
            This will try to reset the XP of all users, it will trigger a worker
            per user, so there is no need to retry this action again, we can
            just check the failed workers if we need to. This will run through
            all users so it will take some time to finish, do you want to
            continue?
          </Alert>
          <Flex mt="md" justify="flex-end">
            <Button
              color="red"
              leftIcon={<IconCheck />}
              loading={isTriggeringEoyXpReset}
              onClick={() => {
                triggerEoyXpReset(
                  {
                    url: `${apiUrl}/xp_reductions/trigger_end_of_year_xp_reset`,
                    method: "post",
                    values: {},
                  },
                  {
                    onSuccess: (data, variables, context) => {
                      setEoyModalOpen(false);
                    },
                  }
                );
              }}
            >
              Trigger The Reset
            </Button>
            <Button
              color="gray"
              leftIcon={<IconX />}
              mx="sm"
              onClick={() => setEoyModalOpen(false)}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>
      <AppliedFilters<XpReduction> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<XpReduction> headerGroups={getHeaderGroups()} />
          <Rows<XpReduction> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
