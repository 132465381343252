import { Header, HeaderGroup, flexRender } from "@tanstack/react-table";
import { ThemeIcon, Container, Flex, Box } from "@mantine/core";
import {
  IconSortAscendingLetters,
  IconSortDescendingLetters,
} from "@tabler/icons";
import DefaultFilter from "./DefaultFilter";
import { useState } from "react";

type HeadersProps<T> = {
  headerGroups: HeaderGroup<T>[];
};

export default function Headers<T>({ headerGroups }: HeadersProps<T>) {
  const [hoveredHeader, setHoveredHeader] = useState<Header<T, unknown> | null>(
    null
  );

  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const sorted = header.column.getIsSorted();
            let headerIcon = null;

            if (sorted) {
              const sortIcon = {
                asc: (
                  <ThemeIcon variant="light" color="violet" size={20} sx={{ position: "absolute", top: 0, left: 0 }}>
                    <IconSortAscendingLetters />
                  </ThemeIcon>
                ),
                desc: (
                  <ThemeIcon variant="light" color="orange" size={20} sx={{ position: "absolute", top: 0, left: 0 }}>
                    <IconSortDescendingLetters />
                  </ThemeIcon>
                ),
              };

              headerIcon = sortIcon[sorted];
            }

            let handler = (event: any) => {};

            if (!(header.column.columnDef.meta as any)?.disableSort) {
              const toggleHandler = header.column.getToggleSortingHandler();
              if (toggleHandler) handler = toggleHandler;
            }

            return (
              <th
                key={header.id}
                onMouseEnter={() => setHoveredHeader(header)}
                onMouseLeave={() => setHoveredHeader(null)}
              >
                <Container
                  onClick={handler}
                  pl={24}
                  sx={() => ({ minWidth: 100, position: "relative", textWrap: "nowrap"})}
                >
                  {headerIcon}
                  {!headerIcon &&
                    !header.isPlaceholder &&
                    hoveredHeader?.id === header.id &&
                    !(header.column.columnDef.meta as any)?.disableSort && (
                      <ThemeIcon variant="light"
                        color="gray"
                        size={20}
                        sx={{ position: "absolute", top: 0, left: 0 }}>
                        <IconSortAscendingLetters />
                      </ThemeIcon>
                    )}
                  {!header.isPlaceholder &&
                    flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </Container>
                {(header.column.columnDef.meta as any)?.filterElement?.({
                  header,
                }) || <DefaultFilter<T> header={header} />}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
}
