import _ from "lodash";

export type Event =
  | "purchase"
  | "sns_instagram_follow"
  | "sns_facebook_like"
  | "sns_twitter_follow"
  | "sns_reddit_join"
  | "birthday"
  | "signup"
  | "activate_streak"
  | "claimed_reward"
  | "stamped_review";

export const events: Event[] = [
  "purchase",
  "sns_instagram_follow",
  "sns_facebook_like",
  "sns_twitter_follow",
  "sns_reddit_join",
  "birthday",
  "signup",
  "activate_streak",
  "claimed_reward",
  "stamped_review",
];

export const selectEvents = events.map((event) => {
  return { value: event, label: _.capitalize(event) };
});

export const colorsByEvent: Record<Event, string> = {
  purchase: "green",
  sns_instagram_follow: "red",
  sns_facebook_like: "blue",
  sns_twitter_follow: "cyan",
  sns_reddit_join: "yellow",
  birthday: "orange",
  signup: "pink",
  activate_streak: "purple",
  claimed_reward: "brown",
  stamped_review: "cyan",
};
