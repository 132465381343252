import _ from "lodash";

export type LikableType = "WordpressArticle" | "Comment"
export const commentableTypes: LikableType[] = ["WordpressArticle", "Comment"];

export const colorsByLikableType: Record<LikableType, string> = {
  WordpressArticle: "cyan",
  Comment: "orange",
};

export const labelsByLikableType: Record<LikableType, string> = {
  WordpressArticle: "Article",
  Comment: "Comment"
}

export const urlsByLikableType: Record<LikableType, string> = {
  WordpressArticle: "/articles",
  Comment: "/comments"
}

export const selectLikableTypes = commentableTypes.map((commentableType) => {
  return { value: commentableType, label: labelsByLikableType[commentableType] };
});

