import { Button, Flex, Pagination, Select, Text } from "@mantine/core";
import {
  HttpError,
  useApiUrl,
  useCustomMutation,
  useDelete,
  useList,
} from "@refinedev/core";
import { CoinSpendingProblem } from "entities/coin_spending_problem";
import {
  LoyaltyOrderProblem,
  selectKeys,
} from "entities/loyalty_order_problem";
import { SanityChecker } from "entities/sanity_checker";
import { useState } from "react";
import { CoinSpendingProblemCard } from "./CoinSpendingProblemCard";
import { LoyaltyOrderProblemCard } from "./LoyaltyOrderProblemCard";

const PAGE_SIZE = 10;

type ProblemsListProps = {
  selectedSanityChecker: SanityChecker;
};

export function ProblemsList({ selectedSanityChecker }: ProblemsListProps) {
  const [problemsPage, setProblemsPage] = useState(1);
  const [key, setKey] = useState("");

  const {
    data: loyaltyOrderProblemData,
    refetch: refetchLoyaltyOrderProblems,
  } = useList<LoyaltyOrderProblem, HttpError>({
    resource: `sanity_checkers/${selectedSanityChecker.id}/loyalty_order_problems`,
    filters: [{ field: "key", operator: "eq", value: key }],
    queryOptions: {
      enabled:
        selectedSanityChecker.check_type === "loyalty_order_problem" &&
        !!selectedSanityChecker,
      keepPreviousData: true,
    },
    pagination: {
      pageSize: PAGE_SIZE,
      current: problemsPage,
    },
  });

  const {
    data: coinSpendingProblemData,
    refetch: refetchCoinSpendingProblems,
  } = useList<CoinSpendingProblem, HttpError>({
    resource: `sanity_checkers/${selectedSanityChecker.id}/coin_spending_problems`,
    filters: [{ field: "key", operator: "eq", value: key }],
    queryOptions: {
      enabled:
        selectedSanityChecker.check_type === "coin_spending_problem" &&
        !!selectedSanityChecker,
      keepPreviousData: true,
    },
    pagination: {
      pageSize: PAGE_SIZE,
      current: problemsPage,
    },
  });

  const apiUrl = useApiUrl();

  const { mutate: autoFixProblem } = useCustomMutation<LoyaltyOrderProblem>();

  const { mutate: autoFixAllProblems } =
    useCustomMutation<LoyaltyOrderProblem>();

  const { mutate: deleteProblem } = useDelete();

  const { mutate: deleteAllProblems } = useCustomMutation<SanityChecker>();

  const refetch = () => {
    if (selectedSanityChecker.check_type === "loyalty_order_problem")
      refetchLoyaltyOrderProblems();
    else if (selectedSanityChecker.check_type === "coin_spending_problem")
      refetchCoinSpendingProblems();
  };

  const handleFixProblem = (
    problem: LoyaltyOrderProblem | CoinSpendingProblem,
  ) => {
    autoFixProblem(
      {
        url: `${apiUrl}/sanity_checkers/${selectedSanityChecker.id}/${selectedSanityChecker.check_type}s/${problem.id}/auto_fix`,
        method: "post",
        values: {},
      },
      {
        onSuccess: () => refetch(),
      },
    );
  };

  const handleFixAllProblems = () => {
    autoFixAllProblems(
      {
        url: `${apiUrl}/sanity_checkers/${selectedSanityChecker.id}/auto_fix_all`,
        method: "post",
        values: {},
      },
      {
        onSuccess: () => refetch(),
      },
    );
  };

  const handleDeleteAllProblems = () => {
    deleteAllProblems(
      {
        url: `${apiUrl}/sanity_checkers/${selectedSanityChecker.id}/delete_all_problems`,
        method: "delete",
        values: {},
      },
      {
        onSuccess: () => refetch(),
      },
    );
  };

  const handleDeleteProblem = (
    problem: LoyaltyOrderProblem | CoinSpendingProblem,
  ) => {
    deleteProblem(
      {
        resource: `sanity_checkers/${selectedSanityChecker.id}/${selectedSanityChecker.check_type}s`,
        id: problem.id,
      },
      {
        onSuccess: () => {
          refetchLoyaltyOrderProblems();
        },
      },
    );
  };

  const loyaltyOrderProblems = loyaltyOrderProblemData?.data ?? [];
  const coinSpendingProblems = coinSpendingProblemData?.data ?? [];
  const problems =
    selectedSanityChecker.check_type === "loyalty_order_problem"
      ? loyaltyOrderProblems
      : coinSpendingProblems;
  const total =
    selectedSanityChecker.check_type === "loyalty_order_problem"
      ? loyaltyOrderProblemData?.total
      : coinSpendingProblemData?.total;

  // if (isError) return <Text>Something went wrong</Text>;
  // if (isLoading)
  //   return (
  //     <Flex>
  //       <Loader /> <Text italic>Loading problems...</Text>
  //     </Flex>
  //   );

  return (
    <>
      <Select
        data={selectKeys}
        onChange={(newValue) => newValue && setKey(newValue)}
      />
      {problems.length !== 0 && (
        <Flex justify="end" gap="sm" my="sm">
          <Button color="green" onClick={handleFixAllProblems}>
            Fix All
          </Button>
          <Button color="red" onClick={handleDeleteAllProblems}>
            Delete All
          </Button>
        </Flex>
      )}
      {problems.length !== 0 ? (
        <>
          <Flex direction="column" gap="sm">
            {problems.map((problem) => {
              if (
                selectedSanityChecker.check_type === "loyalty_order_problem"
              ) {
                const castedProblem = problem as LoyaltyOrderProblem;
                return (
                  <LoyaltyOrderProblemCard
                    problem={castedProblem}
                    fixProblem={handleFixProblem}
                    deleteProblem={handleDeleteProblem}
                  />
                );
              } else if (
                selectedSanityChecker.check_type === "coin_spending_problem"
              ) {
                const castedProblem = problem as CoinSpendingProblem;
                return (
                  <CoinSpendingProblemCard
                    problem={castedProblem}
                    fixProblem={handleFixProblem}
                    deleteProblem={handleDeleteProblem}
                  />
                );
              }
            })}
          </Flex>
          {total && (
            <Pagination
              position="right"
              total={total / PAGE_SIZE}
              page={problemsPage}
              onChange={setProblemsPage}
              my="sm"
            />
          )}
        </>
      ) : (
        <Flex p="lg" justify="center" align="center">
          <Text color="gray" size="lg">
            No problems found!
          </Text>
        </Flex>
      )}
    </>
  );
}
