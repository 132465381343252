import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, Header } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  LoadingOverlay,
  Flex,
  Badge,
  Text,
  Select,
  NumberInput,
} from "@mantine/core";
import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  TextField,
} from "@refinedev/mantine";

import useLoyaltyRewardPermissions from "./useLoyaltyRewardPermissions";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { DateRangePicker } from "@mantine/dates";
import { formatDistanceToNow } from "date-fns";
import { useOutletContext } from "react-router-dom";
import { UserOutletContext } from "entities/user";
import { LoyaltyReward } from "entities/loyalty_reward";
import { colorsByKind, selectKinds } from "entities/loyalty_reward/kind";
import {
  colorsByDestination,
  selectDestinations,
} from "entities/loyalty_reward/destination";

export const LoyaltyRewardList: React.FC<IResourceComponentsProps> = () => {
  const { canEdit, canDelete, canCreate } = useLoyaltyRewardPermissions();
  const userOutletCtx = useOutletContext<UserOutletContext>();

  const columns: ColumnDef<LoyaltyReward>[] = [
    {
      id: "id",
      accessorKey: "id",
      header: "Id",
    },
    {
      id: "kind",
      accessorKey: "kind",
      header: "Kind",
      cell: ({ getValue }) => {
        const kind = getValue<LoyaltyReward["kind"]>();

        return (
          <Badge variant="light" color={colorsByKind[kind]}>
            {kind}
          </Badge>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<LoyaltyReward, any> }) => {
          return (
            <div>
              <Select
                placeholder="Kind"
                data={selectKinds}
                onChange={header.column.setFilterValue}
                withinPortal
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "destination",
      accessorKey: "destination",
      header: "Destination",
      cell: ({ getValue }) => {
        const destination = getValue<LoyaltyReward["destination"]>();

        return (
          <Badge variant="light" color={colorsByDestination[destination]}>
            {destination}
          </Badge>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<LoyaltyReward, any> }) => {
          return (
            <div>
              <Select
                placeholder="Destination"
                data={selectDestinations}
                onChange={header.column.setFilterValue}
                withinPortal
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "cost_in_coins",
      accessorKey: "cost_in_coins",
      header: "Cost In Coins",
      cell({ getValue }) {
        const cost_in_coins = getValue<LoyaltyReward["cost_in_coins"]>();

        return (
          <Text size="md" weight="bold">
            {cost_in_coins ? `${cost_in_coins}` : "--"}
          </Text>
        );
      },
      meta: {
        filterOperator: "eq",
        filterElement: ({ header }: { header: Header<LoyaltyReward, any> }) => {
          return (
            <div>
              <NumberInput
                placeholder="Amount"
                onChange={(v) => header.column.setFilterValue(v?.toString())}
                mx="auto"
                maw={400}
              />
            </div>
          );
        },
      },
    },
    {
      id: "amount_in_usd",
      accessorKey: "amount_in_usd",
      header: "Amount In Usd",
      cell({ getValue }) {
        const amount_in_usd = getValue<LoyaltyReward["amount_in_usd"]>();

        return (
          <Text size="md" weight="bold">
            {amount_in_usd ? `$${amount_in_usd}` : "--"}
          </Text>
        );
      },
      meta: {
        filterOperator: "eq",
        filterElement: ({ header }: { header: Header<LoyaltyReward, any> }) => {
          return (
            <div>
              <NumberInput
                placeholder="Amount"
                onChange={(v) => header.column.setFilterValue(v?.toString())}
                mx="auto"
                maw={400}
              />
            </div>
          );
        },
      },
    },
    {
      id: "name",
      accessorKey: "name",
      header: "Name",
      cell({ getValue }) {
        const name = getValue<LoyaltyReward["name"]>();

        return <Text size="md"> {name} </Text>;
      },
    },
    {
      id: "description",
      accessorKey: "description",
      header: "Description",
      cell({ getValue }) {
        const description = getValue<LoyaltyReward["description"]>();

        return <Text size="md">{description}</Text>;
      },
    },
    {
      id: "updated_at",
      accessorKey: "updated_at",
      header: "Updated At",
      cell({ getValue }) {
        const date = new Date(getValue<LoyaltyReward["updated_at"]>());

        return <TextField value={formatDistanceToNow(date)} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<LoyaltyReward, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={header.column.setFilterValue}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "actions",
      cell: function render({ row: { original: loyalty_reward } }) {
        return (
          <Flex columnGap={4}>
            <ShowButton
              hideText
              resource="loyalty_rewards"
              recordItemId={loyalty_reward.id}
            />
            {canEdit && (
              <EditButton
                hideText
                resource="loyalty_rewards"
                recordItemId={loyalty_reward.id}
              />
            )}
            {canDelete && (
              <DeleteButton
                hideText
                resource="loyalty_rewards"
                recordItemId={loyalty_reward.id}
              />
            )}
          </Flex>
        );
      },
      meta: {
        isPlaceholder: true,
      },
    },
  ];

  const updatedColumns = columns.filter(
    (col) => col.id !== "user" || !userOutletCtx?.user
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns: updatedColumns,
    refineCoreProps: {
      resource: userOutletCtx?.user ? "loyalty_rewards" : undefined,
      filters: {
        permanent: userOutletCtx?.user
          ? [
              {
                field: "user_id",
                operator: "eq",
                value: userOutletCtx?.user?.id,
              },
            ]
          : undefined,
      },
      pagination: {
        pageSize: 50,
      },
    },
  });

  return (
    <List canCreate={canCreate} resource="loyalty_rewards">
      <AppliedFilters<LoyaltyReward> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<LoyaltyReward> headerGroups={getHeaderGroups()} />
          <Rows<LoyaltyReward> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
