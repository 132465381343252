import {
  BaseRecord,
  HttpError,
  IResourceComponentsProps,
  file2Base64,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/mantine";
import {
  NumberInput,
  Select,
  Textarea,
  Flex,
  Text,
  Image,
} from "@mantine/core";
import { CreateGiftCardTemplate } from "entities/gift_card_template";
import { selectChannels } from "entities/gift_card_template/channel";
import { selectConstraints } from "entities/gift_card_template/constraint";
import { DatePicker } from "@mantine/dates";
import { Dropzone } from "@mantine/dropzone";
import { IconPhoto } from "@tabler/icons";
import { useEffect } from "react";
import { formatToJst } from "utils/dates";

export const GiftCardTemplateEdit: React.FC<IResourceComponentsProps> = () => {
  const { getInputProps, saveButtonProps, setFieldValue, values } = useForm<
    BaseRecord,
    HttpError,
    CreateGiftCardTemplate
  >({
    initialValues: {
      initial_value: 0,
      expire_at: new Date(),
      channel: "all_channels",
      constraint: "no_constraints",
      image: { file: undefined, base64: "", url: undefined },
      notes: "",
    },
    transformValues: (values) => {
      return {
        ...values,
        expire_at: formatToJst(values.expire_at),
      };
    },
  });

  const imageProps = getInputProps("image");

  useEffect(() => {
    const transformFile = async () => {
      if (imageProps.value.file) {
        const imageBase64 = await file2Base64({
          originFileObj: imageProps.value.file,
        });
        setFieldValue("image.base64", imageBase64);
      }
    };

    transformFile().catch(console.error);
  }, [imageProps.value.file]);

  const expireAt = new Date(values.expire_at)

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Dropzone
        onDrop={(files) => {
          const file = files[0];
          imageProps.onChange({ file: file, base64: "" });
        }}
        maxFiles={1}
        multiple={false}
        padding="lg"
      >
        <Flex justify="center" align="center" gap="md">
          <IconPhoto size={48} />
          <Flex direction="column">
            <Text size="md">Drop an image here</Text>
            <Text size="sm" color="cyan">
              This will be seen by the user in the App and Emails
            </Text>
          </Flex>
        </Flex>
      </Dropzone>
      <Preview file={imageProps.value.file} srcUrl={imageProps.value.url} />
      <Select
        placeholder="Channel"
        data={selectChannels}
        label="Channel"
        clearable
        {...getInputProps("channel")}
      />
      <Select
        placeholder="Constraint"
        data={selectConstraints}
        label="Constraint"
        clearable
        {...getInputProps("constraint")}
      />
      <NumberInput
        defaultValue={0}
        mt="sm"
        label="Initial value"
        {...getInputProps("initial_value")}
      />
      <DatePicker
        placeholder="Pick dates range"
        maw={400}
        label="Expire at"
        defaultValue={new Date()}
        withinPortal
        {...getInputProps("expire_at")}
        value={expireAt}
      />
      <Textarea label="Notes" {...getInputProps("notes")} />
    </Edit>
  );
};

const Preview = ({ file, srcUrl }: { file: File; srcUrl: string }) => {
  if (file) {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        src={imageUrl}
        height={100}
        width="auto"
        my="lg"
        imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
      />
    );
  } else if (srcUrl) {
    return <Image src={srcUrl} height={100} width="auto" my="lg" />;
  } else {
    return <div />;
  }
};
