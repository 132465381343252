import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  Flex,
  LoadingOverlay,
  Text,
  Image,
  Badge,
  Select,
} from "@mantine/core";
import {
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
} from "@refinedev/mantine";

import type { Header } from "@tanstack/react-table";
import { DateRangePicker } from "@mantine/dates";
import useProductSegmentPermissions from "./useProductSegmentsPermissions";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { Article } from "entities/article";
import { ProductSegment } from "entities/product_segment";
import { colorsByBrand, selectBrands } from "entities/product_segment/brand";

export const ProductSegmentList: React.FC<IResourceComponentsProps> = () => {
  const { canCreate, canEdit, canDelete } = useProductSegmentPermissions();

  const columns = React.useMemo<ColumnDef<ProductSegment>[]>(
    () => [
      {
        id: "image",
        accessorKey: "image",
        header: "Image",
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          const imageUrl = getValue<ProductSegment["image"]>();

          return (
            <Image
              src={imageUrl}
              radius="md"
              alt="Image of the Product Segment"
              height={140}
              width={200}
              withPlaceholder
            />
          );
        },
      },
      {
        id: "thumbnail",
        accessorKey: "thumbnail",
        header: "thumbnail",
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          const imageUrl = getValue<ProductSegment["thumbnail"]>();

          return (
            <Image
              src={imageUrl}
              radius="md"
              alt="Image of the thumbnail"
              height={140}
              width={200}
              withPlaceholder
            />
          );
        },
      },
      {
        id: "id",
        accessorKey: "id",
        header: "Id",
      },
      {
        id: "brand",
        accessorKey: "brand",
        header: "Brand",
        cell: ({ getValue }) => {
          const brand = getValue<ProductSegment["brand"]>();
          return (
            <Badge variant="filled" color={colorsByBrand[brand]}>
              {brand}
            </Badge>
          );
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<ProductSegment, any>;
          }) => {
            return (
              <div>
                <Select
                  placeholder="brand"
                  data={selectBrands}
                  onChange={header.column.setFilterValue}
                  withinPortal
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "title",
        accessorKey: "title",
        header: "Title",
        cell: ({ getValue }) => {
          const value = getValue<ProductSegment["title"]>();

          return <Text>{value}</Text>;
        },
        meta: { filterOperator: "contains" },
      },
      {
        id: "product_type",
        accessorKey: "product_type",
        header: "Product Type",
        cell: ({ getValue }) => {
          const value = getValue<ProductSegment["product_type"]>();

          return <Text>{value}</Text>;
        },
        meta: { filterOperator: "contains" },
      },
      {
        id: "product_label",
        accessorKey: "product_label",
        header: "Product Label",
        cell: ({ getValue }) => {
          const value = getValue<ProductSegment["product_label"]>();

          return <Text>{value}</Text>;
        },
        meta: { filterOperator: "contains" },
      },
      {
        id: "description",
        accessorKey: "description",
        header: "Description",
        cell: ({ getValue }) => {
          const value = getValue<ProductSegment["description"]>();

          return <Text>{value.substring(0, 50)}..</Text>;
        },
        meta: {
          filterOperator: "contains",
        },
      },
      {
        id: "price",
        accessorKey: "price",
        header: "Price",
        cell: ({ getValue }) => {
          const value = getValue<ProductSegment["price"]>();
          if (!value) return <Text color="gray">--</Text>;

          return (
            <Text color="green" weight="bold">
              ${value}
            </Text>
          );
        },
      },
      {
        id: "compare_at_price",
        accessorKey: "compare_at_price",
        header: "Compare At Price",
        cell: ({ getValue }) => {
          const value = getValue<ProductSegment["compare_at_price"]>();
          if (!value) return <Text color="gray">--</Text>;

          return (
            <Text color="blue" weight="bold">
              ${value}
            </Text>
          );
        },
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "Created At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({ header }: { header: Header<Article, any> }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "updated_at",
        accessorKey: "updated_at",
        header: "Updated At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<ProductSegment, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "actions",
        cell: function render({ row: { original: GiftCardCampaign } }) {
          return (
            <Flex columnGap={4}>
              <ShowButton hideText recordItemId={GiftCardCampaign.id} />
              {canEdit && (
                <EditButton hideText recordItemId={GiftCardCampaign.id} />
              )}
              {canDelete && (
                <DeleteButton hideText recordItemId={GiftCardCampaign.id} />
              )}
            </Flex>
          );
        },
        meta: {
          isPlaceholder: true,
        },
      },
    ],
    [canEdit, canDelete]
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  setOptions((prev) => ({
    ...prev,
    meta: {
      ...prev.meta,
    },
  }));

  return (
    <List canCreate={canCreate}>
      <AppliedFilters<ProductSegment> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<ProductSegment> headerGroups={getHeaderGroups()} />
          <Rows<ProductSegment> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
