import {
  Accordion,
  Badge,
  Button,
  Card,
  Divider,
  Flex,
  Loader,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useCustom, useList } from "@refinedev/core";
import { userFriendlySecond } from "@refinedev/core/dist/definitions";
import { IconCrown, IconGif, IconGift, IconStar } from "@tabler/icons";
import { Tier, TierBenefit, colorsByTier } from "entities/tier";
import { useContext } from "react";
import { loginUrl, signupUrl } from "utils/japanhaul";
import { UserContext } from "./Layout";

export default function Benefits({}) {
  const { user, isUserLoading, refetchUser } = useContext(UserContext);

  const { data } = useList<
    Omit<Tier, "tier_benefits"> & { tier_benefits: TierBenefit[] }
  >({
    resource: "tiers",
  });

  if (isUserLoading || !user)
    return (
      <Flex h="100%" w="100%" justify="center" align="center">
        <Loader />
      </Flex>
    );

  const tiers = data?.data || [];
  const currentTier = tiers.find((tier) => tier.name === user.tier.name);

  if (!currentTier) return <Text>User has no tier</Text>;

  return (
    <Flex direction="column" justify="space-between" h={500}>
      <Flex direction="column" align="center" gap="sm" mt="md">
        <Text color="gray.6" size="sm">Current Tier</Text>
        <Flex gap="xs">
          <ThemeIcon size="lg" color={colorsByTier[currentTier.name]}>
            <IconCrown strokeWidth={1} />
          </ThemeIcon>
          <Title color="gray.7" order={2}>
            {currentTier.name}
          </Title>
        </Flex>
      </Flex>
      <Flex direction="column">
        <Text color="gray.6" size="sm" sx={{ alignSelf: "center" }}>All Tiers</Text>
        {tiers.map((tier) => (
          <Accordion variant="contained">
            <Accordion.Item value={tier.name} my={4}>
              <Accordion.Control
                icon={
                  <ThemeIcon
                    variant="filled"
                    size="md"
                    color={colorsByTier[tier.name]}
                  >
                    <IconCrown strokeWidth={1} />
                  </ThemeIcon>
                }
              >
                {tier.name}
              </Accordion.Control>
              <Accordion.Panel>
                {tier.tier_benefits.map((benefit) => {
                  return (
                    <Card my="xs" withBorder>
                      <Flex direction="column">
                        <Text weight="bold" color="gray.7">
                          {benefit.title}
                        </Text>
                        <Text color="gray.6" size="sm" italic>
                          {benefit.description}
                        </Text>
                      </Flex>
                    </Card>
                  );
                })}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        ))}
      </Flex>
    </Flex>
  );
}
