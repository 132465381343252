import { useSelect } from "@refinedev/core";
import { Select } from "@mantine/core";
import { User } from "entities/user";
import _ from "lodash"

type UserSelectProps = {
  onChange: (userId: User["id"]) => any,
  value?: User["id"],
  defaultValue?: User["id"],
}

export default function UserSelect({ onChange, value, defaultValue }: UserSelectProps) {
  const { options } = useSelect<User>(
    {
      resource: "users",
      optionLabel: "email",
      optionValue: "id",
      debounce: 1000,
      defaultValue: defaultValue?.toString(),
      pagination: {
        pageSize: 10,
        mode: "server",
        current: 1,
      },
      onSearch: (value) => [
        {
          field: "email",
          operator: "eq",
          value,
        },
      ],
    }
  );

  const updatedOptions = options.map(option => ({ ...option, value: option.value.toString() }))

  return (
    <Select
      label="User"
      placeholder="Select a user"
      withinPortal
      my="xl"
      data={updatedOptions}
      onChange={(userId) => userId && onChange(parseInt(userId))}
      value={value ? value?.toString() : defaultValue?.toString()}
      searchable
    />
  )
}

