import { Brand } from "./brand";
import { Duration } from "./duration";
import { GiftCardCampaignPlan } from "./gift_card_campaign_plan";

export type GiftCardCampaign = {
  id: string;
  code: string;
  brand: Brand;
  duration: Duration;
  active_from: Date | string;
  active_to: Date | string;
  created_at: Date;
  updated_at: Date;
  user_id: string;
  gift_card_campaign_plans: GiftCardCampaignPlan[];
};

export type GiftCardCampaignFormValues = Pick<
  GiftCardCampaign,
  "code" | "brand" | "active_from" | "active_to"
> & {
  gift_card_campaign_plans: Record<
    GiftCardCampaignPlan["duration"],
    Partial<GiftCardCampaignPlan>
  >;
};

export type UpdateGiftCardCampaign = Pick<
  GiftCardCampaign,
  "code" | "brand" | "active_from" | "active_to"
> & {
  gift_card_campaign_plans: Partial<GiftCardCampaignPlan>[];
};

export type CreateGiftCardCampaign = UpdateGiftCardCampaign;

export const DEFAULT_GIFT_CARD_CAMPAIGN_PLANS: GiftCardCampaignFormValues["gift_card_campaign_plans"] =
  {
    1: { duration: 1, gift_card_template_id: "" },
    3: { duration: 3, gift_card_template_id: "" },
    6: { duration: 6, gift_card_template_id: "" },
    12: { duration: 12, gift_card_template_id: "" },
  };
