import { Flex, Image, Text } from "@mantine/core";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { GetInputProps } from "@mantine/form/lib/types";
import { file2Base64 } from "@refinedev/core";
import { IconMoodSad, IconPhoto } from "@tabler/icons";
import { useEffect, useState } from "react";

type ImageDropZoneProps = {
  onFileChosen: (fileBase64: string) => any;
  title?: string;
  description?: string;
  label?: string;
  maxSize?: number;
} & Omit<ReturnType<GetInputProps<string>>, "onChange">;

export default function ImageDropZone({
  onFileChosen,
  title,
  description,
  label,
  value,
  error,
  maxSize,
}: ImageDropZoneProps) {
  const [file, setFile] = useState<FileWithPath | null>(null);
  const [transformError, setTransformError] = useState<Error | string | null>(
    null,
  );

  useEffect(() => {
    const transformFile = async () => {
      if (file) {
        const imageBase64 = await file2Base64({
          originFileObj: file,
        });
        onFileChosen(imageBase64);
      }
    };

    setTransformError(null);
    transformFile().catch((e) => setTransformError(e));
  }, [file]);

  return (
    <>
      {label && <Text size="sm" weight={500}>{label}</Text>}
      <Dropzone
        onDrop={(files) => setFile(files[0])}
        maxFiles={1}
        multiple={false}
        padding="lg"
        maxSize={maxSize}
        onReject={(rejections) => {
          const fileRejection = rejections[0];
          setTransformError(
            fileRejection.errors.map((e) => e.message).join(","),
          );
        }}
      >
        <Flex justify="center" align="center" gap="md">
          {error || transformError
            ? (
              <>
                <IconMoodSad size={48} />
                <Flex direction="column">
                  <Text size="md">Drop an image here</Text>
                  <Text size="sm" color="red">
                    {error || transformError}
                  </Text>
                </Flex>
              </>
            )
            : (
              <>
                <IconPhoto size={48} />
                <Flex direction="column">
                  <Text size="md">{title || "Drop an image here"}</Text>
                  <Text size="sm" color="cyan">
                    {description ||
                      "This will be used in different placements and notifications"}
                  </Text>
                </Flex>
              </>
            )}
        </Flex>
      </Dropzone>
      {file && <TempPreview file={file} />}
      <Preview srcUrl={value} />
    </>
  );
}

export const TempPreview = ({ file }: { file: File }) => {
  const imageUrl = URL.createObjectURL(file);
  return (
    <Image
      src={imageUrl}
      height={100}
      width="auto"
      my="lg"
      imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
    />
  );
};

const Preview = ({ srcUrl }: { srcUrl: string }) => {
  if (!srcUrl || !srcUrl.startsWith("http")) return <div />;

  return <Image src={srcUrl} height={100} width="auto" my="lg" />;
};
