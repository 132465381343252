import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  Flex,
  ThemeIcon,
  Select,
  LoadingOverlay,
  Anchor,
} from "@mantine/core";
import {
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  EmailField,
  TextField,
} from "@refinedev/mantine";
import { Badge } from "@mantine/core";
import { IconX, IconCheck, IconMoodSmile, IconMoodAngry } from "@tabler/icons";

import type { Header } from "@tanstack/react-table";
import { DateRangePicker } from "@mantine/dates";
import useUsersPermissions from "./useUsersPermissions";
import type { User } from "entities/user";
import { selectRoles, colorsByRole } from "entities/user/role";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { colorsByTier, selectTiers } from "entities/tier";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { canCreate, canEdit, canDelete } = useUsersPermissions();

  const columns = React.useMemo<ColumnDef<User>[]>(
    () => [
      {
        id: "id",
        accessorKey: "id",
        header: "Id",
      },
      {
        id: "email",
        accessorKey: "email",
        header: "Email",
        cell: function render({ getValue }) {
          return <EmailField value={getValue<any>()} />;
        },
      },
      {
        id: "role",
        accessorKey: "role",
        header: "Role",
        cell: ({ getValue }) => {
          const role = getValue<User["role"]>();
          return (
            <Badge variant="filled" color={colorsByRole[role]}>
              {role}
            </Badge>
          );
        },
        meta: {
          filterElement: ({ header }: { header: Header<User, any> }) => {
            return (
              <div>
                <Select
                  placeholder="Role"
                  data={selectRoles}
                  onChange={header.column.setFilterValue}
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "tier",
        accessorKey: "tier",
        header: "Tier",
        cell: ({ getValue }) => {
          const tier = getValue<User["tier"]>();
          if (!tier) return;

          return (
            <Anchor component={Link} to={`/tiers/show/${tier.id}`}>
              <Badge variant="outline" color={colorsByTier[tier.name]}>
                {tier.name}
              </Badge>
            </Anchor>
          );
        },
        meta: {
          filterElement: ({ header }: { header: Header<User, any> }) => {
            return (
              <div>
                <Select
                  placeholder="Tier"
                  data={selectTiers}
                  onChange={header.column.setFilterValue}
                  clearable
                />
              </div>
            );
          },
          disableSort: true,
        },
      },
      {
        id: "current_xp",
        accessorKey: "current_xp",
        header: "Current XP",
        cell: ({ getValue }) => {
          const role = getValue<User["current_xp"]>();

          return (
            <Badge variant="light" color="cyan">
              {role}
            </Badge>
          );
        },
      },
      {
        id: "pending_xp",
        accessorKey: "pending_xp",
        header: "Pending XP",
        cell: ({ getValue }) => {
          const pendingCoins = getValue<User["pending_xp"]>();

          return (
            <Badge variant="light" color="gray">
              {pendingCoins}
            </Badge>
          );
        },
      },
      {
        id: "usable_coins",
        accessorKey: "usable_coins",
        header: "Coins Balance",
        cell: ({ getValue }) => {
          const role = getValue<User["usable_coins"]>();

          return (
            <Badge variant="light" color="pink">
              {role}
            </Badge>
          );
        },
      },
      {
        id: "pending_coins",
        accessorKey: "pending_coins",
        header: "Pending Coins",
        cell: ({ getValue }) => {
          const pendingCoins = getValue<User["pending_coins"]>();

          return (
            <Badge variant="light" color="gray">
              {pendingCoins}
            </Badge>
          );
        },
      },
      {
        id: "first_name",
        accessorKey: "first_name",
        header: "First Name",
      },
      {
        id: "last_name",
        accessorKey: "last_name",
        header: "Last Name",
      },
      {
        id: "activation_state",
        accessorKey: "activation_state",
        header: "Activation State",
        cell: ({ getValue }) => {
          const state = getValue<User["activation_state"]>();

          return (
            <Flex justify="center">
              {state === "active" ? (
                <ThemeIcon variant="light" color="green">
                  <IconCheck />
                </ThemeIcon>
              ) : (
                <ThemeIcon variant="light" color="orange">
                  <IconX />
                </ThemeIcon>
              )}
            </Flex>
          );
        },
        meta: {
          filterElement: ({ header }: { header: Header<User, any> }) => {
            return (
              <div>
                <Select
                  placeholder="State"
                  data={[
                    { value: "active", label: "Active" },
                    { value: "inactive", label: "Inactive" },
                  ]}
                  onChange={header.column.setFilterValue}
                  withinPortal
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "competitor",
        accessorKey: "competitor",
        header: "Competitor",
        cell: function render({ getValue }) {
          const competitor = getValue<User["competitor"]>();

          return (
            <Flex justify="center">
              {competitor ? (
                <ThemeIcon variant="light" color="red">
                  <IconMoodAngry />
                </ThemeIcon>
              ) : (
                <ThemeIcon variant="light" color="cyan">
                  <IconMoodSmile />
                </ThemeIcon>
              )}
            </Flex>
          );
        },
        meta: {
          filterElement: ({ header }: { header: Header<User, any> }) => {
            return (
              <div>
                <Select
                  placeholder="Competitor"
                  data={[
                    { value: "true", label: "True" },
                    { value: "false", label: "False" },
                  ]}
                  onChange={header.column.setFilterValue}
                  withinPortal
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "updated_at",
        accessorKey: "updated_at",
        header: "Updated At",
        cell({ getValue }) {
          const date = new Date(getValue<User["updated_at"]>());

          return <TextField value={formatDistanceToNow(date)} />;
        },
        meta: {
          filterElement: ({ header }: { header: Header<User, any> }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "actions",
        cell: function render({ row: { original: user } }) {
          return (
            <Flex columnGap={4}>
              <ShowButton hideText recordItemId={user.id} />
              {canEdit && <EditButton hideText recordItemId={user.id} />}
              {canDelete && <DeleteButton hideText recordItemId={user.id} />}
            </Flex>
          );
        },
        meta: {
          isPlaceholder: true,
          disableSort: true,
        },
      },
    ],
    [canCreate, canEdit, canDelete],
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  setOptions((prev) => ({
    ...prev,
    meta: {
      ...prev.meta,
    },
  }));

  return (
    <List canCreate={canCreate}>
      <AppliedFilters<User> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<User> headerGroups={getHeaderGroups()} />
          <Rows<User> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
