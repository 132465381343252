import { HttpError, IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/mantine";
import { MultiSelect, NumberInput, Select } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { Article, UpdatableArticle } from "entities/article";
import { selectStatuses } from "entities/article/status";
import { ProductSegment } from "entities/product_segment";
import { Category } from "entities/category";

export const ArticleEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    values,
    getInputProps,
    saveButtonProps,
    setFieldValue,
    refineCore: { queryResult },
  } = useForm<Article, HttpError, any>({
    initialValues: {
      publish_at: new Date(),
      status: "draft",
      article_product_segment_id: null,
      product_segment_position: null,
      category_ids: []
    },
    refineCoreProps: {
      queryOptions: {
        // This is need to make the Select field auto-populated
        // because the values in the list have string ids, but the backend for this resource
        // returns a number
        select: ({ data: articleData }) => {
          return {
            data: {
              ...articleData,
              article_product_segment_id:
                articleData?.article_product_segment_id?.toString() || null,
              category_ids: articleData.categories.map(category => category.id.toString())
            },
          };
        },
      },
    },
  });

  const { selectProps, queryResult: segmentResult } = useSelect<ProductSegment>(
    {
      resource: "product_segments",
      debounce: 1000,
      pagination: {
        pageSize: 10,
        mode: "server",
        current: 1,
      },
      onSearch: (value) => [
        {
          field: "code",
          operator: "contains",
          value,
        },
      ],
    }
  );

  const { selectProps: categorySelectProps, queryResult: categoryQueryResult } =
    useSelect<Category>({
      resource: "categories",
      debounce: 1000,
      pagination: {
        pageSize: 10,
        mode: "server",
        current: 1,
      },
      onSearch: (value) => [
        {
          field: "name",
          operator: "contains",
          value,
        },
      ],
    });

  const segmentProducts =
    segmentResult?.data?.data.map((productSegment) => ({
      label: productSegment.title,
      value: productSegment.id.toString(),
    })) || [];

  const categoriesData =
    categoryQueryResult?.data?.data.map((category) => ({
      label: category.name,
      value: category.id.toString(),
    })) || [];

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Select
        placeholder="Status"
        data={selectStatuses}
        label="status"
        clearable
        {...getInputProps("status")}
      />
      <DatePicker
        placeholder="Publish At"
        maw={400}
        label="Publish At"
        withinPortal
        {...getInputProps("publish_at")}
      />
      <Select
        placeholder="Segment Product"
        data={segmentProducts}
        label="Product Segment"
        searchable
        clearable
        {...getInputProps("article_product_segment_id")}
      />
      <MultiSelect
        placeholder="Categories"
        data={categoriesData}
        label="Categories"
        searchable
        withinPortal
        {...getInputProps("category_ids")}
      />
      <NumberInput
        label="Product Segment Position"
        {...getInputProps("product_segment_position")}
      />
    </Edit>
  );
};
