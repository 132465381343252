import React, { useEffect } from 'react'
import { useSelect } from "@refinedev/mantine";
import { GiftCardTemplate } from "entities/gift_card_template";
import { Card, Select, TextInput, Title } from "@mantine/core";
import { HttpError, useOne } from '@refinedev/core';

export const GiftCardPlan = ({
  duration,
  value,
  onChange,
}: {
  duration: number;
  value: string;
  onChange: (value: string | null) => null;
}) => {
  const {
    selectProps: giftCardTemplateProps,
    queryResult: giftCardTemplatesResult,
  } = useSelect<GiftCardTemplate>({
    resource: "gift_card_templates",
    optionLabel: "notes",
    optionValue: "id",
    pagination: {
      pageSize: 10,
      mode: "server",
      current: 1,
    },
    onSearch: (value) => [
      {
        field: "initial_value",
        operator: "contains",
        value,
      },
    ],
  });

  const giftCardTemplateOptions = giftCardTemplatesResult.data?.data.map(
    (item) => ({
      label: `$${item.initial_value} (${item.notes})`,
      value: item.id.toString(),
      ...item,
    })
  );

  return (
    <Card withBorder sx={(theme) => ({ margin: theme.spacing.sm })}>
      <Title order={4}>{duration} Months Plan</Title>
      <Select
        placeholder="Gift Card Template"
        {...giftCardTemplateProps}
        value={value && value.toString()}
        onChange={onChange}
        data={giftCardTemplateOptions || []}
        withinPortal
      />
    </Card>
  );
};
