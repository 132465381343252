import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  Flex,
  LoadingOverlay,
  Text,
  Avatar,
  Badge,
  Select,
  Anchor
} from "@mantine/core";
import {
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
} from "@refinedev/mantine";

import type { Header } from "@tanstack/react-table";
import { DateRangePicker } from "@mantine/dates";
import useLikePermissions from "./useLikesPermissions";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { Like } from "entities/like";
import { colorsByLikableType, selectLikableTypes, labelsByLikableType, urlsByLikableType } from "entities/like/likable_type";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";

export const LikeList: React.FC<IResourceComponentsProps> = () => {
  const { canCreate, canEdit, canDelete } = useLikePermissions();

  const columns = React.useMemo<ColumnDef<Like>[]>(
    () => [
      {
        id: "id",
        accessorKey: "id",
        header: "Id",
      },
      {
        id: "user",
        accessorKey: "user",
        header: "User",
        cell({ getValue }) {
          const user = getValue<Like["user"]>();

          return (
            <Text size="md" weight="bold">
              <Flex direction="column" align="center">
                <Avatar src={user?.image} size="md" />
                <Text color="white">
                  {user?.first_name}
                  &nbsp;
                  {user?.last_name}
                </Text>
                <Text color="orange">{user?.email}</Text>
              </Flex>
            </Text>
          );
        },
        meta: {
          filterOperator: "eq",
          disableSort: true,
        },
      },
      {
        id: "likable_id",
        accessorKey: "likable_id",
        header: "Likable Id",
        cell: ({ getValue, row }) => {
          const id = getValue<Like["id"]>()
          const likableType = row.original.likable_type
          const url = urlsByLikableType[likableType]

          return (
            <Anchor
              component={Link}
              to={`${url}/show/${id}`}
            >
              {id}
            </Anchor>
          )
        }
      },
      {
        id: "likable_type",
        accessorKey: "likable_type",
        header: "Likable Type",
        cell: ({ getValue }) => {
          const likableType = getValue<Like["likable_type"]>();

          return (
            <Badge variant="light" color={colorsByLikableType[likableType]}>
              {labelsByLikableType[likableType]}
            </Badge>
          );
        },
        meta: {
          filterElement: ({ header }: { header: Header<Like, any> }) => {
            return (
              <div>
                <Select
                  placeholder="Like type"
                  data={selectLikableTypes}
                  onChange={header.column.setFilterValue}
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "Created At",
        cell: function render({ getValue }) {
          return <Text>{formatDistanceToNow(new Date(getValue<any>()))}</Text>
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<Like, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "updated_at",
        accessorKey: "updated_at",
        header: "Updated At",
        cell: function render({ getValue }) {
          return <Text>{formatDistanceToNow(new Date(getValue<any>()))}</Text>
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<Like, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "actions",
        cell: function render({ row: { original: Like } }) {
          return (
            <Flex columnGap={4}>
              <ShowButton hideText recordItemId={Like.id} />
              {canEdit && (
                <EditButton hideText recordItemId={Like.id} />
              )}
              {canDelete && (
                <DeleteButton hideText recordItemId={Like.id} />
              )}
            </Flex>
          );
        },
        meta: {
          isPlaceholder: true,
        },
      },
    ],
    [canCreate, canEdit, canDelete]
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  setOptions((prev) => ({
    ...prev,
    meta: {
      ...prev.meta,
    },
  }));

  return (
    <List canCreate={canCreate}>
      <AppliedFilters<Like> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<Like> headerGroups={getHeaderGroups()} />
          <Rows<Like> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
