import {
  useMany,
  HttpError,
  BaseRecord,
} from "@refinedev/core";
import { UseManyProps } from "@refinedev/core/dist/hooks/data/useMany";
import _ from "lodash";

export default function useManyIndexed<
  K extends string | number | symbol,
  T extends BaseRecord
>(useManyArgs: Parameters<typeof useMany>[0]): Record<K, T | null> {
  const { data } = useMany<T, HttpError>(
    useManyArgs as UseManyProps<T, HttpError, T>
  );

  return _.keyBy(data?.data || [], (item) => item.id) as Record<K, T | null>;
}
