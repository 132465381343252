import React from "react";
import { IResourceComponentsProps, useMenu } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, Header } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  LoadingOverlay,
  Flex,
  Badge,
  Text,
} from "@mantine/core";
import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  TextField,
} from "@refinedev/mantine";

import useTiersPermissons from "./useTiersPermissions";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { DateRangePicker } from "@mantine/dates";
import { Tier, colorsByTier } from "entities/tier";
import { formatDistanceToNow } from "date-fns";

export const TierList: React.FC<IResourceComponentsProps> = () => {
  const { canEdit } = useTiersPermissons();

  const columns: ColumnDef<Tier>[] = [
    {
      id: "id",
      accessorKey: "id",
      header: "Id",
    },
    {
      id: "name",
      accessorKey: "name",
      header: "Name",
      cell({ getValue }) {
        const name = getValue<Tier["name"]>();

        return <Badge color={colorsByTier[name]}>{name}</Badge>;
      },
      meta: {
        filterOperator: "contains",
      },
    },
    {
      id: "xp_required",
      accessorKey: "xp_required",
      header: "XP Required",
      cell({ getValue }) {
        const coinsPerDollar = getValue<Tier["coins_per_dollar"]>();

        return (
          <Text size="md" weight="bold">
            {coinsPerDollar}
          </Text>
        );
      },
      meta: {
        filterOperator: "eq",
      },
    },
    {
      id: "coins_per_dollar",
      accessorKey: "coins_per_dollar",
      header: "Coins Per Dollar",
      cell({ getValue }) {
        const coinsPerDollar = getValue<Tier["coins_per_dollar"]>();

        return (
          <Text size="md" color="blue">
            {coinsPerDollar}
          </Text>
        );
      },
      meta: {
        filterOperator: "eq",
      },
    },
    {
      id: "users_count",
      accessorKey: "users_count",
      header: "Users Count",
      cell({ getValue }) {
        const usersCount = getValue<Tier["users_count"]>();

        return (
          <Text size="md" color="lime">
            {usersCount}
          </Text>
        );
      },
      meta: {
        filterOperator: "eq",
        disableSort: true,
      },
    },
    {
      id: "updated_at",
      accessorKey: "updated_at",
      header: "Updated At",
      cell({ getValue }) {
        const date = new Date(getValue<Tier["updated_at"]>());

        return <TextField value={formatDistanceToNow(date)} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<Tier, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={header.column.setFilterValue}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "actions",
      cell: function render({ row: { original: survey } }) {
        return (
          <Flex columnGap={4}>
            <ShowButton hideText recordItemId={survey.id} />
            {canEdit && <EditButton hideText recordItemId={survey.id} />}
          </Flex>
        );
      },
      meta: {
        isPlaceholder: true,
      },
    },
  ];

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  return (
    <List canCreate={false}>
      <AppliedFilters<Tier> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<Tier> headerGroups={getHeaderGroups()} />
          <Rows<Tier> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
