import { Button, Divider, Flex, Text, Title } from "@mantine/core";
import { loginUrl, signupUrl } from "utils/japanhaul";

export default function Authenticate({}) {
  return (
    <Flex direction="column" justify="space-between" h={500}>
      <Flex direction="column" align="stretch" mt={70}>
        <Title order={2} align="center" color="red">
          Welcome to JH Points!
        </Title>
        <Text color="gray.7" my="md" align="center">
          Join our Rewards Program Access existing perks, savings and rewards
          just by shopping with us!
        </Text>
      </Flex>
      <Flex direction="column">
        <Button color="red" onClick={() => window.open(signupUrl)}>
          Create Account
        </Button>
        <Divider my="lg" label="OR" labelPosition="center" color="gray.5" />
        <Button color="gray.6" onClick={() => window.open(loginUrl)}>
          Login
        </Button>
      </Flex>
    </Flex>
  );
}
