import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Show, NumberField, TextField } from "@refinedev/mantine";
import { Title, Badge, Grid, Flex } from "@mantine/core";
import { XpReduction } from "entities/xp_reduction";
import { DetailsPair } from "components/common/show/DetailsPair";
import { formatDistanceToNow } from "date-fns";
import { History } from "components/common/show/History";
import { DetailsUser } from "components/common/show/DetailsUser";
import { Text } from "@mantine/core";
import { colorsBySource } from "entities/xp_reduction/source";

export const XpReductionShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<XpReduction>();
  const { data, isLoading, refetch } = queryResult;

  const xpReduction = data?.data;
  if (!xpReduction) return <div />;

  return (
    <Show isLoading={isLoading}>
      <Grid>
        <Grid.Col span={8}>
          {xpReduction && (
            <History<XpReduction>
              resources={"xp_reductions"}
              entityId={xpReduction.id}
            />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Flex direction="column" gap="sm">
            <DetailsPair>
              <Title order={5}>User</Title>
              {xpReduction?.user && <DetailsUser user={xpReduction.user} />}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Source
              </Title>
              {xpReduction && (
                <Badge color={colorsBySource[xpReduction.source]}>
                  {xpReduction.source.replaceAll("_", " ")}
                </Badge>
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                XP Amount
              </Title>
              <Text color="green" weight="bold">
                {xpReduction?.amount}
              </Text>
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Created At
              </Title>
              {xpReduction?.created_at && (
                <TextField
                  value={formatDistanceToNow(new Date(xpReduction.created_at))}
                />
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Updated At
              </Title>
              {xpReduction?.updated_at && (
                <TextField
                  value={formatDistanceToNow(new Date(xpReduction.updated_at))}
                />
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Id
              </Title>
              {xpReduction && <NumberField value={xpReduction?.id} />}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Notes
              </Title>
              {xpReduction && <TextField value={xpReduction.notes} />}
            </DetailsPair>
          </Flex>
        </Grid.Col>
      </Grid>
    </Show>
  );
};
