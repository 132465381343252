const env = process.env.NODE_ENV;
const shopifySite =
  env === "production"
    ? "https://admin.shopify.com/store/tokyotreat-shop"
    : "https://admin.shopify.com/store/japanhaul-staging";

export const getDiscountCodeUrl = (couponId: string) =>
  `${shopifySite}/discounts/${couponId}`;

export const getShopifyOrderUrl = (orderId: string) => `${shopifySite}/orders/${orderId.split('-')[1]}`;


export const getShopifyOrderName = (orderId: string) => orderId.split('-')[0]
