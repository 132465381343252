import {
  Accordion,
  Anchor,
  Badge,
  Box,
  Flex,
  Grid,
  Image,
  Switch,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useGetIdentity } from "@refinedev/core";
import { DateField, TextField } from "@refinedev/mantine";
import { IconBell, IconGift, IconMoodSmile, IconSettings } from "@tabler/icons";
import { DetailsPair } from "components/common/show/DetailsPair";
import { DetailsUser } from "components/common/show/DetailsUser";
import { Survey } from "entities/survey";
import { User } from "entities/user";

type ShowDetailsProps = {
  user: User;
  survey: Survey;
};

export function ShowDetails({ user, survey }: ShowDetailsProps) {
  const { data: currentUser } = useGetIdentity<User>();

  return (
    <Grid py="md">
      <Grid.Col span={8}>
        <Accordion variant="separated" defaultValue="home_banner_config">
          {survey?.home_banner_config && (
            <Accordion.Item value="home_banner_config">
              <Accordion.Control>
                <Flex>
                  <ThemeIcon variant="light" color="violet">
                    <IconSettings />
                  </ThemeIcon>
                  <Text ml="md">Home Banner Config</Text>
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>
                <Flex direction="column" gap="sm">
                  <DetailsPair>
                    <Title order={5}>Visible</Title>
                    <Switch checked={survey?.home_banner_config.visible} />
                  </DetailsPair>
                  <DetailsPair>
                    <Title order={5}>Title</Title>
                    <TextField value={survey.home_banner_config.title} />
                  </DetailsPair>
                  <DetailsPair>
                    <Title order={5}>Description</Title>
                    <Box>
                      <p>{survey.home_banner_config.description}</p>
                    </Box>
                  </DetailsPair>
                  <DetailsPair>
                    <Title order={5}>Icon</Title>
                    {survey.home_banner_config.icon === "gift" ? (
                      <IconGift />
                    ) : (
                      <IconMoodSmile />
                    )}
                  </DetailsPair>
                  <DetailsPair>
                    <Title order={5}>Background Color</Title>
                    <Box
                      h={20}
                      w={20}
                      style={{
                        backgroundColor:
                          survey.home_banner_config.background_color,
                      }}
                    />
                  </DetailsPair>
                </Flex>
              </Accordion.Panel>
            </Accordion.Item>
          )}
          {survey?.package_description_config && (
            <Accordion.Item value="package_description_config">
              <Accordion.Control>
                <Flex>
                  <ThemeIcon variant="light" color="violet">
                    <IconSettings />
                  </ThemeIcon>
                  <Text ml="md">Package Description Config</Text>
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>
                <Flex direction="column" gap="sm">
                  <DetailsPair>
                    <Title order={5}>Visible</Title>
                    <Switch
                      checked={survey?.package_description_config.visible}
                    />
                  </DetailsPair>
                  <DetailsPair>
                    <Title order={5}>Year</Title>
                    <Text>{survey?.package_description_config.year}</Text>
                  </DetailsPair>
                  <DetailsPair>
                    <Title order={5}>Month</Title>
                    <Text>{survey?.package_description_config.month}</Text>
                  </DetailsPair>
                </Flex>
              </Accordion.Panel>
            </Accordion.Item>
          )}
          {survey?.giveaways_config && (
            <Accordion.Item value="giveaways_config">
              <Accordion.Control>
                <Flex>
                  <ThemeIcon variant="light" color="violet">
                    <IconSettings />
                  </ThemeIcon>
                  <Text ml="md">Giveaways Config</Text>
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>
                <Flex direction="column" gap="sm">
                  <DetailsPair>
                    <Title order={5}>Visible</Title>
                    <Switch checked={survey?.giveaways_config.visible} />
                  </DetailsPair>
                </Flex>
              </Accordion.Panel>
            </Accordion.Item>
          )}
          {survey?.push_notification_config && (
            <Accordion.Item value="push_notification_config">
              <Accordion.Control>
                <Flex>
                  <ThemeIcon variant="light" color="orange">
                    <IconBell />
                  </ThemeIcon>
                  <Text ml="md">Push Notification Config</Text>
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>
                <Flex direction="column" gap="sm">
                  <DetailsPair>
                    <Title order={5}>Scheduled At</Title>
                    <DateField
                      value={survey?.push_notification_config.scheduled_at}
                    />
                  </DetailsPair>
                  <DetailsPair>
                    <Title order={5}>Title</Title>
                    <TextField value={survey?.push_notification_config.title} />
                  </DetailsPair>
                  <DetailsPair>
                    <Title order={5}>Description</Title>
                    <Box>
                      <p>{survey?.push_notification_config.description}</p>
                    </Box>
                  </DetailsPair>
                </Flex>
              </Accordion.Panel>
            </Accordion.Item>
          )}
          {survey?.in_app_notification_config && (
            <Accordion.Item value="in_app_notification_config">
              <Accordion.Control>
                <Flex>
                  <ThemeIcon variant="light" color="orange">
                    <IconBell />
                  </ThemeIcon>
                  <Text ml="md">In App Notification Config</Text>
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>
                <Flex direction="column" gap="sm">
                  <DetailsPair>
                    <Title order={5}>Scheduled At</Title>
                    <DateField
                      value={survey?.in_app_notification_config.scheduled_at}
                    />
                  </DetailsPair>
                  <DetailsPair>
                    <Title order={5}>Title</Title>
                    <TextField
                      value={survey?.in_app_notification_config.title}
                    />
                  </DetailsPair>
                  <DetailsPair>
                    <Title order={5}>Description</Title>
                    <Box>
                      <p>{survey?.in_app_notification_config.description}</p>
                    </Box>
                  </DetailsPair>
                </Flex>
              </Accordion.Panel>
            </Accordion.Item>
          )}
          {survey?.email_notification_config && (
            <Accordion.Item value="email_notification_config">
              <Accordion.Control>
                <Flex>
                  <ThemeIcon variant="light" color="orange">
                    <IconBell />
                  </ThemeIcon>
                  <Text ml="md">Email Notification Config</Text>
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>
                <Flex direction="column" gap="sm">
                  <DetailsPair>
                    <Title order={5}>Scheduled At</Title>
                    <DateField
                      value={survey?.email_notification_config.scheduled_at}
                    />
                  </DetailsPair>
                </Flex>
              </Accordion.Panel>
            </Accordion.Item>
          )}
        </Accordion>
      </Grid.Col>
      <Grid.Col span={4}>
        <Flex direction="column" gap="sm">
          <DetailsPair>
            <Title my="xs" order={5}>
              Image
            </Title>
            <Image sx={{ maxWidth: 200 }} src={survey?.image} />
          </DetailsPair>
          <DetailsPair>
            <Title order={5}>Title</Title>
            <TextField value={survey?.title} />
          </DetailsPair>
          <DetailsPair>
            <Title order={5}>Description</Title>
            <Box px="md">
              <p>{survey?.description}</p>
            </Box>
          </DetailsPair>
          {survey?.url && currentUser &&
            <DetailsPair>
              <Title order={5}>Form Url</Title>
              <Anchor href={`${survey?.url}#user_id=${currentUser.email}&survey_id=${survey.id}`} target="_blank">
                {`${survey?.url}#user_id=${currentUser.email}&survey_id=${survey.id}`}
              </Anchor>
            </DetailsPair>
          }
          <DetailsPair>
            <Title order={5}>Audience </Title>
            <Badge color="blue">{survey?.audience}</Badge>
          </DetailsPair>
          <DetailsPair>
            <Title order={5}>Audience State</Title>
            <Badge color="blue">{survey?.audience_state}</Badge>
          </DetailsPair>
          <DetailsPair>
            <Title order={5}>Active from</Title>
            <DateField value={survey?.active_from} />
          </DetailsPair>
          <DetailsPair>
            <Title order={5}>Active to</Title>
            <DateField value={survey?.active_from} />
          </DetailsPair>
          <DetailsPair>
            <Title order={5}>Created at</Title>
            <DateField value={survey?.created_at} />
          </DetailsPair>
          <DetailsPair>
            <Title order={5}>Updated at</Title>
            <DateField value={survey?.updated_at} />
          </DetailsPair>
          <DetailsPair>
            <Title order={5}>Reward</Title>
            <Badge color="blue">{survey?.reward_type}</Badge>
          </DetailsPair>
          <DetailsPair>
            <Title order={5}>User</Title>
            {user && <DetailsUser user={user} />}
          </DetailsPair>
        </Flex>
      </Grid.Col>
    </Grid>
  );
}
