import { Flex, Grid, Image, Text } from "@mantine/core";
import {
  HttpError,
  IResourceComponentsProps,
  useNavigation,
  useShow,
} from "@refinedev/core";
import { DeleteButton, EditButton, Show } from "@refinedev/mantine";
import { DetailsCard } from "components/common/show/DetailsPair";
import { History } from "components/common/show/History";

import { PollOption } from "entities/poll_option";

export const PollOptionShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<PollOption, HttpError>();
  const { data, isLoading } = queryResult;
  const pollOption = data?.data;
  const { show } = useNavigation();

  if (!pollOption) return <Text>PollOption Not Found</Text>;

  return (
    <Show
      isLoading={isLoading}
      headerButtons={
        <>
          <EditButton resource="poll_options" recordItemId={pollOption.id}>
            Edit
          </EditButton>
          <DeleteButton
            resource="poll_options"
            recordItemId={pollOption.id}
            onSuccess={() => show("polls", pollOption.poll_id)}
          >
            Delete
          </DeleteButton>
        </>
      }
    >
      <Grid>
        <Grid.Col span={8}>
          {pollOption?.id && (
            <History<PollOption>
              resources={"poll_options"}
              entityId={pollOption.id}
            />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Flex direction="column" gap="sm">
            <DetailsCard title="Image">
              <Image sx={{ maxWidth: 200 }} src={pollOption?.image} />
            </DetailsCard>
            <DetailsCard title="label">
              <Text>{pollOption.label}</Text>
            </DetailsCard>
          </Flex>
        </Grid.Col>
      </Grid>
    </Show>
  );
};
