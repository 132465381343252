import { HttpError, IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/mantine";
import { Select, TextInput, Title } from "@mantine/core";
import {
  GiftCardCampaignFormValues,
  GiftCardCampaign,
  DEFAULT_GIFT_CARD_CAMPAIGN_PLANS,
  CreateGiftCardCampaign,
} from "entities/gift_card_campaign";
import { GiftCardCampaignPlan } from "entities/gift_card_campaign/gift_card_campaign_plan";
import DateTimePicker from "components/common/DateTimePicker";
import { selectBrands } from "entities/gift_card_campaign/brand";
import { durations } from "entities/gift_card_campaign/duration";
import { GiftCardPlan } from "./GiftCardPlan";
import { formatToJst } from "utils/dates";

export const GiftCardCampaignCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const { getInputProps, saveButtonProps } = useForm<
    GiftCardCampaign,
    HttpError,
    GiftCardCampaignFormValues,
    CreateGiftCardCampaign,
    GiftCardCampaignFormValues
  >({
    initialValues: {
      code: "",
      brand: "TT",
      active_from: new Date(),
      active_to: new Date(),
      gift_card_campaign_plans: DEFAULT_GIFT_CARD_CAMPAIGN_PLANS,
    },
    transformValues: (values) => {
      return {
        ...values,
        active_from: formatToJst(values.active_from),
        active_to: formatToJst(values.active_to),
        gift_card_campaign_plans: Object.values(
          values.gift_card_campaign_plans
        ) as Partial<GiftCardCampaignPlan>[],
      };
    },
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <TextInput label="Code" {...getInputProps("code")} />
      <Select
        placeholder="Brand"
        data={selectBrands}
        label="brand"
        clearable
        {...getInputProps("brand")}
      />
      <DateTimePicker
         label="Active from"
         {...getInputProps("active_from")}
      />
      <DateTimePicker
         label="Active to"
         {...getInputProps("active_to")}
      />
      <Title order={2} mt="lg">
        Plans
      </Title>
      {durations.map((duration, i) => (
        <GiftCardPlan
          key={duration}
          duration={duration}
          {...getInputProps(
            `gift_card_campaign_plans.${duration}.gift_card_template_id`
          )}
        />
      ))}
    </Create>
  );
};
