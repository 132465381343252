import { Text, Flex, Anchor, Avatar } from "@mantine/core";
import { DetailsCard } from "components/common/show/DetailsPair";
import { Like } from "entities/like";
import { Link } from "react-router-dom";

export function LikeCard({ like }: { like: Like }) {
  return (
    <DetailsCard>
      <Flex direction="column">
        <Flex gap="sm" justify="space-between">
          <Flex gap="sm">
            <Avatar src={like.user.image} size="lg" />
            <Flex direction="column">
              <Text weight="bold">{like.user.first_name}&nbsp;{like.user.last_name}</Text>
              <Text italic>{like.user.email}</Text>
            </Flex>
          </Flex>
          <Flex direction="column">
            <Text color="gray">{like.created_at.toString()}</Text>
            <Anchor component={Link} to={`/likes/show/${like.id}`}>
              View Like
            </Anchor>
          </Flex>
        </Flex>
      </Flex>
    </DetailsCard>
  )
}
