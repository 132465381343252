import _ from "lodash";

export type CoinEarningRewardKind =
  | "review_sub_box"
  | "review_order_jh_oneoff"
  | "review_order_ichigo_app_oneoff"
  | "review_product_jh_oneoff"
  | "review_product_ichigo_app_oneoff";

export const kinds: CoinEarningRewardKind[] = [
  "review_sub_box",
  "review_order_jh_oneoff",
  "review_order_ichigo_app_oneoff",
  "review_product_jh_oneoff",
  "review_product_ichigo_app_oneoff",
];

export const selectKinds = kinds.map((kind) => {
  return { value: kind, label: _.capitalize(kind.replaceAll("_", " ")) };
});

export const colorsByKind: Record<CoinEarningRewardKind, string> = {
  review_sub_box: "green",
  review_order_jh_oneoff: "blue",
  review_order_ichigo_app_oneoff: "red",
  review_product_jh_oneoff: "pink",
  review_product_ichigo_app_oneoff: "yellow",
};
