import {
  Button,
  Card,
  Flex,
  Grid,
  Loader,
  LoadingOverlay,
  Tabs,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandReddit,
  IconBrandTwitter,
  IconCake,
  IconCoin,
  IconShoppingCart,
} from "@tabler/icons";
import { Dispatch, SetStateAction, useContext } from "react";
import { Tab } from "./Widget";
import { Screen, UserContext } from "./Layout";
import { CoinSpending } from "entities/coin_spending";
import {
  useApiUrl,
  useCustomMutation,
  useList,
  useOne,
  HttpError,
} from "@refinedev/core";
import { User } from "entities/user";
import { LoyaltyReward } from "entities/loyalty_reward";
import {
  CoinEarning,
  JH_FACEBOOK_PAGE,
  JH_INSTAGRAM_PAGE,
  JH_REDDIT_PAGE,
  JH_TWITTER_PAGE,
} from "entities/coin_earning";
import { Tier } from "entities/tier";

type ActionsScreenProps = {
  setScreen: Dispatch<SetStateAction<Screen>>;
  setTab: Dispatch<SetStateAction<Tab>>;
  setCoinSpending: Dispatch<SetStateAction<CoinSpending | null>>;
  setLoyaltyReward: Dispatch<SetStateAction<LoyaltyReward | null>>;
  tab: Tab;
};

export type SnsEvent =
  | "sns_twitter_follow"
  | "sns_reddit_join"
  | "sns_facebook_like"
  | "sns_instagram_follow";

type Action = {
  icon: any;
  title: string;
  description: string;
  buttonText: React.ReactNode | null;
  type: string;
  onAction: any;
};

export default function ActionsScreen({
  tab,
  setTab,
  setScreen,
  setCoinSpending,
  setLoyaltyReward,
}: ActionsScreenProps) {
  const { user, isUserLoading, refetchUser } = useContext(UserContext);
  const apiUrl = useApiUrl();
  const {
    mutate: redeemReward,
    isLoading: isRedeemingReward,
    data: redeemedRewardData,
  } = useCustomMutation<CoinSpending>();

  const {
    mutate: createCoinEarning,
    isLoading: creatingCoinEarning,
    data: createCoinEarningData,
  } = useCustomMutation<CoinEarning>();

  const { data: coinEarningRewardsData } = useList({
    resource: "coin_earning_rewards",
    hasPagination: false,
    filters: [
      {
        field: "kind",
        operator: "contains",
        value: "sns",
      },
    ],
  });

  const snsCoinRewards = coinEarningRewardsData?.data;

  const { data: { data: tierData } = {} } = useOne<Tier, HttpError>({
    resource: "tiers",
    id: user?.tier?.id,
  });

  if (!user || isUserLoading)
    return (
      <Flex h="100%" w="100%" justify="center" align="center">
        <Loader />
      </Flex>
    );

  const takenActions = user.taken_sns_actions.map((action) => action.event);

  const handleGetCoupon = (user: User, reward: LoyaltyReward) => {
    redeemReward(
      {
        url: `${apiUrl}/coin_spendings/redeem_reward`,
        method: "post",
        values: {
          user_id: user.id,
          loyalty_reward_id: reward.id,
        },
      },
      {
        onSuccess: (data, variables, context) => {
          setCoinSpending(data.data);
          setScreen("reward_view");
          refetchUser();
        },
      },
    );
  };

  const handleSnsAction = (event: SnsEvent, url: string) => {
    createCoinEarning(
      {
        url: `${apiUrl}/coin_earnings/sns_action`,
        method: "post",
        values: {
          user_id: user.id,
          event,
          locks: {},
        },
      },
      {
        onSuccess: (data, variables, context) => {
          refetchUser();
          window.open(url, "_blank");
        },
      },
    );
  };

  const basicActions: Action[] = [
    {
      icon: (
        <ThemeIcon variant="light" size="lg">
          <IconShoppingCart strokeWidth="1px" />{" "}
        </ThemeIcon>
      ),
      title: "Make a purchase",
      description: "1 coin per $1 spent",
      buttonText: null,
      type: "purchase",
      onAction: () => {},
    },
  ];

  const instagramCoinReward = snsCoinRewards?.find(
    (reward) => reward.kind === "sns_instagram_follow",
  );

  const twitterCoinReward = snsCoinRewards?.find(
    (reward) => reward.kind === "sns_twitter_follow",
  );

  const facebookCoinReward = snsCoinRewards?.find(
    (reward) => reward.kind === "sns_facebook_like",
  );

  const redditCoinReward = snsCoinRewards?.find(
    (reward) => reward.kind === "sns_reddit_join",
  );

  const instagramAction = instagramCoinReward && {
    icon: (
      <ThemeIcon variant="light" size="lg">
        <IconBrandInstagram strokeWidth="1px" />{" "}
      </ThemeIcon>
    ),
    title: "Instagram Follow",
    description: `${instagramCoinReward.coins} coins`,
    buttonText: !takenActions.includes("sns_instagram_follow") && "Follow Us",
    type: "sns_instagram_follow",
    onAction: () => handleSnsAction("sns_instagram_follow", JH_INSTAGRAM_PAGE),
  };

  const twitterAction = twitterCoinReward && {
    icon: (
      <ThemeIcon variant="light" size="lg">
        <IconBrandTwitter strokeWidth="1px" />{" "}
      </ThemeIcon>
    ),
    title: "Twitter Follow",
    description: `${twitterCoinReward.coins} coins`,
    buttonText: !takenActions.includes("sns_twitter_follow") && "Follow Us",
    type: "sns_twitter_follow",
    onAction: () => handleSnsAction("sns_twitter_follow", JH_TWITTER_PAGE),
  };

  const facebookAction = facebookCoinReward && {
    icon: (
      <ThemeIcon variant="light" size="lg">
        <IconBrandFacebook strokeWidth="1px" />{" "}
      </ThemeIcon>
    ),
    title: "Facebook Like",
    description: `${facebookCoinReward.coins} coins`,
    buttonText: !takenActions.includes("sns_facebook_like") && "Like Us",
    type: "sns_facebook_like",
    onAction: () => handleSnsAction("sns_facebook_like", JH_FACEBOOK_PAGE),
  };

  const redditAction = redditCoinReward && {
    icon: (
      <ThemeIcon variant="light" size="lg">
        <IconBrandReddit strokeWidth="1px" />
      </ThemeIcon>
    ),
    title: "Reddit Join",
    description: `${redditCoinReward.coins} coins`,
    buttonText: !takenActions.includes("sns_reddit_join") && "Join Us",
    type: "sns_reddit_join",
    onAction: () => handleSnsAction("sns_reddit_join", JH_REDDIT_PAGE),
  };

  const actions = basicActions.concat(
    [instagramAction, facebookAction, twitterAction, redditAction].filter(
      (a) => a,
    ) as Action[],
  );

  if (tierData?.tier_benefits?.birthday_bonus) {
    actions.splice(1, 0, {
      icon: (
        <ThemeIcon variant="light" size="lg">
          <IconCake strokeWidth="1px" />{" "}
        </ThemeIcon>
      ),
      title: "Celebrate a birthday",
      description: `${tierData.tier_benefits.birthday_bonus.value} coins`,
      type: "birthday",
      buttonText: !user.birthday && "Pick date",
      onAction: () => setScreen("set_birthday"),
    });
  }

  return (
    <Flex direction="column" align="center">
      <Text color="gray.8">Your Coins</Text>
      <Title color="red" order={2}>
        {user.usable_coins} Coins
      </Title>
      <Flex h={100} justify="center" align="center">
        {tab === "earn_coins" ? (
          <Text color="gray.7">
            Earn Coins for completing actions, and turn your points into
            rewards.
          </Text>
        ) : (
          <Text color="gray.7">Redeem your Coins for great discounts</Text>
        )}
      </Flex>
      <Tabs
        sx={{ alignSelf: "stretch" }}
        color="red"
        value={tab}
        onTabChange={(newTab) =>
          newTab && setTab(newTab as "rewards" | "earn_coins")
        }
      >
        <Tabs.List grow>
          <Tabs.Tab value="earn_coins">Earn Coins</Tabs.Tab>
          <Tabs.Tab value="rewards">Rewards</Tabs.Tab>
          <Tabs.Tab value="my_rewards">My Rewards</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="earn_coins" py="sm">
          <LoadingOverlay visible={creatingCoinEarning} />
          <Flex direction="column" gap="sm">
            {actions.map((action) => {
              const isActionTaken = takenActions.includes(
                action.type as CoinEarning["event"],
              );

              return (
                <Card
                  key={action.type}
                  shadow="md"
                  w="100%"
                  bg={isActionTaken ? "gray.2" : "white"}
                  withBorder
                >
                  <Grid align="center" justify="start">
                    <Grid.Col span={2} p={0}>
                      {action.icon}
                    </Grid.Col>
                    <Grid.Col span={6} p={0}>
                      <Title order={6} color="gray.8">
                        {action.title}
                      </Title>
                      <Text size="xs" color="gray.7">
                        {action.description}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={4} p={0}>
                      {isActionTaken ? (
                        <Text color="gray" size="xs" italic>
                          Completed
                        </Text>
                      ) : (
                        <>
                          {action.buttonText && (
                            <Button
                              size="xs"
                              color="red"
                              onClick={action.onAction}
                            >
                              {action.buttonText}
                            </Button>
                          )}
                        </>
                      )}
                    </Grid.Col>
                  </Grid>
                </Card>
              );
            })}
          </Flex>
        </Tabs.Panel>
        <Tabs.Panel value="rewards" py="sm">
          <Text>
            {isRedeemingReward ? (
              <LoadingOverlay visible />
            ) : (
              <Flex direction="column" gap="sm">
                {user.available_rewards.map((reward) => (
                  <Card key={reward.id} shadow="md" w="100%" withBorder>
                    <Grid align="center" justify="start">
                      <Grid.Col span={2} p={0}>
                        <ThemeIcon variant="light" size="lg">
                          <IconCoin strokeWidth="1px" />
                        </ThemeIcon>
                      </Grid.Col>
                      <Grid.Col span={6} p={0}>
                        <Title order={6} color="gray.8">
                          {reward.name}
                        </Title>
                        <Text size="xs" color="gray.7">
                          {reward.kind === "fixed_amount"
                            ? `$${reward.amount_in_usd} for ${reward.cost_in_coins} coins`
                            : reward.description}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={4} p={0}>
                        <Button
                          size="xs"
                          color="red"
                          onClick={() => {
                            if (reward.kind === "fixed_amount") {
                              handleGetCoupon(user, reward);
                            } else {
                              setLoyaltyReward(reward);
                              setScreen("variable_reward");
                            }
                          }}
                        >
                          Get Coupon
                        </Button>
                      </Grid.Col>
                    </Grid>
                  </Card>
                ))}
                {user.next_rewards.map((reward) => (
                  <Card
                    key={reward.id}
                    shadow="md"
                    w="100%"
                    bg="gray.3"
                    withBorder
                  >
                    <Grid align="center" justify="start">
                      <Grid.Col span={2} p={0}>
                        <ThemeIcon color="gray" variant="light" size="lg">
                          <IconCoin strokeWidth="1px" />
                        </ThemeIcon>
                      </Grid.Col>
                      <Grid.Col span={6} p={0}>
                        <Title order={6} color="gray.8">
                          {reward.name}
                        </Title>
                        <Text size="xs" color="gray.7">
                          ${reward.amount_in_usd} for {reward.cost_in_coins}{" "}
                          coins
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={4} p={0}>
                        <Text
                          color="gray.7"
                          size="xs"
                          italic
                          sx={{ lineHeight: 1 }}
                        >
                          You need{" "}
                          {(reward?.cost_in_coins || 0) - user.usable_coins}{" "}
                          more coins
                        </Text>
                      </Grid.Col>
                    </Grid>
                  </Card>
                ))}
              </Flex>
            )}
          </Text>
        </Tabs.Panel>
        <Tabs.Panel value="my_rewards" py="sm">
          <Text>
            <Flex direction="column" gap="sm">
              {user.coin_spendings.map((coinSpending) => (
                <Card key={coinSpending.id} shadow="md" w="100%" withBorder>
                  <Grid align="center" justify="start">
                    <Grid.Col span={2} p={0}>
                      <ThemeIcon
                        variant="light"
                        size="lg"
                        color={
                          coinSpending.loyalty_reward.kind === "fixed_amount"
                            ? "blue"
                            : "green"
                        }
                      >
                        <IconCoin strokeWidth="1px" />
                      </ThemeIcon>
                    </Grid.Col>
                    <Grid.Col span={6} p={0}>
                      <Title order={6} color="gray.8">
                        ${coinSpending.reward_amount_in_usd} Discount
                      </Title>
                      <Text size="xs" color="gray.7">
                        {coinSpending.loyalty_reward.kind === "fixed_amount"
                          ? `$${coinSpending.loyalty_reward.amount_in_usd} for ${coinSpending.loyalty_reward.cost_in_coins} coins`
                          : coinSpending.loyalty_reward.description}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={4} p={0}>
                      <Button
                        size="xs"
                        color="red"
                        w="100%"
                        onClick={() => {
                          setCoinSpending(coinSpending);
                          setScreen("reward_view");
                        }}
                      >
                        View
                      </Button>
                    </Grid.Col>
                  </Grid>
                </Card>
              ))}
            </Flex>
          </Text>
        </Tabs.Panel>
      </Tabs>
    </Flex>
  );
}
