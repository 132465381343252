import { HttpError, IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/mantine";
import { Box, NumberInput, Select, Textarea, TextInput } from "@mantine/core";
import { ProductSegment, UpdateProductSegment } from "entities/product_segment";
import ImageDropZone from "components/common/ImageDropZone";
import { isNotEmpty } from "@mantine/form";
import { IconCurrencyDollar } from "@tabler/icons";
import { selectBrands } from "entities/product_segment/brand";

export const ProductSegmentCreate: React.FC<IResourceComponentsProps> = () => {
  const { getInputProps, saveButtonProps, setFieldValue, onSubmit } = useForm<
    ProductSegment,
    HttpError,
    UpdateProductSegment
  >({
    initialValues: {
      title: "",
      description: "",
      product_type: "",
      product_label: "",
      thumbnail: "",
      image: "",
      price: 0,
      compare_at_price: 0,
      brand: "TT",
    },
    validateInputOnBlur: true,
    validate: {
      image: isNotEmpty("Must select a valid image"),
      thumbnail: isNotEmpty("Must select a valid thumbnail"),
      title: isNotEmpty(),
      description: isNotEmpty(),
      product_type: isNotEmpty(),
      product_label: isNotEmpty(),
    },
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Box>
        <ImageDropZone
          onFileChosen={(data) => setFieldValue("image", data)}
          label="Main Image"
          title="Drop the main segment's image here"
          description="This will be used as the main image"
          maxSize={5 * 1024 ** 2} // 5mb
          {...getInputProps("image")}
        />
      </Box>
      <Box mt="md">
        <ImageDropZone
          onFileChosen={(data) => setFieldValue("thumbnail", data)}
          label="Thumbnail"
          title="Drop the small thumbnail image here"
          description="This will be used as the box's thumbnail"
          maxSize={5 * 1024 ** 2} // 5mb
          {...getInputProps("thumbnail")}
        />
      </Box>
      <Select
        placeholder="Brand"
        data={selectBrands}
        label="Brand"
        {...getInputProps("brand")}
      />
      <TextInput label="Title" {...getInputProps("title")} />
      <Textarea label="Description" {...getInputProps("description")} />
      <TextInput label="Product Type" {...getInputProps("product_type")} />
      <TextInput label="Product Label" {...getInputProps("product_label")} />
      <NumberInput
        defaultValue={0}
        mt="sm"
        label="Price"
        precision={2}
        step={0.01}
        min={0}
        icon={<IconCurrencyDollar />}
        {...getInputProps("price")}
      />
      <NumberInput
        defaultValue={0}
        mt="sm"
        label="Compare At Price"
        precision={2}
        step={0.01}
        min={0}
        icon={<IconCurrencyDollar />}
        {...getInputProps("compare_at_price")}
      />
    </Create>
  );
};
