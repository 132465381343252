import {
  ActionIcon,
  Avatar,
  Badge,
  Flex,
  LoadingOverlay,
  Modal,
  Pagination,
  ScrollArea,
  Select,
  Table,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import {
  IconBuildingWarehouse,
  IconCheck,
  IconConfetti,
  IconConfettiOff,
  IconHistory,
  IconList,
  IconMoodSad,
  IconMoodSmile,
  IconTruckDelivery,
  IconX,
} from "@tabler/icons";
import { Survey } from "entities/survey";
import { User } from "entities/user";
import { useTable } from "@refinedev/react-table";
import { SurveyResponse } from "entities/survey/response";
import { ColumnDef, Header } from "@tanstack/react-table";
import { DateRangePicker } from "@mantine/dates";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import useManyIndexed from "hooks/useManyIndexed";
import { formatDistanceToNow } from "date-fns";
import { useUpdate } from "@refinedev/core";
import { NullablePartial } from "utils/types";
import { useState } from "react";
import { History } from "components/common/show/History";
import { ResponseDetails } from "./ResponseDetails";
import { UserWins } from "./UserWins";
import { formatToJst } from "utils/dates";

type ShowResponsesProps = {
  surveyId: Survey["id"];
};

export function ShowResponses({ surveyId }: ShowResponsesProps) {
  const [historyResponseId, setHistoryResponseId] = useState<number | null>(
    null
  );
  const [detailsResponse, setDetailsReponse] = useState<SurveyResponse | null>(
    null
  );
  const [wins, setWins] = useState<SurveyResponse["wins"]>([]);
  const { mutate: mutateResponse, isLoading: isUpdatingResponse } =
    useUpdate<SurveyResponse>({
      mutationOptions: {
        meta: {},
      },
    });

  const updateResponse = (
    id: SurveyResponse["id"],
    data: NullablePartial<SurveyResponse>
  ) => {
    mutateResponse({
      resource: `surveys/${surveyId}/responses`,
      values: data,
      id: id,
    });
  };

  const columns: ColumnDef<SurveyResponse>[] = [
    {
      id: "tags",
      cell: ({ row }) => {
        const otherWins = row.original.wins;
        return (
          otherWins.length !== 0 && (
            <Badge
              color="red"
              variant="outline"
              onClick={() => setWins(otherWins)}
              sx={{ cursor: "pointer" }}
            >
              Other Wins
            </Badge>
          )
        );
      },
      meta: {
        isPlaceholder: true,
      },
    },
    {
      id: "id",
      accessorKey: "id",
      header: "Id",
    },
    {
      id: "user",
      accessorKey: "user_id",
      header: "User",
      cell: ({ getValue }) => {
        const userId = getValue<SurveyResponse["user_id"]>();
        const user = users[userId];

        return (
          <Flex direction="column" align="center">
            <Avatar src={user?.image} size="md" />
            <Text color="white">
              {user?.first_name}
              &nbsp;
              {user?.last_name}
            </Text>
            <Text color="orange">{user?.email}</Text>
          </Flex>
        );
      },
      meta: {
        filterOperator: "eq",
      },
    },
    {
      id: "plan_id",
      accessorKey: "plan_id",
      header: "Plan Id",
      cell: ({ getValue }) => {
        const planId = getValue<string>();
        if (!planId) return null;

        return (
          <Badge color="cyan" variant="light" size="sm">
            {planId}
          </Badge>
        );
      },
      meta: {
        filterOperator: "contains",
      },
    },
    {
      id: "submitted_at",
      accessorKey: "submitted_at",
      header: "Submitted At",
      cell: ({ getValue }) => {
        const submittedAt = getValue<SurveyResponse["submitted_at"]>();
        const formatted = formatDistanceToNow(new Date(submittedAt), {
          addSuffix: true,
        });

        return <Text sx={{ fontStyle: "italic" }}>{formatted}</Text>;
      },
      meta: {
        filterElement: ({
          header,
        }: {
          header: Header<SurveyResponse, any>;
        }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={(v) => {
                  const formattedDates = v.map(
                    (date) => date && formatToJst(date)
                  );
                  header.column.setFilterValue(formattedDates);
                }}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "won_at",
      accessorKey: "won_at",
      header: "Winner?",
      cell: ({ getValue }) => {
        const won_at = getValue<SurveyResponse["won_at"]>();

        return won_at ? (
          <ThemeIcon color="green" variant="light">
            <IconMoodSmile />
          </ThemeIcon>
        ) : (
          <ThemeIcon color="red" variant="light">
            <IconMoodSad />
          </ThemeIcon>
        );
      },
      meta: {
        filterElement: ({
          header,
        }: {
          header: Header<SurveyResponse, any>;
        }) => {
          return (
            <div>
              <Select
                placeholder="Select category"
                data={["winners", "non-winners"]}
                onChange={header.column.setFilterValue}
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "reward_delivered_at",
      accessorKey: "reward_delivered_at",
      header: "Reward Delivered?",
      cell: ({ getValue }) => {
        const reward_delivered_at =
          getValue<SurveyResponse["reward_delivered_at"]>();

        return reward_delivered_at ? (
          <ThemeIcon color="green" variant="light">
            <IconCheck />
          </ThemeIcon>
        ) : (
          <ThemeIcon color="red" variant="light">
            <IconX />
          </ThemeIcon>
        );
      },

      meta: {
        filterElement: ({
          header,
        }: {
          header: Header<SurveyResponse, any>;
        }) => {
          return (
            <div>
              <Select
                placeholder="Select category"
                data={["delivered", "not-delivered"]}
                onChange={header.column.setFilterValue}
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "actions",
      cell: function render({ row: { original: surveyResponse } }) {
        const isWinner = !!surveyResponse.won_at;
        const isRewardDelivered = !!surveyResponse.reward_delivered_at;

        return (
          <Flex columnGap={4}>
            <Tooltip label="Response">
              <ActionIcon
                variant="filled"
                onClick={() => setDetailsReponse(surveyResponse)}
              >
                <IconList />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="History">
              <ActionIcon
                variant="filled"
                onClick={() => setHistoryResponseId(surveyResponse.id)}
              >
                <IconHistory />
              </ActionIcon>
            </Tooltip>
            {isWinner ? (
              <Tooltip label="Unmark as a winner">
                <ActionIcon
                  variant="gradient"
                  gradient={{ from: "orange", to: "red" }}
                  onClick={() =>
                    updateResponse(surveyResponse.id, { won_at: null })
                  }
                >
                  <IconConfettiOff />
                </ActionIcon>
              </Tooltip>
            ) : (
              <Tooltip label="Mark as a winner">
                <ActionIcon
                  variant="gradient"
                  gradient={{ from: "indigo", to: "cyan" }}
                  onClick={() =>
                    updateResponse(surveyResponse.id, { won_at: new Date() })
                  }
                >
                  <IconConfetti />
                </ActionIcon>
              </Tooltip>
            )}
            {isWinner && !isRewardDelivered && (
              <Tooltip label="Mark reward as delivered">
                <ActionIcon
                  variant="gradient"
                  gradient={{ from: "indigo", to: "cyan" }}
                  onClick={() =>
                    updateResponse(surveyResponse.id, {
                      reward_delivered_at: new Date(),
                    })
                  }
                >
                  <IconTruckDelivery />
                </ActionIcon>
              </Tooltip>
            )}
            {isRewardDelivered && (
              <Tooltip label="Unmark reward as delivered">
                <ActionIcon
                  variant="gradient"
                  gradient={{ from: "orange", to: "red" }}
                  onClick={() =>
                    updateResponse(surveyResponse.id, {
                      reward_delivered_at: null,
                    })
                  }
                >
                  <IconBuildingWarehouse />
                </ActionIcon>
              </Tooltip>
            )}
          </Flex>
        );
      },
      meta: {
        isPlaceholder: true,
      },
    },
  ];

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable<SurveyResponse>({
    columns,
    refineCoreProps: {
      resource: `surveys/${surveyId}/responses`,
    },
  });

  const userIds = tableData?.data.map((item) => item.user_id) || [];
  const users = useManyIndexed<number, User>({
    resource: "users",
    ids: userIds,
    queryOptions: {
      enabled: userIds.length > 0,
    },
  });

  return (
    <div>
      <Modal
        opened={!!historyResponseId}
        onClose={() => setHistoryResponseId(null)}
        title="History"
        fullScreen
      >
        {historyResponseId && (
          <History<SurveyResponse>
            resources={`surveys/${surveyId}/responses`}
            entityId={historyResponseId}
          />
        )}
      </Modal>
      <Modal
        opened={!!detailsResponse}
        onClose={() => setDetailsReponse(null)}
        size={700}
        title="Details"
      >
        {detailsResponse && (
          <ResponseDetails
            surveyId={surveyId}
            surveyResponseId={detailsResponse.id}
          />
        )}
      </Modal>
      <Modal
        opened={wins.length !== 0}
        onClose={() => setWins([])}
        size={700}
        title="Details"
      >
        <UserWins wins={wins} onClose={() => setWins([])} />
      </Modal>
      <AppliedFilters<SurveyResponse> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<SurveyResponse> headerGroups={getHeaderGroups()} />
          <Rows<SurveyResponse> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </div>
  );
}
