
import {
  IResourceComponentsProps,
  useShow,
} from "@refinedev/core";
import { Show, NumberField, TextField } from "@refinedev/mantine";
import {
  Text,
  Title,
  Badge,
  Grid,
  Flex,
  Anchor,
  Avatar,
} from "@mantine/core";
import { Like } from "entities/like";
import { DetailsCard } from "components/common/show/DetailsPair";
import { formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";
import { colorsByLikableType, labelsByLikableType, urlsByLikableType } from "entities/like/likable_type";

export const LikeShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<Like>();
  const { data, isLoading } = queryResult;

  const like = data?.data;
  if (!like) return <div />;

  return (
    <Show isLoading={isLoading}>
      <Grid>
        <Grid.Col span={8}>
          <Flex gap="sm" direction="column">
            <Text color="gray">History not supported for likes</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={4}>
          <Flex direction="column" gap="sm">
            <DetailsCard title="User">
              <Flex gap="md">
                <Avatar h={48} w={48} src={like.user.image} />
                <Flex direction="column">
                  <Title color="yellow" order={3}>
                    {like.user.last_name}
                    &nbsp;
                    {like.user.first_name}
                  </Title>
                  <Text italic>{like.user.email}</Text>
                </Flex>
              </Flex>
            </DetailsCard>
            <DetailsCard title="Id">
              <NumberField value={like?.id} />
            </DetailsCard>
            <DetailsCard title="Likable Id">
              <Anchor
                component={Link}
                to={`${urlsByLikableType[like.likable_type]}/show/${like.likable_id}`}
              >
                {like.likable_id}
              </Anchor>
            </DetailsCard>
            <DetailsCard title="Likable Type">
              <Badge color={colorsByLikableType[like.likable_type]}>
                {labelsByLikableType[like.likable_type]}
              </Badge>
            </DetailsCard>
            <DetailsCard title="Created At">
              <TextField
                value={formatDistanceToNow(new Date(like.created_at))}
              />
            </DetailsCard>
            <DetailsCard title="Updated At">
              <TextField
                value={formatDistanceToNow(new Date(like.updated_at))}
              />
            </DetailsCard>
          </Flex>
        </Grid.Col>
      </Grid>
    </Show>
  );
};
