import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Show, NumberField, TextField } from "@refinedev/mantine";
import { Title, Badge, Grid, Flex } from "@mantine/core";
import { LoyaltyReward } from "entities/loyalty_reward";
import { DetailsPair } from "components/common/show/DetailsPair";
import { formatDistanceToNow } from "date-fns";
import { History } from "components/common/show/History";
import { Text } from "@mantine/core";
import { colorsByKind } from "entities/loyalty_reward/kind";
import { colorsByDestination } from "entities/loyalty_reward/destination";

export const LoyaltyRewardShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<LoyaltyReward>();
  const { data, isLoading } = queryResult;

  const loyaltyReward = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Grid>
        <Grid.Col span={8}>
          {loyaltyReward && (
            <History<LoyaltyReward>
              resources={"loyalty_rewards"}
              entityId={loyaltyReward.id}
            />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Flex direction="column" gap="sm">
            <DetailsPair>
              <Title my="xs" order={5}>
                Kind
              </Title>
              {loyaltyReward && (
                <Badge color={colorsByKind[loyaltyReward.kind]}>
                  {loyaltyReward.kind.replaceAll("_", " ")}
                </Badge>
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Destination
              </Title>
              {loyaltyReward && (
                <Badge color={colorsByDestination[loyaltyReward.destination]}>
                  {loyaltyReward.destination}
                </Badge>
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Name
              </Title>
              {loyaltyReward && <TextField value={loyaltyReward.name} />}
            </DetailsPair>
            {loyaltyReward?.kind === "fixed_amount" && (
              <DetailsPair>
                <Title my="xs" order={5}>
                  Amount
                </Title>
                <Text color="green" weight="bold">
                  ${loyaltyReward?.amount_in_usd}
                </Text>
              </DetailsPair>
            )}
            <DetailsPair>
              <Title my="xs" order={5}>
                Description
              </Title>
              {loyaltyReward && <TextField value={loyaltyReward.description} />}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Created At
              </Title>
              {loyaltyReward?.created_at && (
                <TextField
                  value={formatDistanceToNow(
                    new Date(loyaltyReward.created_at)
                  )}
                />
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Updated At
              </Title>
              {loyaltyReward?.updated_at && (
                <TextField
                  value={formatDistanceToNow(
                    new Date(loyaltyReward.updated_at)
                  )}
                />
              )}
            </DetailsPair>

            <DetailsPair>
              <Title my="xs" order={5}>
                Id
              </Title>
              {loyaltyReward && <NumberField value={loyaltyReward?.id} />}
            </DetailsPair>
          </Flex>
        </Grid.Col>
      </Grid>
    </Show>
  );
};
