import {
  BaseRecord,
  HttpError,
  IResourceComponentsProps,
} from "@refinedev/core";
import { Create, SaveButton, useForm, useSelect } from "@refinedev/mantine";
import {
  NumberInput,
  Select,
  Textarea,
  Text,
  Image,
  Group,
  Badge,
  Avatar,
  Button,
} from "@mantine/core";
import { CreateGiftCard, GiftCard } from "entities/gift_card";
import { selectChannels } from "entities/gift_card_template/channel";
import { selectConstraints } from "entities/gift_card_template/constraint";
import { DatePicker } from "@mantine/dates";
import { forwardRef } from "react";
import { GiftCardTemplate } from "entities/gift_card_template";
import { User } from "entities/user";
import { formatToJst } from "utils/dates";

export const GiftCardCreate: React.FC<IResourceComponentsProps> = () => {
  const { getInputProps, saveButtonProps, setFieldValue, onSubmit } = useForm<
    BaseRecord,
    HttpError,
    CreateGiftCard
  >({
    initialValues: {
      gift_card_template_id: undefined,
      expire_at: new Date(),
      channel: "all_channels",
      constraint: "no_constraints",
      image: { url: undefined },
      notes: "",
      balance: 0,
      user_id: undefined,
      notification_scheduled_at: undefined,
      is_save_and_send: false,
    },
    transformValues: (values) => {
      return {
        ...values,
        expire_at: formatToJst(values.expire_at),
      };
    },
  });

  const {
    selectProps: giftCardTemplateProps,
    queryResult: giftCardTemplatesQueryResult,
  } = useSelect<GiftCardTemplate>({
    resource: "gift_card_templates",
    optionLabel: "image",
    optionValue: "id",
  });

  const { selectProps: usersProps, queryResult: usersResult } = useSelect<User>(
    {
      resource: "users",
      optionLabel: "email",
      optionValue: "id",
      debounce: 1000,
      pagination: {
        pageSize: 10,
        mode: "server",
        current: 1,
      },
      onSearch: (value) => [
        {
          field: "email",
          operator: "eq",
          value,
        },
      ],
    }
  );

  const giftCardTemplateOptions = giftCardTemplatesQueryResult.data?.data.map(
    (item) => ({
      label: `$${item.initial_value} (${item.notes})`,
      value: item.id.toString(),
      ...item,
    })
  );

  const userOptions = usersResult.data?.data.map((item) => ({
    label: item.email,
    value: item.id.toString(),
    ...item,
  }));

  const imageProps = getInputProps("image");

  const onTemplateSelection = (id: string) => {
    const template = giftCardTemplatesQueryResult.data?.data.find(
      (item) => item.id.toString() === id.toString()
    );

    if (template) {
      setFieldValue("image.url", template.image.url);
      setFieldValue("channel", template.channel);
      setFieldValue("constraint", template.constraint);
      setFieldValue("notes", template.notes);
      setFieldValue("balance", template.initial_value);
      setFieldValue("expire_at", template.expire_at);
    }
  };

  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);
  console.log(getInputProps("notification_scheduled_at").value);
  return (
    <Create
      saveButtonProps={saveButtonProps}
      footerButtons={({ saveButtonProps }) => (
        <>
          {!getInputProps("notification_scheduled_at").value ? (
            <>
              <SaveButton {...saveButtonProps}>Save to Draft</SaveButton>
              <Button
                {...saveButtonProps}
                onClickCapture={(e: React.PointerEvent<HTMLButtonElement>) => {
                  setFieldValue("is_save_and_send", true);
                }}
              >
                Activate and Notify Now
              </Button>
            </>
          ) : (
            <SaveButton {...saveButtonProps}>Save</SaveButton>
          )}
        </>
      )}
    >
      <Select
        label="Template"
        placeholder="Select a template"
        withinPortal
        itemComponent={GiftCardTemplateSelectItem}
        my="xl"
        {...giftCardTemplateProps}
        searchable={false}
        onSearchChange={undefined}
        onChange={onTemplateSelection}
        clearable
        data={giftCardTemplateOptions || []}
      />
      <Preview file={imageProps.value.file} srcUrl={imageProps.value.url} />
      <Select
        placeholder="Channel"
        data={selectChannels}
        label="Channel"
        clearable
        {...getInputProps("channel")}
      />
      <Select
        placeholder="Constraint"
        data={selectConstraints}
        label="Constraint"
        clearable
        {...getInputProps("constraint")}
      />
      <NumberInput
        defaultValue={0}
        mt="sm"
        label="balance"
        {...getInputProps("balance")}
      />
      <DatePicker
        placeholder="Pick dates range"
        maw={400}
        label="Expire at"
        defaultValue={new Date()}
        withinPortal
        {...getInputProps("expire_at")}
      />
      <Textarea label="Notes" {...getInputProps("notes")} />
      <Select
        label="User"
        placeholder="Select a user"
        withinPortal
        itemComponent={UserSelectItem}
        my="xl"
        {...usersProps}
        clearable
        data={userOptions || []}
        onChange={(userId) =>
          userId && setFieldValue("user_id", parseInt(userId))
        }
      />
      <DatePicker
        placeholder="Schedule gift card activation"
        maw={400}
        label="Schedule gift card activation"
        defaultValue={null}
        minDate={tomorrow}
        withinPortal
        {...getInputProps("notification_scheduled_at")}
      />
    </Create>
  );
};

const Preview = ({ file, srcUrl }: { file: File; srcUrl: string }) => {
  if (file) {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        src={imageUrl}
        height={100}
        width="auto"
        my="lg"
        imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
      />
    );
  } else if (srcUrl) {
    return <Image src={srcUrl} height={100} width="auto" my="lg" />;
  } else {
    return <div />;
  }
};

const GiftCardTemplateSelectItem = forwardRef<HTMLDivElement, GiftCardTemplate>(
  (giftCardTemplate: GiftCardTemplate, ref) => (
    <div ref={ref} {...giftCardTemplate}>
      <Group noWrap>
        <Image
          src={giftCardTemplate.image.url}
          height={50}
          width="auto"
          my="md"
        />

        <div>
          <Badge size="sm">{giftCardTemplate.initial_value}</Badge>
          <Text size="xs" opacity={0.65}>
            {giftCardTemplate.notes}
          </Text>
        </div>
      </Group>
    </div>
  )
);

const UserSelectItem = forwardRef<HTMLDivElement, User>((user: User, ref) => (
  <div ref={ref} {...user} id={user.id.toString()}>
    <Group noWrap>
      <Avatar src={user.image} />
      <Text size="xs">{user.email}</Text>
    </Group>
  </div>
));
