import { User } from "entities/user";
import { Source } from "./source";
import { State } from "./state";
import { Lock } from "./lock";
import { Event } from "./event";

export type CoinEarning = {
  id: number;
  source: Source;
  state: State;
  event: Event;
  amount: number;
  notes: string;
  created_at: Date;
  updated_at: Date;
  remote_invoice_id?: string;
  user: User;
  locks: Lock[];
};

export type CoinEarningForm = Omit<
  CoinEarning,
  "id" | "user" | "updated_at"
> & {
  user: User | null;
  user_id?: User["id"];
};

export type UpdateCoinEarning = Omit<
  CoinEarning,
  "id" | "created_at" | "updated_at" | "locks" | "user"
> & {
  user: User | null;
  user_id?: number;
  locks: Record<Lock["lock_type"], Partial<Lock> | null>;
};

export const DEFAULT_LOCKS: Record<Lock["lock_type"], Lock> = {
  shipping_subscription: {
    lock_type: "shipping_subscription",
    released_at: null,
    released_notes: null,
  },
  shipping_shop_products: {
    lock_type: "shipping_shop_products",
    released_at: null,
    released_notes: null,
  },
};

export const JH_TWITTER_PAGE = "https://twitter.com/japanhaul";
export const JH_FACEBOOK_PAGE = "https://facebook.com/japanhaul";
export const JH_INSTAGRAM_PAGE = "https://www.instagram.com/japanhaulofficial";
export const JH_REDDIT_PAGE = "https://www.reddit.com/r/TokyoTreat";
