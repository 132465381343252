import {
  BaseRecord,
  HttpError,
  IResourceComponentsProps,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/mantine";
import { Select, Textarea } from "@mantine/core";
import { CreateLike } from "entities/like";
import UserSelect from "components/users/UserSelect";
import { isNotEmpty } from "@mantine/form"
import { selectLikableTypes } from "entities/like/likable_type";
import ArticleSelect from "components/articles/ArticleSelect";
import CommentSelect from "components/comments/CommentSelect";

export const LikeCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const { getInputProps, saveButtonProps, setFieldValue, values } = useForm<
    BaseRecord,
    HttpError,
    CreateLike
  >({
    initialValues: {
      likable_id: undefined,
      likable_type: "WordpressArticle",
      likable: undefined,
      user: undefined,
    },
    validate: {
      user_id: isNotEmpty(),
      likable_id: isNotEmpty(),
    },
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <UserSelect onChange={(userId) => setFieldValue("user_id", userId)} />
      <Select
        placeholder="Channel"
        data={selectLikableTypes}
        label="Likable Type"
        clearable={false}
        {...getInputProps("likable_type")}
      />
      {values.likable_type === "WordpressArticle" && <ArticleSelect label="Article to like" value={values.likable_id} onChange={articleId => setFieldValue("likable_id", articleId)} />}
      {values.likable_type === "Comment" && <CommentSelect label="Comment to like" onChange={commentId => setFieldValue("likable_id", commentId)} />}
    </Create>
  );
};

