import { getMonth, getYear } from "date-fns";
import _ from "lodash";

export type SurveyPlacement =
  | "home_banner"
  | "package_description_page"
  | "giveaways_page";

export const surveyPlacements: SurveyPlacement[] = [
  "home_banner",
  "package_description_page",
  "giveaways_page",
];

export const selectIcons = ["smile", "gift"].map((icon) => ({
  value: icon,
  label: _.capitalize(icon),
}));

export type HomeBannerConfig = {
  visible: boolean;
  title: string;
  description: string;
  icon: "smile" | "gift";
  background_color: string;
};

export type PackageDescriptionConfig = {
  visible: boolean;
  year: number;
  month: number;
};

export type GiveawaysConfig = {
  visible: boolean;
};

export const DEFAULT_HOME_BANNER_CONFIG: HomeBannerConfig = {
  visible: false,
  title: "",
  icon: "gift",
  description: "",
  background_color: "#E9E3F6",
};

export const DEFAULT_PACKAGE_DESCRIPTION_CONFIG: PackageDescriptionConfig = {
  visible: false,
  year: getYear(new Date()),
  month: getMonth(new Date())
};

export const DEFAULT_GIVEAWAYS_CONFIG: GiveawaysConfig = {
  visible: false,
};
