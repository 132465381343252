import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  Flex,
  LoadingOverlay,
  Anchor,
  Badge,
} from "@mantine/core";
import {
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
} from "@refinedev/mantine";

import type { Header } from "@tanstack/react-table";
import { DateRangePicker } from "@mantine/dates";
import useSaleAnnouncementPermissions from "./useSaleAnnouncementPermissions";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { SaleAnnouncement } from "entities/sale_announcement";

export const SaleAnnouncementList: React.FC<IResourceComponentsProps> = () => {
  const { canCreate, canEdit, canDelete } = useSaleAnnouncementPermissions();

  const columns = React.useMemo<ColumnDef<SaleAnnouncement>[]>(
    () => [
      {
        id: "id",
        accessorKey: "id",
        header: "Id",
      },
      {
        id: "title",
        accessorKey: "title",
        header: "Title",
      },
      {
        id: "url",
        accessorKey: "url",
        header: "URL",
        cell: ({ getValue }) => {
          const url = getValue<SaleAnnouncement["url"]>();
          return <Anchor href={url}>{url}</Anchor>;
        },
      },
      {
        id: "activated_at",
        accessorKey: "activated_at",
        header: "Activated At",
        cell: function render({ getValue }) {
          const activatedAt = getValue();

          if (activatedAt) {
            return (
              <DateField color="green" value={getValue<any>()} weight="bold" />
            );
          }

          return <Badge color="gray">Not Active</Badge>;
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<SaleAnnouncement, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "Created At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<SaleAnnouncement, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "updated_at",
        accessorKey: "updated_at",
        header: "Updated At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<SaleAnnouncement, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "actions",
        cell: function render({ row: { original: SaleAnnouncement } }) {
          return (
            <Flex columnGap={4}>
              <ShowButton hideText recordItemId={SaleAnnouncement.id} />
              {canEdit && (
                <EditButton hideText recordItemId={SaleAnnouncement.id} />
              )}
              {canDelete && (
                <DeleteButton hideText recordItemId={SaleAnnouncement.id} />
              )}
            </Flex>
          );
        },
        meta: {
          isPlaceholder: true,
        },
      },
    ],
    [canCreate, canEdit, canDelete]
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  setOptions((prev) => ({
    ...prev,
    meta: {
      ...prev.meta,
    },
  }));

  return (
    <List canCreate={canCreate}>
      <AppliedFilters<SaleAnnouncement> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<SaleAnnouncement> headerGroups={getHeaderGroups()} />
          <Rows<SaleAnnouncement> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
