import {
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Loader,
  LoadingOverlay,
  Progress,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useApiUrl, useCustomMutation } from "@refinedev/core";
import { IconCoin, IconCrown, IconInfinity } from "@tabler/icons";
import { colorsByTier } from "entities/tier";
import { User } from "entities/user";
import { Dispatch, SetStateAction, useContext } from "react";
import { Tab } from "./Widget";
import { LoyaltyReward } from "entities/loyalty_reward";
import { CoinSpending } from "entities/coin_spending";
import { Screen, UserContext } from "./Layout";

type MainProps = {
  setScreen: Dispatch<SetStateAction<Screen>>;
  setTab: Dispatch<SetStateAction<Tab>>;
  setCoinSpending: Dispatch<SetStateAction<CoinSpending | null>>;
  setLoyaltyReward: Dispatch<SetStateAction<LoyaltyReward | null>>;
};

export default function Main({
  setScreen,
  setTab,
  setCoinSpending,
  setLoyaltyReward,
}: MainProps) {
  const apiUrl = useApiUrl();
  const {
    mutate: redeemReward,
    isLoading: isRedeemingReward,
    data: redeemedRewardData,
  } = useCustomMutation<CoinSpending>();

  const { user, isUserLoading, refetchUser } = useContext(UserContext);

  if (isUserLoading || !user)
    return (
      <Flex h="100%" w="100%" justify="center" align="center">
        <Loader />
      </Flex>
    );

  const next_reward = user.next_rewards ? user.next_rewards[0] : null;

  const handleGetCoupon = (user: User, reward: LoyaltyReward) => {
    redeemReward(
      {
        url: `${apiUrl}/coin_spendings/redeem_reward`,
        method: "post",
        values: {
          user_id: user.id,
          loyalty_reward_id: reward.id,
        },
      },
      {
        onSuccess: (data, variables, context) => {
          setCoinSpending(data.data);
          setScreen("reward_view");
          refetchUser();
        },
      }
    );
  };

  return (
    <Flex direction="column" align="stretch">
      <Title order={4} weight="normal" color="gray.8">
        Welcome back {user.first_name} {user.last_name}!
      </Title>
      {isRedeemingReward ? (
        <LoadingOverlay visible />
      ) : (
        <>
          {user.available_rewards.length > 0 && (
            <>
              <Title
                color="gray.8"
                order={6}
                mt="md"
                sx={{ alignSelf: "start" }}
              >
                Available Rewards
              </Title>
              <Divider w="100%" mb="md" color="gray.2" />
              <Flex direction="column" gap="sm">
                {user.available_rewards.map((reward) => {
                  if (reward.kind === "fixed_amount") {
                    return (
                      <Card key={reward.id} shadow="md" w="100%" withBorder>
                        <Grid align="center" justify="start">
                          <Grid.Col span={2} p={0}>
                            <ThemeIcon variant="light" size="lg">
                              <IconCoin strokeWidth="1px" />
                            </ThemeIcon>
                          </Grid.Col>
                          <Grid.Col span={6} p={0}>
                            <Title order={6} color="gray.8">
                              {reward.name}
                            </Title>
                            <Text size="xs" color="gray.7">
                              ${reward.amount_in_usd} for {reward.cost_in_coins}{" "}
                              coins
                            </Text>
                          </Grid.Col>
                          <Grid.Col span={4} p={0}>
                            <Button
                              size="xs"
                              color="red"
                              onClick={() => handleGetCoupon(user, reward)}
                            >
                              Get Coupon
                            </Button>
                          </Grid.Col>
                        </Grid>
                      </Card>
                    );
                  } else {
                    return (
                      <Card key={reward?.id} shadow="md" w="100%" withBorder>
                        <Grid align="center" justify="start">
                          <Grid.Col span={2} p={0}>
                            <ThemeIcon variant="light" size="lg" color="green">
                              <IconInfinity strokeWidth="1px" />
                            </ThemeIcon>
                          </Grid.Col>
                          <Grid.Col span={6} p={0}>
                            <Title order={6} color="gray.8">
                              {reward?.name}
                            </Title>
                            <Text size="xs" color="gray.7">
                              ${reward?.description}
                            </Text>
                          </Grid.Col>
                          <Grid.Col span={4} p={0}>
                            <Button
                              size="xs"
                              color="red"
                              onClick={() => {
                                setLoyaltyReward(reward);
                                setScreen("variable_reward");
                              }}
                            >
                              Get Coupon
                            </Button>
                          </Grid.Col>
                        </Grid>
                      </Card>
                    );
                  }
                })}
              </Flex>
            </>
          )}
        </>
      )}
      <Divider w="100%" my="md" color="gray.2" />
      <Flex direction="column" align="center" my="xl">
        <Text color="gray.8">Your Coins balance</Text>
        <Title order={2} color="red">
          {user.usable_coins} Coins
        </Title>
        {user.pending_coins !== 0 && (
          <Text size="xs" color="gray.6" italic>
            ({user.pending_coins} coins still pending)
          </Text>
        )}
      </Flex>
      {next_reward && next_reward.cost_in_coins && (
        <Flex direction="column" align="center" my="xl">
          <Progress
            value={(user.usable_coins / next_reward.cost_in_coins) * 100}
            color="red"
            size="xl"
            sx={{ width: 300 }}
          />
          <Text mt="sm" color="gray.8" weight="bold">
            {user.usable_coins} / {next_reward.cost_in_coins}
          </Text>
          <Text mt="md" color="gray.8" italic>
            Available at {next_reward.cost_in_coins} Coins
          </Text>
          <Title order={2} color="red">
            {next_reward.name}
          </Title>
        </Flex>
      )}
      <Button
        color="red"
        mb="md"
        w="100%"
        onClick={() => {
          setScreen("actions_screen");
          setTab("earn_coins");
        }}
      >
        Earn more coins
      </Button>
      <Flex gap="md" w="100%" justify="space-between">
        <Button
          color="red"
          w="100%"
          onClick={() => {
            setScreen("actions_screen");
            setTab("rewards");
          }}
        >
          Spend coins
        </Button>
        <Button
          color="gray"
          variant="outline"
          w="100%"
          onClick={() => {
            setScreen("actions_screen");
            setTab("my_rewards");
          }}
        >
          My Rewards
        </Button>
      </Flex>
      <Flex direction="column" w="100%" mt="md">
        <Title color="gray.8" order={6} mt="md" sx={{ alignSelf: "start" }}>
          VIP Tiers
        </Title>
        <Divider w="100%" mb="md" color="gray.2" />
        <Flex mb="sm" justify="start" align="center" direction="column">
          <ThemeIcon size="lg" color={colorsByTier[user.tier?.name]}>
            <IconCrown strokeWidth={1} />
          </ThemeIcon>
          <Text size="lg" color="gray.7">
            {user.tier?.name}
          </Text>
        </Flex>
        {user.next_tier && (
          <Progress
            value={(user.current_xp / user.next_tier.xp_required) * 100}
            size="xl"
            color={colorsByTier[user.tier.name]}
            label={`$${user.next_tier.xp_required - user.current_xp} To ${
              user.next_tier.name
            } Tier`}
            sx={{ width: 300 }}
            mb="sm"
          />
        )}
        {user.next_tier && (
          <Text sx={{ alignSelf: "center" }} color="gray.7" weight="bold">
            ${user.current_xp} / ${user.next_tier.xp_required}
          </Text>
        )}
        {user.pending_xp !== 0 && (
          <Text size="xs" color="gray" sx={{ alignSelf: "center" }} italic>
            (${user.pending_xp} still pending)
          </Text>
        )}
        <Button mt="md" color="red" onClick={() => setScreen("benefits")}>
          See Benefits
        </Button>
      </Flex>
    </Flex>
  );
}
