import {
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useShow,
} from "@refinedev/core";
import { Show, NumberField, TextField } from "@refinedev/mantine";
import {
  Title,
  Badge,
  Grid,
  Flex,
  Anchor,
  ThemeIcon,
  Card,
  Button,
} from "@mantine/core";
import { CoinSpending } from "entities/coin_spending";
import { DetailsPair } from "components/common/show/DetailsPair";
import { formatDistanceToNow } from "date-fns";
import { History } from "components/common/show/History";
import { DetailsUser } from "components/common/show/DetailsUser";
import { Text } from "@mantine/core";
import { colorsBySource } from "entities/coin_spending/source";
import { IconTrophy } from "@tabler/icons";
import { Link, useParams } from "react-router-dom";
import { colorsByKind } from "entities/loyalty_reward/kind";
import { colorsByDestination } from "entities/loyalty_reward/destination";
import { getCouponUrl, getInvoiceUrl } from "utils/chargebee";
import { getDiscountCodeUrl } from "utils/shopify";

export const CoinSpendingShow: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const { queryResult } = useShow<CoinSpending>();
  const { data, isLoading, refetch } = queryResult;

  const coinSpending = data?.data;

  const apiUrl = useApiUrl();
  const {
    mutate: generateReward,
    isLoading: isGeneratingReward,
    data: generatedRewardData,
  } = useCustomMutation<CoinSpending>();

  if (!coinSpending) return <div />;

  return (
    <Show isLoading={isLoading}>
      <Grid>
        <Grid.Col span={8}>
          {coinSpending.loyalty_reward && (
            <Card shadow="lg" withBorder>
              <Card.Section p="md" withBorder>
                <Flex justify="space-between">
                  <Anchor
                    component={Link}
                    to={`/loyalty_rewards/show/${coinSpending.loyalty_reward.id}`}
                  >
                    <Text weight="bold">
                      {coinSpending?.loyalty_reward.name}
                    </Text>
                  </Anchor>
                  <Flex>
                    <Badge
                      color={colorsByKind[coinSpending.loyalty_reward.kind]}
                      mr="sm"
                    >
                      {coinSpending?.loyalty_reward.kind}
                    </Badge>
                    <Badge
                      color={
                        colorsByDestination[
                          coinSpending.loyalty_reward.destination
                        ]
                      }
                    >
                      {coinSpending?.loyalty_reward.destination}
                    </Badge>
                  </Flex>
                </Flex>
              </Card.Section>
              {coinSpending?.remote_reward_id ? (
                <Card.Section p="md" withBorder>
                  <Flex justify="space-around">
                    <Flex direction="column" align="center">
                      <Flex align="center" mb="sm">
                        <ThemeIcon size="lg" color="yellow">
                          <IconTrophy />
                        </ThemeIcon>
                        <Text
                          size="xl"
                          ml="sm"
                          color="yellow"
                          weight="bold"
                          italic
                        >
                          {coinSpending?.coins_amount}
                        </Text>
                        &nbsp;
                        <Text size="xl" color="yellow" italic>
                          Coins
                        </Text>
                      </Flex>
                      <Text size="xl" color="green" mb="sm">
                        ${coinSpending?.reward_amount_in_usd}
                      </Text>
                      {coinSpending.loyalty_reward.destination ===
                      "chargebee" ? (
                        <Anchor
                          href={getCouponUrl(coinSpending.remote_reward_id)}
                          size="lg"
                          target="_blank"
                        >
                          {coinSpending?.reward_code}
                        </Anchor>
                      ) : (
                        <Anchor
                          href={getDiscountCodeUrl(
                            coinSpending.remote_reward_id,
                          )}
                          size="lg"
                          target="_blank"
                        >
                          {coinSpending?.reward_code}
                        </Anchor>
                      )}
                    </Flex>
                    <Flex direction="column" justify="center" align="center">
                      {coinSpending.reward_used_at ? (
                        <>
                          <Badge color="gray" size="lg" mt="xs">
                            Used At: {coinSpending.reward_used_at.toString()}
                          </Badge>
                          <Text color="gray" mt="xs" italic>
                            about{" "}
                            {formatDistanceToNow(
                              new Date(coinSpending.reward_used_at),
                            )}{" "}
                            ago
                          </Text>
                        </>
                      ) : (
                        <>
                          <Text color="green">Reward Is Not Used Yet</Text>
                        </>
                      )}
                    </Flex>
                  </Flex>
                </Card.Section>
              ) : (
                <Card.Section p="md" withBorder>
                  <Flex justify="center" my="md">
                    <Button
                      variant="light"
                      color="orange"
                      loading={isGeneratingReward}
                      onClick={() =>
                        generateReward(
                          {
                            url: `${apiUrl}/coin_spendings/${id}/generate_reward`,
                            method: "post",
                            values: {},
                          },
                          {
                            onSuccess: (data, variables, context) => {
                              refetch();
                            },
                          },
                        )
                      }
                    >
                      Generate Reward
                    </Button>
                  </Flex>
                </Card.Section>
              )}
            </Card>
          )}
          {coinSpending && (
            <History<CoinSpending>
              resources={"coin_spendings"}
              entityId={coinSpending.id}
            />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Flex direction="column" gap="sm">
            <DetailsPair>
              <Title order={5}>User</Title>
              {coinSpending?.user && <DetailsUser user={coinSpending.user} />}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Source
              </Title>
              {coinSpending && (
                <Badge color={colorsBySource[coinSpending.source]}>
                  {coinSpending.source.replaceAll("_", " ")}
                </Badge>
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Coin Amount
              </Title>
              <Text color="green" weight="bold">
                {coinSpending?.coins_amount}
              </Text>
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Reward Used At
              </Title>
              {coinSpending?.reward_used_at && (
                <TextField
                  value={formatDistanceToNow(
                    new Date(coinSpending.reward_used_at),
                  )}
                />
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Created At
              </Title>
              {coinSpending?.created_at && (
                <TextField
                  value={formatDistanceToNow(new Date(coinSpending.created_at))}
                />
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Updated At
              </Title>
              {coinSpending?.updated_at && (
                <TextField
                  value={formatDistanceToNow(new Date(coinSpending.updated_at))}
                />
              )}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Id
              </Title>
              {coinSpending && <NumberField value={coinSpending?.id} />}
            </DetailsPair>
            <DetailsPair>
              <Title my="xs" order={5}>
                Notes
              </Title>
              {coinSpending && <TextField value={coinSpending.notes} />}
            </DetailsPair>
            {coinSpending.remote_invoice_id && (
              <DetailsPair>
                <Title my="xs" order={5}>
                  Remote Invoice Id
                </Title>
                <Anchor
                  href={getInvoiceUrl(coinSpending.remote_invoice_id)}
                  target="_blank"
                >
                  {coinSpending.remote_invoice_id}
                </Anchor>
              </DetailsPair>
            )}
          </Flex>
        </Grid.Col>
      </Grid>
    </Show>
  );
};
