import { ActionIcon, Button, Flex, Popover, ScrollArea } from "@mantine/core";
import { useApiUrl, useGetIdentity } from "@refinedev/core";
import { IconArrowLeft, IconGift, IconX } from "@tabler/icons";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from "react";
import { HttpError, useOne } from "@refinedev/core";
import { User } from "entities/user";
import Authenticate from "./Authenticate";

export type Screen =
  | "main"
  | "actions_screen"
  | "reward_view"
  | "variable_reward"
  | "set_birthday"
  | "benefits";

type LayoutProps = {
  screen: Screen;
  setScreen: Dispatch<SetStateAction<Screen>>;
  children: ReactNode;
};

export const UserContext = createContext<{
  user: User | null;
  isUserLoading: Boolean;
  refetchUser: any;
}>({ user: null, isUserLoading: false, refetchUser: () => null });

export default function Layout({ screen, setScreen, children }: LayoutProps) {
  const [open, setOpen] = useState(false);
  const identity = useGetIdentity();
  const serializedUser = identity.data as User | null;

  const {
    data: userData,
    isLoading: isUserLoading,
    isError,
    refetch,
  } = useOne<User, HttpError>({
    resource: "users",
    id: serializedUser?.id,
    queryOptions: {
      enabled: !!serializedUser?.id,
    },
  });

  const user = userData?.data;

  return (
    <div>
      <Popover
        width={350}
        position="top-end"
        shadow="md"
        closeOnClickOutside={false}
        opened={open}
        withinPortal
      >
        <Popover.Target>
          <Button
            leftIcon={<IconGift />}
            color="red"
            sx={{ position: "fixed", right: 30, bottom: 30 }}
            onClick={() => setOpen(!open)}
            loading={!!serializedUser && isUserLoading}
          >
            Rewards
          </Button>
        </Popover.Target>
        <Popover.Dropdown sx={{ background: "#fff", color: "#000" }}>
          <Flex direction="column">
            <Flex justify="space-between" direction="row-reverse">
              <ActionIcon onClick={() => setOpen(false)}>
                <IconX />
              </ActionIcon>
              {screen !== "main" && (
                <ActionIcon color="red" onClick={() => setScreen("main")}>
                  <IconArrowLeft />
                </ActionIcon>
              )}
            </Flex>
            <ScrollArea type="never" style={{ height: 500 }} scrollbarSize={14}>
              {isError || !serializedUser ? (
                <Authenticate />
              ) : (
                <>
                  <UserContext.Provider
                    value={{
                      user: user || null,
                      isUserLoading: !!serializedUser && isUserLoading,
                      refetchUser: refetch,
                    }}
                  >
                    {children}
                  </UserContext.Provider>
                </>
              )}
            </ScrollArea>
          </Flex>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
}
