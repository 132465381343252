import {
  Badge,
  Flex,
  Image,
  LoadingOverlay,
  Pagination,
  ScrollArea,
  Select,
  Table,
  Text,
} from "@mantine/core";
import { IResourceComponentsProps } from "@refinedev/core";
import {
  DateField,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
} from "@refinedev/mantine";
import { useTable } from "@refinedev/react-table";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

import { DateRangePicker } from "@mantine/dates";
import type { Header } from "@tanstack/react-table";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { Poll } from "entities/poll";
import { colorsByStatus, selectStatuses } from "entities/poll/status";
import usePollPermissions from "./usePollsPermissions";

export const PollList: React.FC<IResourceComponentsProps> = () => {
  const { canCreate, canEdit, canDelete } = usePollPermissions();

  const columns = React.useMemo<ColumnDef<Poll>[]>(
    () => [
      {
        id: "id",
        accessorKey: "id",
        header: "Id",
      },
      {
        id: "image",
        accessorKey: "image",
        header: "Image",
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          const imageUrl = getValue<Poll["image"]>();

          return (
            <Image
              src={imageUrl}
              radius="md"
              alt="Gift Card Background Image"
              height={140}
              width={200}
              withPlaceholder
            />
          );
        },
      },
      {
        id: "status",
        accessorKey: "status",
        header: "Status",
        cell: ({ getValue }) => {
          const status = getValue<Poll["status"]>();

          return (
            <Badge variant="light" color={colorsByStatus[status]}>
              {status}
            </Badge>
          );
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<Poll["status"], any>;
          }) => {
            return (
              <div>
                <Select
                  placeholder="status"
                  data={selectStatuses}
                  onChange={header.column.setFilterValue}
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "title",
        accessorKey: "title",
        header: "Title",
      },
      {
        id: "description",
        accessorKey: "description",
        header: "Description",
        cell: function render({ getValue }) {
          const description = getValue<Poll["description"]>();

          return <Text>{description.substring(0, 50)}...</Text>;
        },
      },
      {
        id: "active_from",
        accessorKey: "active_from",
        header: "Active From",
        cell: function render({ getValue }) {
          const activeFrom = getValue<Date>();

          return <DateField color="green" value={activeFrom} weight="bold" />;
        },
        meta: {
          filterElement: ({ header }: { header: Header<Poll, any> }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "active_to",
        accessorKey: "active_to",
        header: "Active To",
        cell: function render({ getValue }) {
          const activeTo = getValue<Date>();

          return <DateField color="green" value={activeTo} weight="bold" />;
        },
        meta: {
          filterElement: ({ header }: { header: Header<Poll, any> }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "Created At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({ header }: { header: Header<Poll, any> }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "updated_at",
        accessorKey: "updated_at",
        header: "Updated At",
        cell: function render({ getValue }) {
          return <DateField value={getValue<any>()} />;
        },
        meta: {
          filterElement: ({ header }: { header: Header<Poll, any> }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "actions",
        cell: function render({ row: { original: Poll } }) {
          return (
            <Flex columnGap={4}>
              <ShowButton hideText recordItemId={Poll.id} />
              {canEdit && <EditButton hideText recordItemId={Poll.id} />}
              {canDelete && <DeleteButton hideText recordItemId={Poll.id} />}
            </Flex>
          );
        },
        meta: {
          isPlaceholder: true,
        },
      },
    ],
    [canCreate, canEdit, canDelete],
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  setOptions((prev) => ({
    ...prev,
    meta: {
      ...prev.meta,
    },
  }));

  return (
    <List canCreate={canCreate}>
      <AppliedFilters<Poll> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<Poll> headerGroups={getHeaderGroups()} />
          <Rows<Poll> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
