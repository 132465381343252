import _ from "lodash";

export type Status = "active" | "expired" | "scheduled" | "draft";

export const statuses: Status[] = ["active", "expired", "scheduled", "draft"];

export const colorsByStatus: Record<Status, string> = {
  active: "green",
  expired: "red",
  scheduled: "cyan",
  draft: "gray",
};

export const selectStatus = statuses.map((status) => {
  return { value: status, label: _.capitalize(status) };
});
