import { HttpError, IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/mantine";
import { NumberInput, TextInput, Select, Textarea } from "@mantine/core";
import { isNotEmpty } from "@mantine/form";
import { UpdateLoyaltyReward } from "entities/loyalty_reward";
import { selectKinds } from "entities/loyalty_reward/kind";
import { selectDestinations } from "entities/loyalty_reward/destination";

export const LoyaltyRewardEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    getInputProps,
    saveButtonProps,
    setFieldValue,
    values,
    refineCore: { queryResult },
  } = useForm<UpdateLoyaltyReward, HttpError, UpdateLoyaltyReward>({
    initialValues: {
      kind: "fixed_amount",
      destination: "chargebee",
      name: "",
      description: "",
      amount_in_usd: undefined,
      cost_in_coins: undefined
    },
    validateInputOnBlur: true,
    validate: {
      name: isNotEmpty(),
      description: isNotEmpty(),
      kind: isNotEmpty(),
      destination: isNotEmpty(),
      cost_in_coins: (value, values) => {
        if (values.kind === "fixed_amount" && !value)
          return "Amount should not be empty for fixed amount rewards";
      },
      amount_in_usd: (value, values) => {
        if (values.kind === "fixed_amount" && !value)
          return "Amount should not be empty for fixed amount rewards";
      },
    },
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Select
        placeholder="Kind"
        data={selectKinds}
        label="Kind"
        {...getInputProps("kind")}
      />
      <Select
        placeholder="Destination"
        data={selectDestinations}
        label="Destination"
        {...getInputProps("destination")}
      />
      <TextInput
        defaultValue=""
        mt="sm"
        label="Name"
        {...getInputProps("name")}
      />
      <Textarea
        defaultValue=""
        mt="sm"
        label="Description"
        {...getInputProps("description")}
      />
      {values.kind === "fixed_amount" && (
        <NumberInput
          defaultValue={0}
          mt="sm"
          label="Cost In Coins"
          min={1}
          {...getInputProps("cost_in_coins")}
        />
      )}
      {values.kind === "fixed_amount" && (
        <NumberInput
          defaultValue={0}
          mt="sm"
          label="Amount In Usd"
          min={1}
          {...getInputProps("amount_in_usd")}
        />
      )}
    </Edit>
  );
};
