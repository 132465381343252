import { Row, flexRender } from "@tanstack/react-table";

type RowsProps<T> = {
  rows: Row<T>[];
};

export function Rows<T>({ rows }: RowsProps<T>) {
  return (
    <tbody>
      {rows.map((row) => {
        return (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => {
              return (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}
