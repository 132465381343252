import { Header } from '@tanstack/react-table'
import React from 'react'
import { Input } from '@mantine/core'
import debounce from "debounce";

type DefaultFilterProps<T> = {
    header: Header<T, any>
}

type MetaType = {
  filterElement: React.FC;
  isPlaceholder: boolean;
};

export default function DefaultFilter<T>({ header } :  DefaultFilterProps<T>) {
    if ((header.column.columnDef.meta as MetaType)?.isPlaceholder) {
      return null;
    } else if (!header.column.getCanFilter()) {
      return (
        <div>
          <Input disabled />
        </div>
      );
    } else {
      return (
        <div>
          <Input
            // value={(header.column.getFilterValue() as string) ?? ""}
            // This causes a UI lag, we need a way to debounce the network requests only
            onChange={debounce(
              (e: React.ChangeEvent<HTMLInputElement>) =>
                header.column.setFilterValue(e.target.value),
              500
            )}
          />
        </div>
      );
    }
}
