import { ActionIcon, Card, Grid, Image, Text } from "@mantine/core";
import { useNavigation } from "@refinedev/core";
import { IconTrash } from "@tabler/icons";
import { PollOption } from "entities/poll_option";

type PollOptionCardProps = {
  option: PollOption;
  onDelete?: (option: PollOption) => any;
  size?: "sm" | "md";
};

export function PollOptionCard({
  option,
  onDelete,
  size = "md",
}: PollOptionCardProps) {
  const { show } = useNavigation();
  return (
    <Card
      key={option.id}
      m="xs"
      p={size === "md" ? 10 : 2}
      h={size === "md" ? 150 : 75}
      sx={{ cursor: "pointer" }}
      onClick={() => show("poll_options", option.id)}
      withBorder
    >
      <Grid h="100%" w="100%" align="center" p="xs">
        {option.image && (
          <Grid.Col span={4}>
            {
              <Image
                sx={{
                  maxHeight: size === "md" ? 80 : 50,
                  maxWidth: size === "md" ? 80 : 50,
                }}
                src={option.image}
              />
            }
          </Grid.Col>
        )}
        <Grid.Col span={6}>
          <Text>{option.label}</Text>
        </Grid.Col>
        <Grid.Col span={2}>
          {onDelete && (
            <ActionIcon
              color="red"
              variant="outline"
              ml="sm"
              size="sm"
              onClick={() => onDelete(option)}
            >
              <IconTrash />
            </ActionIcon>
          )}
        </Grid.Col>
      </Grid>
    </Card>
  );
}
