import { TextInput } from "@mantine/core";
import { isNotEmpty } from "@mantine/form";
import {
  BaseRecord,
  HttpError,
  IResourceComponentsProps,
  ParsedParams,
  useParsed,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/mantine";
import ImageDropZone from "components/common/ImageDropZone";
import { CreatePollOption } from "entities/poll_option";

export const PollOptionEdit: React.FC<IResourceComponentsProps> = () => {
  const urlData = useParsed();
  const { poll_id } = urlData.params as ParsedParams<{ poll_id: string }>;

  const { getInputProps, saveButtonProps, setFieldValue, values } = useForm<
    BaseRecord,
    HttpError,
    CreatePollOption
  >({
    initialValues: {
      label: "",
      image: undefined,
      poll_id: poll_id,
    },
    validate: {
      label: isNotEmpty(),
    },
    refineCoreProps: {
      redirect: "show",
    },
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <ImageDropZone
        onFileChosen={(image) => setFieldValue("image", image)}
        value={values.image}
      />
      <TextInput label="Label" {...getInputProps("label")} />
    </Edit>
  );
};
