
import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  Flex,
  LoadingOverlay,
  Text,
  Avatar,
  Badge,
  Select,
  Anchor,
  Image
} from "@mantine/core";
import {
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
} from "@refinedev/mantine";

import type { Header } from "@tanstack/react-table";
import { DateRangePicker } from "@mantine/dates";
import useCommentPermissions from "./useCommentPermissions";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { Comment } from "entities/comment";
import { colorsByCommentableType, selectCommentableTypes } from "entities/comment/commentable_type";
import { labelsByCommentableType } from "entities/comment/commentable_type";
import { Link } from "react-router-dom";
import { urlsByCommentableType } from "entities/comment/commentable_type";
import { formatDistanceToNow } from "date-fns";

export const CommentList: React.FC<IResourceComponentsProps> = () => {
  const { canCreate, canEdit, canDelete } = useCommentPermissions();

  const columns = React.useMemo<ColumnDef<Comment>[]>(
    () => [
      {
        id: "id",
        accessorKey: "id",
        header: "Id",
      },
      {
        id: "user",
        accessorKey: "user",
        header: "User",
        cell({ getValue }) {
          const user = getValue<Comment["user"]>();

          return (
            <Text size="md" weight="bold">
              <Flex direction="column" align="center">
                <Avatar src={user?.image} size="md" />
                <Text color="white">
                  {user?.first_name}
                  &nbsp;
                  {user?.last_name}
                </Text>
                <Text color="orange">{user?.email}</Text>
              </Flex>
            </Text>
          );
        },
        meta: {
          filterOperator: "eq",
          disableSort: true,
        },
      },
      {
        id: "commentable_id",
        accessorKey: "commentable_id",
        header: "Commentable Id",
        cell: ({ getValue, row }) => {
          const id = getValue<Comment["id"]>()
          const commentableType = row.original.commentable_type
          const url = urlsByCommentableType[commentableType]

          return (
            <Anchor
              component={Link}
              to={`${url}/show/${id}`}
            >
              {id}
            </Anchor>
          )
        }
      },
      {
        id: "commentable_type",
        accessorKey: "commentable_type",
        header: "Commentable Type",
        cell: ({ getValue }) => {
          const commentableType = getValue<Comment["commentable_type"]>();

          return (
            <Badge variant="light" color={colorsByCommentableType[commentableType]}>
              {labelsByCommentableType[commentableType]}
            </Badge>
          );
        },
        meta: {
          filterElement: ({ header }: { header: Header<Comment, any> }) => {
            return (
              <div>
                <Select
                  placeholder="Comment type"
                  data={selectCommentableTypes}
                  onChange={header.column.setFilterValue}
                  clearable
                />
              </div>
            );
          },
        },
      },
      {
        id: "parent_comment_id",
        accessorKey: "parent_comment_id",
        header: "Parent",
        cell({ getValue }) {
          const parentCommentId = getValue<Comment["parent_comment_id"]>();
          if (!parentCommentId) return <Text>--</Text>

          return (
            <Anchor size="md" component={Link} to={`/comments/show/${parentCommentId}`}>
              {parentCommentId}
            </Anchor>
          );
        },
        meta: {
          filterOperator: "eq",
        },
      },
      {
        id: "likes_count",
        accessorKey: "likes_count",
        header: "Likes",
      },
      {
        id: "image",
        accessorKey: "image",
        header: "Image",
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          const imageUrl = getValue<Comment["image"]>();
          if (!imageUrl)
            return <Text color="gray">No Image</Text>

          return (
            <Image
              src={imageUrl}
              radius="md"
              alt="Gift Card Background Image"
              height={100}
              width={100}
              withPlaceholder
            />
          );
        },
      },
      {
        id: "body",
        accessorKey: "body",
        header: "body",
        cell({ getValue }) {
          const body = getValue<Comment["body"]>();

          return (
            <Text size="md">{body}</Text>
          );
        },
        meta: {
          filterOperator: "contains",
        },
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: "Created At",
        cell: function render({ getValue }) {
          return <Text>{formatDistanceToNow(new Date(getValue<any>()))}</Text>
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<Comment, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "updated_at",
        accessorKey: "updated_at",
        header: "Updated At",
        cell: function render({ getValue }) {
          return <Text>{formatDistanceToNow(new Date(getValue<any>()))}</Text>
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<Comment, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "deleted_at",
        accessorKey: "deleted_at",
        header: "Deleted At",
        cell: function render({ getValue }) {
          const deletedAt = getValue<Comment["deleted_at"]>()
          if (!deletedAt) return <Text color="gray">--</Text>

          return <Text>{formatDistanceToNow(new Date(deletedAt))}</Text>
        },
        meta: {
          filterElement: ({
            header,
          }: {
            header: Header<Comment, any>;
          }) => {
            return (
              <div>
                <DateRangePicker
                  type="range"
                  placeholder="Pick dates range"
                  onChange={header.column.setFilterValue}
                  mx="auto"
                  maw={400}
                  withinPortal
                />
              </div>
            );
          },
        },
      },
      {
        id: "actions",
        cell: function render({ row: { original: Comment } }) {
          return (
            <Flex columnGap={4}>
              <ShowButton hideText recordItemId={Comment.id} />
              {canEdit && (
                <EditButton hideText recordItemId={Comment.id} />
              )}
              {canDelete && (
                <DeleteButton hideText recordItemId={Comment.id} />
              )}
            </Flex>
          );
        },
        meta: {
          isPlaceholder: true,
        },
      },
    ],
    [canCreate, canEdit, canDelete]
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  setOptions((prev) => ({
    ...prev,
    meta: {
      ...prev.meta,
    },
  }));

  return (
    <List canCreate={canCreate}>
      <AppliedFilters<Comment> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<Comment> headerGroups={getHeaderGroups()} />
          <Rows<Comment> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
