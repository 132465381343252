import {
  HttpError,
  IResourceComponentsProps,
  useSelect,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/mantine";
import {
  NumberInput,
  TextInput,
  Select,
  Textarea,
  Flex,
  Card,
  Badge,
  ActionIcon,
} from "@mantine/core";
import { User } from "entities/user";
import { DEFAULT_LOCKS, UpdateXpEarning } from "entities/xp_earning";
import { selectStates } from "entities/xp_earning/state";
import { selectSources } from "entities/xp_earning/source";
import { isInRange, isNotEmpty } from "@mantine/form";
import { IconTrash } from "@tabler/icons";
import { DatePicker } from "@mantine/dates";
import _ from "lodash";
import { Lock, lockTypes } from "entities/xp_earning/lock";

export const XpEarningCreate: React.FC<IResourceComponentsProps> = () => {
  const {
    getInputProps,
    saveButtonProps,
    setFieldValue,
    values,
    refineCore: { queryResult },
  } = useForm<UpdateXpEarning, HttpError, UpdateXpEarning>({
    initialValues: {
      source: "ichigo_admin",
      state: "pending",
      amount: 0,
      notes: "",
      remote_invoice_id: undefined,
      user: null,
      locks: {
        shipping_subscription: null,
        shipping_shop_products: null,
      },
    },
    validateInputOnBlur: true,
    validate: {
      user_id: isNotEmpty("A user should be selected"),
      amount: isInRange({ min: 1 }, "XP should be a positive number"),
      state: (state, xpEarning) => {
        const isLocked = lockTypes.some(
          (lockType) =>
            xpEarning.locks[lockType] && !xpEarning.locks[lockType]?.released_at
        );

        if (isLocked && state === "active") {
          return "XP earning's state cannot be active when there are unreleased locks";
        }
      },
    },
  });

  const { options, onSearch } = useSelect<User>({
    resource: "users",
    optionLabel: "email",
    optionValue: "id",
    debounce: 1000,
    pagination: {
      pageSize: 10,
      mode: "server",
      current: 1,
    },
    onSearch: (value) => [
      {
        field: "email",
        operator: "eq",
        value,
      },
    ],
  });

  const difference = _.difference(
    Object.keys(DEFAULT_LOCKS),
    Object.entries(values.locks)
      .filter(([k, v]) => !!v)
      .map(([k, v]) => k)
  );

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Select
        data={options}
        onSearchChange={(v) => {
          onSearch(v);
        }}
        {...getInputProps("user_id")}
        onChange={(v) => v && setFieldValue("user_id", parseInt(v))}
        placeholder={values.user?.email}
        label="User"
        searchable
      />

      <NumberInput
        defaultValue={0}
        mt="sm"
        label="Amount"
        min={1}
        {...getInputProps("amount")}
      />
      <Select
        placeholder="State"
        data={selectStates}
        label="State"
        {...getInputProps("state")}
      />
      <Select
        placeholder="Source"
        data={selectSources}
        label="Source"
        description="This should normally be 'Ichigo Admin' choose another source only if it makes sense, and please fill the notes for with the reason."
        {...getInputProps("source")}
      />
      <TextInput
        defaultValue=""
        mt="sm"
        label="Remote Invoice Id"
        {...getInputProps("remote_invoice_id")}
      />
      <Textarea
        defaultValue=""
        mt="sm"
        label="Notes"
        {...getInputProps("notes")}
      />
      <h4>Locks</h4>
      <Flex justify="center">
        {difference.length > 0 && (
          <Select
            label="Add Lock"
            data={difference}
            placeholder="Add a lock"
            onChange={(kind) =>
              setFieldValue(
                `locks.${kind}`,
                DEFAULT_LOCKS[kind as Lock["lock_type"]]
              )
            }
          />
        )}
      </Flex>
      <Flex p="md" direction="column" gap="lg">
        {Object.values(values.locks)
          .filter((lock): lock is Partial<Lock> => !!lock)
          .map((lock) => {
            const releasedAtProps = getInputProps(
              `locks.${lock.lock_type}.released_at`
            );

            return (
              <Card key={lock.lock_type} shadow="md" withBorder>
                <form>
                  <Flex justify="space-between">
                    <Badge mb="md" size="lg">
                      {lock?.lock_type?.toUpperCase().replaceAll("_", " ")}
                    </Badge>
                    <ActionIcon
                      color="red"
                      variant="light"
                      onClick={() =>
                        lock.lock_type &&
                        setFieldValue(`locks.${lock.lock_type}`, null)
                      }
                    >
                      <IconTrash />
                    </ActionIcon>
                  </Flex>
                  <TextInput
                    label="Type"
                    value={lock?.lock_type?.toUpperCase().replaceAll("_", " ")}
                    disabled
                  />
                  <DatePicker
                    my="md"
                    label="Released At"
                    clearable
                    {...getInputProps(`locks.${lock.lock_type}.released_at`)}
                    value={
                      releasedAtProps.value && new Date(releasedAtProps.value)
                    }
                    withinPortal
                  />
                  <Textarea
                    mt="sm"
                    label="Released Notes"
                    {...getInputProps(`locks.${lock.lock_type}.released_notes`)}
                  />
                </form>
              </Card>
            );
          })}
      </Flex>
    </Create>
  );
};
