import _ from "lodash";

export type State = "pending" | "active" | "archived";
export const states: State[] = ["pending", "active", "archived"];

export const selectStates = states.map((state) => {
  return { value: state, label: _.capitalize(state) };
});

export const colorsByState: Record<State, string> = {
  pending: "yellow",
  active: "green",
  archived: "gray"
};
