import _ from "lodash";

export type Kind = "variable_amount" | "fixed_amount";

export const kinds: Kind[] = ["variable_amount", "fixed_amount"];

export const selectKinds = kinds.map((kind) => {
  return { value: kind, label: kind.replaceAll("_", " ").toUpperCase() };
});

export const colorsByKind: Record<Kind, string> = {
  variable_amount: "yellow",
  fixed_amount: "orange",
};
