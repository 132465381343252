import { IResourceComponentsProps, useShow, HttpError } from "@refinedev/core";
import { Show } from "@refinedev/mantine";
import {
  Image,
  Flex,
  Grid,
  Text,
  Anchor,
  Badge,
  Avatar,
  Title,
  Divider,
} from "@mantine/core";
import { DetailsCard } from "components/common/show/DetailsPair";
import { Article } from "entities/article";
import { colorsBySource } from "entities/article/source";
import { colorsByStatus } from "entities/article/status";
import { History } from "components/common/show/History";
import { CommentCard } from "components/comments/Show";

export const ArticleShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<Article, HttpError>();
  const { data, isLoading } = queryResult;
  const article = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Grid>
        <Grid.Col span={8}>
          <DetailsCard title="Content Preview">
            {article?.content && (
              <iframe
                style={{ height: 1000, width: 414, border: "none" }}
                srcDoc={article.content}
              ></iframe>
            )}
          </DetailsCard>
          <Divider my="md" />
          <DetailsCard title="Text Content">
            <Text>{article?.text_content}</Text>
          </DetailsCard>
          <Divider my="md" />
          <DetailsCard title="Comments" defaultCollapsed>
            <Flex direction="column" gap="xs">
              {article?.comments?.map((comment) => (
                <CommentCard
                  key={comment.id}
                  comment={comment}
                  showReplies={true}
                />
              ))}
            </Flex>
          </DetailsCard>
          <Divider my="md" />
          <DetailsCard>
            {article && (
              <History<Article> resources="articles" entityId={article.id} />
            )}
          </DetailsCard>
        </Grid.Col>
        <Grid.Col span={4}>
          {article && (
            <Flex direction="column" gap="sm">
              <DetailsCard title="Thumbnail">
                <Image sx={{ maxWidth: 200 }} src={article.thumbnail} />
              </DetailsCard>
              <DetailsCard title="Title">
                <Text>{article.title}</Text>
              </DetailsCard>
              <DetailsCard title="Source">
                <Badge color={colorsBySource[article.source]}>
                  {article.source}
                </Badge>
              </DetailsCard>
              <DetailsCard title="Status">
                <Badge color={colorsByStatus[article.status]}>
                  {article.status}
                </Badge>
              </DetailsCard>
              <DetailsCard title="Author">
                <Flex gap="md">
                  <Avatar h={48} w={48} src={article.author_avatar} />
                  <Flex direction="column">
                    <Title color="yellow" order={3}>
                      {article.author_name}
                    </Title>
                    <Text italic>{article.author_description}</Text>
                  </Flex>
                </Flex>
              </DetailsCard>
              <DetailsCard title="Url">
                <Anchor>{article.url}</Anchor>
              </DetailsCard>
              <DetailsCard title="Slug">
                <Text>{article.slug}</Text>
              </DetailsCard>
              <DetailsCard title="Views">
                <Text>{article.views_count}</Text>
              </DetailsCard>
              <DetailsCard title="Likes">
                <Text>{article.likes_count}</Text>
              </DetailsCard>
              <DetailsCard title="Comments">
                <Text>{article.comments_count}</Text>
              </DetailsCard>
              <DetailsCard title="Estimated Read Time">
                <Text>{article.estimated_read_time}</Text>
              </DetailsCard>
              <DetailsCard title="Author ID">
                <Text>{article.author_id}</Text>
              </DetailsCard>
              <DetailsCard title="Post ID">
                <Text>{article.post_id}</Text>
              </DetailsCard>
              <DetailsCard title="Categories">
                <Flex gap="sm" wrap="wrap">
                  {article.categories.map((category) => (
                    <Badge color="pink" variant="outline">
                      {category.name}
                    </Badge>
                  ))}
                </Flex>
              </DetailsCard>
              <DetailsCard title="WP Categories">
                <Flex gap="sm" wrap="wrap">
                  {article.wp_categories.map((wp_category) => (
                    <Badge color="orange" variant="outline">
                      {wp_category}
                    </Badge>
                  ))}
                </Flex>
              </DetailsCard>
              <DetailsCard title="WP Tags">
                <Flex gap="sm" wrap="wrap">
                  {article.wp_tags.map((wp_tag) => (
                    <Badge color="green" variant="outline">
                      {wp_tag}{" "}
                    </Badge>
                  ))}
                </Flex>
              </DetailsCard>
              <DetailsCard title="WP Published Date">
                {article.wp_published_date && (
                  <Text>{article.wp_published_date.toString()}</Text>
                )}
              </DetailsCard>
              <DetailsCard title="WP Modified Date">
                {article.wp_modified_date && (
                  <Text>{article.wp_modified_date.toString()}</Text>
                )}
              </DetailsCard>
              <DetailsCard title="Published Date">
                {article.publish_at && (
                  <Text>{article.publish_at.toString()}</Text>
                )}
              </DetailsCard>
              <DetailsCard title="Created At">
                <Text>{article.created_at.toString()}</Text>
              </DetailsCard>
              <DetailsCard title="Updated At">
                <Text>{article.updated_at.toString()}</Text>
              </DetailsCard>
            </Flex>
          )}
        </Grid.Col>
      </Grid>
    </Show>
  );
};
