import {
  IResourceComponentsProps,
  useShow,
  useOne,
  HttpError,
  useCustom,
  useApiUrl,
} from "@refinedev/core";
import { Show, NumberField, DateField, TextField } from "@refinedev/mantine";
import {
  Title,
  Image,
  Badge,
  Flex,
  Table,
  useMantineTheme,
  Anchor,
  Grid,
  Code,
  Avatar,
  Text,
} from "@mantine/core";
import { GiftCard, GiftCardTransaction } from "entities/gift_card";
import { User } from "entities/user";
import { DetailsUser } from "components/common/show/DetailsUser";
import { DetailsPair } from "components/common/show/DetailsPair";
import { History } from "components/common/show/History";
import { IconRobot } from "@tabler/icons";
import { GiftCardCampaign } from "entities/gift_card_campaign";
import { GiftCardCampaignPlan } from "entities/gift_card_campaign/gift_card_campaign_plan";

import { Link } from "react-router-dom";
import { getCouponUrl, getInvoiceUrl } from "utils/chargebee";

type GiftCardTransactionResponse = {
  transactions: GiftCardTransaction[];
};

export const GiftCardShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<GiftCard, HttpError>();
  const { data, isLoading } = queryResult;
  const giftCard = data?.data;

  const user = useOne<User, HttpError>({
    resource: "users",
    id: giftCard?.user_id,
  }).data?.data;

  const createdBy = useOne<User, HttpError>({
    resource: "users",
    id: giftCard?.created_by_id,
    queryOptions: {
      enabled: !!giftCard?.created_by_id,
    },
  }).data?.data;

  const apiUrl = useApiUrl();

  const { data: transactionData, isLoading: isLoadingTransactions } =
    useCustom<GiftCardTransactionResponse>({
      url: `${apiUrl}/gift_cards/${giftCard?.id}/transactions`,
      method: "get",
      queryOptions: {
        enabled: !!giftCard?.id,
      },
    });

  const {
    data: giftCardCampaignPlanData,
    isLoading: isLoadingGiftCardCampaignPlan,
  } = useOne<
    GiftCardCampaignPlan & { gift_card_campaign: GiftCardCampaign },
    HttpError
  >({
    resource: "gift_card_campaign_plans",
    id: giftCard?.gift_card_campaign_plan_id,
    queryOptions: {
      enabled: !!giftCard?.gift_card_campaign_plan_id,
    },
  });

  const giftCardCampaign = giftCardCampaignPlanData?.data.gift_card_campaign;

  return (
    <Show isLoading={isLoading}>
      <Grid>
        <Grid.Col span={8}>
          <h1>Transactions</h1>
          {transactionData && (
            <Transactions transactions={transactionData.data.transactions} />
          )}
          {giftCard?.id && (
            <History<GiftCard>
              resources={"gift_cards"}
              entityId={giftCard.id}
            />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Flex direction="column" gap="sm">
            {giftCardCampaign && (
              <DetailsPair
                containerProps={{
                  sx: (theme) => ({
                    paddingTop: theme.spacing.md,
                    paddingBottom: theme.spacing.md,
                  }),
                }}
              >
                <Title order={5}>Campaign</Title>
                <Flex direction="row" gap="sm">
                  <Anchor
                    component={Link}
                    to={`/gift_card_campaigns/show/${giftCardCampaign.id}`}
                  >
                    {giftCardCampaign.code}
                  </Anchor>
                  <Badge color="green" size="md">
                    {giftCardCampaign.brand}
                  </Badge>
                </Flex>
              </DetailsPair>
            )}
            {giftCard?.chargebee_invoice_id && (
              <DetailsPair>
                <Title order={5}>ChargeBee Invoice</Title>
                <Anchor
                  href={getInvoiceUrl(giftCard.chargebee_invoice_id)}
                  target="_blank"
                >
                  {giftCard.chargebee_invoice_id}
                </Anchor>
              </DetailsPair>
            )}
            <DetailsPair>
              <Title order={5}>Image</Title>
              <Image sx={{ maxWidth: 200 }} src={giftCard?.image} />
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>User</Title>
              {user && <DetailsUser user={user} />}
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Code</Title>
              <Code>{giftCard?.code}</Code>
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Initial Balance</Title>
              <Badge color="gray" size="lg">
                ${giftCard?.initial_value}
              </Badge>
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Balance</Title>
              <Badge color="blue" size="lg">
                ${giftCard?.balance}
              </Badge>
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Channel</Title>
              <Badge color="blue">{giftCard?.channel}</Badge>
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Constraint</Title>
              <Badge color="orange">{giftCard?.constraint}</Badge>
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Status</Title>
              <Badge color="red">{giftCard?.status}</Badge>
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Expire At</Title>
              <DateField value={giftCard?.expire_at} />
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Id</Title>
              <NumberField value={giftCard?.id ?? ""} />
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Created At</Title>
              <DateField value={giftCard?.created_at} />
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Updated At</Title>
              <DateField value={giftCard?.updated_at} />
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Notes</Title>
              <TextField value={giftCard?.notes} />
            </DetailsPair>
            <DetailsPair>
              <Title order={5}>Created By</Title>
              {createdBy ? (
                <DetailsUser user={createdBy} />
              ) : (
                <Flex direction="row" align="center">
                  <Text color="green">Created Automatically</Text>
                  <Avatar size="md" ml="sm">
                    <IconRobot size={24} />
                  </Avatar>
                </Flex>
              )}
            </DetailsPair>
          </Flex>
        </Grid.Col>
      </Grid>
    </Show>
  );
};

const Transactions = ({
  transactions,
}: {
  transactions: GiftCardTransaction[];
}) => {
  const env = process.env.NODE_ENV;
  const chargebeeSite =
    env === "production"
      ? "https://tokyotreat.chargebee.com"
      : "https://tokyotreat-test.chargebee.com";

  return (
    <Table striped highlightOnHover>
      <thead>
        <th>
          <td>Id</td>
        </th>
        <th>
          <td>Created At</td>
        </th>
        <th>
          <td>Channel</td>
        </th>
        <th>
          <td>Amount</td>
        </th>
        <th>
          <td>Remote Invoice Id</td>
        </th>
        <th>
          <td>Remote Coupon ID</td>
        </th>
      </thead>
      <tbody>
        {transactions.map((transaction: GiftCardTransaction) => {
          const invoiceUrl = getInvoiceUrl(transaction.remote_invoice_id);
          const couponUrl = getCouponUrl(transaction.remote_coupon_id);

          return (
            <tr>
              <td>{transaction.id}</td>
              <td>{transaction.created_at.toString()}</td>
              <td>
                <Badge>{transaction.channel}</Badge>
              </td>
              <td>
                <Badge color="red" variant="outline">
                  ${transaction.amount}
                </Badge>
              </td>
              <td>
                <Anchor target="_blank" href={invoiceUrl}>
                  {transaction.remote_invoice_id}
                </Anchor>
              </td>
              <td>
                <Anchor target="_blank" href={couponUrl}>
                  {transaction.remote_coupon_id}
                </Anchor>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
