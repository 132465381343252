import _ from "lodash";

export type CommentableType = "WordpressArticle" | "Poll";
export const commentableTypes: CommentableType[] = ["WordpressArticle", "Poll"];

export const colorsByCommentableType: Record<CommentableType, string> = {
  WordpressArticle: "cyan",
  Poll: "orange",
};

export const labelsByCommentableType: Record<CommentableType, string> = {
  WordpressArticle: "Article",
  Poll: "Poll",
};

export const urlsByCommentableType: Record<CommentableType, string> = {
  WordpressArticle: "/articles",
  Poll: "/polls",
};

export const selectCommentableTypes = commentableTypes.map(
  (commentableType) => {
    return {
      value: commentableType,
      label: labelsByCommentableType[commentableType],
    };
  },
);
