
import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, Header } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  LoadingOverlay,
  Flex,
  Badge,
  Text,
  Select,
  NumberInput,
  ThemeIcon,
} from "@mantine/core";
import {
  EditButton,
  List,
  ShowButton,
  TextField,
} from "@refinedev/mantine";

import useCoinPermissions from "./useCoinPermissions";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { DateRangePicker } from "@mantine/dates";
import { formatDistanceToNow } from "date-fns";
import { CoinEarningReward } from "entities/coin_earning_reward"
import { colorsByKind } from "entities/coin_earning_reward/kind";
import { formatToJst } from "utils/dates";
import { useOutletContext } from "react-router-dom";
import { UserOutletContext } from "entities/user";
import { selectKinds } from "entities/coin_earning_reward/kind";
import { IconCheck, IconX } from "@tabler/icons";

export const CoinEarningRewardList: React.FC<IResourceComponentsProps> = () => {
  const { canEdit } = useCoinPermissions();
  const userOutletCtx = useOutletContext<UserOutletContext>();

  const columns: ColumnDef<CoinEarningReward>[] = [
    {
      id: "id",
      accessorKey: "id",
      header: "Id",
    },
    {
      id: "kind",
      accessorKey: "kind",
      header: "Kind",
      cell: ({ getValue }) => {
        const kind = getValue<CoinEarningReward["kind"]>();

        return (
          <Badge variant="light" color={colorsByKind[kind]}>
            {kind}
          </Badge>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<CoinEarningReward, any> }) => {
          return (
            <div>
              <Select
                placeholder="Kind"
                data={selectKinds}
                onChange={header.column.setFilterValue}
                clearable
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "active",
      accessorKey: "active",
      header: "Active",
      cell: ({ getValue }) => {
        const active = getValue<CoinEarningReward["active"]>();

        return (
          <Flex justify="center">
            {active ? (
              <ThemeIcon variant="light" color="green">
                <IconCheck />
              </ThemeIcon>
            ) : (
              <ThemeIcon variant="light" color="orange">
                <IconX />
              </ThemeIcon>
            )}
          </Flex>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<CoinEarningReward, any> }) => {
          return (
            <div>
              <Select
                placeholder="Active"
                data={[
                  { value: "active", label: "Active" },
                  { value: "inactive", label: "Inactive" },
                ]}
                onChange={header.column.setFilterValue}
                withinPortal
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "coins",
      accessorKey: "coins",
      header: "Coins",
      cell({ getValue }) {
        const amount = getValue<CoinEarningReward["coins"]>();

        return (
          <Text size="md" color="blue">
            {amount}
          </Text>
        );
      },
      meta: {
        filterOperator: "eq",
        filterElement: ({ header }: { header: Header<CoinEarningReward, any> }) => {
          return (
            <div>
              <NumberInput
                placeholder="Coins"
                onChange={(v) => header.column.setFilterValue(v?.toString())}
                mx="auto"
                maw={400}
              />
            </div>
          );
        },
      },
    },
    {
      id: "description",
      accessorKey: "description",
      header: "Description",
      cell({ getValue }) {
        const description = getValue<CoinEarningReward["description"]>();

        return (
          <Text size="md">{description.length > 100 ? `${description.slice(0, 100)}...` : description}</Text>
        );
      },
    },
    {
      id: "created_at",
      accessorKey: "created_at",
      header: "Created At",
      cell({ getValue }) {
        const date = new Date(getValue<CoinEarningReward["created_at"]>());

        return <TextField value={formatDistanceToNow(date)} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<CoinEarningReward, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={(v) =>
                  header.column.setFilterValue(
                    v.map((date) => date && formatToJst(date))
                  )
                }
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
        disableSort: true,
      },
    },
    {
      id: "updated_at",
      accessorKey: "updated_at",
      header: "Updated At",
      cell({ getValue }) {
        const date = new Date(getValue<CoinEarningReward["updated_at"]>());

        return <TextField value={formatDistanceToNow(date)} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<CoinEarningReward, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={header.column.setFilterValue}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "actions",
      cell: function render({ row: { original: coin_earning_reward } }) {
        return (
          <Flex columnGap={4}>
            <ShowButton
              hideText
              resource="coin_earning_rewards"
              recordItemId={coin_earning_reward.id}
            />
            {canEdit && (
              <EditButton
                hideText
                resource="coin_earning_rewards"
                recordItemId={coin_earning_reward.id}
              />
            )}
          </Flex>
        );
      },
      meta: {
        isPlaceholder: true,
      },
    },
  ];

  const updatedColumns = columns.filter(
    (col) => col.id !== "user" || !userOutletCtx?.user
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns: updatedColumns,
    refineCoreProps: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  return (
    <List canCreate={false} resource="coin_earning_rewards">
      <AppliedFilters<CoinEarningReward> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<CoinEarningReward> headerGroups={getHeaderGroups()} />
          <Rows<CoinEarningReward> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
