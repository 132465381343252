const env = process.env.NODE_ENV;
const chargebeeSite =
  env === "production"
    ? "https://tokyotreat.chargebee.com"
    : "https://tokyotreat-test.chargebee.com";

export const getInvoiceUrl = (invoiceId: string) =>
  `${chargebeeSite}/d/invoices/${invoiceId}`;

export const getCouponUrl = (couponId: string) =>
  `${chargebeeSite}/d/coupons/${couponId}`;
