import type { AuthBindings } from "@refinedev/core";
import axiosInstance from "./axiosInstance";
import jwtDecode from "jwt-decode";
import { DEFAULT_ROLE, Role } from "entities/user/role";

const authProvider: AuthBindings = {
  login: async ({ email, password }: { email: string; password: string }) => {
    const result = await axiosInstance.post(
      `${process.env.REACT_APP_CP_API_URL}/api/admin/users/login`,
      {
        email: email,
        password: password,
      }
    );

    const token = result.data?.data?.token;

    if (token) {
      const userData: { user_id: string } = jwtDecode(token);
      localStorage.setItem("ichigo_admin_token", token);

      const result = await axiosInstance.get(
        `${process.env.REACT_APP_CP_API_URL}/api/admin/users/${userData["user_id"]}`,
      );

      localStorage.setItem("ichigo_admin_user", JSON.stringify(result.data));

      return {
        success: true,
        redirectTo: "/",
      };
    } else {
      return {
        success: false,
        error: {
          message: "Login Error",
          name: "Invalid email or password",
        },
      };
    }
  },

  check: async (params: any) => {
    const token = localStorage.getItem("ichigo_admin_token");

    if (token) {
      return { authenticated: true };
    }

    return {
      authenticated: false,
      logout: true,
      redirectTo: "/login",
      error: {
        message: "Check failed",
        name: "Unauthorized",
      },
    };
  },

  logout: async (params: any) => {
    localStorage.removeItem("ichigo_admin_token");
    localStorage.removeItem("ichigo_admin_user");

    return {
      success: true,
      redirectTo: "/login",
    };
  },

  onError: async (error: any) => {
    if (error.statusCode === 401 || error.statusCode === 403) {
      return {
        logout: true,
        redirectTo: "/login",
        error
      }
    }

    return {}
  },

  getPermissions: async (): Promise<Role> => {
    const user = localStorage.getItem("ichigo_admin_user")

    if (user) {
      const { role } = JSON.parse(user)

      return role as Role
    }

    return DEFAULT_ROLE
  },

  getIdentity: async () => {
    const user = localStorage.getItem("ichigo_admin_user")

    if (user) {
      const parsedUser = JSON.parse(user)

      return {
        ...parsedUser,
        name: `${parsedUser.first_name} ${parsedUser.last_name}`,
        image: parsedUser.image?.url
      }
    }

    return null
  }
};

export default authProvider;
