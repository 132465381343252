import type { GetInputProps } from "@mantine/form/lib/types";
import { Box, Flex, InputWrapperBaseProps, Text } from "@mantine/core";
import { DatePicker, DatePickerProps, TimeInput } from "@mantine/dates";
import { getYear, getMonth, getDay, getHours, getMinutes, getDate } from "date-fns";
import { IconAsterisk } from "@tabler/icons";

type DateTimePickerProps = {
  value: Date,
  label: string,
  description?: string
} & ReturnType<GetInputProps<Date>> & InputWrapperBaseProps & DatePickerProps

export default function DateTimePicker(props: DateTimePickerProps) {
  const updatedProps = {
    ...props,
    value: new Date(props.value),
  };

  const onDateChange = (date: Date) => {
    const oldDate = new Date(props.value)
    const year = getYear(date)
    const month = getMonth(date)
    const day = getDate(date)
    const hour = getHours(oldDate)
    const minute = getMinutes(oldDate)

    props.onChange(new Date(year, month, day, hour, minute))
  }

  const onTimeChange = (date: Date) => {
    const oldDate = new Date(props.value)
    const year = getYear(oldDate)
    const month = getMonth(oldDate)
    const day = getDate(oldDate)
    const hour = getHours(date)
    const minute = getMinutes(date)

    props.onChange(new Date(year, month, day, hour, minute))
  }

  return (
    <Box my="xs">
      <Flex>
        <Text size={14} weight={500} mr={4}>{props.label}</Text>
        {props.withAsterisk && <IconAsterisk color="red" size={7} />}
      </Flex>
      <Text size="xs">{props.description}</Text>
      <Flex gap="sm" pt={2}>
        <DatePicker
          placeholder="Choose date"
          maw={400}
          defaultValue={new Date()}
          clearable={false}
          withinPortal
          {...updatedProps}
          label={undefined}
          description={undefined}
          onChange={onDateChange}
        />
        <TimeInput
          placeholder="Choose time"
          maw={400}
          defaultValue={new Date()}
          clearable={false}
          {...updatedProps}
          label={undefined}
          description={undefined}
          onChange={onTimeChange}
        />
      </Flex>
    </Box>
  );
}
