import { HttpError, IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/mantine";
import {
  ActionIcon,
  Badge,
  Card,
  Flex,
  NumberInput,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { DEFAULT_TIER_BENEFITS, TierBenefit, UpdateTier } from "entities/tier";
import { isInRange } from "@mantine/form";
import { IconTrash } from "@tabler/icons";
import _ from "lodash";

export const TierEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    getInputProps,
    saveButtonProps,
    values,
    setFieldValue,
    refineCore: { queryResult },
  } = useForm<UpdateTier, HttpError, UpdateTier>({
    initialValues: {
      coins_per_dollar: 0,
      xp_required: 0,
      tier_benefits: DEFAULT_TIER_BENEFITS,
    },
    validate: {
      coins_per_dollar: isInRange(
        { min: 0, max: 10 },
        "Coins per dollar shold be between 0 and 10"
      ),
      xp_required: isInRange(
        { min: 0, max: 1_000 },
        "Xp required should be between 0 and 1,000"
      ),
    },
  });

  const difference = _.difference(
    Object.keys(DEFAULT_TIER_BENEFITS),
    Object.entries(values.tier_benefits)
      .filter(([k, v]) => !!v)
      .map(([k, v]) => k)
  );

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      canDelete={false}
      title={`Tier: ${queryResult?.data?.data.name}`}
    >
      <h2>Basic Properties</h2>
      <NumberInput
        defaultValue={0}
        mt="sm"
        label="Xp Required"
        min={0}
        max={10}
        {...getInputProps("xp_required")}
      />
      <NumberInput
        defaultValue={0}
        mt="sm"
        label="Coins Per Dollar"
        {...getInputProps("coins_per_dollar")}
      />
      <h2>Benefits</h2>
      <Flex justify="center">
        {difference.length > 0 && (
          <Select
            label="Add Benefit"
            data={difference}
            placeholder="Add a benefit"
            onChange={(kind) =>
              setFieldValue(
                `tier_benefits.${kind}`,
                DEFAULT_TIER_BENEFITS[kind as TierBenefit["kind"]]
              )
            }
          />
        )}
      </Flex>
      {Object.values(values.tier_benefits)
        .filter((tierBenefit) => !!tierBenefit)
        .map((tierBenefit) => {
          return (
            <Card key={tierBenefit.kind} my="md" shadow="md" ml="md" withBorder>
              <Flex direction="column">
                <Flex justify="space-between">
                  <Badge color="violet">
                    {tierBenefit.kind?.replaceAll("_", " ")}
                  </Badge>
                  <ActionIcon
                    color="red"
                    variant="light"
                    onClick={() =>
                      tierBenefit.kind &&
                      setFieldValue(`tier_benefits.${tierBenefit.kind}`, null)
                    }
                  >
                    <IconTrash />
                  </ActionIcon>
                </Flex>
                <TextInput
                  mt="sm"
                  label="Kind"
                  disabled
                  {...getInputProps(`tier_benefits.${tierBenefit.kind}.kind`)}
                />
                <TextInput
                  mt="sm"
                  label="Title"
                  {...getInputProps(`tier_benefits.${tierBenefit.kind}.title`)}
                />
                <TextInput
                  mt="sm"
                  label="Value"
                  {...getInputProps(`tier_benefits.${tierBenefit.kind}.value`)}
                />
                <Textarea
                  mt="sm"
                  label="Description"
                  {...getInputProps(
                    `tier_benefits.${tierBenefit.kind}.description`
                  )}
                />
              </Flex>
            </Card>
          );
        })}
    </Edit>
  );
};
