import { Card, Flex, Loader, Text } from "@mantine/core";
import { Survey } from "entities/survey";
import { SurveyResponse } from "entities/survey/response";
import { useApiUrl, useCustom } from "@refinedev/core";

type ResponseDetailsProps = {
  surveyId: Survey["id"];
  surveyResponseId: SurveyResponse["id"];
};

type AnswerItem = {
  field: {
    id: string;
    title: string;
    type: "text" | "multi_choice" | "number" | "yes_no";
  };
  type: "text" | "choice" | "number" | "boolean";
  text?: string;
  number?: number,
  boolean?: boolean,
  choice?: {
    label: string;
  };
};

type DetailsResponse = {
  result: AnswerItem[];
};

export function ResponseDetails({
  surveyId,
  surveyResponseId,
}: ResponseDetailsProps) {
  const apiUrl = useApiUrl();

  const { data, isFetching } = useCustom<DetailsResponse>({
    url: `${apiUrl}/surveys/${surveyId}/responses/${surveyResponseId}/details`,
    method: "get",
    queryOptions: {
      enabled: !!surveyResponseId,
    },
  });

  if (isFetching) {
    return (
      <Flex direction="column" justify="center" align="center">
        <Loader size="lg" />
        <Text>Loading response from Typeform</Text>
      </Flex>
    );
  }

  const answers = data?.data.result;

  return (
    <Flex direction="column">
      {answers?.map((answer) => {
        return (
          <Card shadow="sm" p="xl" my="md" component="a">
            <Text color="orange" size="md">
              {answer.field.title.replace(/{{.*}}/, "")}
            </Text>
            <Text mt="xs" color="dimmed" size="sm">
              {answer.text || answer.number || answer.choice?.label || `${answer.boolean}`}
            </Text>
          </Card>
        );
      })}
    </Flex>
  );
}
