import _ from "lodash"

export type Constraint = "subscription" | "shop_items" | "no_constraints"

export const colorsByConstraint: Record<Constraint, string> = {
    subscription: "green",
    shop_items: "cyan",
    no_constraints: "gray",
};

export const constraints: Constraint[] = ["subscription", "shop_items", "no_constraints"]

export const selectConstraints = constraints.map(constraint => {
    return { value: constraint, label: _.capitalize(constraint) }
})

