import { RefineThemes } from "@refinedev/mantine";

import { ColorSchemeProvider, Global, MantineProvider } from "@mantine/core";
import { Refine } from "@refinedev/core";

import routerBindings from "@refinedev/react-router-v6";
import axiosInstance from "axiosInstance";
import { CoinSpending } from "entities/coin_spending";
import { LoyaltyReward } from "entities/loyalty_reward";
import { useState } from "react";
import { MemoryRouter } from "react-router-dom";
import authProvider from "../authProvider";
import { dataProvider } from "../dataProvider";
import ActionsScreen from "./ActionsScreen";
import Benefits from "./Benefits";
import Layout, { Screen } from "./Layout";
import Main from "./Main";
import RewardView from "./RewardView";
import SetBirthday from "./SetBirthday";
import VariableReward from "./VariableReward";

export type Tab = "rewards" | "earn_coins" | "my_rewards";

export default function Widget() {
  const [screen, setScreen] = useState<Screen>("main");
  const [tab, setTab] = useState<Tab>("earn_coins");
  const [coinSpending, setCoinSpending] = useState<CoinSpending | null>(null);
  const [loyaltyReward, setLoyaltyReward] = useState<LoyaltyReward | null>(
    null,
  );

  const content = (
    <ColorSchemeProvider colorScheme="light" toggleColorScheme={() => {}}>
      <MantineProvider
        theme={{ ...RefineThemes.Blue, colorScheme: "light" }}
        withNormalizeCSS
        withGlobalStyles
      >
        <Global styles={{ body: { WebkitFontSmoothing: "auto" } }} />
        <Refine
          routerProvider={routerBindings}
          dataProvider={dataProvider(
            `${process.env.REACT_APP_CP_API_URL}/api/admin`,
            axiosInstance,
          )}
          authProvider={authProvider}
          options={{
            syncWithLocation: false,
            warnWhenUnsavedChanges: false,
            projectId: "uS6qCn-hrhIyI-XTgEHu2",
          }}
          resources={[]}
        >
          <Layout screen={screen} setScreen={setScreen}>
            {screen === "main" && (
              <Main
                setScreen={setScreen}
                setTab={setTab}
                setCoinSpending={setCoinSpending}
                setLoyaltyReward={setLoyaltyReward}
              />
            )}
            {screen === "actions_screen" && (
              <ActionsScreen
                setScreen={setScreen}
                tab={tab}
                setTab={setTab}
                setCoinSpending={setCoinSpending}
                setLoyaltyReward={setLoyaltyReward}
              />
            )}
            {screen === "reward_view" && (
              <RewardView setScreen={setScreen} coinSpending={coinSpending} />
            )}
            {screen === "variable_reward" && loyaltyReward && (
              <VariableReward
                setScreen={setScreen}
                loyaltyReward={loyaltyReward}
                setCoinSpending={setCoinSpending}
              />
            )}
            {screen === "set_birthday" && <SetBirthday setScreen={setScreen} />}
            {screen === "benefits" && <Benefits />}
          </Layout>
        </Refine>
      </MantineProvider>
    </ColorSchemeProvider>
  );

  return process.env.REACT_APP_BUILD_TARGET === "widget" ? (
    <MemoryRouter>{content}</MemoryRouter>
  ) : (
    content
  );
}
