import { HttpError, IResourceComponentsProps } from "@refinedev/core";
import { useSelect } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/mantine";
import {
  NumberInput,
  TextInput,
  Select,
  Textarea,
  Flex,
  Card,
  Badge,
  ActionIcon,
} from "@mantine/core";
import { DEFAULT_LOCKS, UpdateCoinEarning } from "entities/coin_earning";
import { selectStates } from "entities/coin_earning/state";
import { selectSources } from "entities/coin_earning/source";
import { User } from "entities/user";
import { isInRange } from "@mantine/form";
import { Lock, lockTypes } from "entities/coin_earning/lock";
import { DatePicker } from "@mantine/dates";
import _ from "lodash";
import { IconTrash } from "@tabler/icons";
import { selectEvents } from "entities/coin_earning/event";

export const CoinEarningEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    getInputProps,
    saveButtonProps,
    setFieldValue,
    values,
    refineCore: { queryResult },
  } = useForm<UpdateCoinEarning, HttpError, UpdateCoinEarning>({
    initialValues: {
      source: "ichigo_app",
      state: "pending",
      event: "purchase",
      amount: 0,
      notes: "",
      remote_invoice_id: "",
      user: null,
      locks: DEFAULT_LOCKS,
    },
    validateInputOnChange: true,
    validate: {
      amount: isInRange({ min: 1 }, "COIN should be a positive number"),
      state: (state, coinEarning) => {
        const isLocked = lockTypes.some(
          (lockType) =>
            coinEarning.locks[lockType] && !coinEarning.locks[lockType]?.released_at
        );

        if (isLocked && state === "active") {
          return "COIN earning's state cannot be active when there are unreleased locks";
        }
      },
    },
  });

  const { options, onSearch } = useSelect<User>({
    resource: "users",
    optionLabel: "email",
    optionValue: "id",
    debounce: 1000,
    pagination: {
      pageSize: 10,
      mode: "server",
      current: 1,
    },
    onSearch: (value) => [
      {
        field: "email",
        operator: "eq",
        value,
      },
    ],
  });

  const difference = _.difference(
    Object.keys(DEFAULT_LOCKS),
    Object.entries(values.locks)
      .filter(([k, v]) => !!v)
      .map(([k, v]) => k)
  );

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Select
        data={options}
        onSearchChange={(v) => {
          onSearch(v);
        }}
        onChange={(v) => v && setFieldValue("user_id", parseInt(v))}
        placeholder={values.user?.email}
        label="User"
        searchable
      />
      <NumberInput
        defaultValue={0}
        mt="sm"
        label="Amount"
        min={1}
        {...getInputProps("amount")}
      />
      <Select
        placeholder="State"
        data={selectStates}
        label="State"
        {...getInputProps("state")}
      />
      <Select
        placeholder="Source"
        data={selectSources}
        label="Source"
        {...getInputProps("source")}
      />
      <Select
        placeholder="Event"
        data={selectEvents}
        label="Event"
        {...getInputProps("event")}
      />
      <TextInput
        defaultValue=""
        mt="sm"
        label="Remote Invoice Id"
        {...getInputProps("remote_invoice_id")}
      />
      <Textarea
        defaultValue=""
        mt="sm"
        label="Notes"
        {...getInputProps("notes")}
      />
      <h4>Locks</h4>
      <Flex justify="center">
        {difference.length > 0 && (
          <Select
            label="Add Lock"
            data={difference}
            placeholder="Add a lock"
            onChange={(kind) =>
              setFieldValue(
                `locks.${kind}`,
                DEFAULT_LOCKS[kind as Lock["lock_type"]]
              )
            }
          />
        )}
      </Flex>
      <Flex p="md" direction="column" gap="lg">
        {Object.values(values.locks)
          .filter((lock): lock is Partial<Lock> => !!lock)
          .map((lock) => {
            const releasedAtProps = getInputProps(
              `locks.${lock.lock_type}.released_at`
            );

            return (
              <Card key={lock.lock_type} shadow="md" withBorder>
                <form>
                  <Flex justify="space-between">
                    <Badge mb="md" size="lg">
                      {lock?.lock_type?.toUpperCase().replaceAll("_", " ")}
                    </Badge>
                    <ActionIcon
                      color="red"
                      variant="light"
                      onClick={() =>
                        lock.lock_type &&
                        setFieldValue(`locks.${lock.lock_type}`, null)
                      }
                    >
                      <IconTrash />
                    </ActionIcon>
                  </Flex>
                  <TextInput
                    label="Type"
                    value={lock?.lock_type?.toUpperCase().replaceAll("_", " ")}
                    disabled
                  />
                  <DatePicker
                    my="md"
                    label="Released At"
                    clearable
                    {...getInputProps(`locks.${lock.lock_type}.released_at`)}
                    value={
                      releasedAtProps.value && new Date(releasedAtProps.value)
                    }
                    withinPortal
                  />
                  <Textarea
                    mt="sm"
                    label="Released Notes"
                    {...getInputProps(`locks.${lock.lock_type}.released_notes`)}
                  />
                </form>
              </Card>
            );
          })}
      </Flex>
    </Edit>
  );
};
