import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, Header } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  LoadingOverlay,
  Flex,
  Badge,
  Text,
  Avatar,
  Anchor,
  Select,
  NumberInput,
  ThemeIcon,
  Box,
} from "@mantine/core";
import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  TextField,
} from "@refinedev/mantine";

import useCoinPermissions from "./useCoinPermissions";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { DateRangePicker } from "@mantine/dates";
import { formatDistanceToNow } from "date-fns";
import { CoinEarning } from "entities/coin_earning";
import { colorsBySource, selectSources } from "entities/coin_earning/source";
import { colorsByState, selectStates } from "entities/coin_earning/state";
import { getInvoiceUrl } from "utils/chargebee";
import { formatToJst } from "utils/dates";
import { selectLockTypes } from "entities/coin_earning/lock";
import { IconLock, IconLockOpen } from "@tabler/icons";
import { useOutletContext } from "react-router-dom";
import { UserOutletContext } from "entities/user";
import { colorsByEvent, selectEvents } from "entities/coin_earning/event";
import { getShopifyOrderName, getShopifyOrderUrl } from "utils/shopify";

export const CoinEarningList: React.FC<IResourceComponentsProps> = () => {
  const { canEdit, canDelete, canCreate } = useCoinPermissions();
  const userOutletCtx = useOutletContext<UserOutletContext>();

  const columns: ColumnDef<CoinEarning>[] = [
    {
      id: "id",
      accessorKey: "id",
      header: "Id",
    },
    {
      id: "user",
      accessorKey: "user",
      header: "User",
      cell({ getValue }) {
        const user = getValue<CoinEarning["user"]>();

        return (
          <Text size="md" weight="bold">
            <Flex direction="column" align="center">
              <Avatar src={user?.image} size="md" />
              <Text color="white">
                {user?.first_name}
                &nbsp;
                {user?.last_name}
              </Text>
              <Text color="orange">{user?.email}</Text>
            </Flex>
          </Text>
        );
      },
      meta: {
        filterOperator: "eq",
        disableSort: true,
      },
    },
    {
      id: "source",
      accessorKey: "source",
      header: "Source",
      cell: ({ getValue }) => {
        const source = getValue<CoinEarning["source"]>();

        return (
          <Badge variant="light" color={colorsBySource[source]}>
            {source}
          </Badge>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<CoinEarning, any> }) => {
          return (
            <div>
              <Select
                placeholder="Source"
                data={selectSources}
                onChange={header.column.setFilterValue}
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "event",
      accessorKey: "event",
      header: "Event",
      cell: ({ getValue }) => {
        const event = getValue<CoinEarning["event"]>();

        return (
          <Badge variant="light" color={colorsByEvent[event]}>
            {event}
          </Badge>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<CoinEarning, any> }) => {
          return (
            <div>
              <Select
                placeholder="Event"
                data={selectEvents}
                onChange={header.column.setFilterValue}
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "state",
      accessorKey: "state",
      header: "State",
      cell: ({ getValue }) => {
        const state = getValue<CoinEarning["state"]>();

        return (
          <Badge variant="filled" color={colorsByState[state]}>
            {state}
          </Badge>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<CoinEarning, any> }) => {
          return (
            <div>
              <Select
                placeholder="State"
                data={selectStates}
                onChange={header.column.setFilterValue}
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "locks",
      accessorKey: "locks",
      header: "Locks",
      cell: ({ getValue }) => {
        const locks = getValue<CoinEarning["locks"]>();

        if (locks.length === 0) {
          return <Text color="gray">No locks</Text>;
        }

        return (
          <Flex direction="column" gap="sm">
            {locks.map((lock) => (
              <Flex
                justify="start"
                align="center"
                sx={(theme) => ({
                  background: lock.released_at
                    ? theme.colors.gray[7]
                    : theme.colors.red[7],
                  borderRadius: theme.spacing.md,
                })}
              >
                <ThemeIcon
                  variant="light"
                  radius="lg"
                  color={lock.released_at ? "gray" : "red"}
                >
                  {lock.released_at ? (
                    <IconLockOpen size={16} />
                  ) : (
                    <IconLock size={16} />
                  )}
                </ThemeIcon>
                <Box sx={{ fontSize: "12px", fontWeight: "bold" }}>
                  {lock.lock_type.toUpperCase().replaceAll("_", " ")}
                </Box>
              </Flex>
            ))}
          </Flex>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<CoinEarning, any> }) => {
          return (
            <div>
              <Select
                placeholder="Lock"
                data={selectLockTypes}
                onChange={header.column.setFilterValue}
                clearable
              />
            </div>
          );
        },
        disableSort: true,
      },
    },
    {
      id: "remote_invoice_id",
      accessorKey: "remote_invoice_id",
      header: "Remote Invoice Id",
      cell({ getValue }) {
        const invoiceId = getValue<CoinEarning["remote_invoice_id"]>();
        if (!invoiceId) return <Text>--</Text>

        return invoiceId.startsWith("JH") ? (
          <Anchor href={getShopifyOrderUrl(invoiceId)} target="_blank">
            {getShopifyOrderName(invoiceId)}
          </Anchor>
        ) : (
          <Anchor href={getInvoiceUrl(invoiceId)} target="_blank">
            {invoiceId}
          </Anchor>
        );
      },
    },
    {
      id: "amount",
      accessorKey: "amount",
      header: "Amount",
      cell({ getValue }) {
        const amount = getValue<CoinEarning["amount"]>();

        return (
          <Text size="md" color="blue">
            {amount}
          </Text>
        );
      },
      meta: {
        filterOperator: "eq",
        filterElement: ({ header }: { header: Header<CoinEarning, any> }) => {
          return (
            <div>
              <NumberInput
                placeholder="Amount"
                onChange={(v) => header.column.setFilterValue(v?.toString())}
                mx="auto"
                maw={400}
              />
            </div>
          );
        },
      },
    },
    {
      id: "notes",
      accessorKey: "notes",
      header: "Notes",
    },
    {
      id: "created_at",
      accessorKey: "created_at",
      header: "Created At",
      cell({ getValue }) {
        const date = new Date(getValue<CoinEarning["created_at"]>());

        return <TextField value={formatDistanceToNow(date)} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<CoinEarning, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={(v) =>
                  header.column.setFilterValue(
                    v.map((date) => date && formatToJst(date))
                  )
                }
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
        disableSort: true,
      },
    },
    {
      id: "updated_at",
      accessorKey: "updated_at",
      header: "Updated At",
      cell({ getValue }) {
        const date = new Date(getValue<CoinEarning["updated_at"]>());

        return <TextField value={formatDistanceToNow(date)} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<CoinEarning, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={header.column.setFilterValue}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "actions",
      cell: function render({ row: { original: coin_earning } }) {
        return (
          <Flex columnGap={4}>
            <ShowButton
              hideText
              resource="coin_earnings"
              recordItemId={coin_earning.id}
            />
            {canEdit && (
              <EditButton
                hideText
                resource="coin_earnings"
                recordItemId={coin_earning.id}
              />
            )}
            {canDelete && (
              <DeleteButton
                hideText
                resource="coin_earnings"
                recordItemId={coin_earning.id}
              />
            )}
          </Flex>
        );
      },
      meta: {
        isPlaceholder: true,
      },
    },
  ];

  const updatedColumns = columns.filter(
    (col) => col.id !== "user" || !userOutletCtx?.user
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns: updatedColumns,
    refineCoreProps: {
      resource: userOutletCtx?.user ? "coin_earnings" : undefined,
      filters: {
        permanent: userOutletCtx?.user
          ? [
              {
                field: "user_id",
                operator: "eq",
                value: userOutletCtx?.user?.id,
              },
            ]
          : undefined,
      },
      pagination: {
        pageSize: 50,
      },
    },
  });

  return (
    <List canCreate={canCreate} resource="coin_earnings">
      <AppliedFilters<CoinEarning> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<CoinEarning> headerGroups={getHeaderGroups()} />
          <Rows<CoinEarning> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
