import { usePermissions } from "@refinedev/core";
import { DEFAULT_ROLE, Role } from "entities/user/role";

export default function useRole() {
  const { data: role } = usePermissions<Role>();

  const _role = role || DEFAULT_ROLE

  const hasRole = (...roles: Role[]) => roles.includes(_role)
  const hasNotRole = (...roles: Role[]) => !roles.includes(_role)

  return { role, hasRole, hasNotRole }
}
