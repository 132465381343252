import { User } from "entities/user";

export type LoyaltyOrderProblem = {
  id: number;
  key: Key;
  remote_order_id: string;
  notes: string;
  state: string;
  title: string;
  user: User;
};

export type Key =
  | "xp_earning_not_found_error"
  | "coins_earning_not_found_error"
  | "xp_earning_has_wrong_state"
  | "coins_earning_has_wrong_state_wrong"
  | "xp_earning_all_locks_are_not_released_error"
  | "coins_earning_all_locks_are_not_released_error"
  | "xp_earning_all_locks_are_released_error"
  | "coins_earning_all_locks_are_released_error"
  | "xp_earning_a_lock_is_released_error"
  | "coins_earning_a_lock_is_released_error"
  | "xp_earning_amount_is_incorrect_error"
  | "coins_earning_amount_is_incorrect_error";

export const keys: Key[] = [
  "xp_earning_not_found_error",
  "coins_earning_not_found_error",
  "xp_earning_has_wrong_state",
  "coins_earning_has_wrong_state_wrong",
  "xp_earning_all_locks_are_not_released_error",
  "coins_earning_all_locks_are_not_released_error",
  "xp_earning_all_locks_are_released_error",
  "coins_earning_all_locks_are_released_error",
  "xp_earning_a_lock_is_released_error",
  "coins_earning_a_lock_is_released_error",
  "xp_earning_amount_is_incorrect_error",
  "coins_earning_amount_is_incorrect_error",
];

export const selectKeys = keys.map((key) => {
  return { value: key, label: key.replaceAll("_", " ").toUpperCase() };
});

export const colorsByKey: Record<Key, string> = {
  xp_earning_not_found_error: "blue",
  coins_earning_not_found_error: "pink",
  xp_earning_has_wrong_state: "brown",
  coins_earning_has_wrong_state_wrong: "yellow",
  xp_earning_all_locks_are_not_released_error: "green",
  coins_earning_all_locks_are_not_released_error: "cyan",
  xp_earning_all_locks_are_released_error: "purple",
  coins_earning_all_locks_are_released_error: "red",
  xp_earning_a_lock_is_released_error: "gray",
  coins_earning_a_lock_is_released_error: "green",
  xp_earning_amount_is_incorrect_error: "blue",
  coins_earning_amount_is_incorrect_error: "purple",
};
