import { Select, Textarea, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { isNotEmpty } from "@mantine/form";
import {
  BaseRecord,
  HttpError,
  IResourceComponentsProps,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/mantine";
import ImageDropZone from "components/common/ImageDropZone";
import { CreatePoll } from "entities/poll";
import {
  selectAudiences,
  selectAudienceStatuses,
} from "entities/survey/audience";
import { formatToJst } from "utils/dates";

export const PollCreate: React.FC<IResourceComponentsProps> = () => {
  const { getInputProps, saveButtonProps, setFieldValue, values, errors } =
    useForm<BaseRecord, HttpError, CreatePoll>({
      initialValues: {
        title: "",
        description: "",
        image: "",
        active_from: new Date(),
        active_to: new Date(),
        audience: "tt",
        audience_state: "active",
      },
      transformValues: (values) => {
        return {
          ...values,
          active_from: formatToJst(values.active_from),
          active_to: formatToJst(values.active_to),
        };
      },
      validate: {
        title: isNotEmpty(),
        description: isNotEmpty(),
        active_from: isNotEmpty(),
        active_to: isNotEmpty(),
        audience: isNotEmpty(),
        audience_state: isNotEmpty(),
        image: isNotEmpty(),
      },
    });

  const imageProps = getInputProps("image");

  return (
    <Create saveButtonProps={saveButtonProps}>
      <ImageDropZone
        onFileChosen={(data) => setFieldValue("image", data)}
        value={null}
        error={imageProps.error}
      />
      <TextInput label="Title" {...getInputProps("title")} />
      <Textarea label="Description" {...getInputProps("description")} />
      <Select
        placeholder="Select audience"
        data={selectAudiences}
        label="Audience"
        clearable={false}
        withAsterisk
        {...getInputProps("audience")}
      />
      <Select
        placeholder="Select audience state"
        data={selectAudienceStatuses}
        label="Audience state"
        clearable={false}
        withAsterisk
        {...getInputProps("audience_state")}
      />
      <DatePicker
        placeholder="Pick dates range"
        maw={400}
        label="Active from"
        defaultValue={new Date()}
        withinPortal
        {...getInputProps("active_from")}
      />
      <DatePicker
        placeholder="Pick dates range"
        maw={400}
        label="Active to"
        defaultValue={new Date()}
        withinPortal
        {...getInputProps("active_to")}
      />
    </Create>
  );
};
