import _ from "lodash";

export type Tier = {
  id: number;
  name: "New Account" | "Basic" | "Bronze" | "Silver" | "Gold" | "Deactivated";
  xp_required: number;
  coins_per_dollar: number;
  created_at: Date;
  updated_at: Date;
  users_count: number;
  tier_benefits: Record<TierBenefit["kind"], Partial<TierBenefit>>;
};

export type TierBenefit = {
  kind:
    | "coins_earning_rate"
    | "birthday_bonus"
    | "active_member_discount"
    | "early_access";
  value: string;
  title: string;
  description: string;
};

export type UpdateTier = Pick<Tier, "coins_per_dollar" | "xp_required"> & {
  name?: Tier["name"];
  tier_benefits: Record<TierBenefit["kind"], Partial<TierBenefit>>;
};

export const colorsByTier: Record<Tier["name"], string> = {
  "New Account": "grape",
  Basic: "green",
  Bronze: "orange",
  Silver: "cyan",
  Gold: "yellow",
  Deactivated: "gray"
};

const tierNames: Tier["name"][] = [
  "New Account",
  "Basic",
  "Bronze",
  "Silver",
  "Gold",
];

export const selectTiers = tierNames.map((tierName) => {
  return { value: tierName, label: _.capitalize(tierName) };
});


export const DEFAULT_TIER_BENEFITS: Record<TierBenefit["kind"], TierBenefit> = {
  coins_earning_rate: {
    kind: "coins_earning_rate",
    value: "",
    title: "",
    description: "",
  },
  birthday_bonus: {
    kind: "birthday_bonus",
    value: "",
    title: "",
    description: "",
  },
  active_member_discount: {
    kind: "active_member_discount",
    value: "",
    title: "",
    description: "",
  },
  early_access: {
    kind: "early_access",
    value: "",
    title: "",
    description: "",
  },
};
