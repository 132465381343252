import { useShow } from "@refinedev/core";
import {
  NumberField,
  EmailField,
  DateField,
  TextField,
  BooleanField,
} from "@refinedev/mantine";
import { Title, Image, Badge, Grid, Flex, Anchor } from "@mantine/core";
import { User } from "entities/user";
import { DetailsPair } from "components/common/show/DetailsPair";
import { formatDistanceToNow } from "date-fns";
import { History } from "components/common/show/History";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { colorsByTier } from "entities/tier";

export function UserDetails() {
  const { user } = useOutletContext<{ user: User | null }>();

  return (
    <Grid p="md">
      <Grid.Col span={8}>
        {user?.id && <History<User> resources={"users"} entityId={user.id} />}
      </Grid.Col>
      <Grid.Col span={4}>
        <Flex direction="column" gap="sm">
          <DetailsPair>
            <Title my="xs" order={5}>
              Image
            </Title>
            <Image sx={{ maxWidth: 200 }} src={user?.image} />
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Role
            </Title>
            <Badge color="lime">{user?.role}</Badge>
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Tier
            </Title>
            {user?.tier && (
              <Anchor component={Link} to={`/tiers/show/${user.tier.id}`}>
                <Badge color={colorsByTier[user.tier.name]}>
                  {user?.tier.name}
                </Badge>
              </Anchor>
            )}
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Current XP
            </Title>
            {user?.current_xp && (
              <Badge color="yellow" size="lg">
                {user.current_xp}
              </Badge>
            )}
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Pending XP
            </Title>
            {user?.pending_xp && (
              <Badge color="gray" size="lg">
                {user.pending_xp}
              </Badge>
            )}
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Earned XP
            </Title>
            {user?.earned_xp && (
              <Badge color="blue" size="md">
                {user.earned_xp}
              </Badge>
            )}
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Reducted XP
            </Title>
            {user?.reducted_xp && (
              <Badge color="green" size="md">
                {user.reducted_xp}
              </Badge>
            )}
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Coins Balance
            </Title>
            {user?.usable_coins && (
              <Badge color="yellow" size="lg">
                {user.usable_coins}
              </Badge>
            )}
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Pending Coins
            </Title>
            {user?.pending_coins && (
              <Badge color="gray" size="lg">
                {user.pending_coins}
              </Badge>
            )}
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Earned Coins
            </Title>
            {user?.earned_coins && (
              <Badge color="blue" size="md">
                {user.earned_coins}
              </Badge>
            )}
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Spent Coins
            </Title>
            {user?.spent_coins && (
              <Badge color="green" size="md">
                {user.spent_coins}
              </Badge>
            )}
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Id
            </Title>
            <NumberField value={user?.id ?? ""} />
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Uuid
            </Title>
            <TextField value={user?.uuid} />
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Email
            </Title>
            <EmailField value={user?.email} />
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Created At
            </Title>
            {user?.created_at && (
              <TextField
                value={formatDistanceToNow(new Date(user?.created_at))}
              />
            )}
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Updated At
            </Title>
            {user?.updated_at && (
              <TextField
                value={formatDistanceToNow(new Date(user?.updated_at))}
              />
            )}
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              First Name
            </Title>
            <TextField value={user?.first_name} />
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Last Name
            </Title>
            <TextField value={user?.last_name} />
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Activation State
            </Title>
            <TextField value={user?.activation_state} />
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Activation Token Expires At
            </Title>
            {user?.activation_token_expires_at && (
              <DateField value={user?.activation_token_expires_at} />
            )}
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Competitor
            </Title>
            <BooleanField value={user?.competitor} />
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Facebook Reviewer
            </Title>
            <BooleanField value={user?.facebook_reviewer} />
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Lootly Customer ID
            </Title>
            <TextField value={user?.lootly_customer_id} />
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Shopify Customer Id
            </Title>
            <TextField value={user?.shopify_customer_id} />
          </DetailsPair>
          <DetailsPair>
            <Title my="xs" order={5}>
              Shopify Email
            </Title>
            <EmailField value={user?.shopify_email} />
          </DetailsPair>
        </Flex>
      </Grid.Col>
    </Grid>
  );
}
