import { useSelect } from "@refinedev/core";
import { Select } from "@mantine/core";
import { Poll } from "entities/poll";
import _ from "lodash";

type PollSelectProps = {
  onChange: (pollId: Poll["id"]) => any;
  value?: Poll["id"];
  defaultValue?: Poll["id"];
  label: string;
};

export default function PollSelect({
  onChange,
  value,
  defaultValue,
  label,
}: PollSelectProps) {
  const { options } = useSelect<Poll>({
    resource: "polls",
    optionLabel: "title",
    optionValue: "id",
    debounce: 1000,
    defaultValue: defaultValue?.toString(),
    pagination: {
      pageSize: 10,
      mode: "server",
      current: 1,
    },
    onSearch: (value) => [
      {
        field: "title",
        operator: "contains",
        value,
      },
    ],
  });

  const updatedOptions = options.map((option) => ({
    ...option,
    value: option.value.toString(),
  }));

  return (
    <Select
      label={label}
      placeholder="Select an poll"
      withinPortal
      my="xl"
      clearable
      data={updatedOptions}
      value={value ? value?.toString() : defaultValue?.toString()}
      onChange={(pollId) => pollId && onChange(parseInt(pollId))}
    />
  );
}
