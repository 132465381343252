import _ from "lodash";

export type Audience =
  | "everyone"
  | "tt"
  | "sc"
  | "yt"
  | "nmnl"
  | "shopify_products";

export type AudienceState = "active" | "inactive";

export type AudienceWithState = "tt" | "sc" | "yt" | "nmnl"

export const audiences: Audience[] = [
  "everyone",
  "tt",
  "sc",
  "yt",
  "nmnl",
  "shopify_products",
];

export const audienceStates: AudienceState[] = ["active", "inactive"];

export const audiencesWithState = ["tt", "sc", "yt", "nmnl"];

export const selectAudiences = audiences.map((audience) => ({
  value: audience,
  label: audience.toUpperCase(),
}));

export const selectAudienceStatuses = audienceStates.map((state) => ({
  value: state,
  label: state.toUpperCase(),
}));

export const colorsByAudience: Record<Audience, string> = {
  "everyone": "gray",
  "tt": "orange",
  "sc": "brown",
  "yt": "purple",
  "nmnl": "pink",
  "shopify_products": "green",
}

export const colorsByAudienceState: Record<AudienceState, string> = {
  active: "green",
  inactive: "gray"
}
