import { User } from "entities/user";
import _ from "lodash";

export type SanityChecker = {
  created_at: Date;
  finished_items: number;
  fixing_progress: number;
  id: number;
  issues_count: number;
  status: "checking" | "done_checking" | "fixing" | "done_fixing";
  total_items: number;
  users_scope: "all_users" | "last_ordered" | "single_user";
  user: User;
  check_type: ProblemType;
};

export type ProblemType = "loyalty_order_problem" | "coin_spending_problem";

export const problemTypes: ProblemType[] = [
  "loyalty_order_problem",
  "coin_spending_problem",
];

export const selectProblemTypes = problemTypes.map((problemType) => {
  return {
    value: problemType,
    label: _.capitalize(problemType).replaceAll("_", " "),
  };
});
