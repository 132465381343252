import {
  Flex,
  LoadingOverlay,
  Pagination,
  ScrollArea,
  Select,
  Table,
} from "@mantine/core";
import {
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useParsed,
} from "@refinedev/core";
import { DateField, List, ShowButton } from "@refinedev/mantine";
import { useTable } from "@refinedev/react-table";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

import { DateRangePicker } from "@mantine/dates";
import type { Header } from "@tanstack/react-table";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { PollOptionCard } from "components/poll_options/show/PollOptionCard";
import { UserCard } from "components/users/show/UserCard";
import { PollAnswer } from "entities/poll_answer";
import { PollOption } from "entities/poll_option";

type PollOptionsResponse = PollOption[];

export const PollAnswerList: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParsed();
  const apiUrl = useApiUrl();

  const { data: optionsData, isLoading: isLoadingOptions } =
    useCustom<PollOptionsResponse>({
      url: `${apiUrl}/poll_options/`,
      method: "get",
      queryOptions: {
        enabled: true,
      },
      config: {
        query: {
          poll_id: id,
        },
      },
    });

  const columns: ColumnDef<PollAnswer>[] = [
    {
      id: "id",
      accessorKey: "id",
      header: "Id",
    },
    {
      id: "user",
      accessorKey: "user",
      header: "User",
      cell: ({ getValue }) => {
        const user = getValue<PollAnswer["user"]>();
        if (!user) return <div />;

        return <UserCard user={user} />;
      },
    },
    {
      id: "poll_option",
      accessorKey: "poll_option",
      header: "Poll Option",
      cell: ({ getValue }) => {
        const pollOption = getValue<PollAnswer["poll_option"]>();

        if (!pollOption) return <div />;

        return <PollOptionCard option={pollOption} size="sm" />;
      },
      meta: {
        filterOperator: "eq",
        filterElement: ({ header }: { header: Header<PollAnswer, any> }) => {
          return (
            <div>
              <Select
                placeholder="Poll Option"
                data={
                  optionsData?.data?.map((po) => ({
                    label: po.label,
                    value: po.id.toString(),
                  })) || []
                }
                onChange={header.column.setFilterValue}
                withinPortal
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "created_at",
      accessorKey: "created_at",
      header: "Created At",
      cell: function render({ getValue }) {
        return <DateField value={getValue<any>()} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<PollAnswer, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={header.column.setFilterValue}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "updated_at",
      accessorKey: "updated_at",
      header: "Updated At",
      cell: function render({ getValue }) {
        return <DateField value={getValue<any>()} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<PollAnswer, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={header.column.setFilterValue}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "actions",
      cell: function render({ row: { original: PollAnswer } }) {
        return (
          <Flex columnGap={4}>
            <ShowButton hideText recordItemId={PollAnswer.id} />
          </Flex>
        );
      },
      meta: {
        isPlaceholder: true,
      },
    },
  ];

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      resource: "poll_answers",
      filters: {
        permanent: [{ operator: "eq", field: "poll_id", value: id }],
      },
      pagination: {
        pageSize: 50,
      },
    },
  });

  setOptions((prev) => ({
    ...prev,
    meta: {
      ...prev.meta,
    },
  }));

  return (
    <List
      canCreate={false}
      breadcrumb={null}
      headerProps={{ sx: { display: "none" } }}
    >
      <AppliedFilters<PollAnswer> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<PollAnswer> headerGroups={getHeaderGroups()} />
          <Rows<PollAnswer> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
