import { HttpError, IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/mantine";
import { TextInput, Select, Textarea } from "@mantine/core";
import { CreateUser } from "entities/user";
import { selectRoles } from "entities/user/role";
import { selectActivationStates } from "entities/user/activationState";
import { DatePicker } from "@mantine/dates";
import { formatToJst } from "utils/dates";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const {
    getInputProps,
    saveButtonProps,
    values
  } = useForm<CreateUser, HttpError, CreateUser>({
    initialValues: {
      activation_state: "active",
      bio: "",
      birthday: new Date(),
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      role: "customer",
      shopify_email: "",
      username: "",
    },
    transformValues: (values) => {
      if (!values.birthday) return values

      return {
        ...values,
        birthday: formatToJst(values.birthday)
      }
    }
  });

  const birthday = values.birthday && new Date(values.birthday)
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Select
        placeholder="Role"
        data={selectRoles}
        label="Role"
        clearable
        {...getInputProps("role")}
      />
      <TextInput
        defaultValue=""
        mt="sm"
        label="Email"
        {...getInputProps("email")}
      />
      <TextInput
        mt="sm"
        label="First Name"
        defaultValue=""
        {...getInputProps("first_name")}
      />
      <TextInput
        mt="sm"
        label="Last Name"
        defaultValue=""
        {...getInputProps("last_name")}
      />
      <Select
        placeholder="Activation State"
        data={selectActivationStates}
        label="Activation State"
        clearable
        {...getInputProps("activation_state")}
      />
      <TextInput
        mt="sm"
        defaultValue=""
        label="Shopify Email"
        {...getInputProps("shopify_email")}
      />
      <DatePicker
        mt="sm"
        label="birthday"
        {...getInputProps("birthday")}
        value={birthday || new Date()}
      />
      <Textarea
        mt="sm"
        label="Bio"
        {...getInputProps("bio")}
      />
      <TextInput
        mt="sm"
        defaultValue=""
        label="Password"
        {...getInputProps("password")}
      />
    </Create>
  );
};
