import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Show, NumberField, TextField } from "@refinedev/mantine";
import {
  Text,
  Title,
  Badge,
  Grid,
  Flex,
  Anchor,
  Avatar,
  Image,
  Box,
  Divider,
} from "@mantine/core";
import { Comment } from "entities/comment";
import { DetailsCard } from "components/common/show/DetailsPair";
import { formatDistanceToNow } from "date-fns";
import { History } from "components/common/show/History";
import { Link } from "react-router-dom";
import {
  colorsByCommentableType,
  labelsByCommentableType,
  urlsByCommentableType,
} from "entities/comment/commentable_type";
import { LikeCard } from "components/like/show/LikeCard";
import { IconThumbUp } from "@tabler/icons";

export const CommentShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<Comment>();
  const { data, isLoading } = queryResult;

  const comment = data?.data;
  if (!comment) return <div />;

  return (
    <Show isLoading={isLoading}>
      <Grid>
        <Grid.Col span={8}>
          <Flex gap="sm" direction="column">
            <DetailsCard title="Body">
              <TextField value={comment.body} />
            </DetailsCard>
            <DetailsCard title="Parent">
              {comment.parent_comment_id ? (
                <Anchor
                  component={Link}
                  to={`/comments/show/${comment.parent_comment_id}`}
                >
                  Parent Comment
                </Anchor>
              ) : (
                <Text color="gray">-</Text>
              )}
            </DetailsCard>
            <DetailsCard title="Replies">
              <Flex direction="column" gap="sm">
                {comment.replies.length !== 0 ? (
                  comment.replies.map((reply) => (
                    <CommentCard key={reply.id} comment={reply} />
                  ))
                ) : (
                  <Text color="gray">-</Text>
                )}
              </Flex>
            </DetailsCard>
            <DetailsCard title="Likes">
              <Flex direction="column" gap="sm">
                {comment.likes.length !== 0 ? (
                  comment.likes.map((like) => <LikeCard like={like} />)
                ) : (
                  <Text color="gray">-</Text>
                )}
              </Flex>
            </DetailsCard>
            <History<Comment> resources={"comments"} entityId={comment.id} />
          </Flex>
        </Grid.Col>
        <Grid.Col span={4}>
          <Flex direction="column" gap="sm">
            <DetailsCard title="Author">
              <Flex gap="md">
                <Avatar h={48} w={48} src={comment.user.image} />
                <Flex direction="column">
                  <Title color="yellow" order={3}>
                    {comment.user.last_name}
                    &nbsp;
                    {comment.user.first_name}
                  </Title>
                  <Text italic>{comment.user.email}</Text>
                </Flex>
              </Flex>
            </DetailsCard>
            <DetailsCard title="Id">
              <NumberField value={comment?.id} />
            </DetailsCard>
            <DetailsCard title="Commentable Id">
              <Anchor
                component={Link}
                to={`${urlsByCommentableType[comment.commentable_type]}/show/${comment.commentable_id}`}
              >
                {comment.commentable_id}
              </Anchor>
            </DetailsCard>
            <DetailsCard title="Commentable Type">
              <Badge color={colorsByCommentableType[comment.commentable_type]}>
                {labelsByCommentableType[comment.commentable_type]}
              </Badge>
            </DetailsCard>
            <DetailsCard title="Attached Image">
              {comment.image ? (
                <Image
                  src={comment.image}
                  radius="md"
                  alt="Gift Card Background Image"
                  height={100}
                  width={100}
                  withPlaceholder
                />
              ) : (
                <Text color="gray">No Image</Text>
              )}
            </DetailsCard>
            <DetailsCard title="Created At">
              <TextField
                value={formatDistanceToNow(new Date(comment.created_at))}
              />
            </DetailsCard>
            <DetailsCard title="Updated At">
              <TextField
                value={formatDistanceToNow(new Date(comment.updated_at))}
              />
            </DetailsCard>
          </Flex>
        </Grid.Col>
      </Grid>
    </Show>
  );
};

export function CommentCard({
  comment,
  showReplies,
}: {
  comment: Comment;
  showReplies?: Boolean;
}) {
  const repliesVisible = showReplies && comment.replies.length > 0;
  return (
    <DetailsCard>
      <Flex direction="column">
        <Flex gap="sm" justify="space-between">
          <Flex gap="sm">
            <Avatar src={comment.user.image} size="lg" />
            <Flex direction="column">
              <Text weight="bold">
                {comment.user.first_name}&nbsp;{comment.user.last_name}
              </Text>
              <Text italic>{comment.user.email}</Text>
            </Flex>
          </Flex>
          <Flex direction="column">
            <Text color="gray">{comment.created_at.toString()}</Text>
            <Flex align="center">
              <Text sx={{ marginRight: 4 }}>{comment.likes_count}</Text>
              <IconThumbUp />
            </Flex>
            <Anchor component={Link} to={`/comments/show/${comment.id}`}>
              View Comment
            </Anchor>
          </Flex>
        </Flex>
        <TextField p="sm" value={comment.body} italic />
        {repliesVisible && <Divider />}
        {repliesVisible && (
          <Flex direction="column" px="lg" gap="xs">
            <Title mt="sm" order={4} sx={{ alignSelf: "center" }}>
              Replies
            </Title>
            {comment.replies.map((reply) => (
              <Box key={reply.id} ml="lg" px="lg">
                <CommentCard comment={reply} />
              </Box>
            ))}
          </Flex>
        )}
      </Flex>
    </DetailsCard>
  );
}
