import React from "react";
import { IResourceComponentsProps, useMenu } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, Header } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  LoadingOverlay,
  Image,
  Badge,
  Code,
  Anchor,
  Flex,
  Select,
} from "@mantine/core";
import { DeleteButton, EditButton, List, ShowButton } from "@refinedev/mantine";

import useSurveysPermissions from "./useSurveysPermissions";
import { RewardConfig, Survey } from "entities/survey";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { selectAudiences } from "entities/survey/audience";
import { selectRewardTypes } from "entities/survey/rewardType";
import { DateRangePicker } from "@mantine/dates";

export const SurveyList: React.FC<IResourceComponentsProps> = () => {
  const { canCreate, canEdit, canDelete } = useSurveysPermissions();
  const columns: ColumnDef<Survey>[] = [
    {
      id: "id",
      accessorKey: "id",
      header: "Id",
    },
    {
      id: "image",
      accessorKey: "image",
      header: "Image",
      enableColumnFilter: false,
      cell: ({ getValue }) => {
        const imageUrl = getValue<Survey["image"]>();

        return (
          <Image
            src={imageUrl}
            radius="md"
            alt="Gift Card Background Image"
            height={140}
            width={200}
            withPlaceholder
          />
        );
      },
    },
    {
      id: "title",
      accessorKey: "title",
      header: "Title",
      meta: {
        filterOperator: "contains",
      },
    },
    {
      id: "description",
      accessorKey: "description",
      header: "Description",
      meta: {
        filterOperator: "contains",
      },
    },
    {
      id: "audience",
      accessorKey: "audience",
      header: "Audience",
      cell: ({ getValue }) => {
        const colors: Record<Survey["audience"], string> = {
          tt: "orange",
          yt: "blue",
          sc: "brown",
          nmnl: "red",
          shopify_products: "cyan",
          everyone: "yellow",
        };

        const value = getValue<Survey["audience"]>();

        return (
          <Badge variant="filled" color={colors[value]}>
            {value.toUpperCase()}
          </Badge>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<Survey, any> }) => {
          return (
            <div>
              <Select
                placeholder="Audience"
                data={selectAudiences}
                onChange={header.column.setFilterValue}
                clearable
              />
            </div>
          );
        },
      },
    },

    {
      id: "reward_type",
      accessorKey: "reward_type",
      header: "Reward Type",
      cell: ({ getValue }) => {
        const value = getValue<Survey["reward_type"]>();

        return (
          <Badge variant="filled" color="green">
            {value.toUpperCase()}
          </Badge>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<Survey, any> }) => {
          return (
            <div>
              <Select
                placeholder="Reward type"
                data={selectRewardTypes}
                onChange={header.column.setFilterValue}
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "reward",
      accessorKey: "reward_config",
      header: "Reward",
      enableColumnFilter: false,
      cell: ({ getValue }) => {
        const value = getValue<RewardConfig>();

        if (value["brand"]) {
          return (
            <Badge variant="filled" color="cyan">
              {value["brand"]}
            </Badge>
          );
        }
      },
    },
    {
      id: "coupon",
      accessorKey: "coupon_code",
      header: "Coupon",
      enableColumnFilter: false,
      cell: ({ getValue }) => {
        const value = getValue<Survey["coupon_code"]>();

        return <Code>{value}</Code>;
      },
    },
    {
      id: "active_from",
      accessorKey: "active_from",
      header: "Active From",
      meta: {
        filterElement: ({ header }: { header: Header<Survey, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={header.column.setFilterValue}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "active_to",
      accessorKey: "active_to",
      header: "Active To",
      meta: {
        filterElement: ({ header }: { header: Header<Survey, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={header.column.setFilterValue}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "actions",
      cell: function render({ row: { original: survey } }) {
        return (
          <Flex columnGap={4}>
            <ShowButton hideText recordItemId={survey.id} />
            {canEdit && <EditButton hideText recordItemId={survey.id} />}
            {canDelete && <DeleteButton hideText recordItemId={survey.id} />}
          </Flex>
        );
      },
      meta: {
        isPlaceholder: true,
      },
    },
  ];

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  return (
    <List canCreate={canCreate}>
      <AppliedFilters<Survey> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<Survey> headerGroups={getHeaderGroups()} />
          <Rows<Survey> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
