import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, Header } from "@tanstack/react-table";
import {
  ScrollArea,
  Table,
  Pagination,
  LoadingOverlay,
  Flex,
  Badge,
  Text,
  Avatar,
  Anchor,
  Select,
  NumberInput,
  Code,
} from "@mantine/core";
import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  TextField,
} from "@refinedev/mantine";

import useCoinPermissions from "./useCoinPermissions";
import AppliedFilters from "components/common/list/AppliedFilters";
import Headers from "components/common/list/Headers";
import { Rows } from "components/common/list/Rows";
import { DateRangePicker } from "@mantine/dates";
import { formatDistanceToNow } from "date-fns";
import { CoinSpending } from "entities/coin_spending";
import { colorsBySource, selectSources } from "entities/coin_spending/source";
import { formatToJst } from "utils/dates";
import { Link, useOutletContext } from "react-router-dom";
import { UserOutletContext } from "entities/user";
import { getCouponUrl, getInvoiceUrl } from "utils/chargebee";
import { getDiscountCodeUrl } from "utils/shopify";

export const CoinSpendingList: React.FC<IResourceComponentsProps> = () => {
  const { canEdit, canDelete, canCreate } = useCoinPermissions();
  const userOutletCtx = useOutletContext<UserOutletContext>();

  const columns: ColumnDef<CoinSpending>[] = [
    {
      id: "id",
      accessorKey: "id",
      header: "Id",
    },
    {
      id: "user",
      accessorKey: "user",
      header: "User",
      cell({ getValue }) {
        const user = getValue<CoinSpending["user"]>();

        return (
          <Text size="md" weight="bold">
            <Flex direction="column" align="center">
              <Avatar src={user?.image} size="md" />
              <Text color="white">
                {user?.first_name}
                &nbsp;
                {user?.last_name}
              </Text>
              <Text color="orange">{user?.email}</Text>
            </Flex>
          </Text>
        );
      },
      meta: {
        filterOperator: "eq",
        disableSort: true,
      },
    },
    {
      id: "source",
      accessorKey: "source",
      header: "Source",
      cell: ({ getValue }) => {
        const source = getValue<CoinSpending["source"]>();

        return (
          <Badge variant="light" color={colorsBySource[source]}>
            {source}
          </Badge>
        );
      },
      meta: {
        filterElement: ({ header }: { header: Header<CoinSpending, any> }) => {
          return (
            <div>
              <Select
                placeholder="Source"
                data={selectSources}
                onChange={header.column.setFilterValue}
                clearable
              />
            </div>
          );
        },
      },
    },
    {
      id: "coins_amount",
      accessorKey: "coins_amount",
      header: "Coins Amount",
      cell({ getValue }) {
        const coinsAmount = getValue<CoinSpending["coins_amount"]>();

        return (
          <Badge size="lg" color="pink">
            {coinsAmount}
          </Badge>
        );
      },
      meta: {
        filterOperator: "eq",
        filterElement: ({ header }: { header: Header<CoinSpending, any> }) => {
          return (
            <div>
              <NumberInput
                placeholder="Coin Amount"
                onChange={(v) => header.column.setFilterValue(v?.toString())}
                mx="auto"
                maw={400}
              />
            </div>
          );
        },
      },
    },
    {
      id: "loyalty_reward",
      accessorKey: "loyalty_reward",
      header: "Reward",
      cell({ getValue }) {
        const loyaltyReward = getValue<CoinSpending["loyalty_reward"]>();
        if (!loyaltyReward) return <Text color="gray">--</Text>;

        return (
          <Anchor
            component={Link}
            to={`/loyalty_rewards/show/${loyaltyReward.id}`}
          >
            {loyaltyReward.name}
          </Anchor>
        );
      },
      meta: {
        filterOperator: "eq",
        disableSort: true,
      },
    },
    {
      id: "remote_invoice_id",
      accessorKey: "remote_invoice_id",
      header: "Remote Invoice Id",
      cell({ getValue }) {
        const remoteInvoiceId = getValue<CoinSpending["remote_invoice_id"]>();

        if (!remoteInvoiceId) {
          return <Text color="gray">No value</Text>;
        }

        return (
          <Anchor href={getInvoiceUrl(remoteInvoiceId)} target="_blank">
            {remoteInvoiceId}
          </Anchor>
        );
      },
    },
    {
      id: "remote_reward_id",
      accessorKey: "remote_reward_id",
      header: "Remote Reward Id",
      cell({ getValue, row }) {
        const remoteRewardId = getValue<CoinSpending["remote_reward_id"]>();
        const reward = row.original.loyalty_reward;

        if (!remoteRewardId) {
          return <Text color="gray">No value</Text>;
        }

        return reward.destination === "chargebee" ? (
          <Anchor href={getCouponUrl(remoteRewardId)} target="_blank">
            {remoteRewardId}
          </Anchor>
        ) : (
          <Anchor href={getDiscountCodeUrl(remoteRewardId)} target="_blank">
            {remoteRewardId}
          </Anchor>
        );
      },
    },
    {
      id: "reward_amount_in_usd",
      accessorKey: "reward_amount_in_usd",
      header: "Reward Amount",
      cell({ getValue }) {
        const rewardAmountInUsd =
          getValue<CoinSpending["reward_amount_in_usd"]>();

        if (!rewardAmountInUsd) {
          return <Text color="gray">No value</Text>;
        }

        return (
          <Text size="md" color="blue">
            ${rewardAmountInUsd}
          </Text>
        );
      },
      meta: {
        filterOperator: "eq",
        filterElement: ({ header }: { header: Header<CoinSpending, any> }) => {
          return (
            <div>
              <NumberInput
                placeholder="Reward Amount"
                onChange={(v) => header.column.setFilterValue(v?.toString())}
                mx="auto"
                maw={400}
              />
            </div>
          );
        },
      },
    },
    {
      id: "reward_code",
      accessorKey: "reward_code",
      header: "Reward Code",
      cell({ getValue }) {
        const rewardCode = getValue<CoinSpending["reward_code"]>();

        if (!rewardCode) {
          return <Text color="gray">No value</Text>;
        }

        return <Code>{rewardCode}</Code>;
      },
    },
    {
      id: "notes",
      accessorKey: "notes",
      header: "Notes",
    },
    {
      id: "reward_used_at",
      accessorKey: "reward_used_at",
      header: "Reward Used At",
      cell({ getValue }) {
        const rewardUsedAt = getValue<CoinSpending["reward_used_at"]>();
        if (!rewardUsedAt) {
          return <Text color="gray">No value</Text>;
        }

        const date = new Date(rewardUsedAt);

        return <TextField value={formatDistanceToNow(date)} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<CoinSpending, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={(v) =>
                  header.column.setFilterValue(
                    v.map((date) => date && formatToJst(date)),
                  )
                }
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
        disableSort: true,
      },
    },
    {
      id: "created_at",
      accessorKey: "created_at",
      header: "Created At",
      cell({ getValue }) {
        const date = new Date(getValue<CoinSpending["created_at"]>());

        return <TextField value={formatDistanceToNow(date)} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<CoinSpending, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={(v) =>
                  header.column.setFilterValue(
                    v.map((date) => date && formatToJst(date)),
                  )
                }
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
        disableSort: true,
      },
    },
    {
      id: "updated_at",
      accessorKey: "updated_at",
      header: "Updated At",
      cell({ getValue }) {
        const date = new Date(getValue<CoinSpending["updated_at"]>());

        return <TextField value={formatDistanceToNow(date)} />;
      },
      meta: {
        filterElement: ({ header }: { header: Header<CoinSpending, any> }) => {
          return (
            <div>
              <DateRangePicker
                type="range"
                placeholder="Pick dates range"
                onChange={header.column.setFilterValue}
                mx="auto"
                maw={400}
                withinPortal
              />
            </div>
          );
        },
      },
    },
    {
      id: "actions",
      cell: function render({ row: { original: coin_spending } }) {
        return (
          <Flex columnGap={4}>
            <ShowButton
              hideText
              resource="coin_spendings"
              recordItemId={coin_spending.id}
            />
            {canEdit && (
              <EditButton
                hideText
                resource="coin_spendings"
                recordItemId={coin_spending.id}
              />
            )}
            {canDelete && (
              <DeleteButton
                hideText
                resource="coin_spendings"
                recordItemId={coin_spending.id}
              />
            )}
          </Flex>
        );
      },
      meta: {
        isPlaceholder: true,
      },
    },
  ];

  const updatedColumns = columns.filter(
    (col) => col.id !== "user" || !userOutletCtx?.user,
  );

  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    getFlatHeaders,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData, isFetching },
    },
  } = useTable({
    columns: updatedColumns,
    refineCoreProps: {
      resource: userOutletCtx?.user ? "coin_spendings" : undefined,
      filters: {
        permanent: userOutletCtx?.user
          ? [
              {
                field: "user_id",
                operator: "eq",
                value: userOutletCtx?.user?.id,
              },
            ]
          : undefined,
      },
      pagination: {
        pageSize: 50,
      },
    },
  });

  return (
    <List canCreate={canCreate} resource="coin_spendings">
      <AppliedFilters<CoinSpending> headers={getFlatHeaders()} />
      <ScrollArea>
        <Table highlightOnHover pos="relative">
          <LoadingOverlay visible={isFetching} />
          <Headers<CoinSpending> headerGroups={getHeaderGroups()} />
          <Rows<CoinSpending> rows={getRowModel().rows} />
        </Table>
      </ScrollArea>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
