import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/mantine";
import { MultiSelect, TextInput, Textarea } from "@mantine/core";
import { Category, UpdateCategory } from "entities/category";

type WpCategoriesResponse = {
  wp_categories: [];
};

export const CategoryEdit: React.FC<IResourceComponentsProps> = () => {
  const { getInputProps, saveButtonProps, setFieldValue, values } = useForm<
    Category,
    HttpError,
    UpdateCategory
  >({
    initialValues: {
      name: "",
      description: "",
      wp_categories: [],
    },
  });

  const apiUrl = useApiUrl();

  const { data: wpCategoriesData, isLoading: isLoadingWpCategories } =
    useCustom<WpCategoriesResponse>({
      url: `${apiUrl}/categories/wp_categories`,
      method: "get",
      queryOptions: {
        enabled: true,
      },
    });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <TextInput label="Name" {...getInputProps("name")} />
      <Textarea label="Description" {...getInputProps("description")} />
      <MultiSelect
        placeholder="Category"
        data={(wpCategoriesData?.data.wp_categories as string[] || []).concat(values.wp_categories)}
        label="WP Categories"
        getCreateLabel={(query) => `+ Add ${query}`}
        onCreate={item =>  {
          setFieldValue("wp_categories", values.wp_categories.concat([item]))
          return item
        }}
        searchable
        multiple
        clearable
        creatable
        withinPortal
        {...getInputProps("wp_categories")}
      />
    </Edit>
  );
};
