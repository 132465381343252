import {
  BaseRecord,
  HttpError,
  IResourceComponentsProps,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/mantine";
import { Select, TextInput } from "@mantine/core";
import {
  GiftCardCampaignFormValues,
  GiftCardCampaign,
  UpdateGiftCardCampaign,
  DEFAULT_GIFT_CARD_CAMPAIGN_PLANS,
} from "entities/gift_card_campaign";
import { GiftCardCampaignPlan } from "entities/gift_card_campaign/gift_card_campaign_plan";
import DateTimePicker from "components/common/DateTimePicker";
import { selectBrands } from "entities/gift_card_campaign/brand";
import { GiftCardPlan } from "./GiftCardPlan";
import { Duration, durations } from "entities/gift_card_campaign/duration";
import { useEffect } from "react";
import _ from "lodash";
import { formatToJst } from "utils/dates";

export const GiftCardCampaignEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    values,
    getInputProps,
    saveButtonProps,
    setFieldValue,
    refineCore: { queryResult },
  } = useForm<
    GiftCardCampaign,
    HttpError,
    GiftCardCampaignFormValues,
    UpdateGiftCardCampaign,
    GiftCardCampaignFormValues
  >({
    initialValues: {
      code: "",
      brand: "TT",
      active_from: new Date(),
      active_to: new Date(),
      gift_card_campaign_plans: DEFAULT_GIFT_CARD_CAMPAIGN_PLANS,
    },
    transformValues: (values) => {
      return {
        ...values,
        active_from: formatToJst(values.active_from),
        active_to: formatToJst(values.active_to),
        gift_card_campaign_plans: Object.values(
          values.gift_card_campaign_plans
        ) as Partial<GiftCardCampaignPlan>[]
      }
    },
    refineCoreProps: {
      queryOptions: {
        select: (data) => {
          let gift_card_campaign_plans = _.keyBy(
            data.data.gift_card_campaign_plans,
            (item) => item.duration
          ) as Record<Duration, Partial<GiftCardCampaignPlan>>;

          gift_card_campaign_plans = {
            ...DEFAULT_GIFT_CARD_CAMPAIGN_PLANS,
            ...gift_card_campaign_plans,
          };

          return { data: { ...data.data, gift_card_campaign_plans } };
        },
      },
    },
  });

  useEffect(() => {
    if (queryResult?.data?.data.gift_card_campaign_plans) {
      Object.values(queryResult.data.data.gift_card_campaign_plans).forEach(
        (plan: Partial<GiftCardCampaignPlan>) => {
          // This is because we can have only some plans with values
          if (plan.duration) {
            setFieldValue(
              `gift_card_campaign_plans.${plan.duration}.gift_card_template_id`,
              plan["gift_card_template_id"]
            );
          }
        }
      );
    }
  }, [queryResult?.data?.data.gift_card_campaign_plans]);

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <TextInput label="Code" {...getInputProps("code")} />
      <Select
        placeholder="Brand"
        data={selectBrands}
        label="brand"
        clearable
        {...getInputProps("brand")}
      />
      <DateTimePicker
         label="Active from"
         {...getInputProps("active_from")}
      />
      <DateTimePicker
         label="Active to"
         {...getInputProps("active_to")}
      />
      {durations.map((duration, i) => (
        <GiftCardPlan
          key={duration}
          duration={duration}
          {...getInputProps(
            `gift_card_campaign_plans.${duration}.gift_card_template_id`
          )}
        />
      ))}
    </Edit>
  );
};
